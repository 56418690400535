/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import Button from '../../../../Common/Components/Button';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import PartnerModal from './Components/PartnerModal';
import Loading from '../../../../Common/Components/Loading';
import AccountsPagePartners from './Components/AccountsPagePartners';
import Textbox from '../../../../Common/Components/Textbox';
import { sensitySystemsOrgId } from '../../../../utils/constants';
import { getRequest } from '../../../../utils/fetch';
import { CustomerObject } from '../../../../types/CustomerObject';
import { partnersCustomersFn } from '../../../../utils/ApiDataHelpers';
import Utils from '../../../../utils/Utils';
import { TableHeadersProp } from '../../../../types/TableHeadersProp';
import getHeaderProps from '../../../../utils/getHeaderProps';
import TableToolbar from '../Components/TableToolbar';
import TableToolbarSubtitle from '../Components/TableToolbarSubtitle';

import { ReactComponent as SearchIcon } from '../../../../img/icons/search.svg';

function AccountsPage(): JSX.Element {
  const { data: orderedCustomers, mutate: updateCustomers } = useSWR<Array<CustomerObject> | undefined>(
    ['/customers', 'GetCustomers'],
    (url) => getRequest(url, {}, (data: Array<CustomerObject>): Array<CustomerObject> => {
      const tmpData = data ? [...data] : [];
      tmpData?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return tmpData;
    }),
  );
  const [modalOpen, setModalOpen] = useState(0);
  const [hasSearchResult, setHasSearchResult] = useState(false);
  const createPartner = {
    name: '',
    type: 'partner',
    street1: '',
    street2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    contact_email: '',
    contact_phone: '',
    contact_name: '',
  };

  const { partners, partnerCustomers } = useMemo(() => partnersCustomersFn(orderedCustomers), [orderedCustomers]);

  const [searchCustomer, setSearchCustomer] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState<Map<string, CustomerObject[]>>(partnerCustomers);

  const downloadAccountsCSV = () => {
    const csvHeaders: TableHeadersProp[] = getHeaderProps('AccountsList');
    const csvData: Record<string, string>[] = [];
    filteredCustomers.forEach((customerList, partnerId) => {
      const partnerName = partners.get(partnerId) === undefined ? 'No partner' : partners.get(partnerId)?.name;
      customerList.forEach((customer) => {
        csvData.push({
          name: customer.name,
          type: customer.type || '',
          street1: customer.street1,
          street2: customer.street2,
          city: customer.city,
          state: customer.state,
          postal_code: customer.postal_code,
          country: customer.country,
          contact_email: customer.contact_email,
          contact_phone: customer.contact_phone,
          contact_name: customer.contact_name,
          partner: partnerName || '',
          partner_id: partnerId,
          account_id: customer.orgid,
        });
      });
    });

    Utils.downloadCSV(csvHeaders, csvData, 'accounts');
  };

  useEffect(() => {
    setFilteredCustomers(() => {
      const tmpMap: Map<string, CustomerObject[]> = new Map();
      partnerCustomers.forEach((customers, partnerId) => {
        if (!searchCustomer && customers.length === 0) {
          tmpMap.set(partnerId, []);
        }
        const partnerName = partners.get(partnerId) === undefined ? 'No partner' : partners.get(partnerId)?.name;
        if (customers.length === 0) {
          if (!searchCustomer || partnerName?.toLowerCase().includes(searchCustomer.toLowerCase())) {
            tmpMap.set(partnerId, []);
          } else {
            setHasSearchResult(true);
          }
        }
        customers.forEach((customer) => {
          const customerPartnerId = customer.po === sensitySystemsOrgId ? '' : customer.po || '';
          const tmpArr = tmpMap.get(customerPartnerId) || [];

          if (!searchCustomer || customer.name.toLowerCase().includes(searchCustomer.toLowerCase()) || partnerName?.toLowerCase().includes(searchCustomer.toLowerCase())) {
            tmpMap.set(customerPartnerId, [...tmpArr, customer]);
          } else {
            setHasSearchResult(true);
          }
        });
      });

      return tmpMap;
    });
  }, [partnerCustomers, partners, searchCustomer]);

  const customerCount = useRef(0);
  const partnerComponents: JSX.Element[] = useMemo(() => {
    customerCount.current = 0;
    const tmpArr: JSX.Element[] = [];

    filteredCustomers.forEach((customers: CustomerObject[], partnerId: string) => {
      customerCount.current += customers.length;

      if (partnerId !== '') {
        const partner = partners.get(partnerId) as CustomerObject;
        tmpArr.push(
          <div key={partnerId}>
            <AccountsPagePartners
              partner={partner || {}}
              partners={partners}
              customers={filteredCustomers.get(partnerId)}
              updateCustomers={updateCustomers}
            />
          </div>,
        );
      } else if (partnerId === '' && customers.length) {
        tmpArr.push(
          <div key="0">
            <AccountsPagePartners
              customers={filteredCustomers.get('')}
              updateCustomers={updateCustomers}
            />
          </div>,
        );
      }
    });

    return tmpArr;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCustomers, partners]);

  return (
    <>
      <div className="content content--admin">
        <AdminSidebar title="Account admin">
          <AdminSidebarAdminItems path={window.location.pathname} />
        </AdminSidebar>
        <div className="page-content page-content--medium-margin accounts">
          <TableToolbar
            title="Accounts"
            subtitle={<TableToolbarSubtitle listLength={customerCount.current || 0} download={() => downloadAccountsCSV()} subtitle="Accounts" />}
            addClass="accounts"
          >
            <div className="tabletoolbar__children">
              <Textbox
                value={searchCustomer}
                placeholder="Search"
                onChange={(e) => setSearchCustomer(e.target.value)}
                icon={<SearchIcon />}
                small
              />
              {Utils.isSensityUserAdmin() && (
                <div className="tabletoolbar__children-btn">
                  <Button
                    onClick={() => setModalOpen(1)}
                    label="Create partner"
                    buttonType="secondary"
                  />
                </div>
              )}
            </div>
          </TableToolbar>
          <div className="accounts__partners">
            {filteredCustomers.size > 0
              ? (
                <>
                  {partnerComponents}
                </>
              ) : (<Loading isLoading={!hasSearchResult} />)}
          </div>
        </div>
      </div>
      {modalOpen > 0 && (
      <PartnerModal
        type="create"
        partner={createPartner as CustomerObject}
        setModalOpen={setModalOpen}
        updateCustomers={updateCustomers}
      />
      )}
    </>
  );
}

export default AccountsPage;
