import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequest } from '../../../../../utils/fetch';
import Textbox from '../../../../../Common/Components/Textbox';
import formValidation from '../../../../../utils/form/formValidation';
import { AdminSidebarGenerateAPIKeyProps, ResponseApiKey } from '../../../../../types/AdminSidebarGenerateAPIKeyProps';

import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';

function AdminSidebarHandleAPIKey(props: AdminSidebarGenerateAPIKeyProps): JSX.Element {
  const {
    setOpenModal,
    selectedUser,
    operation,
    path,
    text,
    setCopyActivatedKey,
  } = props;

  const { addNotification } = useAppContext();
  const operationIsGenerate = operation === 'Generate API key';

  const [apiKeyTextbox, setApiKeyTextbox] = useState({
    text: '',
    error: false,
    errorMessage: '',
  });

  return (
    <Modal
      setModalOpen={() => setOpenModal(0)}
      className="admin-user-modal create-api"
      title={operation}
      primaryButtonAction={async () => {
        if (!operationIsGenerate) {
          const validation = formValidation(apiKeyTextbox.text, { required: true });
          if (validation.hasError) {
            setApiKeyTextbox((oldVal) => ({ ...oldVal, error: validation.hasError, errorMessage: validation.errorMsg }));
            return;
          }
        }
        setOpenModal(0);
        if (selectedUser !== undefined) {
          try {
            const data = operationIsGenerate ? { email: selectedUser.email } : { email: selectedUser.email, key: apiKeyTextbox.text };
            postRequest(path, data)
              .then((result) => {
                const apiKey = (result.data as ResponseApiKey).api_key;
                if (!result.error) {
                  if (operationIsGenerate) {
                    setCopyActivatedKey(apiKey);
                    setOpenModal(12);
                  } else {
                    addNotification({
                      type: 'success',
                      message: 'User API key has been successfully deactivated.',
                    });
                  }
                } else {
                  addNotification({ type: 'error', message: `Your "${operation}" operation is failed.` });
                }
              });
          } catch (e) {
            addNotification({ type: 'error', message: `Your "${operation}" operation is failed.` });
          }
        }
      }}
      primaryButtonLabel="Submit"
      secondaryButtonAction={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <>
        <div className="admin-user-modal-content">
          <WarningIcon className="admin-user-modal-content-icon" />
          {text}
        </div>
        {
          !operationIsGenerate && (
          <Textbox
            label="API key"
            placeholder="Enter API key"
            isRequired
            errorMessage="Please enter API key"
            error={apiKeyTextbox.error}
            value={apiKeyTextbox.text}
            onChange={(event) => {
              const newVal = event.target.value;
              const validation = formValidation(apiKeyTextbox.text, { required: true });
              setApiKeyTextbox({ text: newVal, error: newVal.length === 0, errorMessage: validation.errorMsg });
            }}
          />
          )
        }
      </>
    </Modal>
  );
}

export default AdminSidebarHandleAPIKey;
