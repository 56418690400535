import { addDays, subDays, differenceInDays } from 'date-fns';
import React, { useEffect } from 'react';
import { DateRangePickerProps } from '../../types/DateRangePickerProps';
import Datepicker from './Datepicker';

function DateRangePicker(props: DateRangePickerProps): JSX.Element {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    maxDaysRange,
    setDaysDelta,
    type,
    pickerPlacement = 'bottom',
  } = props;

  const now = new Date();
  const minRange = subDays(now, maxDaysRange);
  const maxRange = addDays(now, maxDaysRange);

  useEffect(() => {
    setDaysDelta(differenceInDays(endDate, startDate));
  }, [startDate, endDate, setDaysDelta]);

  return (
    <>
      <Datepicker
        placeholder="Select start date"
        label="Start date"
        selectedDate={startDate}
        setSelectedDate={setStartDate}
        minDate={minRange}
        maxDate={endDate}
        type={type}
        placement={pickerPlacement}
      />
      <Datepicker
        placeholder="Select end date"
        label="End date"
        selectedDate={endDate}
        setSelectedDate={setEndDate}
        minDate={startDate}
        maxDate={maxRange > now ? now : maxRange}
        type={type}
        placement={pickerPlacement}
      />
    </>
  );
}

export default DateRangePicker;
