import React, { useEffect, useState } from 'react';

import SlideIn from '../../../Common/Components/SlideIn';
import Textbox from '../../../Common/Components/Textbox';
import Modal from '../../../Common/Components/Modal';
import Button from '../../../Common/Components/Button';
import SlideinListElementBig from '../Components/SlideinListElementBig';

import { ReactComponent as PinIcon } from '../../../img/icons/pin.svg';
import { NodeObject } from '../../../types/NodeObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Utils from '../../../utils/Utils';
import { postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';

function LightsLocation(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems, updateNodes, gpsPinPosition, setGpsPinPosition = () => undefined } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;

  const { addNotification } = useAppContext();
  const [latitude, setLatitude] = useState(gpsPinPosition?.lat || selectedNode.latitude);
  const [longitude, setLongitude] = useState(gpsPinPosition?.lng || selectedNode.longitude);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (gpsPinPosition && gpsPinPosition.lat && gpsPinPosition.lng && gpsPinPosition.lat !== latitude && gpsPinPosition.lng !== longitude) {
      setLatitude(gpsPinPosition.lat);
      setLongitude(gpsPinPosition.lng);
      setGpsPinPosition(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsPinPosition]);

  return (
    <div className="location">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Location</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content">
            <div className="slide-in__content-title">
              <p>GPS Location</p>
              <p className="slide-in__content-subtitle">
                <span>Updated</span>
                {Utils.getConvertedDate(selectedNode.gpsLastReportedTimestamp) === '' ? '--' : Utils.getConvertedDate(selectedNode.gpsLastReportedTimestamp)}
              </p>
            </div>
            <SlideinListElementBig
              title="Latitude"
              value={selectedNode.latitudeGps}
              valueType="small"
            />
            <SlideinListElementBig
              title="Longitude"
              value={selectedNode.longitudeGps}
              valueType="small"
            />
            <SlideinListElementBig
              title="Sample count"
              value={selectedNode.gpsSampleSize ? selectedNode.gpsSampleSize.toString() : '--'}
              valueType="small"
            />
            <SlideinListElementBig
              title="Precision metric"
              value={selectedNode.gpsAccuracy ? `${selectedNode.gpsAccuracy} m` : '--'}
              border="light"
              valueType="small"
            />
          </div>
        </>
      </SlideIn>
      <SlideIn isExpanded>
        <>
          <div className="slide-in__content location__content">
            <div className="slide-in__title" />
            <div className="location-title__container">
              <p className="location-title__container-title">
                Display location
              </p>
              <p className="location-title__container-subtitle">
                <span>Adjust display location</span>
              </p>
              <div className="location__content-icon-container">
                <span>
                  <PinIcon onClick={() => setGpsPinPosition((oldVal) => {
                    if (oldVal && oldVal.lat && oldVal.lng) {
                      return undefined;
                    }
                    return { lat: latitude, lng: longitude };
                  })}
                  />
                </span>
                <p>
                  Click on the pin, then click on the map to set the location
                  or enter latitude and longitude values below.
                </p>
              </div>
            </div>
            <form action="">
              <Textbox
                label="Latitude"
                value={latitude}
                placeholder="37.583972"
                onChange={(event) => setLatitude(event.target.value)}
                textboxType="long"
                styleType="dark"
              />
              <Textbox
                label="Longitude"
                value={longitude}
                placeholder="-121,998115"
                onChange={(event) => setLongitude(event.target.value)}
                textboxType="long"
                styleType="dark"
              />
            </form>
            {Utils.isNonReadOnly() && (
            <div className="slide-in__link multiple">
              <Button
                onClick={() => {
                  setLatitude(selectedNode.latitudeGps);
                  setLongitude(selectedNode.longitudeGps);
                }}
                label="Reset to GPS"
                extraClasses="margin-right-10"
              />
              <Button onClick={() => setOpenModal(true)} label="Update" />
            </div>
            )}
          </div>
        </>
      </SlideIn>
      {
        openModal && (
          <Modal
            width="325"
            setModalOpen={setOpenModal}
            title="Update display location?"
            subtitle="Show this node at new location?"
            secondaryButtonLabel="Cancel"
            primaryButtonLabel="Submit"
            primaryButtonAction={async () => {
              try {
                const postBody = {
                  nodeid: selectedNode.nodeid,
                  name: selectedNode.name,
                  model: selectedNode.model,
                  latitude,
                  longitude,
                };

                const result = await postRequest(
                  `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${selectedNode.nodeid}`,
                  postBody,
                );

                if (!result.error) {
                  addNotification({ type: 'info', message: 'Your "Update display location" operation is completed.' });
                  setOpenModal(false);

                  if (updateNodes) {
                    setTimeout(() => updateNodes(), 1000);
                  }
                } else {
                  addNotification({ type: 'error', message: `Your "Update display location" operation is failed: ${result.error}` });
                }
              } catch (e) {
                addNotification({ type: 'error', message: 'Your "Update display location" operation is failed.' });
              }
            }}
          />
        )
      }
    </div>
  );
}

export default LightsLocation;
