import React from 'react';

import { ReactComponent as ScrollRightIcon } from '../../../img/icons/scroll-right.svg';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { ReactComponent as OrgGroupIcon } from '../../../img/icons/org-group.svg';

import AutoOpenSelectbox from '../../../Common/Components/AutoOpenSelectbox';
import { AutoOpenSelectboxListType } from '../../../types/AutoOpenSelectboxPropsType';
import { GroupActionsOrgGroupProps } from '../../../types/GroupActionsOrgGroupProps';

function GroupActionsOrgGroup(props: GroupActionsOrgGroupProps): JSX.Element {
  const { selectedNodes, orgGroups, title, comment, selectedOrgGroup, setSelectedOrgGroup } = props;

  const fontSizeThreshold = 6;
  const maxItems = 10;
  const nodesSizeStyle = { fontSize: selectedNodes.size.toString().length > fontSizeThreshold ? '24px' : '32px' };

  return (
    <div className="org-group">
      <span className="org-group__title-name">{title}</span>
      <span style={nodesSizeStyle} className="org-group__title-nodes">{selectedNodes.size}</span>
      <ScrollRightIcon className="icon" />
      <div className="org-group__content">
        <AutoOpenSelectbox
          list={orgGroups}
          label="Select org group"
          maxItems={maxItems}
          selectedItemList={selectedOrgGroup}
          onClick={(selected: AutoOpenSelectboxListType, isSelected: boolean) => {
            if (isSelected) {
              setSelectedOrgGroup(selected);
            }
          }}
        >
          <div className="label">
            <OrgGroupIcon />
            <span className="label__title">Select org group</span>
          </div>
        </AutoOpenSelectbox>
        <div className="org-group__content-info">
          <InfoIcon className="org-group__content-info-icon" />
          <span>{comment}</span>
        </div>
      </div>
    </div>
  );
}

export default GroupActionsOrgGroup;
