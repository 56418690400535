/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import Textbox from '../../../../Common/Components/Textbox';
import Button from '../../../../Common/Components/Button';
import { useAppContext } from '../../../../utils/AppContext';
import { postRequest, getRequest } from '../../../../utils/fetch';

import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import { NodediagnosticsPageProps } from '../../../../types/NodediagnosticsPageProps';
import { ResponseNodeDiagnosticsRealtime } from '../../../../types/NodeDiagnosticsRealtimeProps';

function NodeDiagnosticsPage(props: NodediagnosticsPageProps): JSX.Element {
  const [IMEI, setIMEI] = useState('');
  const [ObjectID, setObjectID] = useState('');
  const [Instance, setInstance] = useState('');
  const [ResourceID, setResourceID] = useState('');
  const [Result, setResult] = useState({});
  const [ResultTitle, setResultTitle] = useState('');
  const [Waiting, setWaiting] = useState(false);
  const [Attempts, setAttempts] = useState(0);

  let count = 0;

  const { addNotification } = useAppContext();
  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  function parseInputData(data: string) {
    const res: string = data
      .replace(/\\+/g, '')
      .replace(/"{/g, '{')
      .replace(/}"/g, '}');

    return JSON.parse(res);
  }

  function doStatic() {
    try {
      getRequest(
        `/diag/node/imei/${IMEI}?timeout=60`,
      ).then((response) => {
        setResultTitle('Static');
        setResult(response);
      });
    } catch (e) {
      addNotification({ type: 'error', message: 'Call failed' });
    }
  }
  function doExternal() {
    try {
      getRequest(
        `/diag/node/devicedetailexternal/nokia/${IMEI}?timeout=60`,
      ).then((response) => {
        setResultTitle('External');
        setResult(response);
      });
    } catch (e) {
      addNotification({ type: 'error', message: 'Call failed' });
    }
  }

  function pollRealtime(requestID) {
    if (count > 12) {
      addNotification({ type: 'info', message: 'No results after 12 attempts' });
      count = 0;
      setAttempts(0);
    } else {
      try {
        getRequest(
          `/diag/node/devicedetailrealtime/${requestID}`,
        ).then((response) => {
          // fetch library turns 204 into empty response
          if (Object.getOwnPropertyNames(response).length === 0) {
            if (count >= 0) {
              count += 1;
              setAttempts(count);
              forceUpdate();
              window.setTimeout(() => { pollRealtime(requestID); }, 4000);
            }
          } else {
            switch (response.status) {
              case 200:
                count = 0;
                setAttempts(count);
                setResultTitle('Realtime');
                setResult(parseInputData(response.data));
                break;
              case 204:
                count += 1;
                setAttempts(count);
                forceUpdate();
                window.setTimeout(() => { pollRealtime(requestID); }, 4000);
                break;
              default:
                addNotification({ type: 'error', message: `API call failed: ${response.status}` });
                count = 0;
                break;
            }
          }
        });
      } catch (e) {
        addNotification({ type: 'error', message: 'Call failed' });
      }
    }
  }

  function doRealtime() {
    let url = `/diag/node/devicedetailrealtime/${IMEI}`;
    if (ObjectID !== '') {
      url = `${url}/${ObjectID}`;
      if (Instance !== '') {
        url = `${url}/${Instance}`;
        if (ResourceID !== '') {
          url = `${url}/${ResourceID}`;
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!confirm('You are about to fetch all available resources for this node, '
        + 'which can take up 30 minutes through Nokia.  Are you sure you want to do this?')) {
        setWaiting(false);
        return;
      }
    }
    try {
      postRequest(url, undefined)
        .then((result) => {
          const requestID = (result.data as ResponseNodeDiagnosticsRealtime).requestid;
          if (result.status === 202) {
            count = 1;
            setAttempts(count);
            forceUpdate();
            window.setTimeout(() => { pollRealtime(requestID); }, 4000);
          } else {
            addNotification({ type: 'error', message: 'Call failed' });
          }
        });
    } catch (e) {
      addNotification({ type: 'error', message: 'Call failed' });
    }
  }

  return (
    <div className="content content--admin">
      <AdminSidebar title="Node diagnostics">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin node-diagnostics">
        <div className="node-diagnostics-imei">
          <Textbox
            label="IMEI"
            isRequired
            value={IMEI}
            onChange={(e) => {
              setIMEI(e.target.value);
            }}
          />
          <div className="node-diagnostics-static">
            <Button
              buttonType="secondary"
              disabled={Waiting}
              onClick={() => {
                if (!Waiting) {
                  setWaiting(true);
                  doStatic();
                  setWaiting(false);
                }
              }}
            >
              <span>Static</span>
            </Button>
          </div>
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                setWaiting(true);
                doExternal();
                setWaiting(false);
              }
            }}
          >
            <span>External</span>
          </Button>
        </div>
        <div className="node-diagnostics-realtime">
          <Textbox
            label="ObjectID"
            value={ObjectID}
            onChange={(e) => {
              setObjectID(e.target.value);
            }}
          />
          <Textbox
            label="Instance"
            value={Instance}
            onChange={(e) => {
              setInstance(e.target.value);
            }}
          />
          <Textbox
            label="ResourceID"
            value={ResourceID}
            onChange={(e) => {
              setResourceID(e.target.value);
            }}
          />
          <Button
            buttonType="secondary"
            disabled={Waiting}
            onClick={() => {
              if (!Waiting) {
                setWaiting(true);
                doRealtime();
                setWaiting(false);
              }
            }}
          >
            <span>{Attempts === 0 ? 'Realtime' : `Polling ${Attempts}/12`}</span>
          </Button>
        </div>
        <div className="node-diagnostics-result">
          <h2>{ResultTitle}</h2>
          <pre>
            {JSON.stringify(Result, undefined, 3)}
          </pre>
        </div>
      </div>
    </div>
  );
}

export default NodeDiagnosticsPage;
