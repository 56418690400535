import React, { useState } from 'react';
import Modal from '../../../Common/Components/Modal';
import RadioButtons from '../../../Common/Components/RadioButtons';
import SelectBox from '../../../Common/Components/SelectBox';
import Textbox from '../../../Common/Components/Textbox';

import { CreateGroupPostProp, CreateGroupProps, LightingGroupStateProp, OrgGroupStateProp } from '../../../types/CreateGroupProps';
import { RadioButtonElementProps } from '../../../types/RadioButtonsProps';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';

import Utils from '../../../utils/Utils';
import { postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';
import formValidation from '../../../utils/form/formValidation';

const selectBoxWidth = 309;

function CreateGroup(props: CreateGroupProps): JSX.Element {
  const { modalOpen, selectedCustomer, selectedSite, scheduleResp, updateGroupsList, isLightingGroupSelected } = props;
  const [isLightingSelected, setIsLightingSelected] = useState(isLightingGroupSelected);
  const [groupType, setGroupType] = useState<RadioButtonElementProps>(
    isLightingSelected
      ? { label: 'Lighting group', key: '1' }
      : { label: 'Org group', key: '2' },
  );

  const { addNotification } = useAppContext();

  const validationRules = { required: true, min: 2, max: 256, noSpecCharacters: true };
  function checkNameValidation(currGroup: LightingGroupStateProp | OrgGroupStateProp, newName?: string) {
    const name = newName !== undefined ? newName : currGroup.name;
    const validation = formValidation(
      name,
      validationRules,
    );

    if (isLightingSelected) {
      setCreateLightingGroup((oldValues) => ({
        ...oldValues,
        name,
        hasError: validation.hasError,
        errorMessage: validation.errorMsg,
      }));
    } else {
      setCreateOrgGroup((oldValues) => ({
        ...oldValues,
        name,
        hasError: validation.hasError,
        errorMessage: validation.errorMsg,
      }));
    }
  }

  const getDefaultSchedule = () => {
    const defaultSchedule = scheduleResp.find((sch) => sch.name === 'Default schedule');
    if (defaultSchedule) {
      return { title: defaultSchedule.name, key: defaultSchedule.scheduleid };
    }
    return { title: '', key: '' };
  };

  const [createLightingGroup, setCreateLightingGroup] = useState<LightingGroupStateProp>({
    name: '',
    description: '',
    schedule: getDefaultSchedule(),
    proximityDimmingProfile: { title: '', key: '' },
    hasError: false,
    errorMessage: '',
  });
  const [createOrgGroup, setCreateOrgGroup] = useState<OrgGroupStateProp>({
    name: '',
    description: '',
    hasError: false,
    errorMessage: '',
  });

  return (
    <div className="group-interactions">
      <Modal
        title="Create new group"
        setModalOpen={modalOpen}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          const currentGroup: (LightingGroupStateProp | OrgGroupStateProp) = isLightingSelected
            ? createLightingGroup
            : createOrgGroup;

          if (currentGroup.name === '') {
            checkNameValidation(currentGroup);
            return;
          }

          if (currentGroup.hasError) {
            return;
          }

          try {
            const postBody: CreateGroupPostProp = {
              name: currentGroup.name,
              description: currentGroup.description,
              type: isLightingSelected ? 'lighting' : 'organizational',
              nodeList: [],
            };

            if (isLightingSelected) {
              postBody.group_type = 'lco';
            }
            if (isLightingGroupSelected) {
              postBody.pdprofiles = [];
              postBody.schedules = [];
            }

            if (isLightingGroupSelected && createLightingGroup.schedule.key) {
              postBody.schedules = [{
                name: createLightingGroup.schedule.title,
                scheduleid: createLightingGroup.schedule.key,
              }];
            }

            const result = await postRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`,
              postBody,
            );

            if (!result.error) {
              if (isLightingSelected) {
                addNotification({ type: 'info', message: 'Your "Create group" operation has started.  You can check the Jobs page for status.' });
              } else {
                addNotification({ type: 'info', message: 'Your "Create group" operation is completed.' });
              }
              updateGroupsList();
            } else {
              addNotification({ type: 'error', message: `Your "Create group" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Create group" operation is failed.' });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => modalOpen(false)}
      >
        <div className="modal-divided">
          <div className="modal-divided__sidebar">
            <RadioButtons
              onClick={(clickedRadioBtn: RadioButtonElementProps) => {
                setIsLightingSelected(clickedRadioBtn.key === '1');
                setGroupType(clickedRadioBtn);
              }}
              selected={groupType}
              list={[{ label: 'Lighting group', key: '1' }, { label: 'Org group', key: '2' }]}
              type="light"
              extraClasses="vertical"
            />
          </div>
          <div className="modal-divided__content">
            {isLightingSelected
              ? (
                <>
                  <div className="modal-divided__content-comment">
                    <div>A lighting group can be controlled by a schedule.</div>
                    <div>A node can belong to only one lighting group.</div>
                  </div>
                  <div className="modal-divided__content-data">
                    <Textbox
                      label="Lighting group name"
                      value={createLightingGroup.name}
                      placeholder="Enter name"
                      onChange={(event) => {
                        const name = event.target.value;
                        checkNameValidation(createLightingGroup, name);
                      }}
                      isRequired
                      error={createLightingGroup.hasError}
                      errorMessage={createLightingGroup.errorMessage}
                      gridTemplateColumns={`auto ${selectBoxWidth}px`}
                    />
                    <Textbox
                      label="Lighting group description"
                      value={createLightingGroup.description}
                      placeholder="Enter description"
                      onChange={(event) => {
                        const description = event.target.value;
                        setCreateLightingGroup((oldValues) => ({ ...oldValues, description }));
                      }}
                      gridTemplateColumns={`auto ${selectBoxWidth}px`}
                    />
                    <SelectBox
                      label="Assign schedule"
                      onClick={(item: SelectBoxItemType) =>
                        setCreateLightingGroup((oldValues) => ({ ...oldValues, schedule: item }))}
                      selectedItemKey={createLightingGroup?.schedule?.key || ''}
                      list={Utils.arrayToSelectbox(scheduleResp || [], 'scheduleid', 'name')}
                      title={createLightingGroup.schedule.title || 'no schedule'}
                      type="light"
                      listWidth={selectBoxWidth}
                    />
                    {/* Proximity dimming profile
                    <SelectBox
                      label="Proximity dimming profile"
                      onClick={(item: SelectBoxItemType) =>
                        setCreateLightingGroup((oldValues) => ({ ...oldValues, proximityDimmingProfile: item }))}
                      selectedItemKey={createLightingGroup?.proximityDimmingProfile?.key || ''}
                      list={Utils.arrayToSelectbox(proximityDimmingProfile || [], 'scheduleid', 'name')}
                      title={createLightingGroup.proximityDimmingProfile.title || 'no proximity dimming profile'}
                      type="light"
                      listWidth={selectBoxWidth}
                    />
                  </div>
                  <div className="modal-divided__content-comment footer-comment">
                    <div>If proximity dimming profile is not selected, the schedule and profiles of the Default group will apply.</div>
                  </div> */}
                  </div>
                </>
              )
              : (
                <>
                  <div className="modal-divided__content-comment">
                    <div>Org groups are collections of nodes defined as needed. A node can belong to more than one org group.</div>
                  </div>
                  <div className="modal-divided__content-data">
                    <Textbox
                      label="Org group name"
                      value={createOrgGroup.name}
                      placeholder="Enter name"
                      onChange={(event) => {
                        const name = event.target.value;
                        checkNameValidation(createOrgGroup, name);
                      }}
                      isRequired
                      error={createOrgGroup.hasError}
                      errorMessage={createOrgGroup.errorMessage}
                      gridTemplateColumns={`auto ${selectBoxWidth}px`}
                    />
                    <Textbox
                      label="Org group description"
                      value={createOrgGroup.description}
                      placeholder="Enter description"
                      onChange={(event) => {
                        const description = event.target.value;
                        setCreateOrgGroup((oldValues) => ({ ...oldValues, description }));
                      }}
                      gridTemplateColumns={`auto ${selectBoxWidth}px`}
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateGroup;
