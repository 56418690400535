import React, { useMemo, useState } from 'react';
import useSWR from 'swr';
import { DateTime } from 'luxon';
import SelectBox from '../../../Common/Components/SelectBox';
import SlideIn from '../../../Common/Components/SlideIn';
import Button from '../../../Common/Components/Button';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { FirmwareObject } from '../../../types/FirmwareObjects';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Utils from '../../../utils/Utils';
import { NodeObject } from '../../../types/NodeObject';
import { firmwareFetcherFn } from '../../../utils/ApiDataHelpers';
import Modal from '../../../Common/Components/Modal';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';

function LightsFirmware(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;

  const { addNotification } = useAppContext();

  const [expand, setExpand] = useState(false);
  const [selectedFirmware, setSelectedFirmware] = useState({ title: 'Select', key: '0' });
  const [updateFirmwareModal, setUpdateFirmwareModal] = useState(false);

  const { data: firmwareResp } = useSWR<Array<FirmwareObject> | undefined>(['/firmwares', 'GetFirmwares'], firmwareFetcherFn);
  const firmwareItem = useMemo(() => firmwareResp?.find((i) => i.release === selectedFirmware.title), [firmwareResp, selectedFirmware.title]);
  const selectedFirmwareByNode = useMemo(() => Utils.getFirmwareByNodeModelName(selectedNode, firmwareResp), [firmwareResp, selectedNode]);
  const updateFirmwareVersions = useMemo(() => {
    if (selectedNode.model === 'lco') {
      return firmwareResp?.filter((fw) => fw.type === 'lco');
    }

    return firmwareResp?.filter((fw) => fw.type !== 'lco');
  }, [selectedNode, firmwareResp]);

  const isSensityUserAdmin = Utils.isSensityUserAdmin();

  return (
    <div className="firmware">
      <SlideIn hasExpandButton={isSensityUserAdmin} expand={expand} setExpand={setExpand}>
        <>
          <div className="slide-in__title">
            <span>App firmware</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content">
            <SlideinListElementBig
              title="Node model"
              value={selectedNode.modelName}
              isFirstElement
              valueType="small"
            />
            <SlideinListElementBig
              title="ID"
              value={selectedFirmwareByNode?.firmwareid || '--'}
              valueType="small"
            />
            <SlideinListElementBig
              title="Release date"
              value={selectedFirmwareByNode?.when ? DateTime.fromISO(selectedFirmwareByNode?.when).toFormat('MM/dd/yyyy') : '--'}
              valueType="small"
            />
            <SlideinListElementBig
              title="Version"
              value={selectedFirmwareByNode?.release || '--'}
              valueType="small"
            />
          </div>
        </>
      </SlideIn>
      {isSensityUserAdmin && expand && (
        <SlideIn
          hasExpandButton={false}
          expand={expand}
          setExpand={setExpand}
          isExpanded
          setExpandClose={setExpand}
        >
          <div className="firmware-expanded">
            <div className="slide-in__title"> </div>
            <div className="slide-in__content">
              <div className="slide-in__content-title">Update firmware</div>
              <div className="slide-in__content-subtitle">Firmware version</div>
              <SelectBox
                type="dark"
                list={(firmwareResp && Utils.arrayToSelectbox(updateFirmwareVersions || [], 'firmwareid', 'release')) || undefined}
                onClick={(item: SelectBoxItemType) => {
                  setSelectedFirmware(item);
                }}
                selectedItemKey={selectedFirmware.key}
                title={selectedFirmware.title}
              />
              <div className="firmware-expanded__subtitle">Firmware details</div>
              <div className="firmware-expanded__information">
                <div>Model name</div>
                <div>{ Utils.getModelName(firmwareItem?.type || '')}</div>
                <div>Firmware ID</div>
                <div>{firmwareItem?.firmwareid || '' }</div>
                <div>Release date</div>
                <div>
                  {firmwareItem?.when !== undefined ? (DateTime.fromISO(firmwareItem?.when).toFormat('MM/dd/yyyy') || '') : '' }
                </div>
                <div>Version</div>
                <div>{firmwareItem?.release || '' }</div>
                <div>Image size</div>
                <div>{firmwareItem?.image_size || '' }</div>
              </div>
              <div className="slide-in__link">
                <Button onClick={() => setUpdateFirmwareModal(true)} label="Update" />
              </div>
            </div>
          </div>
        </SlideIn>
      )}
      {updateFirmwareModal && (
        <Modal
          title="Update app firmware"
          width="360"
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => setUpdateFirmwareModal(false)}
          primaryButtonLabel="Submit"
          primaryButtonAction={async () => {
            try {
              const result = await postRequest(
                `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/firmwares/${selectedFirmware.key}/assign/node/${selectedNode.nodeid}`,
                { version: selectedFirmware.title },
              );

              if (!result.error) {
                addNotification({ type: 'info', message: 'Your "Update firmware" operation is successfully initiated.' });
                setUpdateFirmwareModal(false);
              } else {
                addNotification({ type: 'error', message: `Your "Update firmware" operation is failed: ${result.error}` });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Update firmware" operation is failed.' });
            }
          }}
        >
          <div className="firmware-update__confirm">
            <div className="firmware-update__confirm--title">
              Update node to app firmware version
              {' '}
              {selectedFirmware.title}
              ?
            </div>
            <div className="firmware-update__confirm--content">
              <InfoIcon />
              <div>
                A firmware job will start.
                <br />
                You can monitor the job on the Jobs page.
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LightsFirmware;
