/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { DateTime } from 'luxon';

import { PageComponentProps } from '../../types/PageComponentProps';
import { NodeObject } from '../../types/NodeObject';
import { SiteObject } from '../../types/SiteObject';
import Utils from '../../utils/Utils';
import {
  getScheduleTimelineFn,
  groupsNodesFn,
  groupsScheduleFn,
  nodeExtendedPropsFn,
  nodesFetcherFn,
  schedulesGroupsFn,
} from '../../utils/ApiDataHelpers';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import LightingMap from '../../Common/Components/LightingMap';
import Loading from '../../Common/Components/Loading';
import { GroupObject } from '../../types/GroupObject';
import { ScheduleObject } from '../../types/ScheduleObject';
import ScheduleModal from './Components/ScheduleModal';
import Datepicker from '../../Common/Components/Datepicker';
import ScheduleTimeline from './Components/ScheduleTimeline';
import { ScheduleTimelineObject } from '../../types/ScheduleTimelineProps';
import ScheduleDropdown from './Components/SchedulesDropdown';
import ToolbarButtonContainer from '../../Common/Components/ToolbarButtonContainer';
import ToolbarLink from '../../Common/Components/ToolbarLink';
import LightingGroupScheduleList from './Components/LightingGroupScheduleList';

// icons
import { ReactComponent as CalendarIcon } from '../../img/icons/calendar.svg';
import { ReactComponent as SchedulesIcon } from '../../img/icons/schedule-large.svg';
import { ReactComponent as LightingGroupSheduleIcon } from '../../img/icons/lighting-group.svg';
import { getRequest } from '../../utils/fetch';

const mapZoomLevel = 14;

function SchedulesPage(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;

  const [selectedSchedules, setSelectedSchedules] = useState<Map<string, ScheduleObject>>(new Map());
  const [selectedGroups, setSelectedGroups] = useState<Map<string, GroupObject>>(new Map());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [datepickerVisible, setDatepickerVisible] = useState<boolean>(false);
  const [timelineAnimating, setTimelineAnimating] = useState<boolean>(true);
  const [timelineTime, setTimelineTime] = useState<number>(0);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const formattedDate = useMemo(() => DateTime.fromJSDate(selectedDate).toFormat('yyyy-LL-dd'), [selectedDate]);

  // Site data

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id ? [`/customers/${selectedCustomer.id}/sites`, 'GetSites'] : null);
  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  const sunTimes = useMemo(() => Utils.getSunriseSunset(selectedDate, Utils.getSiteLatLng(site), site?.time_zone || 'America/Los_Angeles'), [selectedDate, site]);

  const [activeTabs, setActiveTabs] = useState(-1);
  const tabSchedules = 1;
  const tabGroups = 2;

  // Nodes

  const { data: nodesResp } = useSWR<Array<NodeObject> | undefined>(
    selectedSite.id
      ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes`, 'GetNodesResp']
      : null,
    nodesFetcherFn,
  );

  // Groups

  const { data: groupsResp, mutate: updateGroups } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);

  // Schedules

  const { data: schedulesResp, mutate: updateSchedules } = useSWR<Array<ScheduleObject>>(
    selectedSite.id ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/schedules`, 'SchedulesPage'] : null,
    (url) => getRequest(url, {}, (data: ScheduleObject[]): ScheduleObject[] => data?.map((schedule) => {
      schedule.events.forEach((event) => {
        if (event.date) {
          event.date = DateTime.fromFormat(event.date as unknown as string, 'yyyy-LL-dd').toJSDate();
        }
      });
      return schedule;
    })),
  );

  const [nodesList, setNodesList] = useState<Map<string, NodeObject>>(new Map());

  const { groupsNodes = {} } = useMemo(() => groupsNodesFn(groupsResp), [groupsResp]);

  const lightingGroupsObjects = useMemo(
    () => groupsResp?.filter((group) => group.type !== 'organizational'),
    [groupsResp],
  );

  const schedulesGroups = useMemo(() => schedulesGroupsFn(schedulesResp), [schedulesResp]);

  const groupsSchedule = useMemo(() => groupsScheduleFn(groupsResp, schedulesResp), [groupsResp, schedulesResp]);

  const defaultGroupDefaultSchedule = useMemo(() => groupsResp?.some((group) => (group.type === 'site-lighting' && group.schedules.some((schedule) => schedule.name === 'Default schedule'))), [groupsResp]);

  useEffect(() => {
    if (nodesResp?.length && Array.isArray(groupsResp)) {
      setNodesList(nodeExtendedPropsFn(nodesResp, groupsNodes));
    }
  }, [nodesResp, groupsResp, groupsNodes]);

  const scheduleTimelines: React.MutableRefObject<ScheduleTimelineObject> = useRef(new Map());
  const filteredNodes: React.MutableRefObject<NodeObject[] | undefined> = useRef();
  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  useEffect(() => {
    if (Array.isArray(nodesResp) && nodesList.size === 0) {
      filteredNodes.current = [];
      forceUpdate();
    } else if (nodesList?.size && Array.isArray(groupsResp) && Array.isArray(schedulesResp)) {
      let filteredGroupIdsArr: string[] = [];

      if (activeTabs === tabSchedules && selectedSchedules.size > 0) {
        selectedSchedules.forEach((schedule) => {
          const scheduleGroups = schedulesGroups.get(schedule.scheduleid);

          if (scheduleGroups?.length) {
            filteredGroupIdsArr = filteredGroupIdsArr.concat(scheduleGroups);
          }
        });
      } else if (activeTabs === tabGroups && selectedGroups.size > 0) {
        filteredGroupIdsArr = Array.from(selectedGroups.keys());
      } else {
        filteredGroupIdsArr = Array.from(groupsSchedule.keys());
      }

      const filteredGroupIds = new Set(filteredGroupIdsArr);

      scheduleTimelines.current = getScheduleTimelineFn(selectedDate, sunTimes, undefined, groupsSchedule, filteredGroupIds);

      filteredNodes.current = Array.from(nodesList.values())
        .reduce((filtered: Array<NodeObject>, node: NodeObject) => {
          if (filteredGroupIds.has(node.lightinggroupid)) {
            filtered.push(node);
          }

          return filtered;
        }, [] as Array<NodeObject>);

      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSchedules,
    selectedGroups,
    selectedDate,
    activeTabs,
    groupsResp,
    nodesList,
  ]);

  const animId = useRef<number>(-1);
  const minuteRound = 5;
  const minsInDay = 1440;
  const animNextFrameMs = 50;

  useEffect(() => {
    if (mapLoaded) {
      let last = 0;

      const animate = (now: number): void => {
        if (!last || now - last >= animNextFrameMs) {
          last = now;
          setTimelineTime((oldVal) => {
            const newVal = oldVal + minuteRound;
            return newVal >= minsInDay ? 0 : newVal;
          });
        }

        animId.current = window.requestAnimationFrame(animate);
      };

      if (timelineAnimating) {
        animId.current = window.requestAnimationFrame(animate);
      } else {
        window.cancelAnimationFrame(animId.current);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineAnimating, mapLoaded]);

  // Create / Edit schedule modals
  const newSchedule: ScheduleObject = {
    name: '',
    description: '',
    events: [
      {
        actions: [],
        // these 3 below never changes
        photocell_enabled: false,
        photocell_highLevel: 100,
        photocell_lowLevel: 0,
      },
    ],
    groups: [],
    network: {
      highLevel: 60,
      highTime: '18:00:00',
      photocell_enabled: true,
      photocell_highLevel: 100,
      photocell_lowLevel: 0,
    },
    nodes: [],
    scheduleid: '',
    sites: [],
  };
  const [activeSchedule, setActiveSchedule] = useState<ScheduleObject>(newSchedule);
  const [openScheduleModal, _setOpenScheduleModal] = useState(0);
  const setOpenScheduleModal = (newVal: number) => {
    setTimelineAnimating(false);

    if (newVal === 0) {
      setActiveSchedule(newSchedule);
    }

    _setOpenScheduleModal(newVal);
  };

  return (
    <>
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Schedule simulator" subtitle={selectedSite.name} />
          </div>
        </div>
        <ToolbarButtonContainer>
          <ToolbarLink
            title="Schedules"
            subtitleStyle="light"
            subtitle="schedules"
            subtitleNum={selectedSchedules.size === 0 ? 'All' : selectedSchedules.size}
            subtitleText="schedule"
            onclick={() => (activeTabs === tabSchedules ? setActiveTabs(0) : setActiveTabs(tabSchedules))}
            tabPanelisActive={activeTabs}
            order={tabSchedules}
            icon={<SchedulesIcon />}
            addClass="schedule-toolbar"
          >
            <ScheduleDropdown
              handleActiveToolbar={setActiveTabs}
              schedules={schedulesResp}
              setSelectedSchedules={setSelectedSchedules}
              selectedSchedules={selectedSchedules}
              selectedDate={formattedDate}
              setOpenScheduleModal={setOpenScheduleModal}
              setActiveSchedule={setActiveSchedule}
              newSchedule={newSchedule}
              defaultGroupDefaultSchedule={defaultGroupDefaultSchedule}
            />
          </ToolbarLink>
          <ToolbarLink
            title="Lighting groups"
            subtitleStyle="light"
            subtitle="groups"
            subtitleNum={selectedGroups.size === 0 ? 'All' : selectedGroups.size}
            subtitleText="group"
            onclick={() => (activeTabs === tabGroups ? setActiveTabs(0) : setActiveTabs(tabGroups))}
            tabPanelisActive={activeTabs}
            order={tabGroups}
            icon={<LightingGroupSheduleIcon />}
            addClass="schedule-toolbar"
          >
            <LightingGroupScheduleList
              handleActiveToolbar={setActiveTabs}
              groups={lightingGroupsObjects}
              selectedGroups={selectedGroups}
              setSelectedGroups={setSelectedGroups}
              groupsSchedule={groupsSchedule}
              selectedDate={formattedDate}
              schedules={schedulesResp}
              updateSchedules={() => {
                updateGroups();
                updateSchedules();
              }}
              setActiveSchedule={setActiveSchedule}
              setOpenScheduleModal={setOpenScheduleModal}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
            />
          </ToolbarLink>
        </ToolbarButtonContainer>
      </Toolbar>
      { openScheduleModal > 0 && (
      <ScheduleModal
        selectedCustomer={selectedCustomer}
        selectedSite={selectedSite}
        schedules={schedulesResp}
        activeSchedule={activeSchedule}
        openScheduleModal={openScheduleModal}
        setOpenScheduleModal={setOpenScheduleModal}
        updateSchedules={updateSchedules}
      />
      )}
      <div style={{ height: 'calc(100% - 70px)' }}>
        {site ? (
          <LightingMap
            id="SchedulesPageMap"
            zoom={mapZoomLevel}
            center={Utils.getSiteLatLng(site)}
            data={filteredNodes.current}
            scheduleTimelines={scheduleTimelines.current || undefined}
            timelineAnimating={timelineAnimating}
            timelineTime={timelineTime}
            setMapLoaded={setMapLoaded}
            featuresEnabled={{ layersSelectionControl: false, scheduleTimeline: true }}
          />
        ) : (<Loading />)}
      </div>
      <div className="schedule-footer">
        <div className="schedule-footer__datepicker">
          <button type="button" className="schedule-footer__button" onClick={() => setDatepickerVisible(!datepickerVisible)}>
            <CalendarIcon />
            <div>
              <span>{DateTime.fromJSDate(selectedDate).toFormat('EEEE,')}</span>
              <span>{DateTime.fromJSDate(selectedDate).toFormat('MMMM d, yyyy')}</span>
            </div>
          </button>
          {datepickerVisible && (
            <Datepicker
              selectedDate={selectedDate}
              setSelectedDate={(newDate: Date) => {
                setSelectedDate(newDate);
                setDatepickerVisible(false);
              }}
              minDate={new Date()}
              inline
            />
          )}
        </div>
        <div className="schedule-footer__timeline">
          {site && (
          <ScheduleTimeline
            scheduleTimelines={scheduleTimelines.current || undefined}
            sunTimes={sunTimes}
            timelineAnimating={timelineAnimating}
            setTimelineAnimating={setTimelineAnimating}
            timelineTime={timelineTime}
            setTimelineTime={setTimelineTime}
          />
          )}
        </div>
      </div>
    </>
  );
}

export default SchedulesPage;
