/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useRef, useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { ScheduleTimelineProps } from '../../../types/ScheduleTimelineProps';
import Utils from '../../../utils/Utils';

import { ReactComponent as PlayIcon } from '../../../img/icons/play-circle.svg';
import { ReactComponent as PauseIcon } from '../../../img/icons/pause-circle.svg';
import { ReactComponent as SunriseIcon } from '../../../img/icons/sunrise-half.svg';
import { ReactComponent as SunsetIcon } from '../../../img/icons/sunset-half.svg';
import { ReactComponent as TimelineButttonIcon } from '../../../img/icons/schedule-timeline-button.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import { ScheduleTimelineBarObject } from '../../../types/global';

function ScheduleTimeline(props: ScheduleTimelineProps): JSX.Element {
  const { sunTimes, timelineAnimating, setTimelineAnimating, timelineTime, setTimelineTime } = props;

  const [timelineBarHover, setTimelineBarHover] = useState<ScheduleTimelineBarObject>({ minMaxMinute: 0, timeStr: '', mouseX: -1 });

  const minsInDay = 1440;
  const hoursInDay = 24;
  const percent = 100;

  const playbarIconPositionPercent = (timelineTime / minsInDay) * percent;
  const sunrisePercent = useMemo(() => (sunTimes.sunrise / minsInDay) * percent, [sunTimes]);
  const sunsetPercent = useMemo(() => (sunTimes.sunset / minsInDay) * percent, [sunTimes]);
  const hourPercent = percent / hoursInDay;

  const hours = useRef(Utils.getHours());

  const calcMinMaxMinute = (event: React.MouseEvent<HTMLElement>): ScheduleTimelineBarObject | undefined => {
    const target = (event.target as HTMLElement).closest('.schedule-footer__timeline--bar') as HTMLElement;

    if (!target) {
      return undefined;
    }

    const margin = 20;
    const minuteRound = 5;

    const rect = target.getBoundingClientRect();
    const barLength = rect.right - rect.left - margin;
    const mouseX = event.clientX - rect.left;
    const minute = minsInDay * (mouseX / barLength);
    const roundedMinute = Math.round(minute / minuteRound) * minuteRound;
    const minMaxMinute = Math.min(Math.max(roundedMinute, 0), minsInDay);
    const duration = Duration.fromObject({ minutes: minMaxMinute });
    const [durHour, durMin] = duration.toFormat('hh:mm').split(':');
    const timeStr = DateTime.fromObject({ hour: parseInt(durHour, 10), minute: parseInt(durMin, 10) }).toFormat('h:mma').toLowerCase();

    return {
      minMaxMinute,
      timeStr,
      mouseX,
    };
  };

  const everySecond = 2;

  return (
    <>
      <button type="button" className="schedule-footer__timeline--play" onClick={() => setTimelineAnimating(!timelineAnimating)}>
        {timelineAnimating ? (<PauseIcon />) : (<PlayIcon />)}
      </button>
      <div
        className="schedule-footer__timeline--bar"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          const minMaxMinute = calcMinMaxMinute(event)?.minMaxMinute;

          if (minMaxMinute) {
            setTimelineTime(minMaxMinute);
          }
        }}
        onMouseMove={(event: React.MouseEvent<HTMLElement>) => {
          const minMaxMinuteObj = calcMinMaxMinute(event);

          if (minMaxMinuteObj) {
            setTimelineBarHover(minMaxMinuteObj);
          }
        }}
        onMouseLeave={() => setTimelineBarHover({ minMaxMinute: 0, timeStr: '', mouseX: -1 })}
      >
        <div className="timeline-playbar">
          <div style={{ width: `${playbarIconPositionPercent}%` }} />
          <TimelineButttonIcon style={{ left: `calc(${playbarIconPositionPercent}% - 8px)` }} />
        </div>
        <div
          className="timeline-gradientbar"
          style={{
            background: `#FFFFFF linear-gradient(90deg, #0088CE08 0%, #0E164159 0%, #10194959 ${sunrisePercent}%, #0088CE1A ${sunrisePercent + hourPercent}%, #0088CE14 ${sunrisePercent + ((sunsetPercent - sunrisePercent) / everySecond)}%, #0088CE1A ${sunsetPercent - hourPercent}%, #10194959 ${sunsetPercent}%, #0E164159 100%) 0% 0% no-repeat padding-box`,
          }}
        >
          <SunriseIcon style={{ left: `calc(${sunrisePercent}% - 9px)` }} />
          <SunsetIcon style={{ left: `calc(${sunsetPercent}% - 9px)` }} />
          {Array.from(Array(hoursInDay).keys()).map((i) => <div key={`timeline-hour-${i}`} className="timeline-hour-bar" style={{ left: `${i * hourPercent}%` }} />)}
          <div key="timeline-hour-24" className="timeline-hour-bar" style={{ right: 0 }} />
          <div className="timeline-sunrise-sunset-bar" style={{ left: `${sunrisePercent}%` }} />
          <div className="timeline-sunrise-sunset-bar" style={{ left: `calc(${sunrisePercent}% + ${hourPercent}%)` }} />
          <div className="timeline-sunrise-sunset-bar" style={{ left: `calc(${sunsetPercent}% - ${hourPercent}%` }} />
          <div className="timeline-sunrise-sunset-bar" style={{ left: `${sunsetPercent}%` }} />
          {timelineBarHover.mouseX > -1 && <Tooltip showAlways text={timelineBarHover.timeStr} addStyle={{ left: `calc(${(timelineBarHover.minMaxMinute / minsInDay) * percent}% - 1px)` }}><div className="timeline-gradientbar--hover" /></Tooltip>}
        </div>
        <div className="timeline-clock">
          {hours.current.map((hour, i) => <div key={`timeline-clock-${hour.key}`} style={{ left: i === 0 ? 0 : `calc(${i * hourPercent}% - 12px)` }}>{hour.title}</div>)}
          <div key="timeline-clock-24" style={{ right: 0 }}>12am</div>
        </div>
      </div>
    </>
  );
}

export default ScheduleTimeline;
