import React, { ChangeEvent, useState } from 'react';
import { ListRowProps } from 'react-virtualized';
import Searchbox from '../../../Common/Components/Searchbox';
import SlideIn from '../../../Common/Components/SlideIn';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import LightsGroupsMoveNodeToLightingGroupModal from './Components/LightsGroupsMoveNodeToLightingGroupModal';
import LightsGroupsRemoveFromOrgGroup from './Components/LightsGroupsRemoveFromOrgGroup';
import LightsGroupsAddNodeToOrgGroup from './Components/LightsGroupsAddNodeToOrgGroup';
import Button from '../../../Common/Components/Button';
import { GroupObject } from '../../../types/GroupObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { LightsGroupsItem } from '../../../types/LightsGroupProps';
import { NodeObject } from '../../../types/NodeObject';
import Utils from '../../../utils/Utils';

import { useAppContext } from '../../../utils/AppContext';

import { ReactComponent as CheckIcon } from '../../../img/icons/large-checkmark.svg';
import { ReactComponent as RemoveIcon } from '../../../img/icons/close-large.svg';

const listWidth = 185;
const listRowHeight = 41;

function LightsGroups(props: SlideInPanelPropsType): JSX.Element {
  const { groups, selectedItems, groupsNodes, lightingGroups, orgGroups, selectedCustomer, selectedSite, updateGroup } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;

  const isNonReadOnly = Utils.isNonReadOnly();
  const { addNotification } = useAppContext();

  const nodeOrgGroups = groupsNodes[selectedNode.nodeid].orggroups;
  const nodeLightingGroup = groupsNodes[selectedNode.nodeid].lightinggroup;

  const displayedLightsGroups = lightingGroups?.filter(
    (lightingGroup: LightsGroupsItem) => lightingGroup.id !== nodeLightingGroup.id,
  );

  const displayedOrgGroups = orgGroups?.filter(
    (orgGroup: LightsGroupsItem) => !nodeOrgGroups.map(
      (nodeOrgGroup: LightsGroupsItem) => nodeOrgGroup.id,
    ).includes(orgGroup.id),
  );

  const getSelectedSchedule = groups.filter((group: GroupObject) =>
    group.groupid === nodeLightingGroup.id)[0]?.schedules?.map((schedule: { name: string; scheduleid: string }) => schedule.name)?.join(', ') || '';

  const [filteredRemoveOrgGroup, setFilteredRemoveOrgGroup] = useState(nodeOrgGroups);
  const [searchRemoveOrgGroup, setSearchRemoveOrgGroup] = useState('');
  const handleRemoveOrgGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchRemoveOrgGroup(event.target.value);
    setFilteredRemoveOrgGroup(nodeOrgGroups.filter((item: LightsGroupsItem) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase())));
  };

  const [filteredAddOrgGroup, setFilteredAddOrgGroup] = useState(displayedOrgGroups);
  const [searchAddOrgGroup, setSearchAddOrgGroup] = useState('');
  const handleAddOrgGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAddOrgGroup(event.target.value);
    setFilteredAddOrgGroup(displayedOrgGroups?.filter((item: LightsGroupsItem) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase())));
  };

  const [filteredMoveLightingGroups, setFilteredMoveLightingGroups] = useState(displayedLightsGroups);
  const [searchMoveLightingGroups, setSearchMoveLightingGroups] = useState('');
  const handleMoveLightingGroupsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchMoveLightingGroups(event.target.value);
    setFilteredMoveLightingGroups(displayedLightsGroups?.filter((item: LightsGroupsItem) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase())));
  };

  const [selectedLightingGroup, setSelectedLightingGroup] = useState<LightsGroupsItem>();
  const [modalOpen, setModalOpen] = useState(0);

  const modalMoveToLightingGroup = 1;
  const modalRemoveFromOrgGroup = 2;
  const modalAddToOrgGroups = 3;

  function moveToLightingGroup({ key, index, style }: ListRowProps) {
    if (!filteredMoveLightingGroups) {
      return <></>;
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        key={key}
        style={{ ...style, cursor: 'pointer' }}
        className={`org-group-list ${selectedLightingGroup?.id === filteredMoveLightingGroups[index].id ? 'selected' : ''}`}
        onClick={() => {
          setSelectedLightingGroup(filteredMoveLightingGroups[index]);
        }}
      >
        <span
          style={{
            fontSize: '14px',
          }}
        >
          {filteredMoveLightingGroups[index].name}
        </span>
      </div>
    );
  }

  const [removeFromOrgGroup, setRemoveFromOrgGroup] = useState<LightsGroupsItem>();
  function renderRemoveOrgGroups({ key, index, style }: ListRowProps) {
    return (
      <div
        key={key}
        style={{ ...style }}
        className="org-group-list"
      >
        <span style={{ fontSize: '14px', color: '#FFBC3D' }}>
          {filteredRemoveOrgGroup[index].name}
        </span>
        <span className="org-group-list__icon">
          <RemoveIcon onClick={() => {
            setModalOpen(modalRemoveFromOrgGroup);
            setRemoveFromOrgGroup(filteredRemoveOrgGroup[index]);
          }}
          />
        </span>
      </div>
    );
  }

  const [addToOrgGroups, setAddToOrgGroups] = useState<LightsGroupsItem>();
  function renderAllOrgGroups({ key, index, style }: ListRowProps) {
    if (!filteredAddOrgGroup) {
      return <></>;
    }

    return (
      <div
        key={key}
        style={{ ...style, backgroundColor: '#333' }}
        className="org-group-list"
      >
        <span>
          {filteredAddOrgGroup[index].name}
        </span>
        <span className="org-group-list__icon">
          <CheckIcon onClick={() => {
            setModalOpen(modalAddToOrgGroups);
            setAddToOrgGroups(filteredAddOrgGroup[index]);
          }}
          />
        </span>
      </div>
    );
  }

  const rowNumber = 7;
  const showSearchInputThreshold = 12;

  return (
    <div className="groups">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Lighting Group</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content">
            <div className="slide-in__first-section">
              <div className="margin-top-15">
                <SlideinListElementBig
                  title="Lighting group name"
                  value={groupsNodes[selectedNode.nodeid]?.lightinggroup.name}
                  valueType="small"
                />
              </div>
              <SlideinListElementBig
                title="Schedule"
                value={getSelectedSchedule}
                valueType="small"
              />
            </div>
            {isNonReadOnly && (
            <div>
              <div className="slide-in__content-title slide-in__content-title--bigger-margin">
                Move to lighting group
              </div>
              <Searchbox
                type="dark"
                title="Find"
                list={filteredMoveLightingGroups || []}
                listWidth={listWidth}
                listRowHeight={listRowHeight}
                searchValue={searchMoveLightingGroups}
                listRowRenderer={moveToLightingGroup}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleMoveLightingGroupsChange(e)}
                alwaysOpen
                rowNumber={rowNumber}
              />
              <div className="slide-in__link">
                <Button
                  onClick={() => setModalOpen(modalMoveToLightingGroup)}
                  label="Submit"
                />
              </div>
            </div>
            )}
          </div>
        </>
      </SlideIn>
      <SlideIn isExpanded>
        <>
          <div className="slide-in__title">
            <span>Org Groups</span>
          </div>
          <div className="slide-in__content">
            <div className="">
              <div className="slide-in__subtitle">
                Member of
                <span className="slide-in__subtitle--num">{selectedNode.orggroup === '' ? '0' : selectedNode.orggroup.split(', ').length}</span>
                <span className="slide-in__subtitle--bold"> org groups</span>
              </div>
              <Searchbox
                type="dark"
                list={filteredRemoveOrgGroup}
                title="Find"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleRemoveOrgGroupChange(e)}
                searchValue={searchRemoveOrgGroup}
                listRowRenderer={renderRemoveOrgGroups}
                listWidth={listWidth}
                listRowHeight={listRowHeight}
                alwaysOpen
                showInput={filteredRemoveOrgGroup.length >= showSearchInputThreshold}
              />
            </div>
            {isNonReadOnly && (
            <>
              <div className="slide-in__content-title slide-in__content-title--bigger-margin">
                Add to org group
              </div>
              <Searchbox
                type="dark"
                list={filteredAddOrgGroup || []}
                title="Find"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleAddOrgGroupChange(e)}
                searchValue={searchAddOrgGroup}
                listRowRenderer={renderAllOrgGroups}
                listWidth={listWidth}
                listRowHeight={listRowHeight}
                alwaysOpen
              />
            </>
            )}
          </div>
        </>
      </SlideIn>
      {modalOpen === modalMoveToLightingGroup && (
        <LightsGroupsMoveNodeToLightingGroupModal
          setModalOpen={setModalOpen}
          selectedNode={selectedNode}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          addNotification={addNotification}
          updateGroup={updateGroup}
          selectedLightingGroup={selectedLightingGroup}
        />
      )}
      {modalOpen === modalRemoveFromOrgGroup && (
        <LightsGroupsRemoveFromOrgGroup
          setModalOpen={setModalOpen}
          selectedNode={selectedNode}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          addNotification={addNotification}
          updateGroup={updateGroup}
          orgGroups={orgGroups}
          removeFromOrgGroup={removeFromOrgGroup}
        />
      )}
      {modalOpen === modalAddToOrgGroups && (
        <LightsGroupsAddNodeToOrgGroup
          setModalOpen={setModalOpen}
          selectedNode={selectedNode}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          addNotification={addNotification}
          updateGroup={updateGroup}
          orgGroups={orgGroups}
          addToOrgGroups={addToOrgGroups}
        />
      )}
    </div>
  );
}

export default LightsGroups;
