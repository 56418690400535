import React, { useEffect, useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import Textbox from '../../../../../Common/Components/Textbox';
import Checkbox from '../../../../../Common/Components/Checkbox';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import { AdminEditUserProps, EditUserStateProp, EditUserPostProp } from '../../../../../types/AdminEditUserProps';
import Utils from '../../../../../utils/Utils';
import { postRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import formValidation from '../../../../../utils/form/formValidation';

function AdminSidebarEditUser(props: AdminEditUserProps): JSX.Element {
  const { modalOpen, updateCustomers, editUser } = props;
  const { addNotification } = useAppContext();
  const [editSelectedUser, setEditSelectedUser] = useState<EditUserStateProp>({
    username: editUser?.email || '',
    partner: editUser?.partner || '',
    account: editUser?.account || '',
    roles: { title: editUser?.rolesTitle || editUser?.roles, key: editUser?.roles },
    firstname: editUser?.name.split(' ')[0] || '',
    lastname: editUser?.name.split(' ')[1] || '',
    mobile: editUser?.phone || '1',
    mfa: (editUser?.mfa === 'Enabled') || false,
    mobileError: false,
    mobileErrorMessage: '',
  });

  const selectBoxWidth = 300;

  const [rolesFilter, setRolesFilter] = useState([{ title: 'None', key: '0' }]);

  const checkPhoneValidation = (newName?: string) => {
    if (newName === '' || !newName?.startsWith('1')) {
      // eslint-disable-next-line no-param-reassign
      newName = `1${newName}`;
    }
    const mobile = newName !== undefined ? newName : editSelectedUser.mobile;
    const validation = formValidation(
      mobile,
      { phone: true },
    );
    setEditSelectedUser((oldValues) => ({
      ...oldValues,
      mobile,
      mobileError: validation.hasError,
      mobileErrorMessage: validation.errorMsg,
    }));
  };
  const checkMobileSelectedValidation = (checked?: boolean) => {
    const validationError = editSelectedUser.mobile === '' || editSelectedUser.mobile === '1';
    const validationErrorMsg = validationError ? 'Enter mobile number for two-factor authentication.' : '';
    if (checked) {
      const mfa = !editSelectedUser.mfa;
      setEditSelectedUser((oldValues) => ({
        ...oldValues,
        mfa,
        mobileError: validationError,
        mobileErrorMessage: validationErrorMsg,
      }));
    } else {
      const mfa = !editSelectedUser.mfa;
      setEditSelectedUser((oldValues) => ({
        ...oldValues,
        mfa,
        mobileError: false,
        mobileErrorMessage: '',
      }));
    }
  };

  useEffect(() => {
    if (editUser.roledetails === 'sensityroles') {
      setRolesFilter(Utils.getUserRolesByType('sensity'));
    } else if (editUser.roledetails === 'partnerroles') {
      setRolesFilter(Utils.getUserRolesByType('partner'));
    } else {
      setRolesFilter(Utils.getUserRolesByType('account'));
    }
  }, [
    editUser,
  ]);

  return (
    <div className="user-interactions edit-user">
      <Modal
        className="modal"
        title="Edit user"
        setModalOpen={modalOpen}
        primaryButtonLabel="Save"
        primaryButtonAction={async () => {
          const currentUser = editSelectedUser;
          if (currentUser.mobileError) {
            return;
          }
          try {
            const phone = editSelectedUser.mobile === '' || editSelectedUser.mobile === '1' ? '' : editSelectedUser.mobile;
            const postBody: EditUserPostProp = {
              email: editSelectedUser.username,
              roles: editSelectedUser.roles.key,
              name: `${editSelectedUser.firstname} ${editSelectedUser.lastname}`.trimEnd(),
              phone,
              mfa: editSelectedUser.mfa,
            };
            const result = await postRequest(
              `/customers/${editUser.accountid}/users/${editUser.userid}`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Edit user" operation is completed.' });
              if (updateCustomers) {
                updateCustomers();
              }
            } else {
              addNotification({ type: 'error', message: `Your "Edit user" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Edit user" operation is failed.' });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        width="710"
        secondaryButtonAction={() => modalOpen(false)}
      >
        <div className="modal-divided">
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              <Textbox
                label="Partner"
                name="Partner"
                value={editSelectedUser?.partner || ''}
                placeholder={editSelectedUser?.partner || ''}
                disabled
                gridTemplateColumns={`auto ${selectBoxWidth}px`}
              />
              <Textbox
                label="Account"
                name="Account"
                value={editSelectedUser?.account || ''}
                placeholder={editSelectedUser?.account || ''}
                disabled
                gridTemplateColumns={`auto ${selectBoxWidth}px`}
              />
              <SelectBox
                label="Role"
                onClick={(item: SelectBoxItemType) =>
                  setEditSelectedUser((oldValues) => ({ ...oldValues, roles: item }))}
                selectedItemKey={editSelectedUser?.roles?.key || ''}
                list={Utils.arrayToSelectbox(rolesFilter || [], 'key', 'title')}
                title={editSelectedUser.roles.title}
                type="light"
                listWidth={selectBoxWidth}
                isRequired
              />
              <Textbox
                label="Username (Email)"
                name="Email"
                value={editSelectedUser?.username}
                gridTemplateColumns={`auto ${selectBoxWidth}px`}
                disabled
              />
            </div>
          </div>
          <div className="modal-divided__rightcontent">
            <Textbox
              label="First name"
              name="FirstName"
              value={editSelectedUser.firstname}
              placeholder="Enter"
              onChange={(event) => {
                const firstname = event.target.value;
                setEditSelectedUser((oldValues) => ({ ...oldValues, firstname }));
              }}
              gridTemplateColumns={`auto ${selectBoxWidth}px`}
            />
            <Textbox
              label="Last name"
              name="LastName"
              value={editSelectedUser.lastname}
              placeholder="Enter"
              onChange={(event) => {
                const lastname = event.target.value;
                setEditSelectedUser((oldValues) => ({ ...oldValues, lastname }));
              }}
              gridTemplateColumns={`auto ${selectBoxWidth}px`}
            />
            <Textbox
              label="Mobile (SMS)"
              name="Mobile"
              value={editSelectedUser.mobile}
              placeholder="Enter"
              onChange={(event) => {
                const mobile = event.target.value;
                checkPhoneValidation(mobile);
              }}
              error={editSelectedUser.mobileError}
              errorMessage={editSelectedUser.mobileErrorMessage}
              gridTemplateColumns={`auto ${selectBoxWidth}px`}
            />
            <div className="users-mfa">
              <span>Multi-factor authentication (MFA)</span>
              <div className="mfa-content">
                <Checkbox
                  checked={editSelectedUser.mfa}
                  onClick={(checked) => {
                    checkMobileSelectedValidation(checked);
                  }}
                />
                <span>Required</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AdminSidebarEditUser;
