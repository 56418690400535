/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { ListRowProps } from 'react-virtualized';
import Table from '../../../../Common/Components/Table';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import getHeaderProps, { defaultHeaders } from '../../../../utils/getHeaderProps';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import { NodeObject } from '../../../../types/NodeObject';
import { nodesFetcherFn } from '../../../../utils/ApiDataHelpers';
import { CommissioningObject } from '../../../../types/CommissioningObject';
import Tooltip from '../../../../Common/Components/Tooltip';
import Modal from '../../../../Common/Components/Modal';
import Textbox from '../../../../Common/Components/Textbox';
import formValidation from '../../../../utils/form/formValidation';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../../utils/fetch';
import { useAppContext } from '../../../../utils/AppContext';
import DropDown from '../../../../Common/Components/DropDown';
import Utils from '../../../../utils/Utils';
import DownloadList from '../../../../Common/Components/DownloadList';
import SelectNodesByFile from '../../../../Common/Components/SelectNodesByFile';
import CustomizeList from '../../../../Common/Components/CustomizeList';
import { TableHeadersProp } from '../../../../types/TableHeadersProp';
import { sensitySystemsOrgId } from '../../../../utils/constants';
import RebootModal from '../../../../Common/Components/RebootModal';
import TableToolbar from '../Components/TableToolbar';
import Searchbox from '../../../../Common/Components/Searchbox';
import { AllSiteObject } from '../../../../types/SiteObject';
import { SelectedPartner } from '../../../../types/UsersPageProps';

import { ReactComponent as RebootIcon } from '../../../../img/icons/reboot.svg';
import { ReactComponent as ComposeIcon } from '../../../../img/icons/compose.svg';
import { ReactComponent as CommisionIcon } from '../../../../img/icons/commision.svg';
import { ReactComponent as DeleteIcon } from '../../../../img/icons/trash.svg';
import { ReactComponent as DotdotdotIcon } from '../../../../img/icons/dotdotdot.svg';
import { ReactComponent as AssignIcon } from '../../../../img/icons/assign.svg';
import { ReactComponent as CustomizeIcon } from '../../../../img/icons/customize-list.svg';
import { ReactComponent as DownloadIcon } from '../../../../img/icons/download.svg';
import { ReactComponent as UploadIcon } from '../../../../img/icons/upload2.svg';
import { ReactComponent as ArrowIcon } from '../../../../img/icons/arrow.svg';

const tooltipOffset = 0;
const listWidth = 300;
const listRowHeight = 40;
const minListHeight = 200;
const searchBoxMaxItems = 5;
const maxSelectedNodes = 100;
const fixColCount = 3;
const manualCommissionModalHeight = 220;

function CommissioningPage(): JSX.Element {
  const { addNotification } = useAppContext();

  const [selectedComissioningObjectMap, setSelectedComissioningObjectMap] = useState<Map<string, CommissioningObject>>(new Map());
  const [modalToggle, setModalToggle] = useState(0);
  const [selectedNode, setSelectedNode] = useState<NodeObject | undefined>(undefined);
  const [editTextBox, _setEditTextBox] = useState({
    editErrorMsg: '',
    error: false,
    value: '',
  });
  const [selectedDropdown, _setSelectedDropdown] = useState(0);
  const setSelectedDropdown = (newVal: number) => {
    if (newVal === selectedDropdown) {
      _setSelectedDropdown(0);
    } else {
      _setSelectedDropdown(newVal);
    }
  };

  const [openModal, setOpenModal] = useState(0);

  const modalToggleReboot = 1;
  const modalToggleEditNote = 2;
  const openModalAutoCommission = 1;
  const openModalManualCommission = 2;
  const openModalDownloadList = 3;
  const openModalCustomizeList = 4;
  const openModalAddNodesFromFile = 5;
  const dropdownCommission = 1;
  const dropdownDeleteNodes = 2;
  const dropdownMore = 3;

  const setEditTextBox = (val: string) => {
    const validation = formValidation(
      val,
      { max: 45 },
    );

    _setEditTextBox((oldVal) => ({
      value: validation.hasError ? editTextBox.value : val,
      editErrorMsg: validation.errorMsg,
      error: validation.hasError,
    }));
  };

  const { data: nodesResp, mutate: updateCommissioning } = useSWR<Array<NodeObject> | undefined>(
    sensitySystemsOrgId
      ? [`/customers/${sensitySystemsOrgId}/sites/_nosite_/nodes`, 'CommissioningPage'] : null,
    nodesFetcherFn,
  );

  const nodesNodeObjectList = useMemo(() => nodesResp?.map((c: NodeObject) =>
    ({
      node_id: c.nodeid,
      actions: (
        <div className="commissioning-actions">
          <Tooltip text="Reboot modem" offset={tooltipOffset}>
            <RebootIcon
              onClick={() => { setModalToggle(modalToggleReboot); setSelectedNode(c); }}
            />
          </Tooltip>
          <Tooltip text="Edit note" offset={tooltipOffset} addStyle={{ marginLeft: '20px' }}>
            <ComposeIcon
              onClick={async () => {
                setModalToggle(modalToggleEditNote);
                setSelectedNode(c);
                setEditTextBox(selectedNode?.note || '');
              }}
            />
          </Tooltip>
        </div>),
      account_name: c.accountName,
      account_id: c.accountRef,
      status: c.commissionState,
      activatedTableSort: Date.parse(c.activationDate) || 0,
      network: c.netStat ? 'Connected' : 'Disconnected',
      declaredTableSort: Date.parse(c.declared) || 0,
      declared: Utils.getConvertedDate(c.declared),
      commisionedTableSort: Date.parse(c.commissionedDate) || 0,
      commissioned: Utils.getConvertedDate(c.commissionedDate),
      note: c.note,
      latitude: c.latitude,
      longitude: c.longitude,
      gps: c.gpsSampleSize,
      app_firmware: c.softwareVersion,
      model: c.model,
      msisdn: c.msisdn,
      iccid: c.iccid,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })), [nodesResp]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nodesNodeObjectMap = (): Map<string, any> => {
    const list = new Map([]);
    nodesNodeObjectList?.forEach((nodeObj) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      list.set(nodeObj.node_id as string, nodeObj as any);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return list as Map<string, any>;
  };

  const [filteredCount, setFilteredCount] = useState(0);

  const customizedHeader = JSON.parse(localStorage.getItem('CommissioningListHeader') || '[]');
  const headers = customizedHeader.length === 0 ? getHeaderProps('CommissioningList') : customizedHeader;

  const [headerList, _setHeaders] = useState(headers);
  const setHeaders = (newHeaders: TableHeadersProp[]) => {
    localStorage.setItem('CommissioningListHeader', JSON.stringify(newHeaders));
    _setHeaders(newHeaders);
  };

  const colWidthCalcFn = (col: number, width: number): number => {
    const remainColCount = 6;
    const { actions, checkbox } = { checkbox: 47, actions: 100 };
    const colWidth = (width - checkbox - actions) / remainColCount;
    const notCustomizableHeadersWidth = [checkbox, colWidth, actions];
    const customizableHeadersWidth = Array.from(new Array(headerList.length - notCustomizableHeadersWidth.length), (item) => item || colWidth);

    return [...notCustomizableHeadersWidth, ...customizableHeadersWidth][col];
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const handleFileUpload = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setUploadFile(file);
      setOpenModal(openModalAddNodesFromFile);
    }
  };
  const clearInputFileAndCloseModal = () => {
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.value = '';
    }
    setOpenModal(0);
  };

  const [listComputedHeight, setListComputedHeight] = useState(0);
  const [searchboxFilteredSiteList, setSearchboxFilteredSiteList] = useState<SelectedPartner[]>([]);
  const [searchboxSiteList, setSearchboxSiteList] = useState<SelectedPartner[]>([]);

  const { data: siteList } = useSWR<AllSiteObject[]>(['/sites', 'Commissioning'], (url) => getRequest(url, {}, (data: { sites : AllSiteObject[] }): AllSiteObject[] => data?.sites?.filter((s) => s.orgid !== '')));

  useEffect(() => {
    const newList: SelectedPartner[] = [];

    siteList?.forEach((siteData: AllSiteObject) => {
      newList.push({
        name: siteData.name,
        id: siteData.siteid,
      });
    });
    setSearchboxSiteList(newList);
    setSearchboxFilteredSiteList(newList);
  }, [siteList]);

  const [selectedSite, setSelectedSite] = useState<SelectedPartner>({ id: '', name: '' });
  const [searchSite, setSearchSite] = useState(selectedSite.name || '');

  const siteRowRenderer = ({ key, index, style }: ListRowProps, setToggle: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!searchboxFilteredSiteList) {
      return <></>;
    }

    const { name, id } = searchboxFilteredSiteList[index];

    return (
      <li
        key={key}
        style={style}
        className="search-partner--list"
        onClick={() => {
          setToggle(false);
          setSelectedSite({ id: searchboxFilteredSiteList[index].id, name: searchboxFilteredSiteList[index].name });

          const filtered = searchboxSiteList?.filter((item: SelectedPartner) => item.name.toLowerCase().includes(name.toLowerCase()));
          setSearchboxFilteredSiteList(filtered);
          setListComputedHeight(Math.min(filtered.length * listRowHeight, minListHeight));

          setListComputedHeight(0);
        }}
      >
        <span className={id === selectedSite.id ? 'selected' : ''}>{name || ''}</span>
      </li>
    );
  };
  const handleSiteListChange = (name: string) => {
    setSelectedSite({ id: '', name: '' });
    setSearchSite(name);

    if (name === '') {
      setSearchboxFilteredSiteList(searchboxSiteList);
      setListComputedHeight(0);
    } else {
      const filtered = searchboxSiteList?.filter((item: SelectedPartner) => item.name.toLowerCase().includes(name.toLowerCase()));
      setSearchboxFilteredSiteList(filtered);
      setListComputedHeight(Math.min(filtered.length * listRowHeight, minListHeight));
    }
  };

  return (
    <>
      <div className="content commissioning">
        <AdminSidebar title="Account admin">
          <AdminSidebarAdminItems path={window.location.pathname} />
        </AdminSidebar>
        <div className="page-content">
          <TableToolbar title="Commissioning">
            <div className="commissioning__info">
              <div className="commissioning__info--element">
                Total nodes
                <span className="commissioning__info--element-val">
                  {nodesNodeObjectList?.length.toLocaleString()}
                </span>
              </div>
              <div className="commissioning__info--element">
                Nodes showing
                <span className="commissioning__info--element-val">
                  {filteredCount.toLocaleString()}
                </span>
              </div>
              <div className="commissioning__info--actions">
                <button type="button" className={`commissioning__info--actions-btn ${selectedDropdown === dropdownCommission ? 'selected' : ''}`} onClick={() => setSelectedDropdown(dropdownCommission)}>
                  <CommisionIcon />
                </button>
                {
                  selectedDropdown === dropdownCommission && (
                    <div className="actions-commission">
                      <DropDown>
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              if (selectedComissioningObjectMap.size === 0) {
                                addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                              } else {
                                setOpenModal(openModalAutoCommission);
                                setSelectedDropdown(0);
                              }
                            }}
                            className="actions-commission__element"
                          >
                            Auto-commission
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (selectedComissioningObjectMap.size === 0) {
                                addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                              } else if (selectedComissioningObjectMap.size > maxSelectedNodes) {
                                addNotification({ type: 'error', message: `More than ${maxSelectedNodes} nodes selected. Please fix selection and try again.` });
                              } else {
                                setOpenModal(openModalManualCommission);
                              }
                              setSelectedDropdown(0);
                            }}
                            className="actions-commission__element"
                          >
                            Manual commission
                          </button>
                        </>
                      </DropDown>
                    </div>
                  )
                }
                <Tooltip text="Permanently delete nodes" offset={tooltipOffset}>
                  <button
                    type="button"
                    className={`commissioning__info--actions-btn ${selectedDropdown === dropdownDeleteNodes ? 'selected' : ''}`}
                    onClick={() => {
                      if (selectedComissioningObjectMap.size === 0) {
                        addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
                      } else {
                        setSelectedDropdown(dropdownDeleteNodes);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </Tooltip>
                <button type="button" className={`commissioning__info--actions-btn commissioning__info--actions-list ${selectedDropdown === dropdownMore ? 'selected' : ''}`} onClick={() => setSelectedDropdown(dropdownMore)}>
                  <DotdotdotIcon />
                </button>
                {
                  selectedDropdown === dropdownMore && (
                    <div className="actions-commission">
                      <DropDown>
                        <>
                          <button type="button" onClick={() => { setOpenModal(openModalCustomizeList); setSelectedDropdown(0); }} className="actions-commission__element">
                            <CustomizeIcon />
                            Customize list
                          </button>
                          <button type="button" onClick={() => { setOpenModal(openModalDownloadList); setSelectedDropdown(0); }} className="actions-commission__element">
                            <DownloadIcon />
                            Download list
                          </button>
                          <SelectNodesByFile
                            addClass="actions-commission__element"
                            setSelectedNodes={setSelectedComissioningObjectMap}
                            nodesList={nodesNodeObjectMap()}
                            selectedNodes={selectedComissioningObjectMap}
                            closeDropdown={() => setSelectedDropdown(0)}
                          >
                            <>
                              <AssignIcon />
                              Select nodes by file
                            </>
                          </SelectNodesByFile>
                          <button type="button" onClick={() => { handleFileUpload(); setSelectedDropdown(0); }} className="actions-commission__element">
                            <UploadIcon />
                            Add nodes from file
                          </button>
                        </>
                      </DropDown>
                    </div>
                  )
                }
              </div>
            </div>
          </TableToolbar>
          <div className="table table--light auto-height">
            <Table
              headers={headerList.filter((h: TableHeadersProp) => !h.isHidden)}
              data={nodesNodeObjectList}
              selectedItems={selectedComissioningObjectMap}
              setSelectedItems={setSelectedComissioningObjectMap}
              skipCellMeasure
              colWidthCalcFn={colWidthCalcFn}
              fixColCount={fixColCount}
              setFilteredCount={setFilteredCount}
              autoheight
            />
          </div>
        </div>
      </div>
      {modalToggle === modalToggleReboot && (
        <RebootModal
          customerid={sensitySystemsOrgId} // todo - selectedNode.orgid
          siteid="_nosite_" // todo - selectedNode.siteid
          nodeList={selectedNode ? [selectedNode.nodeid] : []}
          setModalOpen={() => { setModalToggle(0); setEditTextBox(''); }}
        />
      )}
      {modalToggle === modalToggleEditNote && (
      <Modal
        title="Edit note"
        width="360"
        setModalOpen={() => { setModalToggle(0); setEditTextBox(''); }}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setModalToggle(0);
          const postbody = {
            nodeids: [selectedNode?.nodeid],
            note: editTextBox.value,
          };

          try {
            const resp = await putRequest(
              '/nodes/note',
              postbody,
            );

            if (!resp.error) {
              addNotification({ type: 'success', message: 'Your "Edit note" operation was successful' });
            } else {
              addNotification({ type: 'error', message: 'Your "Edit note" operation is failed.' });
            }
          } catch (error) {
            addNotification({ type: 'error', message: 'Your "Edit note" operation is failed.' });
          }
        }}
        secondaryButtonLabel="Cancel"
        className="commissioning"
      >
        <Textbox
          label="Note"
          error={editTextBox.error}
          errorMessage={editTextBox.editErrorMsg}
          placeholder="Edit note"
          name="Name"
          type="text"
          value={editTextBox.value}
          onChange={(e) => setEditTextBox(e.target.value)}
        />
        <span className="commissioning__note">Max 45 characters</span>
      </Modal>
      )}
      {openModal === openModalAutoCommission && (
      <Modal
        title="Auto-commission"
        width="336"
        setModalOpen={() => setOpenModal(0)}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setOpenModal(0);
          const nodeIdList = Array.from(selectedComissioningObjectMap.keys());
          try {
            const resp = await postRequest('/nodes/auto_commission', {
              nodeids: nodeIdList,
            });

            if (resp.error) {
              addNotification({ type: 'error', message: resp.error });
            } else {
              addNotification({ type: 'success', message: `Node ${nodeIdList.join(', ')} has been successfully commissioned.` });
              setSelectedComissioningObjectMap(new Map([]));
            }
          } catch (error) {
            addNotification({ type: 'error', message: 'Your "Auto-commission" operation is failed.' });
          }
        }}
        secondaryButtonLabel="Cancel"
        className="auto-commission"
      >
        <span>Are you sure you want to auto-commission selected nodes?</span>
      </Modal>
      )}
      {openModal === openModalManualCommission && (
      <Modal
        title="Commission manually"
        width="360"
        height={`${listComputedHeight + manualCommissionModalHeight}`}
        setModalOpen={() => setOpenModal(0)}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setOpenModal(0);
          const nodeIdList = Array.from(selectedComissioningObjectMap.keys());
          try {
            const selectedCustomerId = siteList?.find((s) => s.siteid === selectedSite.id)?.orgid;
            const resp = await Utils.sendFormData(
              `/customers/${selectedCustomerId}/sites/${selectedSite?.id}/nodes/assign`,
              nodeIdList,
            );

            if (resp.ok) {
              addNotification({ type: 'success', message: `Node ${nodeIdList.join(', ')} has been successfully commissioned.` });
              setSelectedComissioningObjectMap(new Map([]));
              updateCommissioning();
            } else {
              addNotification({ type: 'error', message: 'Your "Commission manually" operation is failed.' });
            }
          } catch (error) {
            addNotification({ type: 'error', message: 'Your "Commission manually" operation is failed.' });
          }

          clearInputFileAndCloseModal();
        }}
        secondaryButtonLabel="Cancel"
        className={`auto-commission ${listComputedHeight > 0 ? 'modal-full-height' : ''}`}
      >
        <div className="search-site margin-right-auto">
          <span className="search-site__label">
            Assign to site
          </span>
          <Searchbox
            list={searchboxFilteredSiteList}
            type="light"
            icon={<ArrowIcon className="search__icon" />}
            alwaysShowIcon
            title="Select site"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleSiteListChange(event.target.value)}
            searchValue={selectedSite.name || searchSite}
            listWidth={listWidth}
            listRowHeight={listRowHeight}
            listRowRenderer={siteRowRenderer}
            rowNumber={searchBoxMaxItems}
            iconClickToggleList
            className="search-site__searchox"
            listStyle={{ position: 'absolute' }}
            setIsListOpen={setListComputedHeight}
            isListOpen={listComputedHeight}
          />
        </div>
      </Modal>
      )}
      {selectedDropdown === dropdownDeleteNodes && (
      <Modal
        title="Delete nodes"
        width="273"
        setModalOpen={() => setSelectedDropdown(0)}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          setSelectedDropdown(0);
          const deleteNodes = Array.from(selectedComissioningObjectMap.keys());
          const resp = await deleteRequest('/nodes', {
            nodeids: deleteNodes,
          });
          if (resp.error) {
            addNotification({ type: 'error', message: 'Your "Delete nodes" operation is failed.' });
          } else {
            addNotification({ type: 'success', message: `Node ${deleteNodes.join(', ')} has been successfully deleted.` });
            updateCommissioning();
            setSelectedComissioningObjectMap(new Map([]));
          }
        }}
        secondaryButtonLabel="Cancel"
        className="delete-nodes"
      >
        <div className="delete-nodes__content">Are you sure you want to delete selected nodes?</div>
        <div className="delete-nodes__content">These nodes will be permanently deleted from the system.</div>
      </Modal>
      )}
      {openModal === openModalDownloadList && (
        <DownloadList
          setOpenModal={setOpenModal}
          headerList={headerList}
          skipColumns={['rowSelectCheckbox', 'actions']}
          enitreList={nodesNodeObjectList}
          selectedNodes={selectedComissioningObjectMap}
          fileName="commissioning_list"
        />
      )}
      {openModal === openModalCustomizeList && (
        <CustomizeList
          setOpenModal={setOpenModal}
          headerList={headerList}
          setHeaders={setHeaders}
          defaultHeaders={defaultHeaders.CommissioningList}
        />
      )}
      {openModal === openModalAddNodesFromFile && (
        <Modal
          width="330"
          title="Add nodes from file"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Upload"
          primaryButtonAction={async () => {
            try {
              const resp = await Utils.sendFormData('/nodes', undefined, uploadFile);
              const uploadedNodeidList = await resp.json();
              if (uploadedNodeidList.error || uploadedNodeidList.length === 0) {
                addNotification({ type: 'error', message: 'Your "Add nodes from file" operation is failed.' });
              } else {
                addNotification({ type: 'success', message: 'Your "Add nodes from file" operation is completed.' });
                updateCommissioning();
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Add nodes from file" operation is failed' });
            }
            clearInputFileAndCloseModal();
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => clearInputFileAndCloseModal()}
        >
          <div className="add-nodes">
            <span className="add-nodes__title">File name: </span>
            <span>{uploadFile?.name}</span>
            <div className="add-nodes__content">
              Upload a CSV file with values on each line for nodeid, model, orgid, siteid, latitude and longitude (in that order).
              Also include a header row with those column names.
            </div>
          </div>
        </Modal>
      )}
      <input type="file" id="file" ref={inputFile} accept=".csv" style={{ display: 'none' }} onChange={onChangeFileUpload} />
    </>
  );
}

export default CommissioningPage;
