import React from 'react';
import AdminSidebarItem from '../../../../Common/Components/AdminSidebarItem';
import { AdminSidebarConfigItemsProps } from '../../../../types/AdminSidebarConfigItems';

import { ReactComponent as NonExpandIcon } from '../../../../img/icons/right-caret.svg';
import { ReactComponent as ExpandIcon } from '../../../../img/icons/down-caret.svg';
import { ReactComponent as HandshakeIcon } from '../../../../img/icons/handshake.svg';
import { ReactComponent as SitesIcon } from '../../../../img/icons/site-locator.svg';
import { ReactComponent as GroupIcon } from '../../../../img/icons/group.svg';
import { ReactComponent as SystemAlertsIcon } from '../../../../img/icons/alert.svg';
import { ReactComponent as NodeDiagnosticsIcon } from '../../../../img/icons/binoculars.svg';
import { ReactComponent as CommissioningIcon } from '../../../../img/icons/distribution-blk.svg';
import Utils from '../../../../utils/Utils';

function AdminSidebarAdminItems(props: AdminSidebarConfigItemsProps): JSX.Element {
  const { path } = props;
  const usersIsSelected = (path.endsWith('/admin/users')) || (path.endsWith('/admin/suspendedusers'));
  const showExpandIcon = (usersIsSelected) ? (<ExpandIcon />) : (<NonExpandIcon />);

  return (
    <>
      <AdminSidebarItem
        icon={<HandshakeIcon />}
        title="Accounts"
        path={path}
        link="/admin/accounts"
      />
      <AdminSidebarItem
        icon={<SitesIcon />}
        title="Site manager"
        path={path}
        link="/admin/sites"
      />
      <AdminSidebarItem
        icon={<GroupIcon />}
        expandicon={showExpandIcon}
        showSub={usersIsSelected}
        title="Users"
        path={path}
        link="/admin/users"
      />
      {usersIsSelected && (
        <div className="sub-user-items">
          <AdminSidebarItem
            title="Active Users"
            path={path}
            link="/admin/users"
            isSubItem
            greyBackground
          />
          <AdminSidebarItem
            title="Suspended Users"
            path={path}
            link="/admin/suspendedusers"
            isSubItem
            greyBackground
          />
        </div>
      )}
      {Utils.isVerizonUser() && (
        <>
          <AdminSidebarItem
            icon={<CommissioningIcon />}
            title="Commissioning"
            path={path}
            link="/admin/commissioning"
          />
          <AdminSidebarItem
            icon={<SystemAlertsIcon />}
            title="System Alerts"
            path={path}
            link="/admin/systemalerts"
          />
          <AdminSidebarItem
            icon={<NodeDiagnosticsIcon />}
            title="Node Diagnostics"
            path={path}
            link="/admin/nodediagnostics"
          />
        </>
      )}
    </>
  );
}

export default AdminSidebarAdminItems;
