/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import { PageComponentProps } from '../../types/PageComponentProps';
import { JobObject } from '../../types/JobObject';
import { GroupObject } from '../../types/GroupObject';
import { FirmwareJobObject } from '../../types/FirmwareJobObject';
import { FirmwareObject } from '../../types/FirmwareObjects';

import Utils from '../../utils/Utils';

import useJobsPageState from '../../utils/state/useJobsPageState';

import Toolbar from '../../Common/Components/Toolbar';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import ToolbarItem from '../../Common/Components/ToolbarItem';
import JobsFilter from './Components/JobsFilter';

import Checkbox from '../../Common/Components/Checkbox';
import Loading from '../../Common/Components/Loading';
import JobsSort from './Components/JobsSort';
import JobsList from './Components/JobsList';

import { firmwareFetcherFn } from '../../utils/ApiDataHelpers';

// Icons
import { ReactComponent as FilterLargeIcon } from '../../img/icons/filterlarge.svg';
import { ReactComponent as SortLargeIcon } from '../../img/icons/sortlarge.svg';
import { jobStatusMsg, jobTypeMsg } from '../../utils/constants';

function JobsPage(props: PageComponentProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
  } = props;
  const {
    headers,
    setHeaders,
    activeToolbar,
    handleActiveToolbar,
  } = useJobsPageState();

  const showOtaJobs: boolean = Utils.isVerizonUser();

  // Async Jobs

  const { data: jobsResp } = useSWR<Array<JobObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/async-jobs`, 'GetAsyncJobs'] : null);

  // Firmware Jobs

  const { data: firmwareJobsResp } = useSWR<Array<FirmwareJobObject>>(() => (showOtaJobs ? `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/ota_status` : null));

  // Firmwares (to get versions)

  const { data: firmwaresResp } = useSWR<Array<FirmwareObject>>(() => (showOtaJobs ? ['/firmwares', 'GetFirmwares'] : null), firmwareFetcherFn);

  // Groups (to get group names)

  const { data: groupsResp } = useSWR<Array<GroupObject>>(
    () => (showOtaJobs ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups'] : null),
  );

  const defaultJobFilterSettings: Record<string, string> = useMemo(() => ({
    displayBy: 'Time frame',
    timeFrame: 'Last 7 days',
    startDate: DateTime.now().minus({ days: 7 }).toISO(),
    endDate: DateTime.now().toISO(),
    jobType: 'ALL',
    status: 'ALL',
  }), []);

  const defaultJobSortSettings: Record<string, string> = useMemo(() => ({
    primary: 'Start date',
  }), []);

  const otaStatusToAsyncStatus: Record<string, string> = useMemo(() => ({
    JOB_STARTED: 'RUNNING',
    JOB_DONE: 'COMPLETED',
    JOB_FAILED: 'FAILED',
    JOB_ABORTED: 'ABORTED',
  }), []);

  const otaJobToAsyncJob = useCallback((firmwareJob: FirmwareJobObject):JobObject => (
    {
      id: firmwareJob.jobid,
      username: '',
      status: otaStatusToAsyncStatus[firmwareJob.status || 'JOB_DONE'],
      taskCreationTime: firmwareJob.when.concat('+00:00'),
      targettype: firmwareJob.targettype || firmwareJob.target_type,
      targetid: ((firmwareJob.targettype || firmwareJob.target_type) === 'group')
        ? (groupsResp?.find((group) => (group.groupid === firmwareJob.targetid
          || group.groupid === firmwareJob.target_id)))?.name
        : (firmwareJob.targetid || firmwareJob.target_id),
      description: firmwareJob.description,
      firmwareid: firmwareJob.firmwareid,
      toVersion: firmwaresResp?.find((f) => f.firmwareid === firmwareJob.firmwareid)?.release,
      finishTime: firmwareJob.when.concat('+00:00'),
      operationType: `UPDATE_FIRMWARE_${firmwareJob.targettype
        ? firmwareJob.targettype.toUpperCase()
        : firmwareJob.target_type?.toUpperCase()}`,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [firmwaresResp]);

  const [jobFilterSettings, setApplyJobFilter] = useState(defaultJobFilterSettings);
  const [jobSortSettings, setApplyJobSort] = useState(defaultJobSortSettings);
  const [ownJobsOnly, setOwnJobsOnly] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState<JobObject[]>();
  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  useEffect(() => {
    if (Array.isArray(jobsResp)
      && jobsResp.length === 0
      && (showOtaJobs ? (Array.isArray(firmwareJobsResp) && firmwareJobsResp.length === 0) : true)) {
      setFilteredJobs([]);
      forceUpdate();
    } else if (Array.isArray(jobsResp)
      && (showOtaJobs ? Array.isArray(firmwareJobsResp) : true)
      && (jobsResp.length > 0 || (showOtaJobs && firmwareJobsResp && firmwareJobsResp.length > 0))) {
      // combine the async-jobs response and the ota_status reponse
      let jobs = jobsResp;
      if (showOtaJobs && firmwareJobsResp) {
        jobs = jobs.concat(firmwareJobsResp.map((firmwareJob) => otaJobToAsyncJob(firmwareJob)));
      }
      jobs = jobs.filter((job) =>
        (jobFilterSettings.jobType === 'ALL' || jobFilterSettings.jobType === job.operationType)
        && (jobFilterSettings.status === 'ALL' || jobFilterSettings.status === job.status)
        && (!ownJobsOnly || job.username === Utils.getUserName()));

      switch (jobSortSettings.primary) {
        case 'Start date':
          jobs.sort((a:JobObject, b:JobObject) => (
            a.taskCreationTime < b.taskCreationTime ? 1 : -1
          ));
          break;
        case 'End date':
          jobs.sort((a:JobObject, b:JobObject) => (
            a.finishTime < b.finishTime ? 1 : -1
          ));
          break;
        case 'User':
          jobs.sort((a:JobObject, b:JobObject) => (
            a.username > b.username ? 1 : -1
          ));
          break;
        default:
          break;
      }

      if (jobFilterSettings.displayBy === 'Time frame') {
        let start = DateTime.now();
        switch (jobFilterSettings.timeFrame) {
          case 'Today':
            start = start.startOf('day');
            break;
          case 'Last 7 days':
            start = start.minus({ days: 7 });
            break;
          case 'Last 30 days':
            start = start.minus({ days: 30 });
            break;
          case 'Last 60 days':
            start = start.minus({ days: 60 });
            break;
          default:
            break;
        }
        jobs = jobs.filter((job) => DateTime.fromISO(job.taskCreationTime) > start);
      }

      if (jobFilterSettings.displayBy === 'Date range') {
        const start = jobFilterSettings.startDate.replace(/T\d\d:\d\d:\d\d/, 'T00:00:00');
        const end = jobFilterSettings.endDate.replace(/T\d\d:\d\d:\d\d/, 'T23:59:59');
        jobs = jobs.filter((job) => job.taskCreationTime >= start && job.taskCreationTime <= end);
      }

      setFilteredJobs(jobs);
      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobFilterSettings,
    jobSortSettings,
    ownJobsOnly,
    jobsResp,
    firmwareJobsResp,
    firmwaresResp,
    otaJobToAsyncJob,
  ]);

  const toolbarFields = [
    jobFilterSettings.displayBy === 'Time frame'
      ? { name: 'Time frame', value: jobFilterSettings.timeFrame }
      : { name: 'Date range',
        value: DateTime.fromISO(jobFilterSettings.startDate).toFormat('MMMM d - ')
        + DateTime.fromISO(jobFilterSettings.endDate).toFormat('MMMM d, yyyy') },
    { name: 'Job type', value: jobTypeMsg[jobFilterSettings.jobType] },
    { name: 'Status', value: jobStatusMsg[jobFilterSettings.status] },
  ];

  const toolbarFilter = 1;
  const toolbarSort = 2;

  return (
    <>
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Jobs" subtitle={selectedSite.name} />
            <ToolbarItem
              addClass="toolbaritem-jobs-filter"
              icon={<FilterLargeIcon />}
              onclick={() => handleActiveToolbar(toolbarFilter)}
              order={toolbarFilter}
              tabPanelisActive={activeToolbar}
              fields={toolbarFields}
              vertical={false}
            >
              <JobsFilter
                className="dropdown-jobs-filter"
                jobFilterSettings={jobFilterSettings}
                setApplyJobFilter={setApplyJobFilter}
                handleActiveToolbar={handleActiveToolbar}
              />
            </ToolbarItem>
            <ToolbarItem
              addClass="toolbaritem-jobs-sort last"
              icon={<SortLargeIcon />}
              onclick={() => handleActiveToolbar(toolbarSort)}
              order={toolbarSort}
              tabPanelisActive={activeToolbar}
              fields={[{ name: 'Sort by', value: jobSortSettings.primary }]}
              vertical
            >
              <JobsSort
                className="dropdown-jobs-sort"
                jobSortSettings={jobSortSettings}
                setApplyJobSort={setApplyJobSort}
                handleActiveToolbar={handleActiveToolbar}
              />
            </ToolbarItem>
          </div>
        </div>
        <div className="jobs-ownjobsonly">
          <div>
            <Checkbox
              checked={ownJobsOnly}
              onClick={() => { setOwnJobsOnly(!ownJobsOnly); }}
            />
          </div>
          <div>
            <span>Show my jobs only</span>
          </div>
        </div>
      </Toolbar>
      <div className="content">
        {Array.isArray(filteredJobs)
          ? (
            <JobsList
              headers={headers}
              setHeaders={setHeaders}
              filteredjobs={filteredJobs}
              site={selectedSite}
              customer={selectedCustomer}
            />
          ) : (<Loading />)}
      </div>
    </>
  );
}

export default JobsPage;
