import React, { createRef, useState } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import Button from '../../../Common/Components/Button';
import Checkbox from '../../../Common/Components/Checkbox';
import Loading from '../../../Common/Components/Loading';
// icons
import { ReactComponent as CaretIcon } from '../../../img/icons/down-arrow.svg';
import { ReactComponent as EditIcon } from '../../../img/icons/edit.svg';
import { ReactComponent as TotalEnergyIcon } from '../../../img/icons/smart-meter.svg';
import { ReactComponent as TrashIcon } from '../../../img/icons/trash.svg';
import { GroupListsProps } from '../../../types/GroupsLists';
import Utils from '../../../utils/Utils';
import GroupsDetailView from './GroupsDetailView';

const rowHeightExpanded = 203;
const rowHeight = 43;

function OrgGroupsList(props: GroupListsProps): JSX.Element {
  const {
    data,
    alerts,
    selectedItems,
    setSelectedItems,
    setEditGroup,
    setOpenEditModal = () => undefined,
    setOpenDeleteModal = () => undefined,
    setDeleteGroup = () => undefined,
    setEnergyGroup,
    setOpenEnergyModal = () => undefined,
  } = props;
  const [openedOrgDetailView, setOpenOrgDetailView] = useState<Array<number>>([]);

  const isNonReadOnly = Utils.isNonReadOnly();

  const list = createRef<List>();
  const getRowHeight = ({ index }: {index: number}) => (openedOrgDetailView.includes(index) ? rowHeightExpanded : rowHeight);

  const orgGroupRenderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>
      <div className="groups-list__elements">
        <div className="groups-list__first-cell groups-list__first-cell--wide">
          <CaretIcon
            className={`groups-list__element ${openedOrgDetailView.includes(index) ? 'groups-list__element--open' : ''} `}
            onClick={() => {
              if (openedOrgDetailView.includes(index)) {
                setOpenOrgDetailView(openedOrgDetailView.filter((item) => item !== index));
              } else {
                setOpenOrgDetailView([...openedOrgDetailView, index]);
              }
              list.current?.forceUpdateGrid();
              list.current?.recomputeRowHeights(index);
            }}
          />
          <Checkbox
            checked={selectedItems.includes(data[index].groupid)}
            onClick={(checked) => {
              if (checked) {
                setSelectedItems([...selectedItems, data[index].groupid], 'orgGroupIds');
              } else {
                setSelectedItems(
                  selectedItems.filter((id: string) => id !== data[index].groupid),
                  'orgGroupIds',
                );
              }
            }}
          />
          <div className="groups-list__element">{data[index].name}</div>
        </div>
        <div className="groups-list__alerts-cell">
          <div className="groups-list__element">{data[index].nodeList.length || 0 }</div>
          <div className="groups-list__element groups-list__element--ok-nodes">{data[index].nodeList.length - data[index].alertCount}</div>
          <div className="groups-list__element groups-list__element--alert-nodes">{data[index].alertCount}</div>
        </div>
        <Button extraClasses="groups-list__action-cell" onClick={() => { setOpenEnergyModal(true); setEnergyGroup(data[index]); }}>
          <TotalEnergyIcon className="groups-list__element" />
        </Button>
        {isNonReadOnly && (
        <>
          <Button extraClasses="groups-list__action-cell" onClick={() => { setOpenEditModal(true); setEditGroup(data[index]); }}>
            <EditIcon className="groups-list__element" />
          </Button>
          <Button
            extraClasses="groups-list__action-cell"
            onClick={() => { setOpenDeleteModal(true); setDeleteGroup(data[index]); }}
          >
            <TrashIcon className="groups-list__element" />
          </Button>
        </>
        )}
      </div>
      <div>
        {openedOrgDetailView.includes(index) && (
          <GroupsDetailView group={data[index]} alerts={alerts} listKey={key} descriptionLabel="Org description" />
        )}
      </div>
    </div>
  );

  return (
    <>
      {data ? (
        <AutoSizer disableWidth>
          {({ height }) => (
            <List
              ref={list}
              width={1}
              height={height}
              rowHeight={getRowHeight}
              rowCount={data && data.length}
              rowRenderer={orgGroupRenderer}
              list={data}
              containerStyle={{
                width: '100%',
                maxWidth: '100%',
              }}
              style={{
                width: 'calc(100% - 10px)',
              }}
            />
          )}
        </AutoSizer>
      ) : (<Loading />)}
    </>
  );
}

export default OrgGroupsList;
