/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, useState } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { ResizableBox } from 'react-resizable';
import { Link } from 'react-router-dom';
import AccountsPageList from './AccountsPageList';
import PartnerModal from './PartnerModal';
import AccountModal from './AccountModal';
import Button from '../../../../../Common/Components/Button';
import { AccountsPagePartnerProps } from '../../../../../types/AccountsPagePartnerProps';
import { CustomerObject } from '../../../../../types/CustomerObject';

// icons
import { ReactComponent as EditIcon } from '../../../../../img/icons/edit.svg';
import { ReactComponent as HandshakeIcon } from '../../../../../img/icons/handshake.svg';
import { ReactComponent as UsersIcon } from '../../../../../img/icons/group.svg';
import { ReactComponent as ResizeIcon } from '../../../../../img/icons/corner-grab.svg';
import Tooltip from '../../../../../Common/Components/Tooltip';
import Utils from '../../../../../utils/Utils';

const rowHeight = 55;
const maxItems = 3;
const minItems = 3;

function AccountsPagePartners(props: AccountsPagePartnerProps): JSX.Element {
  const { partner, customers, partners, updateCustomers } = props;
  const computedHeight = customers && (customers.length > maxItems ? rowHeight * maxItems : rowHeight * customers.length);
  const listHasScrollbar = customers && customers.length > maxItems;
  const containerMinHeight = rowHeight * minItems;
  const containerMaxHeight = customers && (customers.length * rowHeight);

  const modalEditPartner = 1;
  const modalCreateAccount = 2;
  const modalEditAccount = 3;

  const [modalOpen, setModalOpen] = useState(0);
  const list = createRef<List>();
  const [editedCustomer, setEditedCustomer] = useState({} as CustomerObject);

  const customerListRenderer = ({ key, index, style }: ListRowProps) => {
    if (!customers) {
      return <></>;
    }

    return (
      <div key={key} style={style}>
        <AccountsPageList
          listItem={customers[index]}
          setModalOpen={setModalOpen}
          setEditedCustomer={() => setEditedCustomer(customers[index])}
        />
      </div>
    );
  };

  const partnerItem = {
    ...partner as CustomerObject,
    type: 'partner',
  };

  const createAccount = {
    name: '',
    type: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    contact_email: '',
    contact_phone: '',
    contact_name: '',
    vzids: '',
  } as CustomerObject;

  const resizableConstraint = 1250;
  const defaultMinHeight = 100;
  const defaultMaxHeight = 420;

  return (
    <>
      <div className="partner">
        {((partner && partner.name) || partner === undefined) && (
        <div className="partner__actions">
          <div className="partner__actions-name">
            <div className="partner__actions-name-title">
              <HandshakeIcon />
              {partner ? partner.name : 'No partner'}
            </div>
            <div className="partner__account-text">
              <span className="partner__account-value">
                {customers?.length || 0}
              </span>
              {customers !== undefined && customers?.length <= 1 ? 'Account' : 'Accounts'}
            </div>
          </div>
          {partner && partner.name && Utils.isAdminOrPartnerAdmin() && (
          <div className={`partner__buttons ${listHasScrollbar ? 'has-scrollbar' : ''}`}>
              {Utils.isSensityAdmin() && (
                <div>
                  <Button buttonType="secondary" buttonSize="small" label="Create account" onClick={() => setModalOpen(modalCreateAccount)} />
                </div>
              )}
            <Tooltip text="Go to Partner Users">
              <Link to={{ pathname: '/admin/users', state: { partnerId: partner.orgid, partnerName: partner.name } }} className="partner__user">
                <UsersIcon />
              </Link>
            </Tooltip>
            <Tooltip text="Edit Partner">
              <button type="button" className="partner__buttons-edit" onClick={() => setModalOpen(modalEditPartner)}>
                <EditIcon />
              </button>
            </Tooltip>
          </div>
          )}
        </div>
        )}
        {listHasScrollbar ? (
          <ResizableBox
            axis="y"
            width={0}
            height={containerMinHeight || defaultMinHeight}
            minConstraints={[resizableConstraint, containerMinHeight || defaultMinHeight]}
            maxConstraints={[resizableConstraint, containerMaxHeight || defaultMaxHeight]}
            draggableOpts={{ grid: [rowHeight, rowHeight] }}
            handle={<span className="custom-handle custom-handle-se"><ResizeIcon /></span>}
          >
            <div className="partner__customer-list customer-list">
              {customers?.length ? (
                <AutoSizer disableWidth>
                  {({ height }) => (
                    <List
                      ref={list}
                      width={1}
                      height={height}
                      rowHeight={rowHeight}
                      rowCount={customers && customers.length}
                      rowRenderer={customerListRenderer}
                      list={customers}
                      containerStyle={{
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      style={{
                        width: '100%',
                      }}
                    />
                  )}
                </AutoSizer>
              ) : <></>}
            </div>
          </ResizableBox>
        ) : (
          <div className="partner__customer-list customer-list">
            {customers?.length ? (
              <List
                ref={list}
                width={1}
                height={computedHeight || 0}
                rowHeight={rowHeight}
                rowCount={customers && customers.length}
                rowRenderer={customerListRenderer}
                list={customers}
                containerStyle={{
                  width: '100%',
                  maxWidth: '100%',
                }}
                style={{
                  width: '100%',
                }}
              />
            ) : <></>}
          </div>
        )}
      </div>
      {modalOpen === modalEditPartner && (
      <PartnerModal
        type="edit"
        partner={partnerItem}
        setModalOpen={setModalOpen}
        updateCustomers={updateCustomers}
      />
      )}
      {modalOpen === modalCreateAccount && (
        <AccountModal
          type="create"
          customer={createAccount}
          currentPartner={partner}
          partners={partners}
          setModalOpen={setModalOpen}
          updateCustomers={updateCustomers}
        />
      )}
      {modalOpen === modalEditAccount && (
      <AccountModal
        type="edit"
        setModalOpen={setModalOpen}
        customer={editedCustomer}
        currentPartner={partner}
        updateCustomers={updateCustomers}
      />
      )}
    </>
  );
}

export default AccountsPagePartners;
