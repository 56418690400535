/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { TextboxPropTypes } from '../../types/TextboxPropTypes';

function Textbox(props: TextboxPropTypes): JSX.Element {
  const {
    type = 'text',
    name = '',
    value = '',
    placeholder = '',
    autofocus = false,
    onChange,
    onBlur,
    label = '',
    styleType,
    disabled = false,
    error = false,
    errorMessage = '',
    textboxType,
    isRequired,
    isHorizontal = false,
    gridTemplateColumns,
    icon,
    small = false,
  } = props;

  const gridTemplateColumnsStyle = gridTemplateColumns ? { gridTemplateColumns } : {};

  return (
    <div
      className={
      `textbox ${textboxType || ''}
      ${styleType || ''}
      ${error ? 'error' : ''}
      ${isHorizontal ? 'horizontal' : ''}
      ${small ? 'small' : ''}`
    }
      style={gridTemplateColumnsStyle}
    >
      {label && (
      <label htmlFor="textbox">
        {label}
        {isRequired && (<span className="textbox-required">*</span>)}
      </label>
      )}
      <input
        aria-label={name}
        aria-invalid={!!error}
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autofocus}
        onChange={onChange}
        onBlur={onBlur}
        className={`textbox__input ${icon ? 'textbox__input--icon' : ''}`}
      />
      {icon && <span className="textbox__icon">{icon}</span>}
      {error && errorMessage && <span>{errorMessage}</span>}
    </div>
  );
}

export default Textbox;
