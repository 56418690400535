import React, { useState } from 'react';
import Button from '../../../Common/Components/Button';
import { AlarmsClearByTypePropsType } from '../../../types/AlarmsClearByType';
import SelectBox from '../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import Utils from '../../../utils/Utils';

function AlarmsClearByType(props: AlarmsClearByTypePropsType): JSX.Element {
  const {
    className,
    alarmTypeItems,
    setShowModal,
    setSelectedClearType,
  } = props;

  const listWidth = 175;
  const modalClearByType = 4;

  const [valueType, setValueType] = useState<SelectBoxItemType>(
    Utils.valueToSelectBoxItem(alarmTypeItems[0].title, alarmTypeItems),
  );

  return (
    <div className={className}>
      <div className="dropdown__content alarms-clearbytype">
        <div className="alarms-clearbytype__form-section">
          <div className="alarms-clearbytype__label">
            Select alarm type
          </div>
          <SelectBox
            title={valueType.title}
            selectedItemKey={valueType.key}
            list={Utils.arrayToSelectbox(alarmTypeItems, 'key', 'title')}
            listWidth={listWidth}
            onClick={(item: SelectBoxItemType) => {
              setValueType(item);
              setSelectedClearType(item.title);
            }}
            type="light"
          />
        </div>
        <div className="jobs-filter__button-bar">
          <Button
            buttonType="secondary"
            onClick={() => {
              setShowModal(0);
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            buttonType="primary"
            onClick={() => {
              setShowModal(modalClearByType);
            }}
          >
            <span>Clear all</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AlarmsClearByType;
