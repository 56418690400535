import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import RadioButtons from '../../../../../Common/Components/RadioButtons';

import Textbox from '../../../../../Common/Components/Textbox';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import { RadioButtonElementProps } from '../../../../../types/RadioButtonsProps';
import { CreateEditFixtureProps, NewEditFixtureStateProp, NewEditFixtureValidationProp, CreateEditFixturePostProp } from '../../../../../types/CreateEditFixtureProps';
import Utils from '../../../../../utils/Utils';
import { postRequest, deleteRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import formValidation from '../../../../../utils/form/formValidation';

function CreateEditFixture(props: CreateEditFixtureProps): JSX.Element {
  const { modalOpen, selectedCustomer, selectedSite, updateFixtures, actions, fixtureDetails } = props;
  const { addNotification } = useAppContext();
  const [createEditFixture, setCreateEditFixture] = useState<NewEditFixtureStateProp>({
    name: fixtureDetails?.name || '',
    PowerDraw: fixtureDetails?.PowerDraw || '',
    description: fixtureDetails?.description || '',
    fixtureType: { title: fixtureDetails?.fixtureType, key: fixtureDetails?.fixtureType } || { title: '', key: '' },
    MaxPower0: fixtureDetails?.MaxPower0 || '',
    MaxPower10: fixtureDetails?.MaxPower10 || '',
    MaxPower50: fixtureDetails?.MaxPower50 || '',
    MaxPower80: fixtureDetails?.MaxPower80 || '',
    MaxPower100: fixtureDetails?.MaxPower100 || '',
    MinPower0: fixtureDetails?.MinPower0 || '',
    MinPower10: fixtureDetails?.MinPower10 || '',
    MinPower50: fixtureDetails?.MinPower50 || '',
    MinPower80: fixtureDetails?.MinPower80 || '',
    MinPower100: fixtureDetails?.MinPower100 || '',
    manufacturer: fixtureDetails?.manufacturer || '',
    manufacturersku: fixtureDetails?.manufacturersku || '',
    MinimumLightLevelForFailureDetection: fixtureDetails?.MinimumLightLevelForFailureDetection || '',
    LegacyPowerDraw: fixtureDetails?.LegacyPowerDraw || '',
    nemasocket: fixtureDetails?.nemasocket || 'Yes',
  });

  const [createEditFixtureValidation, setCreateEditFixtureValidation] = useState<NewEditFixtureValidationProp>({
    nameError: false,
    nameErrorMessage: '',
    minLightLevelError: false,
    minLightLevelErrorMessage: '',
    legacyPowerDrawError: false,
    legacyPowerDrawErrorMessage: '',
    MinPower100Error: false,
    MinPower100ErrorMessage: '',
    MinPower80Error: false,
    MinPower80ErrorMessage: '',
    MinPower50Error: false,
    MinPower50ErrorMessage: '',
    MinPower10Error: false,
    MinPower10ErrorMessage: '',
    MinPower0Error: false,
    MinPower0ErrorMessage: '',
    MaxPower100Error: false,
    MaxPower100ErrorMessage: '',
    MaxPower80Error: false,
    MaxPower80ErrorMessage: '',
    MaxPower50Error: false,
    MaxPower50ErrorMessage: '',
    MaxPower10Error: false,
    MaxPower10ErrorMessage: '',
    MaxPower0Error: false,
    MaxPower0ErrorMessage: '',
  });

  const selectBoxWidth = 127;
  const [fixtureTypeFilter] = useState(Utils.getFixtureType());
  const [nemaSocket, setNemaSocket] = useState<RadioButtonElementProps>({ label: createEditFixture?.nemasocket, key: createEditFixture?.nemasocket });
  const powerDrawArray = [
    { title: '100%', min: 'MinPower100', max: 'MaxPower100', minValue: createEditFixture.MinPower100, maxValue: createEditFixture.MaxPower100, minError: createEditFixtureValidation.MinPower100Error, minErrorMessage: createEditFixtureValidation.MinPower100ErrorMessage, maxError: createEditFixtureValidation.MaxPower100Error, maxErrorMessage: createEditFixtureValidation.MaxPower100ErrorMessage },
    { title: '80%', min: 'MinPower80', max: 'MaxPower80', minValue: createEditFixture.MinPower80, maxValue: createEditFixture.MaxPower80, minError: createEditFixtureValidation.MinPower80Error, minErrorMessage: createEditFixtureValidation.MinPower80ErrorMessage, maxError: createEditFixtureValidation.MaxPower80Error, maxErrorMessage: createEditFixtureValidation.MaxPower80ErrorMessage },
    { title: '50%', min: 'MinPower50', max: 'MaxPower50', minValue: createEditFixture.MinPower50, maxValue: createEditFixture.MaxPower50, minError: createEditFixtureValidation.MinPower50Error, minErrorMessage: createEditFixtureValidation.MinPower50ErrorMessage, maxError: createEditFixtureValidation.MaxPower50Error, maxErrorMessage: createEditFixtureValidation.MaxPower50ErrorMessage },
    { title: '10%', min: 'MinPower10', max: 'MaxPower10', minValue: createEditFixture.MinPower10, maxValue: createEditFixture.MaxPower10, minError: createEditFixtureValidation.MinPower10Error, minErrorMessage: createEditFixtureValidation.MinPower10ErrorMessage, maxError: createEditFixtureValidation.MaxPower10Error, maxErrorMessage: createEditFixtureValidation.MaxPower10ErrorMessage },
    { title: '0%', min: 'MinPower0', max: 'MaxPower0', minValue: createEditFixture.MinPower0, maxValue: createEditFixture.MaxPower0, minError: createEditFixtureValidation.MinPower0Error, minErrorMessage: createEditFixtureValidation.MinPower0ErrorMessage, maxError: createEditFixtureValidation.MaxPower0Error, maxErrorMessage: createEditFixtureValidation.MaxPower0ErrorMessage },
  ];
  const setName = (newName: string) => {
    const name = newName;
    const validation = formValidation(
      name,
      { required: true },
    );
    setCreateEditFixture((oldValues) => ({
      ...oldValues,
      name,
    }));
    if (validation.hasError) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        nameError: validation.hasError,
        nameErrorMessage: validation.errorMsg,
      }));
    } else {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        nameError: false,
        nameErrorMessage: '',
      }));
    }
  };

  const setPowerDraw = (powerDrawName:string, newName: string) => {
    const powerValue = newName;
    const validation = formValidation(
      powerValue,
      { range: true },
    );
    setCreateEditFixture((oldValues) => ({
      ...oldValues,
      [powerDrawName]: powerValue,
    }));
    if (validation.hasError) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        [`${powerDrawName}Error`]: validation.hasError,
        [`${powerDrawName}ErrorMessage`]: validation.errorMsg,
      }));
    } else {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        [`${powerDrawName}Error`]: false,
        [`${powerDrawName}ErrorMessage`]: '',
      }));
    }
  };

  function checkValidation() : boolean {
    if ((Number(createEditFixture.MinimumLightLevelForFailureDetection) < 0) || (Number(createEditFixture.MinimumLightLevelForFailureDetection) > 100)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        minLightLevelError: true,
        minLightLevelErrorMessage: 'Val should be a number between 0 and 100',
      }));
      return true;
    }
    if (Number(createEditFixture.LegacyPowerDraw) < -1) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        legacyPowerDrawError: true,
        legacyPowerDrawErrorMessage: 'Val should be a number >= -1',
      }));
      return true;
    }

    if (Number(createEditFixture.MinPower0) > Number(createEditFixture.MaxPower0)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        MinPower0Error: true,
        MinPower0ErrorMessage: 'Min should be < Max',
      }));
      return true;
    }
    if (Number(createEditFixture.MinPower10) > Number(createEditFixture.MaxPower10)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        MinPower10Error: true,
        MinPower10ErrorMessage: 'Min should be < Max',
      }));
      return true;
    }
    if (Number(createEditFixture.MinPower50) > Number(createEditFixture.MaxPower50)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        MinPower50Error: true,
        MinPower50ErrorMessage: 'Min should be < Max',
      }));
      return true;
    }
    if (Number(createEditFixture.MinPower80) > Number(createEditFixture.MaxPower80)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        MinPower80Error: true,
        MinPower80ErrorMessage: 'Min should be < Max',
      }));
      return true;
    }
    if (Number(createEditFixture.MinPower100) > Number(createEditFixture.MaxPower100)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        MinPower100Error: true,
        MinPower100ErrorMessage: 'Min should be < Max',
      }));
      return true;
    }
    if (Number(createEditFixture.LegacyPowerDraw) <= Number(createEditFixture.PowerDraw)) {
      setCreateEditFixtureValidation((oldValues) => ({
        ...oldValues,
        legacyPowerDrawError: true,
        legacyPowerDrawErrorMessage: 'Legacy power draw should be greater than wattage rating',
      }));
      return true;
    }
    return false;
  }
  const fixtureTitle = (actions === 'Create') ? 'Create new ' : 'Edit ';

  return (
    <div className="fixture-interactions create-fixture">
      <Modal
        setModalOpen={modalOpen}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          const currentFixture = createEditFixture;
          if (currentFixture.name === '') {
            setName(currentFixture.name);
            return;
          }

          if (checkValidation()) {
            return;
          }
          try {
            const postBody: CreateEditFixturePostProp = {
              name: currentFixture.name,
              PowerDraw: currentFixture.PowerDraw,
              description: currentFixture.description,
              fixtureType: currentFixture.fixtureType.key,
              MaxPower0: currentFixture.MaxPower0,
              MaxPower10: currentFixture.MaxPower10,
              MaxPower50: currentFixture.MaxPower50,
              MaxPower80: currentFixture.MaxPower80,
              MaxPower100: currentFixture.MaxPower100,
              MinPower0: currentFixture.MinPower0,
              MinPower10: currentFixture.MinPower10,
              MinPower50: currentFixture.MinPower50,
              MinPower80: currentFixture.MinPower80,
              MinPower100: currentFixture.MinPower100,
              manufacturer: currentFixture.manufacturer,
              manufacturersku: currentFixture.manufacturersku,
              MinimumLightLevelForFailureDetection: currentFixture.MinimumLightLevelForFailureDetection,
              LegacyPowerDraw: currentFixture.LegacyPowerDraw,
              nemasocket: nemaSocket.key,
            };
            let result;
            if (actions === 'Create') {
              result = await postRequest(
                `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures`,
                postBody,
              );
            } else {
              result = await postRequest(
                `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures/${fixtureDetails?.fixtureid}`,
                postBody,
              );
            }
            if (!result.error) {
              addNotification({ type: 'info', message: `Your ${actions} fixture operation is completed.` });
              if (updateFixtures) {
                updateFixtures();
              }
            } else {
              addNotification({ type: 'error', message: `Your ${actions} fixture operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: `Your ${actions} user operation is failed.` });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel={actions === 'Edit' ? 'Delete' : undefined}
        secondaryButtonAction={async () => {
          try {
            const result = await deleteRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures/${fixtureDetails?.fixtureid}`,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Delete fixture" operation is completed.' });
              if (updateFixtures) {
                updateFixtures();
              }
            } else {
              addNotification({ type: 'error', message: `Your "Delete fixture" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Delete fixture" operation is failed.' });
          }
          modalOpen(false);
        }}
        tertiaryButtonLabel="Cancel"
        tertiaryButtonAction={() => modalOpen(false)}
        width="650"
      >
        <div className="modal-divided">
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              <div className="fixture-title">
                {fixtureTitle}
                fixture
              </div>
              <div className="fixture-details"> Fixture details </div>
              <Textbox
                label="Name"
                name="Name"
                value={createEditFixture.name}
                placeholder="Enter"
                onChange={(event) => {
                  setName(event.target.value);
                }}
                isRequired
                error={createEditFixtureValidation.nameError}
                errorMessage={createEditFixtureValidation.nameErrorMessage}
              />
              <Textbox
                label="Description"
                name="Description"
                value={createEditFixture.description}
                placeholder="Enter"
                onChange={(event) => {
                  const description = event.target.value;
                  setCreateEditFixture((oldValues) => ({ ...oldValues, description }));
                }}
              />
              <div className="typeWattageWrapper">
                <SelectBox
                  label="Fixture type"
                  onClick={(item: SelectBoxItemType) =>
                    setCreateEditFixture((oldValues) => ({ ...oldValues, fixtureType: item }))}
                  selectedItemKey={createEditFixture?.fixtureType?.key || ''}
                  list={Utils.arrayToSelectbox(fixtureTypeFilter || [], 'key', 'title')}
                  title={createEditFixture.fixtureType.title || 'Select'}
                  type="light"
                  listWidth={selectBoxWidth}
                />
                <Textbox
                  label="Wattage rating"
                  name="WattageRating"
                  value={createEditFixture.PowerDraw}
                  placeholder="Enter"
                  onChange={(event) => {
                    const PowerDraw = event.target.value;
                    setCreateEditFixture((oldValues) => ({ ...oldValues, PowerDraw }));
                  }}
                />
                <span className="wattageRatingUnits"> W </span>
              </div>
              <div className="nemaSocketWrapper">
                <span>NEMA socket</span>
                <RadioButtons
                  onClick={(clickedRadioBtn: RadioButtonElementProps) =>
                    setNemaSocket(clickedRadioBtn)}
                  selected={nemaSocket}
                  list={[{ label: 'Yes', key: 'Yes' }, { label: 'No', key: 'No' }]}
                  type="light"
                />
              </div>
              <div className="manufacturerSkuWrapper">
                <Textbox
                  label="Manufacturer"
                  name="Manufacturer"
                  value={createEditFixture.manufacturer}
                  placeholder="Enter"
                  onChange={(event) => {
                    const manufacturer = event.target.value;
                    setCreateEditFixture((oldValues) => ({ ...oldValues, manufacturer }));
                  }}
                />
                <Textbox
                  label="Manufacturer SKU"
                  name="ManufacturerSKU"
                  value={createEditFixture.manufacturersku}
                  placeholder="Enter"
                  onChange={(event) => {
                    const manufacturersku = event.target.value;
                    setCreateEditFixture((oldValues) => ({ ...oldValues, manufacturersku }));
                  }}
                />
              </div>
              <Textbox
                label="Minimum Light Level for Failure Detection"
                name="MinimumLightLevelForFailureDetection"
                value={createEditFixture.MinimumLightLevelForFailureDetection}
                placeholder="Enter"
                onChange={(event) => {
                  const MinimumLightLevelForFailureDetection = event.target.value;
                  setCreateEditFixture((oldValues) => ({ ...oldValues, MinimumLightLevelForFailureDetection }));
                }}
                error={createEditFixtureValidation.minLightLevelError}
                errorMessage={createEditFixtureValidation.minLightLevelErrorMessage}
              />
              <Textbox
                label="Legacy Power Draw"
                name="LegacyPowerDraw"
                value={createEditFixture.LegacyPowerDraw}
                placeholder="Enter"
                onChange={(event) => {
                  const LegacyPowerDraw = event.target.value;
                  setCreateEditFixture((oldValues) => ({ ...oldValues, LegacyPowerDraw }));
                }}
                error={createEditFixtureValidation.legacyPowerDrawError}
                errorMessage={createEditFixtureValidation.legacyPowerDrawErrorMessage}
              />
            </div>
          </div>
          <div className="modal-divided__rightcontent">
            <div className="powerdraw-details"> Power draw profile </div>
            <div className="powerDrawWrapper no-border">
              <div className="powerDrawHeading1"> Driver level </div>
              <div className="powerDrawHeading2"> Min </div>
              <div className="powerDrawHeading3"> Max </div>
            </div>

            {powerDrawArray.map((element) => (
              <div key={element.title} className="powerDrawWrapper">
                <div className="powerDrawtitle">
                  { element.title }
                </div>
                <Textbox
                  label=""
                  name={`Min${element.title}`}
                  value={element.minValue}
                  placeholder="Enter"
                  onChange={(event) => {
                    setPowerDraw(element.min, event.target.value);
                  }}
                  error={element.minError}
                  errorMessage={element.minErrorMessage}
                />
                <span className="powerDrawUnits"> W </span>
                <Textbox
                  label=""
                  name={`Max${element.title}`}
                  value={element.maxValue}
                  placeholder="Enter"
                  onChange={(event) => {
                    setPowerDraw(element.max, event.target.value);
                  }}
                  error={element.maxError}
                  errorMessage={element.maxErrorMessage}
                />
                <span className="powerDrawUnits"> W </span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateEditFixture;
