import React, { useEffect, useState } from 'react';
import { ListRowProps } from 'react-virtualized';

import Modal from '../../../../../Common/Components/Modal';

import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import Checkbox from '../../../../../Common/Components/Checkbox';

import { CopyFixtureProps, CopyFixtureStateProp, CopyFixtureValidationProp } from '../../../../../types/CopyFixtureProps';
import { FixtureObject } from '../../../../../types/FixtureObject';

import Utils from '../../../../../utils/Utils';
import { getRequest, postRequestAll } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import formValidation from '../../../../../utils/form/formValidation';

function CopyFixture(props: CopyFixtureProps): JSX.Element {
  const { modalOpen, allAccountsDetails, allSitesDetails, selectedCustomer, selectedSite, updateFixtures } = props;
  const { addNotification } = useAppContext();

  const selectBoxWidth = 300;
  const [sitesList, setSitesList] = useState(allSitesDetails);
  const [fixturesList, setFixturesList] = useState<Array<FixtureObject>>();
  const [selectedFixtures, setSelectedFixtures] = useState<Array<FixtureObject>>([]);

  const [copyFixture, setCopyFixture] = useState<CopyFixtureStateProp>({
    account: { title: '', key: '' },
    site: { title: '', key: '' },
    fixtures: { title: '', key: '' },
  });

  const [copyFixtureValidation, setCopyFixtureValidation] = useState<CopyFixtureValidationProp>({
    hasError: false,
    errorMessage: '',
    accountError: false,
    accountErrorMessage: '',
    siteError: false,
    siteErrorMessage: '',
    fixturesError: false,
    fixturesErrorMessage: '',
  });

  const setAccount = (newName?: SelectBoxItemType) => {
    const account = newName !== undefined ? newName : copyFixture.account;
    const validation = formValidation(
      account.key,
      { required: true },
    );
    setCopyFixture((oldValues) => ({
      ...oldValues,
      account,
      site: { title: '', key: '' },
      fixtures: { title: '', key: '' },
    }));
    setCopyFixtureValidation((oldValues) => ({
      ...oldValues,
      accountError: validation.hasError,
      accountErrorMessage: validation.errorMsg,
    }));
  };

  const setSite = (newName?: SelectBoxItemType) => {
    const site = newName !== undefined ? newName : copyFixture.site;
    const validation = formValidation(
      site.key,
      { required: true },
    );

    if (copyFixture.account.key === '') {
      setCopyFixture((oldValues) => ({
        ...oldValues,
        site: { title: '', key: '' },
        fixtures: { title: '', key: '' },
      }));
      setCopyFixtureValidation((oldValues) => ({
        ...oldValues,
        accountError: true,
        accountErrorMessage: 'Please select an account',
      }));
    } else {
      setCopyFixture((oldValues) => ({
        ...oldValues,
        site,
        fixtures: { title: '', key: '' },
      }));
      setCopyFixtureValidation((oldValues) => ({
        ...oldValues,
        accountError: false,
        accountErrorMessage: '',
        siteError: validation.hasError,
        siteErrorMessage: validation.errorMsg,
      }));
    }
  };

  useEffect(() => {
    if (copyFixture.account.key !== '') {
      setSitesList(allSitesDetails?.filter((siteData) => (siteData.orgid === copyFixture.account.key)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    copyFixture.account,
  ]);

  useEffect(() => {
    if ((copyFixture.account.key !== '') && (copyFixture.site.key !== '')) {
      const requests = `/customers/${copyFixture.account.key}/sites/${copyFixture.site.key}/fixtures`;
      getRequest(requests)
        .then((responses) => {
          setFixturesList(responses);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    copyFixture.site,
  ]);
  function rowRendererFixtures({ key, index, style }: ListRowProps) {
    return (
      <div key={key} style={style} className="fixtures-filter__list-row">
        {(typeof fixturesList !== 'undefined' && fixturesList?.length > 0)
        && (
        <div className="fixturesListRenderer">
          <Checkbox
            checked={selectedFixtures.some((selectedFixture) => fixturesList[index].fixtureid === selectedFixture.fixtureid)}
            onClick={(checked) => {
              if (checked) {
                setSelectedFixtures([
                  ...selectedFixtures,
                  fixturesList[index],
                ]);
              } else {
                setSelectedFixtures(
                  selectedFixtures.filter(
                    (fixture: FixtureObject) => fixture.fixtureid !== fixturesList[index].fixtureid,
                  ),
                );
              }
            }}
          />
          <span>{fixturesList[index].name}</span>
        </div>
        )}
      </div>
    );
  }
  return (
    <div className="fixture-interactions copy-fixture">
      <Modal
        setModalOpen={modalOpen}
        primaryButtonLabel="Submit"
        primaryButtonAction={async () => {
          const currentCopyFixture = copyFixture;
          if (currentCopyFixture.account.key === '') {
            setAccount(currentCopyFixture.account);
            return;
          }
          if (currentCopyFixture.site.key === '') {
            setSite(currentCopyFixture.site);
            return;
          }
          if (selectedFixtures === undefined || selectedFixtures.length === 0) {
            return;
          }
          if (selectedFixtures !== undefined) {
            try {
              const requests: { path: string, data: {
                name: string,
                PowerDraw: string,
                description: string,
                fixtureType: string,
                MaxPower0: string,
                MaxPower10: string,
                MaxPower50: string,
                MaxPower80: string,
                MaxPower100: string,
                MinPower0: string,
                MinPower10: string,
                MinPower50: string,
                MinPower80: string,
                MinPower100: string,
                manufacturer: string,
                manufacturersku: string,
                nemasocket: string, }}[] = Array.from(selectedFixtures.values()).map(
                  (value) => ({
                    path: `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures`,
                    data: { name: `${value.name}-copy`,
                      PowerDraw: value.PowerDraw,
                      description: value.description,
                      fixtureType: value.fixtureType,
                      MaxPower0: value.MaxPower0,
                      MaxPower10: value.MaxPower10,
                      MaxPower50: value.MaxPower50,
                      MaxPower80: value.MaxPower80,
                      MaxPower100: value.MaxPower100,
                      MinPower0: value.MinPower0,
                      MinPower10: value.MinPower10,
                      MinPower50: value.MinPower50,
                      MinPower80: value.MinPower80,
                      MinPower100: value.MinPower100,
                      manufacturer: value.manufacturer,
                      manufacturersku: value.manufacturersku,
                      nemasocket: value.nemasocket },
                  }),
                );
              postRequestAll(requests)
                .then((responses) => {
                  responses.results.forEach((resp, index) => {
                    if (resp.status === 'fulfilled') {
                      if (updateFixtures) {
                        updateFixtures();
                      }
                    } else {
                      addNotification({ type: 'error', message: `Your "Copy fixture from site" operation for ${requests[index].data.name} is failed.` });
                    }
                  });

                  if (responses.resolved === requests.length) {
                    addNotification({ type: 'info', message: 'Your "Copy fixture from site" operation is completed.' });
                    if (updateFixtures) {
                      updateFixtures();
                    }
                  }
                });
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Copy fixture from site" operation is failed.' });
            }
          }
          modalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => modalOpen(false)}
        width="361"
      >
        <div className="modal-content-data">
          <div className="copyFixture-title"> Copy fixture from site </div>
          <SelectBox
            label="Account"
            onClick={(item: SelectBoxItemType) => setAccount(item)}
            selectedItemKey={copyFixture?.account?.key || ''}
            list={Utils.arrayToSelectbox(allAccountsDetails || [], 'orgid', 'name')}
            title={copyFixture.account.title || 'Select'}
            type="light"
            error={copyFixtureValidation.accountError}
            errorMessage={copyFixtureValidation.accountErrorMessage}
            isRequired
            listWidth={selectBoxWidth}
          />
          <SelectBox
            label="Site"
            onClick={(item: SelectBoxItemType) => setSite(item)}
            selectedItemKey={copyFixture?.site?.key || ''}
            list={Utils.arrayToSelectbox(sitesList || [], 'siteid', 'name')}
            title={copyFixture.site.title || 'Select'}
            type="light"
            error={copyFixtureValidation.siteError}
            errorMessage={copyFixtureValidation.siteErrorMessage}
            isRequired
            listWidth={selectBoxWidth}
          />
          <SelectBox
            label="Fixtures"
            onClick={() => undefined}
            selectedItemKey={copyFixture?.fixtures?.key || ''}
            list={Utils.arrayToSelectbox(fixturesList || [], 'fixtureid', 'name')}
            rowRenderer={rowRendererFixtures}
            title="Select"
            type="light"
            isRequired
            listWidth={selectBoxWidth}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CopyFixture;
