/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { DimmingSequenceProps } from '../../../types/DimmingSequenceProps';
import Utils from '../../../utils/Utils';

import { ReactComponent as DeleteIcon } from '../../../img/icons/trash.svg';
import { ReactComponent as RegularDayIcon } from '../../../img/icons/schedule-small.svg';
import { ReactComponent as SpecialDayIcon } from '../../../img/icons/schedule-highlight.svg';
import { ReactComponent as NoSelectedDateTypeIcon } from '../../../img/icons/dotdotdot-horizontal.svg';

function DimmingSequence(props: DimmingSequenceProps): JSX.Element {
  const { index, selectedIndex, scheduleEvent, isReadOnly, deleteSequenceDisabled, deleteSequence, setSelectedSequence } = props;

  const days = scheduleEvent.days && scheduleEvent.days?.length > 0 ? scheduleEvent.days.join(', ') : 'undefined';
  const subtitle = scheduleEvent.date ? Utils.getConvertedDate(scheduleEvent.date, 'LLLL d, yyyy') : days;

  return (
    <div key={index} className={`dimming-sequence ${index === selectedIndex ? 'selected' : ''} ${deleteSequenceDisabled ? 'disabled' : ''}`}>
      <div onClick={setSelectedSequence} className="dimming-sequence__content">
        <div className="dimming-sequence__content-title">
          { (scheduleEvent.days === undefined && scheduleEvent.date === undefined)
            ? (<NoSelectedDateTypeIcon className="unselected" />)
            : (scheduleEvent.date ? <SpecialDayIcon /> : <RegularDayIcon />)}
          <span className="dimming-sequence__content-title-text">{`Sequence ${index + 1}`}</span>
        </div>
        <span className={`dimming-sequence__content-subtitle ${subtitle === 'undefined' ? 'no-days' : ''}`}>
          {subtitle}
        </span>
      </div>
      {!isReadOnly && <button className="dimming-sequence__delete" type="button" disabled={deleteSequenceDisabled} onClick={deleteSequence}><DeleteIcon /></button>}
    </div>
  );
}

export default DimmingSequence;
