/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartDataSets } from 'chart.js';
import { DateTime } from 'luxon';
import React from 'react';
import { ReactComponent as LightsOffIcon } from '../img/icons/lights-off.svg';
import { ReactComponent as LightsOn100Icon } from '../img/icons/lights-on-100.svg';
import { ReactComponent as LightsOn25Icon } from '../img/icons/lights-on-25.svg';
import { ReactComponent as LightsOn50Icon } from '../img/icons/lights-on-50.svg';
import { ReactComponent as LightsOn75Icon } from '../img/icons/lights-on-75.svg';
import { AddNotificationProps } from '../types/AppContext';
import { GenericSensorData, GenericSensorTableData, StaticSensorValue } from '../types/GenericSensorData';
import { NodeObject } from '../types/NodeObject';
import { SelectedCustomer, SelectedSite } from '../types/PageComponentProps';
import { SelectBoxItemType } from '../types/SelectBoxPropsType';
import { RealTimeSensorRequestPayload, SensorDatapoint, SensorRequestParams, SensorResponseData } from '../types/SensorRequestData';
import { SensorReportResponseItem, SensorRequestPayload } from '../types/SensorRequestPayload.d';
import { postRequest } from './fetch';
import Utils from './Utils';

class Sensors {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  static createChartPayload(
    startDate: Date,
    endDate: Date,
    selectedSite: SelectedSite,
    selectedCustomer: SelectedCustomer,
    selectedItems: Map<string, NodeObject>,
    sensorName1: string,
    sensorName2: string,
  ): SensorRequestPayload {
    const startDateFormatted = Utils.getConvertedDate(startDate, "yyyy-MM-dd'T'00:00:00'Z'");
    const endDateFormatted = Utils.getConvertedDate(endDate, "yyyy-MM-dd'T'23:59:00'Z'");
    return {
      siteid: selectedSite.id,
      orgid: selectedCustomer.id,
      nodeids: Array.from(selectedItems).map(([, node]) => node.nodeid),
      start: startDateFormatted,
      end: endDateFormatted,
      sensors:
        Array.from(
          new Set(
            Array.from(selectedItems).map(([, node]) => [
              Sensors.getSensorId(sensorName1, node.model),
              Sensors.getSensorId(sensorName2, node.model),
            ]).flat(),
          ),
        ).filter((sensorId) => sensorId.length > 0),
      timezone: selectedSite.timezone || 'America/New_York',
    };
  }

  static getChartDataFromResults(
    results: SensorReportResponseItem[],
    sensor1Key: string,
    selectedNodes: Map<string, NodeObject>,
  ): ChartDataSets[] {
    const borderDash = [5, 5];
    return results
      .map((sensorData, index) => {
        const isFirstSensor = sensor1Key === Sensors.getSensorName(sensorData.sensor);
        return {
          label: sensorData.nodeid,
          nodeName: selectedNodes.get(sensorData.nodeid)?.name || '',
          yAxisID: Sensors.getSensorName(sensorData.sensor),
          sensorLabel: Sensors.getSensorTitleById(sensorData.sensor),
          sensorId: sensorData.sensor,
          data: sensorData.datapoints.map((dp) => ({ x: dp.timestamp, y: dp.value })),
          pointStyle: isFirstSensor ? 'circle' : 'rectRot',
          fill: false,
          steppedLine: 'before',
          pointRadius: 4,
          pointHoverRadius: 4,
          borderWidth: 2,
          borderDash: isFirstSensor ? [0, 0] : borderDash,
        };
      });
  }

  static transformSensorResults(
    results: PromiseSettledResult<{ data: SensorResponseData, error: string, status: number }>[],
    reqSensors: string[],
    selectedNode: NodeObject,
  ): { data?: SensorResponseData | undefined, error?: string | undefined, status: number } {
    let response: { data?: SensorResponseData; error?: string; status: number; } = { status: 200 };
    let first = true;

    results.forEach((result, sensorIndex) => {
      if (result.status === 'fulfilled') {
        if (first) {
          first = false;
          response = result.value;
        } else if (response.data) {
          response.data.sensorid = response.data.sensorid.concat(result.value.data?.sensorid);
          response.data.data[0].data = response.data.data[0]?.data?.concat(result.value.data?.data[0]?.data);
        }

        if (response.data?.data[0]?.data[sensorIndex]) {
          response.data.data[0].data[sensorIndex].sensorid = Sensors.getSensorId(reqSensors[sensorIndex], selectedNode.model);
        }
      }
    });

    return response;
  }

  static transformSensorResultsMultiNode(
    results: PromiseSettledResult<{ data: SensorResponseData, error: string, status: number }>[],
    reqSensors: string[],
    selectedNodes: NodeObject[],
  ): { data?: SensorResponseData | undefined, error?: string | undefined, status: number } {
    const response: { data?: SensorResponseData; error?: string; status: number; } = {
      status: 200,
      data: {
        sensorid: reqSensors,
        nodeid: selectedNodes.map((node) => node.nodeid),
        data: selectedNodes.map((node) => ({
          nodeid: node.nodeid,
          message: '',
          data: [],
        })),
      },
    };

    results.forEach((result, sensorIndex) => {
      const sensorId = Sensors.getSensorId(reqSensors[sensorIndex], 'lco');

      if (result.status === 'fulfilled') {
        response.data?.data.forEach((sensorData, respIndex) => {
          result.value.data.data.forEach((resultData) => {
            if (sensorData.nodeid === resultData.nodeid && resultData.data.length > 0) {
              response.data?.data[respIndex].data.push({
                sensorid: sensorId,
                time: resultData.data[0].time,
                value: resultData.data[0].value,
              });
            }
          });
        });
      }
    });

    return response;
  }

  static getSensorTableWithDisplayedString(
    selectedItems: Map<string, NodeObject>,
    reqSensors: string[],
    response: { data?: SensorResponseData; error?: string; status: number; },
    staticFields?: {[index: string]: StaticSensorValue},
    originalData?: GenericSensorTableData[],
  ): GenericSensorTableData[] {
    const tmpOriginalData = originalData?.map((item) => {
      const res = { nodeid: item.nodeid } as GenericSensorData;
      Object.keys(item).forEach((key) => {
        if (key !== 'nodeid') {
          res[key] = { value: item[key] };
        }
      });
      return res;
    });
    return this.getSensorTableDataWithValueAndSensor(selectedItems, reqSensors, response, tmpOriginalData, staticFields)
      .map((item) => {
        const res: GenericSensorTableData = { nodeid: item.nodeid } as GenericSensorTableData;
        Object.keys(item).forEach((key) => {
          if (key !== 'nodeid') {
            res[key] = `${item[key].value} ${item[key].unit ? item[key].unit : ''}`;
          }
        });
        return res;
      });
  }

  static getSensorTableDataWithValueAndSensor(
    selectedItems: Map<string, NodeObject>,
    reqSensors: string[],
    response: { data?: SensorResponseData; error?: string; status: number; },
    originalData?: GenericSensorData[],
    staticFields: {[index: string]: StaticSensorValue} = {},
  ): GenericSensorData[] {
    const newData: GenericSensorData[] = [];
    const percent = 100;
    selectedItems.forEach((node) => {
      const originalValue = originalData && originalData.find((i) => i.nodeid === node.nodeid);
      const nodeSensorData = reqSensors
        .reduce(
          (o, key) => ({ ...{ [key]: { value: '--' } }, ...o }),
          originalValue || { nodeid: node.nodeid },
        ) as GenericSensorData;
      if (response.data) {
        const value = response.data as SensorResponseData;
        value.data?.forEach((item) => {
          if (item.nodeid === node.nodeid && item.data.length > 0) {
            const dataBySensorId = reqSensors.length === 1 ? { [Sensors.getSensorId(reqSensors[0], node.model)]: item.data } : Sensors.groupBySensorId(item);
            Object.keys(dataBySensorId).forEach((key) => {
              if (dataBySensorId[key] && dataBySensorId[key].length) {
                const sensorName: string = Sensors.getSensorName(key);
                const sensorUnit = Sensors.getSensorUnit(sensorName);
                const dataItem = dataBySensorId[key].sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())[0];
                const datapoint = Sensors.getFirstSensorDatapoint(dataItem, Sensors.getSensorConversion(sensorName));
                let convertedSensorUnitObj = Sensors.getConvertedUnits(datapoint.value, sensorUnit);
                if (sensorName === 'powerFactor') {
                  convertedSensorUnitObj = {
                    value: Math.round(datapoint.value * percent).toString(),
                    unit: '%',
                  };
                }
                if (sensorName === 'driverLevel') {
                  convertedSensorUnitObj = {
                    value: Math.round(parseFloat(datapoint.value)).toString(),
                    unit: '%',
                  };
                  if (reqSensors.includes('lastDriverLevelReport')) {
                    nodeSensorData.lastDriverLevelReport = { value: datapoint.time, unit: '', time: datapoint.time };
                  }
                }
                nodeSensorData[sensorName] = { ...convertedSensorUnitObj, time: datapoint.time };
              }
            });
          }
        });
      }

      const newStaticFields = { ...staticFields };

      Object.keys(staticFields).forEach((staticField) => {
        if ((node as any)[staticField] !== undefined) {
          const conversionFn = staticFields[staticField].conversion || ((val) => val);
          newStaticFields[staticField] = { value: conversionFn((node as any)[staticField]) };
        }
      });

      newData.push({ ...nodeSensorData, ...newStaticFields });
    });
    return newData;
  }

  private static groupBySensorId(item: { nodeid: string; message: string; data: { sensorid: string; time: string; value: number; }[]; }):
   { [index: string]: { time: string; value: number; }[]; } {
    return item.data.reduce<{ [index: string]: { time: string; value: number; }[]; }>((acc, nextItem) => {
      if (acc[nextItem.sensorid]) {
        acc[nextItem.sensorid].push({ value: nextItem.value, time: nextItem.time });
      } else {
        acc[nextItem.sensorid] = [{ value: nextItem.value, time: nextItem.time }];
      }
      return acc;
    }, {} as { [index: string]: { time: string; value: number; }[]; });
  }

  static getSensorDataUrl(params: { customerId: string, siteId: string }): string {
    return `/customers/${params.customerId}/sites/${params.siteId}/sensors?LCOFetch=true`;
  }

  static getSensorPayload({ date, nodeIds, sensorIds, fromDate = 'yesterday' }:
  SensorRequestParams): RealTimeSensorRequestPayload {
    const daysInMonth = 30;
    const hoursInDay = 24;
    const minutesInHour = 60;
    const secondsInMin = 60;
    const msInSec = 1000;
    const makeDateYesterday = new Date(date);
    const yesterday = new Date(makeDateYesterday
      .setTime(makeDateYesterday.getTime() - (hoursInDay * minutesInHour * secondsInMin * msInSec))).toISOString();

    const makeDateLastMonth = new Date(date);
    const lastMonth = new Date(makeDateLastMonth.setTime(
      makeDateLastMonth.getTime() - (daysInMonth * hoursInDay * minutesInHour * secondsInMin * msInSec),
    )).toISOString();

    const d = fromDate === 'yesterday' ? yesterday : lastMonth;

    return {
      nodeids: nodeIds,
      sensorids: sensorIds,
      limit: 1,
      date: d,
    };
  }

  static getSensorPayloadForDriverLevel({ date, nodeIds, sensorIds }:
  SensorRequestParams): RealTimeSensorRequestPayload {
    return {
      nodeids: nodeIds,
      sensorids: sensorIds,
      limit: 1,
      date: date.toISOString(),
    };
  }

  static retrySensorDataFetcher(
    siteId: string,
    orgId: string,
    selectedNodes: NodeObject[],
    sensorIds: string[],
  ): Promise<{ data: SensorResponseData, error: string, status: number }> {
    const url = Sensors.getSensorDataUrl({
      customerId: orgId,
      siteId,
    });
    const payloadParams = {
      date: new Date(),
      nodeIds: selectedNodes.map((nodeobj) => nodeobj.nodeid),
      sensorIds,
      fromDate: 'yesterday',
    } as SensorRequestParams;
    return postRequest<RealTimeSensorRequestPayload, SensorResponseData>(
      url,
      Sensors.getSensorPayload(payloadParams),
    ).then((resp) => {
      const sensorsLength = sensorIds.filter((sensor) => sensor !== '').length;
      const nodeHasAllSensorData = resp.data?.data?.filter((dataForNode) => dataForNode.data.length === sensorsLength).length === payloadParams.nodeIds.length;
      if (!nodeHasAllSensorData) {
        return postRequest<RealTimeSensorRequestPayload, SensorResponseData>(
          url,
          Sensors.getSensorPayload({
            ...payloadParams,
            fromDate: 'lastmonth',
          }),
        );
      }
      return Promise.resolve(resp);
    });
  }

  static getFirstSensorDatapoint(
    datapoint: {time: string, value: number | string} | undefined,
    conversion: (val: any) => any = (val) => val,
    fixedNumber = 2,
  ): SensorDatapoint {
    return {
      time: datapoint?.time ? Utils.getConvertedDate(datapoint.time) : '--',
      value: datapoint?.value !== undefined ? Number(conversion(datapoint.value)).toFixed(fixedNumber).toString() : '--',
    };
  }

  static fetchAndDisplayChartData(
    startDate: Date,
    endDate: Date,
    selectedCustomer: SelectedCustomer,
    selectedSite: SelectedSite,
    selectedItems: Map<string, NodeObject>,
    sensor1: SelectBoxItemType,
    sensor2: SelectBoxItemType,
    setChartDataset: React.Dispatch<React.SetStateAction<ChartDataSets[] | undefined>>,
    setRawChartDataset: React.Dispatch<React.SetStateAction<SensorReportResponseItem[] | undefined>>,
    setChartModalToggle: (newVal: boolean) => void,
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>,
    addNotification: (item: AddNotificationProps) => void,
  ): void {
    const maxDayDiff = 30;
    const maxDataPoints = 5000;

    const dayDiff = Math.ceil(DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), ['days']).toObject().days || 0);
    if (dayDiff > maxDayDiff) {
      addNotification({ type: 'warning', message: 'Please select maximum 30 days difference between both the dates.' });
    } else {
      setChartDataset([]);
      setRawChartDataset([]);
      setChartModalToggle(true);
      setWaiting(true);
      const payload = Sensors.createChartPayload(
        startDate,
        endDate,
        selectedSite,
        selectedCustomer,
        selectedItems,
        sensor1.key,
        sensor2.key,
      );

      postRequest<SensorRequestPayload, SensorReportResponseItem[]| { looker_error: string } >(`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/sensors-report`, payload)
        .then(
          (response) => {
            if ((response.data as { looker_error: string }).looker_error) {
              addNotification({ type: 'error', message: 'An internal error occurred and sensor data cannot be retrieved at this time.' });
              setWaiting(false);
            }
            const finalResult = Sensors.getChartDataFromResults(
              response.data as SensorReportResponseItem[],
              sensor1.key,
              selectedItems,
            );
            if (finalResult.map(((i) => i.data)).flat().length > maxDataPoints) {
              addNotification({ type: 'info', message: 'Too many data points. Try reducing the time span or number of nodes.' });
              setChartDataset([]);
              setRawChartDataset([]);
              setWaiting(false);
            } else {
              setChartDataset(finalResult);
              setRawChartDataset(response.data as SensorReportResponseItem[]);
              setWaiting(false);
            }
          },
          (error) => {
            addNotification({ type: 'error', message: 'An internal error occurred and sensor data cannot be retrieved at this time.' });
            setChartDataset([]);
            setRawChartDataset([]);
            setWaiting(false);
          },
        );
    }
  }

  static getSensorName(sensorId: string): string {
    switch (sensorId) {
      case 'lt':
        return 'driverLevel';
      case 'mP':
      case 'P':
        return 'activePower';
      case 'mw':
      case 'w':
        return 'cumulativeEnergyUse';
      case 'l-i':
      case 'l':
        return 'ambientLight';
      case 'sinr':
        return 'sinr';
      case 'RF':
        return 'radioSignalStrength';
      case 'rsrq':
        return 'linkQuality';
      case 'mi':
        return 'mainsCurrent';
      case 'mPr':
        return 'reactivePower';
      case 'v':
        return 'mainsVoltage';
      case 'mwr':
        return 'cumulativeReactiveEnergyUse';
      case 'BHL':
        return 'cumulativeOnStateLuminaire';
      case 'BHN':
        return 'cumulativeOnStateNode';
      case 'T':
        return 'internalTemperature';
      case 'mPF':
      case 'PF':
        return 'powerFactor';
      case 'cellid':
        return 'cellId';
      case 'bc':
        return 'nodePowerCycleCount';
      case 'rlym':
        return 'relayCycleCount';
      case 'mdmtm':
        return 'modemUptime';
      case 'wbcnt':
        return 'modemBootCount';
      case 'rcnt':
        return 'apFsRestoreCount';
      default: break;
    }

    return '';
  }

  static getSensorUnit(sensorName: string): string {
    switch (sensorName) {
      case 'driverLevel':
        return '%';
      case 'activePower':
        return 'W';
      case 'cumulativeEnergyUse':
        return 'kWh';
      case 'ambientLight':
        return 'lux';
      case 'sinr':
        return 'dB';
      case 'radioSignalStrength':
        return 'dBm';
      case 'linkQuality':
        return 'dB';
      case 'mainsVoltage':
        return 'V';
      case 'mainsCurrent':
        return 'mA';
      case 'reactivePower':
        return 'VAR';
      case 'cumulativeReactiveEnergyUse':
        return 'VARh';
      case 'cumulativeOnStateLuminaire':
        return 'h';
      case 'cumulativeOnStateNode':
        return 'h';
      case 'internalTemperature':
        return 'degC';
      case 'powerFactor':
        return '';
      case 'cellId':
        return '';
      case 'nodePowerCycleCount':
        return '';
      case 'relayCycleCount':
        return '';
      case 'modemUptime':
        return 's';
      case 'modemBootCount':
        return '';
      case 'apFsRestoreCount':
        return '';
      default: break;
    }

    return '';
  }

  static getRoundValue(value: string | undefined): string {
    return ((!value && value !== '0') || Number.isNaN(parseFloat(value))) ? '--' : Math.round(parseFloat(value)).toString();
  }

  static getConvertedUnits(value: number | string, unit: string): { value: string; unit: string; } {
    const rounding = 1000;
    const toFixed = 2;
    const val = Number(value);

    if (!Number.isNaN(val)) {
      const needToConvert = val >= rounding;
      const convertedValue = needToConvert ? (val / rounding).toFixed(toFixed) : val.toString();

      if (unit === 'mA') {
        return {
          value: convertedValue,
          unit: needToConvert ? 'A' : 'mA',
        };
      }

      if (unit === 'kWh') {
        return {
          value: convertedValue,
          unit: needToConvert ? 'MWh' : 'kWh',
        };
      }
    }

    return { value: value?.toString() || '--', unit };
  }

  static getSensorConversion(sensorName: string): any {
    const dbConv1 = 2;
    const dbConv2 = 200;
    const dbConv3 = 10;
    const roundTo = 10;
    const round = 1000;
    const minutesInHour = 60;

    switch (sensorName) {
      case 'sinr':
        return (val: any): string => {
          if (Number.isNaN(val)) {
            return val;
          }

          const dbval = (val * dbConv1 - dbConv2) / dbConv3;
          return (Math.round(dbval * roundTo) / roundTo).toString();
        };
      case 'cumulativeEnergyUse':
        return (val: number) => (val / round);
      case 'cumulativeOnStateLuminaire':
        return (val: number) => (val / minutesInHour);
      case 'cumulativeOnStateNode':
        return (val: number) => (val / minutesInHour);

      default: break;
    }

    return undefined;
  }

  static getSensorTitleById = (sensorId: string): string =>
    Sensors.getSensorName(sensorId);

  static getSensorTitleByName = (sensorId: string): string => {
    switch (sensorId) {
      case 'driverLevel':
        return 'Driver level';
      case 'ambientLight':
        return 'Ambient light level';
      case 'radioSignalStrength':
        return 'Radio signal strength';
      case 'linkQuality':
        return 'Link quality';
      case 'sinr':
        return 'SINR';
      case 'mainsCurrent':
        return 'Mains current';
      case 'activePower':
        return 'Active power';
      case 'mainsVoltage':
        return 'Mains voltage';
      case 'reactivePower':
        return 'Reactive power';
      case 'cumulativeEnergyUse':
        return 'Active energy';
      case 'cumulativeReactiveEnergyUse':
        return 'Reactive energy';
      case 'cumulativeOnStateLuminaire':
        return 'Fixture burn hours';
      case 'cumulativeOnStateNode':
        return 'Node burn hours';
      case 'powerFactor':
        return 'Power factor';
      case 'internalTemperature':
        return 'Internal temperature';
      case 'cellId':
        return 'Cell ID';
      case 'nodePowerCycleCount':
        return 'Node power cycle count';
      case 'relayCycleCount':
        return 'Relay cycle Count';
      case 'modemUptime':
        return 'Modem uptime';
      case 'modemBootCount':
        return 'Modem Boot Count';
      case 'apFsRestoreCount':
        return 'AP FS restore count';
      default:
        return '';
    }
  };

  static getSensorId(type: string, model: string): string {
    switch (type) {
      case 'driverLevel':
        if (['unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'lt';
        }
        break;
      case 'activePower':
        if (['unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'mP';
        }
        if (['unode-v2'].includes(model)) {
          return 'P';
        }
        break;
      case 'cumulativeEnergyUse':
        if (['unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'mw';
        }
        if (['unode-v2'].includes(model)) {
          return 'w';
        }
        break;
      case 'ambientLight':
        if (['unode-v5', 'unode-v61', 'unode-v62', 'lco'].includes(model)) {
          return 'l-i';
        }
        if (['unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'cnext'].includes(model)) {
          return 'l';
        }
        break;
      case 'sinr':
        if (['lco'].includes(model)) {
          return 'sinr';
        }
        break;
      case 'radioSignalStrength':
        if (['unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'falcon-q', 'cnext', 'lco'].includes(model)) {
          return 'RF';
        }
        break;
      case 'mainsCurrent':
        if (['unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'mi';
        }
        if (['unode-v2'].includes(model)) {
          return 'i';
        }
        break;
      case 'mainsVoltage':
        if (['unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'v';
        }
        break;
      case 'reactivePower':
        if (['lco'].includes(model)) {
          return 'mPr';
        }
        break;
      case 'cumulativeReactiveEnergyUse':
        if (['unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'mwr';
        }
        break;
      case 'linkQuality':
        if (['lco'].includes(model)) {
          return 'rsrq';
        }
        break;
      case 'cumulativeOnStateLuminaire':
        if (['cnext', 'unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v6', 'unode-v61', 'unode-v62', 'lco'].includes(model)) {
          return 'BHL';
        }
        break;
      case 'cumulativeOnStateNode':
        if (['cnext', 'unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v6', 'unode-v61', 'unode-v62', 'lco'].includes(model)) {
          return 'BHN';
        }
        break;
      case 'powerFactor':
        if (['unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'mPF';
        }
        if (['unode-v2'].includes(model)) {
          return 'PF';
        }
        break;
      case 'internalTemperature':
        if (['unode-v2', 'unode-v3', 'unode-v4', 'unode-v5', 'unode-v61', 'unode-v62', 'cnext', 'lco'].includes(model)) {
          return 'T';
        }
        break;
      case 'cellId':
        if (['lco'].includes(model)) {
          return 'cellid';
        }
        break;
      case 'nodePowerCycleCount':
        if (['lco'].includes(model)) {
          return 'bc';
        }
        break;
      case 'relayCycleCount':
        if (['lco'].includes(model)) {
          return 'rlym';
        }
        break;
      case 'modemUptime':
        if (['lco'].includes(model)) {
          return 'mdmtm';
        }
        break;
      case 'modemBootCount':
        if (['lco'].includes(model)) {
          return 'wbcnt';
        }
        break;
      case 'apFsRestoreCount':
        if (['lco'].includes(model)) {
          return 'rcnt';
        }
        break;
      default:
        break;
    }

    return '';
  }

  static getLightsOnIcon(val: number): JSX.Element {
    const icon100 = 100;
    const icon75 = 75;
    const icon50 = 50;
    const icon25 = 25;

    if (val === icon100) {
      return <LightsOn100Icon />;
    }

    if (val > icon75) {
      return <LightsOn75Icon />;
    }

    if (val > icon50) {
      return <LightsOn50Icon />;
    }

    if (val > icon25) {
      return <LightsOn25Icon />;
    }

    return <LightsOffIcon />;
  }
}

export default Sensors;
