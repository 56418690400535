/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import AssignFixture from '../../../Common/Components/AssignFixture';
import Button from '../../../Common/Components/Button';
import ManualOverride from '../../../Common/Components/ManualOverride';
import SlideIn from '../../../Common/Components/SlideIn';
import { ReactComponent as FullSunIcon } from '../../../img/icons/full-sun.svg';
import { ReactComponent as HalfSunIcon } from '../../../img/icons/half-sun.svg';
import { ReactComponent as MoonIcon } from '../../../img/icons/moon.svg';
import { FixtureObject } from '../../../types/FixtureObject';
import { GenericSensorData } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { ScheduleObject } from '../../../types/ScheduleObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import { getRequest } from '../../../utils/fetch';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import ViewFixture from '../../Config/Pages/Fixtures/Components/ViewFixture';
import ScheduleModal from '../../Schedules/Components/ScheduleModal';
import PlotSensor from '../Components/PlotSensors';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';

function LightsOperation(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;
  const siteTimezone = selectedSite.timezone || 'local';
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const [expand, setExpand] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(0);
  const [openFixtureModal, setOpenFixtureModal] = useState(0);
  const { addNotification } = useAppContext();

  const isNonReadOnly = Utils.isNonReadOnly();

  const timestamp = useRef<number>(0);

  const displayedSensors = ['driverLevel', 'activePower', 'cumulativeEnergyUse', 'ambientLight'/* , 'lastDriverLevelReport' */];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);

  useEffect(() => {
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      [Sensors.getSensorId(sensor, selectedNode.model)],
    ));

    Promise.allSettled(sensorRequests).then((results) => {
      setRefreshLoadingSensors(false);

      if (timestamp.current <= currentDate) {
        const response = Sensors.transformSensorResults(results, reqSensors, selectedNode);

        const newData = Sensors.getSensorTableDataWithValueAndSensor(
          selectedItems,
          reqSensors,
          response,
          originalData,
        );

        setSensorData(newData[0]);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqSensors]);

  const getIcon = (val: number): JSX.Element => {
    const moon = 64;
    const sun = 96;

    if (val < moon) {
      return <MoonIcon />;
    } if (val > sun) {
      return <FullSunIcon />;
    } if (val <= sun && val >= moon) {
      return <HalfSunIcon />;
    }
    return <></>;
  };

  // Schedules

  const { data: schedulesResp } = useSWR<Array<ScheduleObject>>(
    selectedSite.id ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/schedules`, 'SchedulesPage'] : null,
    (url) => getRequest(url, {}, (data: ScheduleObject[]): ScheduleObject[] => data?.map((schedule) => {
      schedule.events.forEach((event) => {
        if (event.date) {
          event.date = DateTime.fromFormat(event.date as unknown as string, 'yyyy-LL-dd').toJSDate();
        }
      });
      return schedule;
    })),
  );

  const nodeSchedule: ScheduleObject | undefined = useMemo(() => schedulesResp?.find(
    (schedule) => schedule.nodes.find((sn) => sn.nodeid === selectedNode.nodeid),
  ), [schedulesResp, selectedNode]);

  // Fixture

  const { data: nodeFixture } = useSWR<FixtureObject>(selectedSite.id && selectedNode?.fixtureid
    ? [`/customers/${selectedCustomer?.id}/sites/${selectedSite?.id}/fixtures/${selectedNode?.fixtureid}`, 'GetNodeFixture']
    : null);

  const modalScheduleView = 3;
  const listWidth = 185;

  return (
    <div className="operation">
      <SlideIn hasExpandButton expand={expand} setExpand={setExpand}>
        <>
          <div className="slide-in__title">
            <span>Operation</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content operation__content">
            <div className="custom-list-element">
              <div className="list-element-title">Schedule</div>
              <div className="custom-list-element-subtitle">
                {nodeSchedule?.name}
              </div>
              <div className="custom-list-element__content">
                <div className="custom-list-element__content-name">
                  Scheduled driver level
                </div>
                <div className="custom-list-element__content-info">
                  <div className="custom-list-element__content-info-data">
                    <div>
                      <span>{(selectedNode.schedDriverLevel || 0).toString()}</span>
                      %
                    </div>
                    {Sensors.getLightsOnIcon(selectedNode.schedDriverLevel || 0)}
                  </div>
                  <div className="slide-in__link">
                    <Button
                      label="View schedule"
                      onClick={() => {
                        setOpenScheduleModal(1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-list-element fixture">
              <div className="list-element-title">Fixture</div>
              <div className="custom-list-element-subtitle">
                <div>{selectedNode.fixturename}</div>
                <div className="slide-in__link">
                  <Button
                    label="View profile"
                    onClick={() => setOpenFixtureModal(1)}
                  />
                </div>
              </div>
            </div>

            <div className="custom-list-element sensors">
              <div className="list-element-title">Sensors</div>
              {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  setReqSensors(displayedSensors);
                  setRefreshLoadingSensors(true);
                }}
              />
              )}
              {(refreshLoadingSensors) && (
                <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
              )}

              <SlideinListElementBig
                title="Driver level"
                valueBold={Sensors.getRoundValue(sensorData?.driverLevel?.value)}
                date={Utils.getConvertedDate(sensorData?.driverLevel?.time, undefined, siteTimezone)}
                value="%"
                isFirstElement
                customVal={Sensors.getLightsOnIcon(sensorData?.driverLevel?.value
                  ? parseInt(sensorData?.driverLevel?.value, 10) : 0)}
              />
              <SlideinListElementBig
                title="Active power"
                valueBold={sensorData?.activePower?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.activePower?.time, undefined, siteTimezone)}
                value="W"
              />
              <SlideinListElementBig
                title="Cumulative energy use"
                valueBold={sensorData?.cumulativeEnergyUse?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.cumulativeEnergyUse?.time, undefined, siteTimezone)}
                value={sensorData?.cumulativeEnergyUse?.unit || 'kWh'}
              />
              <SlideinListElementBig
                title="Ambient light"
                valueBold={sensorData?.ambientLight?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.ambientLight?.time, undefined, siteTimezone)}
                value="lux"
                border="light"
                icon={getIcon(parseInt(sensorData?.ambientLight?.value || '', 10))}
              />
            </div>
          </div>
        </>
      </SlideIn>
      {openScheduleModal === 1 && nodeSchedule && (
        <ScheduleModal
          activeSchedule={nodeSchedule}
          openScheduleModal={modalScheduleView}
          setOpenScheduleModal={() => setOpenScheduleModal(0)}
        />
      )}
      {openFixtureModal === 1 && nodeFixture && (
        <ViewFixture
          modalOpen={() => setOpenFixtureModal(0)}
          fixtureDetails={nodeFixture}
        />
      )}
      {expand && (
        <SlideIn
          hasExpandButton={false}
          expand={expand}
          setExpand={setExpand}
          isExpanded
          setExpandClose={setExpand}
        >
          <>
            <div className="slide-in__content--expanded manual-override">
              {isNonReadOnly && (
              <ManualOverride
                selectedCustomer={selectedCustomer}
                selectedSite={selectedSite}
                selectedItems={selectedItems}
                notDropdown
                type="nodes"
                listLength={selectedItems.size}
                primaryButtonLabel=""
              />
              )}
              <div className="manual-override-element">
                <PlotSensor
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectedItems={selectedItems}
                  listWidth={listWidth}
                />
              </div>
              <div className="manual-override-element fixture">
                {isNonReadOnly && (
                <AssignFixture
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectedItems={selectedItems}
                  closeAssignFixture={() => undefined}
                  notDropdown
                  hideSelectboxLabel
                  listWidth={listWidth}
                  isInSidebar
                  type="nodes"
                  selectedLength={selectedItems.size}
                  primaryButtonLabel=""
                />
                )}
              </div>
            </div>
          </>
        </SlideIn>
      )}
    </div>
  );
}

export default LightsOperation;
