/* eslint-disable max-len */
import React, { createRef, useState } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { ReactComponent as NotificationFilledIcon } from '../../../img/icons/notification-filled.svg';
import { SlideinInfoElementPropsType } from '../../../types/SlideinInfoElementPropsType';

import Utils from '../../../utils/Utils';
import Loading from '../../../Common/Components/Loading';

import { ReactComponent as CaretIcon } from '../../../img/icons/down-arrow.svg';
import { ReactComponent as TrashIcon } from '../../../img/icons/trash.svg';
import { ReactComponent as PlaybookIcon } from '../../../img/icons/playbook.svg';

function SlideinInfoAlert(props: SlideinInfoElementPropsType): JSX.Element {
  const {
    nodeId,
    site,
    setOpenConfirmDismissAlert,
    setOpenAlarmPlaybook = () => undefined,
    alerts,
    setAlertObj,
    setAlertDismissArray,
  } = props;
  const fontSizeThreshold = 28;
  const rowHeightExpanded = 300;
  const rowHeight = 53;
  const [openedAlertDetailView, setOpenAlertDetailView] = useState<Array<number>>([]);
  const list = createRef<List>();
  const getRowHeight = ({ index }: {index: number}) => (openedAlertDetailView.includes(index) ? rowHeightExpanded : rowHeight);
  function alertRenderer({ key, index, style }: ListRowProps) {
    return (
      <div key={key} style={style}>
        <div className="alarms-list__elements">
          <div className="alarms-list__first-cell alarms-list__first-cell--wide">
            <div className="alarm-list__container">
              <div className="alarms-list__element">
                <span style={{ width: '14px', marginRight: '15px', marginTop: '1px' }}><NotificationFilledIcon className={`${alerts[index].severity.toLowerCase()}`} /></span>
                {alerts[index].ufname}
              </div>
              <div className="break" />
              <span className="alarms-list__element__title">Occurred</span>
              <span title={alerts[index].ufname} className="alarms-list__element__length" style={{ height: `${alerts[index].ufname.length > fontSizeThreshold ? '32px' : '16px'}` }}>
                {alerts[index].nodeData.length}
                {' '}
                times
              </span>
            </div>

            <div className="alarms-list__element__buttons">
              <button
                type="button"
                onClick={() => {
                  if (alerts !== undefined && setAlertObj) {
                    setAlertObj(alerts[index]);
                    setOpenAlarmPlaybook(true);
                  }
                }}
              >
                <PlaybookIcon />
              </button>
              {Utils.isNonReadOnly() && (
                <button
                  type="button"
                  onClick={() => {
                    if (setOpenConfirmDismissAlert) {
                      setOpenConfirmDismissAlert(true);
                    }
                    if (alerts[index] !== undefined && setAlertObj && setAlertDismissArray) {
                      if (alerts[index].nodeData.length > 1) {
                        const alertDismissList: Array<string> = [];
                        alerts[index].nodeData.forEach((detail) => {
                          alertDismissList.push(detail.alertid);
                        });
                        setAlertDismissArray(alertDismissList);
                        setAlertObj(alerts[index]);
                      } else {
                        setAlertDismissArray([alerts[index].nodeData[0].alertid]);
                        setAlertObj(alerts[index]);
                      }
                    }
                  }}
                >
                  <TrashIcon />
                </button>
              )}
            </div>
            <CaretIcon
              className={`alarms-list__element ${openedAlertDetailView.includes(index) ? 'alarms-list__element--open' : ''} `}
              onClick={() => {
                if (openedAlertDetailView.includes(index)) {
                  setOpenAlertDetailView(openedAlertDetailView.filter((item) => item !== index));
                } else {
                  setOpenAlertDetailView([...openedAlertDetailView, index]);
                }
                list.current?.forceUpdateGrid();
                list.current?.recomputeRowHeights(index);
              }}
            />
          </div>
        </div>
        <div>
          {openedAlertDetailView.includes(index) && (
          <div className={`info-element ${nodeId ? 'info-element--multi' : ''}`}>
            {alerts[index].nodeData.map((data, i) => (
              <>
                <div className="info-element-wrapper">
                  <div className="info-element-container">
                    <div className={`info-element-letterhead ${nodeId ? 'info-element-letterhead--multi' : ''}`} data-testid="slidein-inf-alert-status">
                      <span className="info-element-letterhead__title">Node ID</span>
                      <span title={alerts[index].ufname} className="info-element-letterhead__title__id" style={{ height: `${alerts[index].ufname.length > fontSizeThreshold ? '32px' : '16px'}` }}>
                        {data.id}
                      </span>
                      <span title={alerts[index].ufname} className="info-element-letterhead__title__description" style={{ height: `${alerts[index].ufname.length > fontSizeThreshold ? '32px' : '16px'}` }}>
                        {data.description}
                      </span>

                    </div>
                  </div>
                  <div className="info-element__info">
                    <span className="info-element__info-title">Created</span>
                    <span className="info-element__date">{Utils.convertISOtoAlarmTime(data.createdAt as string, site?.time_zone)}</span>
                    <span className="info-element__info-title info-element__info-updated">Updated</span>
                    <span className="info-element__date">{Utils.convertISOtoAlarmTime(data.updatedAt as string, site?.time_zone)}</span>
                    <div className="info-element-buttons">
                      <button
                        type="button"
                        onClick={() => {
                          if (alerts !== undefined && setAlertObj) {
                            setAlertObj(alerts[index]);
                            setOpenAlarmPlaybook(true);
                          }
                        }}
                      >
                        <PlaybookIcon />
                      </button>
                      {!alerts[index].nodeData[i].dismissed && Utils.isNonReadOnly() && (
                      <button
                        type="button"
                        onClick={() => {
                          if (setOpenConfirmDismissAlert) {
                            setOpenConfirmDismissAlert(true);
                          }
                          if (alerts[index] !== undefined && setAlertObj && setAlertDismissArray) {
                            setAlertDismissArray([alerts[index].nodeData[i].alertid]);
                            setAlertObj(alerts[index]);
                          }
                        }}
                      >
                        <TrashIcon />
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      {alerts !== undefined ? (

        <AutoSizer disableWidth style={{ height: 'calc( 100vh - 142px )' }}>
          {({ height }) => (
            <List
              ref={list}
              width={1}
              height={height}
              rowHeight={getRowHeight}
              rowCount={alerts && alerts.length}
              rowRenderer={alertRenderer}
              list={alerts}
              containerStyle={{
                width: '100%',
                maxWidth: '100%',
              }}
              style={{
                width: '100%',
              }}
            />
          )}
        </AutoSizer>
      ) : (<Loading />)}
    </>
  );
}
export default SlideinInfoAlert;
