import React, { useState } from 'react';
import { DownloadListProps } from '../../types/DownloadListProps';
import Utils from '../../utils/Utils';
import Modal from './Modal';
import RadioButtons from './RadioButtons';

function DownloadList(props: DownloadListProps): JSX.Element {
  const { setOpenModal, headerList, selectedNodes, enitreList, fileName, skipColumns } = props;
  const downloadList = [{ label: 'Selected nodes only', key: 'selected' }, { label: 'Entire list', key: 'entire' }];
  const [selectedDownloadListElement, setSelectedDownloadListElement] = useState(downloadList[0]);

  const downloadNodeList = () => {
    setOpenModal(0);
    const csvHeaders = headerList
      .filter((header) => !skipColumns.includes(header.key))
      .filter((h) => h.isHidden === false || h.isHidden === undefined);
    let list = enitreList;
    if (selectedDownloadListElement.key === 'selected') {
      list = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedNodes.forEach((element: any) => list.push(element));
    }
    Utils.downloadCSV(
      csvHeaders,
      Array.isArray(list)
        ? list.map((nodeEntry) => {
          const newNodeEntry = nodeEntry;
          Object.entries(nodeEntry?.customAttributes).forEach(([keyName, value]) => {
            newNodeEntry[`customAttributes_${keyName}`] = value;
          });
          return newNodeEntry;
        })
        : [],
      fileName,
    );
  };

  return (
    <Modal
      height="245"
      width="240"
      title="Download list"
      setModalOpen={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={() => downloadNodeList()}
      secondaryButtonLabel="Cancel"
      className="download-list"
    >
      <>
        <span className="download-list__subtitle">Download Options</span>
        <RadioButtons
          list={downloadList}
          selected={selectedDownloadListElement}
          onClick={(selected) => setSelectedDownloadListElement(selected)}
          type="light"
          extraClasses="vertical"
        />
      </>
    </Modal>
  );
}

export default DownloadList;
