import React, { useState } from 'react';
import Modal from '../../../Common/Components/Modal';
import Textbox from '../../../Common/Components/Textbox';
import { DeleteNodesProps } from '../../../types/DeleteNodesProps';
import { useAppContext } from '../../../utils/AppContext';
import { putRequest } from '../../../utils/fetch';
import formValidation from '../../../utils/form/formValidation';
import Utils from '../../../utils/Utils';

function DeleteNodes(props: DeleteNodesProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
    selectedNodes,
    activeToolbarBtn,
    closeDeleteNodes,
    updateNodes,
  } = props;
  const [openModal, setOpenModal] = useState(activeToolbarBtn !== 0);

  const { addNotification } = useAppContext();

  const [editTextBox, _setEditTextBox] = useState({
    editErrorMsg: '',
    error: false,
    value: '',
  });

  const setEditTextBox = (val: string) => {
    const validation = formValidation(
      val,
      { max: 45 },
    );

    _setEditTextBox((oldVal) => ({
      value: validation.hasError ? editTextBox.value : val,
      editErrorMsg: validation.errorMsg,
      error: validation.hasError,
    }));
  };

  return (
    <div className="multi-node-modals">
      {openModal && (
        <div className="manual-override">
          <Modal
            title="Delete nodes"
            width="335"
            setModalOpen={() => {
              setOpenModal(false);
              closeDeleteNodes();
            }}
            primaryButtonLabel="Submit"
            primaryButtonAction={async () => {
              if (selectedNodes.size > 0) {
                if (editTextBox.value !== '') {
                  const postbody = {
                    nodeids: Array.from(selectedNodes.keys()),
                    note: editTextBox.value,
                  };

                  try {
                    const resp = await putRequest(
                      '/nodes/note',
                      postbody,
                    );

                    if (!resp.error) {
                      addNotification({ type: 'success', message: 'Your "Edit note" operation was successful' });
                    } else {
                      addNotification({ type: 'error', message: 'Your "Edit note" operation is failed.' });
                    }
                  } catch (error) {
                    addNotification({ type: 'error', message: 'Your "Edit note" operation is failed.' });
                  }
                }

                try {
                  const path = `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/delete`;
                  const resp = await Utils.sendFormData(path, selectedNodes);

                  if (resp.ok) {
                    addNotification({ type: 'success', message: 'Your "Delete nodes" operation is completed.' });

                    if (updateNodes) {
                      updateNodes();
                    }
                  } else {
                    addNotification({ type: 'error', message: 'Your "Delete nodes" operation is failed.' });
                  }
                } catch (e) {
                  addNotification({ type: 'error', message: 'Your "Delete nodes" operation is failed.' });
                }
              } else {
                addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
              }
              closeDeleteNodes();
            }}
            secondaryButtonLabel="Cancel"
          >
            <div className="subtitle">
              Delete nodes from
              <span>
                {' '}
                {selectedSite.name}
              </span>
              ?
            </div>
            <div className="content">
              <div className="content-selected-nodes">
                <span className="content-selected-nodes-title">Delete</span>
                <span className="content-selected-nodes-big">{selectedNodes.size}</span>
                <span>{selectedNodes.size > 1 ? 'Nodes selected' : 'Node selected'}</span>
              </div>
              <div className="content-text">
                Nodes will appear on the commissioning page.
              </div>
            </div>
            <div className="edit-note">
              <span className="edit-note__title">Change note for each node</span>
              <Textbox
                label="Note"
                error={editTextBox.error}
                errorMessage={editTextBox.editErrorMsg}
                placeholder="Edit note"
                name="Name"
                type="text"
                value={editTextBox.value}
                onChange={(e) => setEditTextBox(e.target.value)}
              />
              <span className="edit-note__info">Max 45 characters</span>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default DeleteNodes;
