import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import { AutoOpenSelectboxListType } from '../../../../types/AutoOpenSelectboxPropsType';
import { RemoveNodesFromOrgGroupProps } from '../../../../types/GroupActionsPropTypes';
import { LightsGroupsItem } from '../../../../types/LightsGroupProps';
import { postRequest } from '../../../../utils/fetch';
import GroupActionsOrgGroup from '../GroupActionsOrgGroup';

function RemoveNodesFromOrgGroup(props: RemoveNodesFromOrgGroupProps): JSX.Element {
  const { setOpenModal, selectedNodes, addNotification, orgGroups, selectedCustomer, selectedSite, updateGroup } = props;
  const [selectedOrgGroup, setSelectedOrgGroup] = useState<AutoOpenSelectboxListType>({ id: '', name: '' });
  return (
    <Modal
      title="Remove nodes from org group"
      width="546"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          if (selectedOrgGroup.id === '') {
            addNotification({ type: 'error', message: 'Please select an org group.' });
          } else {
            const removedNodes = Array.from(selectedNodes.keys());
            const groupObject = orgGroups.find((group) => group.id === selectedOrgGroup.id) as LightsGroupsItem;
            const newNodeList = groupObject && groupObject.nodeList?.filter((node) => !removedNodes.includes(node));

            groupObject.nodeList = newNodeList;

            const result = await postRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${selectedOrgGroup.id}`,
              groupObject,
            );

            if (!result.error) {
              addNotification({ type: 'success', message: 'Your "Remove nodes from org group" operation is completed.' });
              updateGroup();
              setOpenModal(0);
            } else {
              addNotification({ type: 'error', message: `Your "Remove nodes from org group" operation is failed: ${result.error}` });
            }
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Remove nodes from org group" operation is failed.' });
        }
      }}
    >
      <GroupActionsOrgGroup
        title="Remove selected nodes"
        comment="Nodes will be removed from all selected org groups."
        selectedNodes={selectedNodes}
        orgGroups={orgGroups.filter((group) => group.nodeList?.some((nodeId) => Array.from(selectedNodes.keys()).includes(nodeId)))}
        selectedOrgGroup={selectedOrgGroup}
        setSelectedOrgGroup={setSelectedOrgGroup}
      />
    </Modal>
  );
}

export default RemoveNodesFromOrgGroup;
