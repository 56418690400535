import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { AdminSidebarReactivateUserProps } from '../../../../../types/AdminSidebarReactivateUserProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { deleteRequestAll } from '../../../../../utils/fetch';
import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';
import { UserObject } from '../../../../../types/UserObject';

function AdminSidebarReactivateUser(props: AdminSidebarReactivateUserProps): JSX.Element {
  const {
    setOpenModal,
    selectedUsers,
    updateCustomers,
    setLoader } = props;

  const { addNotification } = useAppContext();

  return (
    <Modal
      setModalOpen={() => setOpenModal(0)}
      className="admin-user-modal"
      title="Restore access"
      primaryButtonAction={async () => {
        setOpenModal(0);
        setLoader();
        if (selectedUsers !== undefined) {
          try {
            const requests: { path: string, data: UserObject }[] = Array.from(selectedUsers.values()).map(
              (value) => ({
                path: `/customers/${value.accountid}/suspended-users/${value.userid}`,
                data: value,
              }),
            );
            deleteRequestAll(requests)
              .then((results) => {
                if (results.resolved === requests.length) {
                  addNotification({ type: 'success', message: 'User access has been successfully restored. User(s) have been notified by email.' });
                  if (updateCustomers) {
                    updateCustomers();
                  }
                } else {
                  addNotification({ type: 'error', message: 'Your "Restore user" operation is failed.' });
                }
              });
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Restore user" operation is failed.' });
          }
        }
      }}
      primaryButtonLabel="Apply"
      secondaryButtonAction={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <div className="admin-user-modal-content">
        <WarningIcon className="admin-user-modal-content-icon" />
        Are you sure you want to restore access for these users?
      </div>
    </Modal>
  );
}

export default AdminSidebarReactivateUser;
