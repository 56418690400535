/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import Button from '../../Common/Components/Button';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import ToolbarLink from '../../Common/Components/ToolbarLink';
import { ReactComponent as CalendarIcon } from '../../img/icons/calendar.svg';
import { ReactComponent as DetailedEnergyIcon } from '../../img/icons/download-csv.svg';
import { ReactComponent as DownloadIcon } from '../../img/icons/download.svg';
import { ReactComponent as MapIcon } from '../../img/icons/site-locator.svg';
import { ReactComponent as TotalEnergyIcon } from '../../img/icons/smart-meter.svg';
import { EnergyGraphProps, EnergyRecord, FilledEnergyRecord } from '../../types/EnergyGraphProps';
import { EnergyQueryPayload } from '../../types/EnergyQueryPayload';
import { GroupObject } from '../../types/GroupObject';
import { ChartBase } from '../../types/GroupsSelector';
import { EnergyPageComponentProps } from '../../types/PageComponentProps';
import { TableHeadersProp } from '../../types/TableHeadersProp';
import { groupsNodesFn } from '../../utils/ApiDataHelpers';
import { useAppContext } from '../../utils/AppContext';
import { getRequest, postRequest } from '../../utils/fetch';
import getHeaderProps from '../../utils/getHeaderProps';
import useEnergyPageState from '../../utils/state/useEnergyPageState';
import Utils from '../../utils/Utils';
import DatePeriodSelector from './components/DatePeriodSelector';
import EnergyReportGraph from './components/EnergyReportGraph';
import EnergyReportWizard from './components/EnergyReportWizard';
import ToolbarGroupSelector from './components/ToolbarGroupSelector';
import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';
import { httpOk } from '../../utils/constants';

const basicSiteChartBase: ChartBase = {
  type: 'site',
  icon: <MapIcon />,
};

function EnergyPage(props: EnergyPageComponentProps): JSX.Element {
  const { addNotification } = useAppContext();

  const {
    selectedSite,
    selectedCustomer,
    defaultSelectedChartBase = basicSiteChartBase,
    isModal = false,
    closeModal,
  } = props;
  const {
    activeToolbar,
    handleActiveToolbar,
  } = useEnergyPageState();

  const initDate = () => {
    let start = DateTime.fromMillis(Date.now());
    const end = DateTime.fromMillis(Date.now());

    start = start.minus({ days: 30 });

    return {
      start,
      end,
    };
  };

  const { data: groupsResp } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);
  const {
    lightingGroups,
    orgGroups,
  } = useMemo(() => groupsNodesFn(groupsResp || []), [groupsResp]);

  const [groupFilterActiveTab, setGroupFilterActiveTab] = useState<string>('site');
  const [selectedChartBase, setSelectedChartBase] = useState<ChartBase>(defaultSelectedChartBase);
  const [startDate, setStartDate] = useState(initDate().start);
  const [endDate, setEndDate] = useState(initDate().end);
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [chartSubTitle, setChartSubtitle] = useState<string>('All nodes');
  const [totalConsumption, setTotalConsumption] = useState<number>(0);

  const [energyChartProps, setEnergyChartProps] = useState<EnergyGraphProps>({
    waiting: true,
    noDataAvailable: false,
    records: [],
    granularity: 'day',
  });

  const queryInterval = useRef<NodeJS.Timeout>();
  const isMounted = useRef<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({});
  const isFetched = useRef<boolean>(false);
  const twoSec = 2000;
  const consumptionDivider = 1000;
  const toFixed = 2;

  useEffect(() => {
    setFormattedDate(`${startDate.toFormat('LLLL d')} - ${endDate.toFormat('LLLL d, yyyy')}`);
  }, [startDate, endDate]);

  useEffect(() => {
    let matchChartBaseToSubtitle;
    switch (selectedChartBase.type) {
      case ('site'):
        matchChartBaseToSubtitle = 'All nodes';
        break;
      case ('nodeList'):
        matchChartBaseToSubtitle = selectedChartBase.selectedNodes.length.toString();
        break;
      case ('nodeid'):
        matchChartBaseToSubtitle = selectedChartBase.selectedNode.nodeid;
        break;
      default: matchChartBaseToSubtitle = selectedChartBase.group.name;
    }
    setChartSubtitle(matchChartBaseToSubtitle);
  }, [selectedChartBase]);

  useEffect(() => {
    async function sendEnergyQuery(payload: EnergyQueryPayload) {
      try {
        const { status, data, error } = await postRequest<EnergyQueryPayload, { queryTaskId: string; }>(`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/energy`, payload);
        if (status === httpOk && data?.queryTaskId) {
          const { queryTaskId } = data;
          startPolling(queryTaskId, payload, fetchData);
        } else {
          throw new Error(error);
        }
      } catch (error) {
        setEnergyChartProps((oldValues) => ({ ...oldValues, waiting: false, noDataAvailable: true }));
        addNotification({ type: 'warning', message: `Could not fetch energy data: '${error}'` });
      }
    }

    async function startPolling(
      queryTaskId: string,
      payload: EnergyQueryPayload,
      fn: (_qId: string, _payload: EnergyQueryPayload) => Promise<void>,
    ) {
      isFetched.current = false;
      clearInterval(queryInterval.current as NodeJS.Timeout);
      queryInterval.current = setInterval(() => fn(queryTaskId, payload), twoSec);
    }
    const abortController = new AbortController();
    const fetchData = async (queryTaskId: string, payload: EnergyQueryPayload) => {
      try {
        const data = await getRequest(`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/energy/queryresult/${queryTaskId}`, { signal: abortController.signal });
        const { dataset } = data;
        if (!isFetched.current && dataset && isMounted) {
          isFetched.current = true;
          clearInterval(queryInterval.current as NodeJS.Timeout);
          if (dataset.length === 0) {
            setTotalConsumption(0);
            setEnergyChartProps({
              records: [],
              granularity: payload.granularity,
              waiting: false,
              noDataAvailable: true,
            } as EnergyGraphProps);
          } else {
            setTotalConsumption((
              (dataset as FilledEnergyRecord[])
                .map((r) => r.energy_consumption)
                .reduce((a: number, b: number) => a + b)) / consumptionDivider);
            setEnergyChartProps({
              records: Utils.fillEmptyGapsInEnergyConsumptionData(
                dataset,
                payload.timePeriod.start,
                payload.timePeriod.end,
                payload.granularity,
              ),
              granularity: payload.granularity,
              waiting: false,
              noDataAvailable: false,
            });
          }
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          return;
        }
        if (isMounted.current) {
          clearInterval(queryInterval.current as NodeJS.Timeout);
          setEnergyChartProps({
            records: [],
            granularity: payload.granularity,
            waiting: false,
            noDataAvailable: true,
          } as EnergyGraphProps);
          addNotification({ type: 'warning', message: `Could not fetch energy data: '${error}'` });
        }
      }
    };
    setEnergyChartProps((oldValues) => ({ ...oldValues, waiting: true, noDataAvailable: false }));
    sendEnergyQuery(Utils.createEnergyPayload(startDate, endDate, selectedChartBase, selectedSite));
    return () => {
      isMounted.current = false;
      clearInterval(queryInterval.current as NodeJS.Timeout);
      abortController.abort();
      setState({});
    };
    // eslint-disable-next-line no-return-assign
  }, [startDate, endDate, selectedChartBase, selectedSite, selectedCustomer.id, addNotification]);

  const downloadChartDataCsv = () => {
    let csvHeaders: TableHeadersProp[] = [];
    let selectedItemVal = '';

    if (selectedChartBase.type === 'lighting') {
      selectedItemVal = selectedChartBase.group.name;
      csvHeaders = getHeaderProps('EnergyGraphLightingGroupBased');
    } else if (selectedChartBase.type === 'organizational') {
      selectedItemVal = selectedChartBase.group.name;
      csvHeaders = getHeaderProps('EnergyGraphOrgGroupBased');
    } else if (selectedChartBase.type === 'site') {
      selectedItemVal = props.selectedSite.name;
      csvHeaders = getHeaderProps('EnergyGraphSiteBased');
    } else if (selectedChartBase.type === 'nodeid') {
      selectedItemVal = selectedChartBase.selectedNode.nodeid;
      csvHeaders = getHeaderProps('EnergyGraphNodeBased');
    } else if (selectedChartBase.type === 'nodeList') {
      selectedItemVal = 'Multiple Nodes';
      csvHeaders = getHeaderProps('EnergyGraphNodeBased');
    }

    const csvData: Record<string, string>[] = (energyChartProps.records as FilledEnergyRecord[]).map((d) => ({
      tick_datetime: d.tick_datetime,
      base: selectedItemVal,
      energy_consumption: (d.energy_consumption ? d.energy_consumption / consumptionDivider : 0).toString(),
      unit: 'kWh',
      overall_estimation: d.overall_estimation || '',
    } as Record<string, string>));

    Utils.downloadCSV(csvHeaders, csvData, 'energy_chart');
  };

  const toolbarTotalEnergyUsed = 1;
  const toolbarNodeSiteGroup = 2;
  const toolbarTime = 3;
  const toolbarReport = 4;

  return (
    <div className="energy-panel">
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Energy use" subtitle={selectedSite.name} />
            <ToolbarLink
              icon={<TotalEnergyIcon />}
              title={`${totalConsumption.toFixed(toFixed)} kWh`}
              subtitle="Total energy use"
              onclick={() => handleActiveToolbar(toolbarTotalEnergyUsed)}
              tabPanelisActive={activeToolbar}
              order={toolbarTotalEnergyUsed}
              addClass="toolbar-tabs__container-tabset-container--large-font"
              highlightActive={false}
            />
            <ToolbarLink
              icon={selectedChartBase.icon}
              title={isModal ? chartSubTitle : Utils.getChartTypeString(selectedChartBase.type)}
              subtitle={isModal ? Utils.getChartTypeString(selectedChartBase.type) : chartSubTitle}
              onclick={() => !isModal && handleActiveToolbar(toolbarNodeSiteGroup)}
              order={toolbarNodeSiteGroup}
              tabPanelisActive={activeToolbar}
              addClass={`toolbar-tabs__container-tabset-container--${isModal ? 'large-font wide' : 'wide'}`}
              highlightActive={false}
            >
              <ToolbarGroupSelector
                className=""
                lightingGroups={lightingGroups}
                orgGroups={orgGroups}
                selectedChartBase={selectedChartBase}
                setSelectedChartBase={setSelectedChartBase}
                handleActiveToolbar={handleActiveToolbar}
                setGroupFilterActiveTab={setGroupFilterActiveTab}
                groupFilterActiveTab={groupFilterActiveTab}
                selectedSite={selectedSite}
              />
            </ToolbarLink>
            <ToolbarLink
              icon={<CalendarIcon />}
              title="Time period"
              subtitle={formattedDate}
              onclick={() => handleActiveToolbar(toolbarTime)}
              tabPanelisActive={activeToolbar}
              order={toolbarTime}
              addClass="toolbar-tabs__container-tabset-container--date last"
              highlightActive={false}
            >
              <DatePeriodSelector
                startDate={startDate.toJSDate()}
                endDate={endDate.toJSDate()}
                setStartDate={(dt) => setStartDate(DateTime.fromJSDate(dt as Date))}
                setEndDate={(dt) => setEndDate(DateTime.fromJSDate(dt as Date))}
                maxDays={365}
                handleActiveToolbar={handleActiveToolbar}
              />
            </ToolbarLink>
          </div>
          {isModal && (
          <ToolbarLink
            icon={<CloseIcon />}
            onclick={closeModal}
            tabPanelisActive={activeToolbar}
            order={toolbarReport}
            addClass="toolbar-tabs__container-tabset-container--close"
            highlightActive={false}
          />
          )}
          {!isModal && (
          <ToolbarLink
            icon={<DetailedEnergyIcon />}
            title="Request"
            subtitle="Detailed report"
            onclick={() => handleActiveToolbar(toolbarReport)}
            tabPanelisActive={activeToolbar}
            order={toolbarReport}
            addClass="toolbar-tabs__container-tabset-container--standalone"
            highlightActive={false}
          />
          )}
          {activeToolbar === toolbarReport && (
            <EnergyReportWizard
              closeModal={() => handleActiveToolbar(0)}
              lightingGroups={lightingGroups}
              orgGroups={orgGroups}
              selectedSite={selectedSite}
              selectedCustomer={selectedCustomer}
            />
          )}
        </div>
      </Toolbar>
      <div className="content">
        {!energyChartProps.waiting && !energyChartProps.noDataAvailable && (
        <div className="energy-title">
          <span className="energy-title-text">Energy use chart</span>
          <Button onClick={downloadChartDataCsv} buttonType="textlink" extraClasses="energy-title-button">
            <span>
              Download chart data
              <DownloadIcon title="Download" />
            </span>
          </Button>
        </div>
        ) }
        <EnergyReportGraph
          waiting={energyChartProps.waiting}
          records={energyChartProps.records as EnergyRecord[]}
          granularity={energyChartProps.granularity}
          noDataAvailable={energyChartProps.noDataAvailable}
        />
      </div>
    </div>
  );
}

export default EnergyPage;
