import React from 'react';
import { LoadingProps } from '../../types/loading';

function Loading(props: LoadingProps): JSX.Element {
  const {
    fullScreen = false,
    isLoading = true,
  } = props;
  const loadingClass = fullScreen ? 'loading--fullscreen' : 'loading--non-fullscreen';

  return (
    <div className={`loading ${loadingClass}`} style={{ display: isLoading ? 'flex' : 'none' }}>
      <span className="loading__loader">
        <span className="loading__loader-inner" />
      </span>
    </div>
  );
}

export default Loading;
