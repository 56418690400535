/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import DropDown from './DropDown';
import Modal from './Modal';
import Textbox from './Textbox';
import { ManualOverrideProps, ManualOverrideStateProps } from '../../types/ManualOverrideProps';
import { useAppContext } from '../../utils/AppContext';

import { ReactComponent as ScrollRightIcon } from '../../img/icons/scroll-right.svg';
import { ReactComponent as CloseIcon } from '../../img/icons/close.svg';
import { maxSelectedNodes } from '../../utils/constants';
import { postRequestAll } from '../../utils/fetch';
import { NodeObject } from '../../types/NodeObject';

const maxDriverLevelNumber = 100;

function ManualOverride(props: ManualOverrideProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
    notDropdown = false,
    closeManualOverwrite: _closeManualOverwrite,
    closeButton,
    closeButtonAction,
    updateNodes,
    listLength,
    selectedItems,
    type,
    primaryButtonLabel = 'Cancel',
  } = props;

  const { addNotification } = useAppContext();

  const [manualOverride, _setManualOverride] = useState<ManualOverrideStateProps>({
    driverLevel: '100',
    driverLevelHrs: '',
    driverLevelMin: '10',
  });
  const [openModal, setOpenModal] = useState(false);
  const hr11 = 11;
  const hr12 = 12;
  const minInHour = 60;
  const secInHour = 3600;

  const setManualOverride = (newVal: ManualOverrideStateProps) => {
    if (newVal.driverLevel === '') {
      _setManualOverride((oldVal) => ({ ...oldVal, driverLevel: '' }));
    } else if (newVal.driverLevelHrs === '') {
      _setManualOverride((oldVal) => ({ ...oldVal, driverLevelHrs: '' }));
    } else if (newVal.driverLevelMin === '') {
      _setManualOverride((oldVal) => ({ ...oldVal, driverLevelMin: '' }));
    } else if (newVal.driverLevel) {
      const driverLevel = Number(newVal.driverLevel);
      if (!isNaN(driverLevel)
          && driverLevel <= maxDriverLevelNumber
          && driverLevel >= 0) {
        _setManualOverride((oldVal) => ({ ...oldVal, driverLevel: driverLevel.toString() }));
      }
    } else if (newVal.driverLevelHrs) {
      const driverLevelHrs = Number(newVal.driverLevelHrs);
      if (!isNaN(driverLevelHrs)
        && driverLevelHrs <= (Number(manualOverride.driverLevelMin) > 0 ? hr11 : hr12)
        && driverLevelHrs >= 0) {
        _setManualOverride((oldVal) => ({ ...oldVal, driverLevelHrs: driverLevelHrs.toString() }));
      } else if (newVal.driverLevelHrs === '') {
        _setManualOverride((oldVal) => ({ ...oldVal, driverLevelHrs: '' }));
      }
    } else if (newVal.driverLevelMin) {
      const driverLevelMin = Number(newVal.driverLevelMin);
      if (!isNaN(driverLevelMin)
        && driverLevelMin < minInHour
        && driverLevelMin >= 0
        && Number(manualOverride.driverLevelHrs) < hr12) {
        _setManualOverride((oldVal) => ({ ...oldVal, driverLevelMin: driverLevelMin.toString() }));
      } else if (newVal.driverLevelMin === '') {
        _setManualOverride((oldVal) => ({ ...oldVal, driverLevelMin: '' }));
      }
    }
  };

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const closeManualOverwrite = () => {
    if (_closeManualOverwrite) _closeManualOverwrite();
  };

  const getDurationText = (): string => {
    let val = '';
    if (manualOverride.driverLevelHrs === '' && manualOverride.driverLevelMin === '') {
      val = '-';
    } else if (manualOverride.driverLevelHrs === '') {
      val = `0 Hrs, ${manualOverride.driverLevelMin} Min`;
    } else if (manualOverride.driverLevelMin === '') {
      val = `${manualOverride.driverLevelHrs} Hrs`;
    } else {
      val = `${manualOverride.driverLevelHrs} Hrs, ${manualOverride.driverLevelMin} Min`;
    }
    return val;
  };

  return (
    <div className="multi-node-modals">
      {(!openModal || notDropdown) && (
      <DropDown
        title="Manual override"
        subtitle={(
          <>
            <span>Set driver level and duration</span>
            <span className="small"> (5 min - 12 hrs)</span>
          </>
        )}
        secondaryButtonLabel="Submit"
        secondaryButtonSize="small"
        secondaryButtonAction={() => {
          let hasError = false;
          if (type === 'nodes') {
            if (listLength === 0) {
              addNotification({ type: 'warning', message: 'No nodes were selected for the operation.' });
              hasError = true;
            } else if (listLength > maxSelectedNodes) {
              addNotification({ type: 'error', message: 'This operation has a limit of 300 nodes. Please revise your selections.' });
              hasError = true;
            }
          } else if (type === 'groups') {
            if (listLength === 0) {
              addNotification({ type: 'warning', message: 'No groups were selected for the operation.' });
              hasError = true;
            }
          }
          if (!manualOverride.driverLevel) {
            addNotification({ type: 'warning', message: 'No driver level is set for the operation.' });
            hasError = true;
          } else if (!manualOverride.driverLevelHrs && !manualOverride.driverLevelMin) {
            addNotification({ type: 'warning', message: 'No duration is set for the operation.' });
            hasError = true;
          } else if ((!manualOverride.driverLevelHrs || Number(manualOverride.driverLevelHrs) === 0)
            && Number(manualOverride.driverLevelMin) < 5) {
            addNotification({ type: 'warning', message: 'Duration must be at least 5 minutes.' });
            hasError = true;
          }
          if (!hasError) {
            setOpenModal(true);
          }
        }}
        secondaryButtonType="secondary"
        primaryButtonLabel={primaryButtonLabel}
        primaryButtonType="primary"
        primaryButtonSize="small"
        primaryButtonAction={() => closeManualOverwrite()}
        className="toolbar-action-dropdown"
      >
        <div className="dropdown__content manual-override">
          {closeButton
          && (
          <button
            type="button"
            className="manual-override__close-button"
            onClick={closeButtonAction}
          >
            <CloseIcon />
          </button>
          )}
          <div className="dropdown__content__data">
            <div className="dropdown__content__data-main">
              <Textbox
                value={manualOverride.driverLevel}
                name="driverLevel"
                placeholder="Enter %"
                onChange={
                  (event) =>
                    setManualOverride({ driverLevel: event.target.value })
                }
                label="Driver level"
                styleType="dark"
              />
            </div>
            <div className="dropdown__content__data-main">
              <Textbox
                value={manualOverride.driverLevelHrs}
                name="driverLevelHrs"
                placeholder="00"
                onChange={
                  (event) =>
                    setManualOverride({ driverLevelHrs: event.target.value })
                }
                label="Hrs"
                styleType="dark"
              />
              <Textbox
                value={manualOverride.driverLevelMin}
                name="driverLevelMin"
                placeholder="00"
                onChange={
                  (event) =>
                    setManualOverride({ driverLevelMin: event.target.value })
                }
                label="Min"
                styleType="dark"
              />
            </div>
          </div>
        </div>
      </DropDown>
      )}
      {openModal && (
        <div className="manual-override">
          <Modal
            title="Override lighting schedule"
            width="auto"
            setModalOpen={() => {
              setOpenModal(false);
              closeManualOverwrite();
            }}
            primaryButtonAction={() => {
              setSubmitDisabled(true);
              const level = parseInt(manualOverride.driverLevel || '', 10);
              const hours = parseInt(manualOverride.driverLevelHrs || '0', 10);
              const mins = parseInt(manualOverride.driverLevelMin || '0', 10);
              const timeout = hours * secInHour + mins * minInHour;

              const requests: { path: string; data?: unknown; }[] = [];

              if (type === 'nodes' && selectedItems instanceof Map) {
                selectedItems.forEach((node: NodeObject) => {
                  requests.push({
                    path: `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/lightcontrol/node/${node.nodeid}`,
                    data: { level, timeout },
                  });
                });
              } else if (type === 'groups' && selectedItems instanceof Array) {
                selectedItems.forEach((groupId: string) => {
                  requests.push({
                    path: `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/lightcontrol/groups/${groupId}`,
                    data: { level, timeout },
                  });
                });
              }

              // TODO - this should be bulk api request
              postRequestAll(requests)
                .then((results) => {
                  if (results.resolved === requests.length) {
                    addNotification({ type: 'info', message: 'Your "Manual override" operation is completed.' });
                    setOpenModal(false);
                    closeManualOverwrite();

                    if (updateNodes) {
                      updateNodes();
                    }
                  } else {
                    addNotification({ type: 'error', message: 'Your "Manual override" operation is failed.' });
                  }
                });
            }}
            primaryButtonLabel="Submit"
            primaryButtonDisabled={submitDisabled}
            secondaryButtonLabel="Cancel"
          >
            <div className="content">
              <div className="content-selected-nodes">
                <span className="content-selected-nodes-big">
                  {listLength}
                </span>
                <span>{type === 'nodes' ? (listLength > 1 ? 'Nodes selected' : 'Node selected') : (listLength > 1 ? 'Groups selected' : 'Group selected')}</span>
              </div>
              <div className="content__confirm">
                <div className="arrow">
                  <ScrollRightIcon />
                </div>
                <div className="content__confirm-data">
                  <span className="content__confirm-data-title">
                    Driver Level
                  </span>
                  <span className="content__confirm-data-value">
                    {`${manualOverride.driverLevel !== '' ? manualOverride.driverLevel : '-'}`}
                    {' '}
                    %
                  </span>
                </div>
                <div className="content__confirm-data">
                  <span className="content__confirm-data-title">Duration</span>
                  <span className="content__confirm-data-value">
                    {getDurationText()}
                  </span>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default ManualOverride;
