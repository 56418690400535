import React, { useState } from 'react';
import useSWR from 'swr';
import { FirmwareObject } from '../../../types/FirmwareObjects';
import { PageComponentProps } from '../../../types/PageComponentProps';
import { useAppContext } from '../../../utils/AppContext';
import FirmwareUpdateModal from '../Components/FirmwareUpdateModal';
import FirmwareVersionList from '../Components/FirmwareVersionList';
import AdminSidebar from '../../../Common/Components/AdminSidebar';
import AdminSidebarConfigItems from '../Components/AdminSidebarConfigItems';
import { firmwareFetcherFn } from '../../../utils/ApiDataHelpers';

function FirmwareSettings(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;

  const { addNotification } = useAppContext();

  const pageTitle = 'App firmware packages';
  const noSiteMessage = 'No site is selected for the operation.';
  const { data: firmwaresResp = [] } = useSWR<Array<FirmwareObject>>(['/firmwares', 'GetFirmwares'], firmwareFetcherFn);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFirmware, setSelectedFirmware] = useState<FirmwareObject>();

  const openModal = (selectedFirmwareVersion: FirmwareObject) => {
    if (!selectedCustomer?.id || !selectedSite?.id) {
      addNotification({ type: 'warning', message: noSiteMessage });
    } else {
      setSelectedFirmware(selectedFirmwareVersion);
      setIsModalOpen(true);
    }
  };

  return (
    <div className="config__content firmware-page">
      <AdminSidebar selectedSite={selectedSite} title="Site config">
        <AdminSidebarConfigItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--large-margin">
        <div className="toolbar-container toolbar-container--right">
          <div className="config__content-title margin-left-5">
            {pageTitle}
          </div>
        </div>
        <FirmwareVersionList
          data={firmwaresResp}
          openModal={(sfw: FirmwareObject) => openModal(sfw)}
        />
        {isModalOpen && (
          <FirmwareUpdateModal
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            selectedFirmware={selectedFirmware}
            isActive={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
}

export default FirmwareSettings;
