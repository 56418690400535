/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
import { GetAllRequests, NonGetAllRequests } from '../types/Fetch';
import { BASEPATH } from './constants';
import FetchError from './FetchError';
import Utils from './Utils';

const pathRegexes = {
  ALARMS_V4: [/\/manage\/alarms/],
  ALERTS_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/alerts/],
  ALERT_REPORTS_AND_DASHBOARD_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/alert-reports/, /\/customers\/[^/]*\/sites\/[^/]*\/alert-dashboard/],
  ASYNC_JOBS_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/async-jobs/],
  CUSTOM_ATTRIBUTES_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/customattributes/],
  DIAGNOSTIC_V4: [/\/diag\/node/],
  ENERGY_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/energy/, /\/energy-report/],
  IMPORT_FIXTURES_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/fixtures\/import/],
  NOTES_V4: [/\/nodes\/note/],
  SENSOR_HISTORY_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/sensors$/, /\/customers\/[^/]*\/sites\/[^/]*\/sensors\//, /\/customers\/[^/]*\/sites\/[^/]*\/nodes\/[^/]*\/sensors/],
  DEVICE_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/nodes\/[^/]*\/connection_status/],
  FIRMWARE_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/nodes\/[^/]*\/assigned_firmware/],
  NODE_LOCATION_V4: [/^\/nodes\/[^/]*$/],
  THINGSPACE_PROXY_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/nodes\/external_device_information/],
  NOTIFICATIONS_V4: [/\/customers\/[^/]*\/sites\/[^/]*\/notifications/, /\/notificationtypes$/],
  ALERT_SUMMARY_V4: [/^\/report$/],
  SITES_V4: [/^\/sites$/],
  SYSTEM_FEEDBACK_CHANNEL_V4: [/\/system-feedback-channel\/system-alerts/],
  TOKENS_V4: [/^\/code/],
};

function getURL(path: string): string {
  let url;
  Object.entries(JSON.parse(window.NSN.featureToggles!)).forEach((featureToggle) => {
    const feature = featureToggle[0];
    const enabled = featureToggle[1];
    if (enabled === 'true' && pathRegexes[feature].some((pathRegex) => pathRegex.test(path))) {
      url = `${window.NSN.apiURLV4}${path}`;
    }
  });
  return url || `${window.NSN.apiURL}${path}`;
}

async function useSWRGetRequest(path: string, _cacheKey?: string): Promise<any> {
  return getRequest(path);
}

async function getRequest(
  path: string,
  options: string | RequestInit = {},
  modifyData: (data: any | Array<any>) => any | Array<any> = (data) => data,
  abortSignal?: AbortSignal,
  retry?: boolean,
): Promise<any> {
  const url = getURL(path);

  if (!options || typeof options === 'string') {
    // eslint-disable-next-line no-param-reassign
    options = {};
  }

  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers();
  }

  (options.headers as Headers).set('x-lighting-ui', 'true');

  const response = await fetch(url, { credentials: 'include', ...options, signal: abortSignal });

  if (response.headers.get(Utils.csrfTokenName)) {
    localStorage.setItem(Utils.csrfTokenName, response.headers.get(Utils.csrfTokenName) || '');
  }

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      if (!retry && await tryLogin(response)) {
        return getRequest(path, options, modifyData, abortSignal, true);
      }
    }

    const error = new FetchError(`${response.status}: ${response.statusText}`);
    try {
      error.info = await response.json();
    } catch (e) {
      error.info = JSON.stringify(e);
    }

    error.status = response.status;
    throw error;
  }

  if (response.status === 204) {
    return modifyData({});
  }

  try {
    const respObj = await response.json();

    return modifyData(respObj);
  } catch (e) {
    return modifyData({});
  }
}

async function getRequestAll(
  requests: GetAllRequests,
): Promise<{
  resolved: number,
  rejected: number,
  results: PromiseSettledResult<any>[]
}> {
  const promises: Promise<any>[] = [];

  requests.forEach((request) => {
    promises.push(new Promise((resolve, reject) => {
      getRequest(request.path, request.options || undefined, request.modifyData || undefined, request.abortSignal || undefined)
        .then((result) => resolve(result))
        .catch((e) => reject(e));
    }));
  });

  const results = await Promise.allSettled(promises);
  let resolved = 0;

  results.forEach((result) => {
    if (result.status === 'fulfilled') {
      resolved += 1;
    }
  });

  return {
    resolved,
    rejected: promises.length - resolved,
    results,
  };
}

async function nonGetRequest<I, O>(
  method: string,
  path: string,
  data?: I,
  retry?: boolean,
): Promise<{ data: O, error: string, status: number }> {
  const url = getURL(path);

  const netsenseCsrfToken: string = localStorage.getItem(Utils.csrfTokenName) || '';
  const headers: HeadersInit = new Headers();

  headers.set('Content-Type', 'application/json');
  headers.set('X-CSRF-Token', netsenseCsrfToken);
  headers.set('x-lighting-ui', 'true');

  const response = await fetch(url, {
    method,
    credentials: 'include',
    headers,
    body: JSON.stringify(data),
  });

  if (response.status === 401 || response.status === 403) {
    if (!retry && await tryLogin(response)) {
      return nonGetRequest(method, path, data, true);
    }
  }

  const resp = {
    data: {} as O,
    error: '',
    status: response.status,
  };

  if (response.status === 204) {
    return resp;
  }

  if (!response.ok && response.status >= 400) {
    if (response.status === 401 || response.status === 403) {
      resp.error = response.statusText || 'Access Denied';
    } else {
      resp.error = response.statusText || 'Internal Server Error';
    }
  }

  try {
    const respObj = await response.json();

    if (respObj.error) {
      resp.error = respObj.message || response.statusText || resp.error;
    }

    if (!resp.error) {
      resp.data = respObj;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log(e);
  }

  return resp;
}

async function nonGetRequestAll<I>(
  method: string,
  requests: NonGetAllRequests<I>,
): Promise<{
  resolved: number,
  rejected: number,
  results: PromiseSettledResult<any>[]
}> {
  const promises: Promise<any>[] = [];

  requests.forEach((request) => {
    promises.push(new Promise((resolve, reject) => {
      nonGetRequest(method, request.path, request.data || undefined)
        .then((result) => resolve(result))
        .catch((e) => reject(e));
    }));
  });

  const results = await Promise.allSettled(promises);
  let resolved = 0;

  results.forEach((result) => {
    if (result.status === 'fulfilled' && result.value.error === '') {
      resolved += 1;
    }
  });

  return {
    resolved,
    rejected: promises.length - resolved,
    results,
  };
}

async function tryLogin(response: Response) {
  const loginResponse = await fetch(`${window.NSN.apiURL}/login`, { credentials: 'include' });

  if (!loginResponse.ok && (response.status === 401 || response.status === 403)) {
    localStorage.removeItem('netsense-csrf-token');
    sessionStorage.clear();
    window.location.href = `${BASEPATH}/login`;
    return false;
  }

  localStorage.setItem(Utils.csrfTokenName, loginResponse.headers.get(Utils.csrfTokenName) || '');
  const data = await loginResponse.json();

  window.NSN = {
    ...window.NSN,
    userInfo: {
      ...data,
      name: data.name.replace(/([a-z])([A-Z])/g, '$1 $2'),
    },
  };

  sessionStorage.setItem('userInfo', JSON.stringify(data));
  return true;
}

const postRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('POST', path, data);

const putRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('PUT', path, data);

const patchRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('PATCH', path, data);

const deleteRequest = <I, O>(path: string, data?: I): Promise<{
  data: O, error: string, status: number }> => nonGetRequest<I, O>('DELETE', path, data);

const postRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('POST', requests);

const putRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('PUT', requests);

const patchRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('PATCH', requests);

const deleteRequestAll = <I>(requests: Array<{ path: string, data?: I }>): Promise<{
  resolved: number, rejected: number, results: PromiseSettledResult<any>[] }> => nonGetRequestAll<I>('DELETE', requests);

export {
  useSWRGetRequest,
  getRequest,
  getRequestAll,
  postRequest,
  postRequestAll,
  putRequest,
  putRequestAll,
  patchRequest,
  patchRequestAll,
  deleteRequest,
  deleteRequestAll,
  getURL,
};
