import React from 'react';
import { GroupsDetailViewProps } from '../../../types/GroupsDetailView';

// icons
import { ReactComponent as SuccessIcon } from '../../../img/icons/success.svg';
import { ReactComponent as NotWorkingIcon } from '../../../img/icons/not-working.svg';
import Sensors from '../../../utils/Sensors';

function GroupsDetailView(props: GroupsDetailViewProps): JSX.Element {
  const { group, alerts, listKey, descriptionLabel, scheduledDriverLevel } = props;
  let max = 1;
  const percent = 100;
  const colorSwitchAt = 7;

  Object.entries(alerts[group.groupid]).forEach(([keyName, value]) => {
    if (keyName !== 'count' && value > max) {
      max = value;
    }
  });

  const colors = ['#AC66CF', '#FFED7B', '#C2E28A', '#E0966B', '#65B7E1', '#65C2B6', '#E865BA'];

  return (
    <div className="groups-list-details">
      <div className="groups-list-details__nodes">
        <div className="groups-list-details__nodes-title">
          <span className="groups-list-details__nodes-title-value">
            {group.nodeList.length || 0 }
          </span>
          Total Nodes
        </div>
        <div className="groups-list-details__status-list">
          <div className="groups-list-details__nodes-status">
            <span className="groups-list-details__nodes-status-ok">
              <SuccessIcon />
              {group.nodeList.length - group.alertCount}
            </span>
            Everything ok
          </div>
          <div className="groups-list-details__nodes-status">
            <span className="groups-list-details__nodes-status-warning">
              <NotWorkingIcon />
              {group.alertCount}
            </span>
            Watch list
          </div>
          {group.type !== 'organizational' && (
          <div className="groups-list-details__nodes-status">
            <span className="groups-list-details__nodes-status-schedule">
              {Sensors.getLightsOnIcon(scheduledDriverLevel || 0)}
              {(scheduledDriverLevel || 0).toString()}
              %
            </span>
            Scheduled driver level
          </div>
          )}
        </div>
        <div className="groups-list-details__group-desc">
          <span className="groups-list-details__group-desc-title">{descriptionLabel}</span>
          {group.description}
        </div>
      </div>
      <div className="groups-list-details__alerts">
        <div className="groups-list-details__alerts-title">
          <span className="groups-list-details__alerts-title-value">
            {alerts[group.groupid]?.count || '--'}
          </span>
          Total alerts
        </div>
        <div className="groups-list-details__alerts-section">
          <div className="groups-list-details__alerts-section--total-alerts">
            {Object.entries(alerts[group.groupid]).map(([keyName, value], index) => (
              <div className="groups-list-details__alerts-all" key={`${listKey}-${index}`}>
                <div className="groups-list-details__alerts-obj">
                  <div className="groups-list-details__alerts-types" title={keyName}>{keyName}</div>
                </div>
                <div className="groups-list-details__alerts-value">
                  <span className="groups-list-details__alerts-numbers">
                    <span>{value}</span>
                  </span>
                  <div className="groups-list-details__alerts-count">
                    <div style={{ width: `${(value / max) * percent}%`, backgroundColor: colors[index % colorSwitchAt], height: '8px' }} />
                  </div>
                </div>
              </div>
            )).slice(1)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupsDetailView;
