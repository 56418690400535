import React, { useEffect, useRef, useState } from 'react';
import DropDown from '../../../Common/Components/DropDown';
import Modal from '../../../Common/Components/Modal';

// icons
import { ReactComponent as CustomListIcon } from '../../../img/icons/advanced-settings.svg';
import { ReactComponent as DownloadIcon } from '../../../img/icons/download.svg';
import { ReactComponent as UploadIcon } from '../../../img/icons/upload2.svg';
import { ReactComponent as SuccessIcon } from '../../../img/icons/success.svg';
import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';

import { ListOptionsProps } from '../../../types/ListOptionsProps';
import Utils from '../../../utils/Utils';
import { useAppContext } from '../../../utils/AppContext';
import Button from '../../../Common/Components/Button';
import DownloadList from '../../../Common/Components/DownloadList';
import CustomizeList from '../../../Common/Components/CustomizeList';
import getHeaderProps, { defaultHeaders } from '../../../utils/getHeaderProps';

function ListOptions(props: ListOptionsProps): JSX.Element {
  const {
    customAttributes,
    headersForDownload,
    headers,
    setHeaders,
    closeListOptions,
    nodesList,
    setSelectedNodes,
    filteredNodes,
    selectedNodes,
    filteredAndSelectedNodes,
  } = props;
  const [openModal, _setOpenModal] = useState(0);
  const [notFoundInNodeIDs, setNotFoundInNodeIDs] = useState<string[]>([]);

  const { addNotification } = useAppContext();

  const modalCustomizeList = 1;
  const modalUploadFile = 2;
  const modalDownloadList = 3;

  useEffect(() => {
    if (notFoundInNodeIDs.length > 0) {
      setOpenModal(modalUploadFile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notFoundInNodeIDs]);

  const setOpenModal = (newModal: number) => {
    _setOpenModal(newModal);

    if (newModal === 0) {
      closeListOptions();
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const nodeListStr = e.target?.result as string;
        const nodeList = nodeListStr?.split(/\r\n|\n|\r/).filter((el) => el !== '');
        const found = new Map();
        const notFound: string[] = [];

        nodeList.forEach((node) => {
          const nodeStr = String(node.replace(/\s+/g, ''));
          if (nodesList.has(nodeStr)) {
            found.set(nodeStr, nodesList.get(nodeStr));
          } else {
            notFound.push(node);
          }
        });
        setSelectedNodes(found);
        setNotFoundInNodeIDs(notFound);

        if (inputFile !== null && inputFile.current !== null) {
          inputFile.current.value = '';
        }

        if (notFound.length === 0) {
          addNotification({
            type: 'success',
            message: 'The nodes from your file are selected.',
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const downloadNotFoundNodes = () => {
    const header = getHeaderProps('NotFoundNodeIds');
    const idList = notFoundInNodeIDs.map((nodeId) => ({ nodeId }));

    Utils.downloadCSV(header, idList, 'not_found_nodeids');
  };

  const customHeaders = customAttributes.map((customAttribute) => (
    { key: `customAttributes_${customAttribute}`, val: customAttribute }
  ));

  return (
    <div className="list-options">
      { openModal === 0 && (
        <DropDown
          title="List options"
        >
          <div className="dropdown__content list-options__dropdown-content">
            <button
              type="button"
              className="list-options__element"
              onClick={() => {
                setOpenModal(modalCustomizeList);
              }}
            >
              <span>
                <CustomListIcon />
              </span>
              Customize list
            </button>
            <button
              type="button"
              className="list-options__element list-options__element--with-border"
              onClick={() => setOpenModal(modalDownloadList)}
            >
              <span>
                <DownloadIcon />
              </span>
              Download list
            </button>
            <button
              type="button"
              className="list-options__element"
              onClick={() => {
                setOpenModal(-1);
                handleFileUpload();
              }}
            >
              <span>
                <UploadIcon />
              </span>
              Select nodes by file
            </button>
          </div>
        </DropDown>
      )}
      {openModal === modalCustomizeList && (
        <CustomizeList
          customHeaders={customHeaders}
          headerList={headers}
          setOpenModal={() => setOpenModal(0)}
          setHeaders={setHeaders}
          defaultHeaders={defaultHeaders.LightsMain}
        />
      )}
      {openModal === modalUploadFile && (
        <Modal
          width="280"
          height="430"
          title="List uploaded"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Ok"
          primaryButtonAction={() => {
            setOpenModal(0);
            closeListOptions();
          }}
          className="list-uploaded"
        >
          <div className="list-uploaded__success">
            <SuccessIcon />
            <span>
              <span className="list-uploaded__success--bold">{`${selectedNodes.size} nodes`}</span>
              {' '}
              are selected
            </span>
          </div>
          <div className="list-uploaded__warning">
            <WarningIcon />
            <span>
              <span className="list-uploaded__success--bold">{`${notFoundInNodeIDs.length} nodes`}</span>
              {' '}
              were not found
            </span>
          </div>
          <Button onClick={() => downloadNotFoundNodes()} buttonType="none">
            <>
              <span>Download</span>
              <DownloadIcon />
            </>
          </Button>
          <div className="list-uploaded__id-list">
            {notFoundInNodeIDs.map((nodeId, i) => <span key={i}>{nodeId}</span>)}
          </div>
        </Modal>
      )}
      {openModal === modalDownloadList && (
        <DownloadList
          setOpenModal={setOpenModal}
          headerList={headersForDownload}
          enitreList={filteredNodes}
          selectedNodes={filteredAndSelectedNodes}
          fileName="lights_customized_list"
          skipColumns={['rowSelectCheckbox', 'location']}
        />
      )}
      <input type="file" id="file" ref={inputFile} accept=".csv" style={{ display: 'none' }} onChange={onChangeFileUpload} />
    </div>
  );
}

export default ListOptions;
