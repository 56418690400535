import React, { useState } from 'react';
import DropDown from '../../../Common/Components/DropDown';
import DropDownButton from '../../../Common/Components/DropDownButton';
import { ReactComponent as DetailedEnergyIcon } from '../../../img/icons/detailed-energy.svg';
import { ReactComponent as SmartMeterIcon } from '../../../img/icons/smart-meter.svg';
import { NodeReportProps, ReportProps } from '../../../types/ReportProps';
import { useAppContext } from '../../../utils/AppContext';
import EnergyModal from '../../Groups/Components/EnergyModal';
import GroupsEnergyReportWizard from './GroupsEnergyReportWizard';
import NodesEnergyReportWizard from './NodesEnergyReportWizard';

function Reports(props: ReportProps): JSX.Element {
  const { type, selectedSite, selectedCustomer, selectedNodes, selectedGroupList, closeReports } = props as NodeReportProps;

  const [openWizard, setOpenWizard] = useState<boolean>(false);
  const [openEnergyModal, setOpenEnergyModal] = useState<boolean>(false);
  const { addNotification } = useAppContext();
  const maxNodes = 1000;

  return (
    <>
      {!openWizard && !openEnergyModal && (
      <DropDown title="Reports">
        <>
          <DropDownButton
            icon={<SmartMeterIcon />}
            addClass="divider"
            text="Summary energy use"
            click={() => {
              if (type === 'lights-page') {
                if (selectedNodes.length < 1) {
                  addNotification({ type: 'warning', message: 'Please select at least one node.' });
                } else if (selectedNodes.length > maxNodes) {
                  addNotification({ type: 'warning', message: `Maximum ${maxNodes} nodes allowed. Please modify your selections.` });
                } else {
                  setOpenEnergyModal(true);
                }
              } else if (type === 'groups-page' && selectedGroupList !== undefined) {
                if (selectedGroupList.length < 1) {
                  addNotification({ type: 'warning', message: 'Please select at least one group.' });
                } else if (selectedGroupList.length > 1) {
                  addNotification({ type: 'warning', message: 'Please select only one group.' });
                } else {
                  setOpenEnergyModal(true);
                }
              }
              return '';
            }}
          />
          <DropDownButton
            icon={<DetailedEnergyIcon />}
            addClass="last"
            text="Detailed energy use"
            click={() => {
              if (type === 'lights-page') {
                if (selectedNodes.length < 1) {
                  addNotification({ type: 'warning', message: 'Please select at least one node.' });
                } else if (selectedNodes.length > maxNodes) {
                  addNotification({ type: 'warning', message: `Maximum ${maxNodes} nodes allowed. Please modify your selections.` });
                } else {
                  setOpenWizard(true);
                }
              } else if (type === 'groups-page') {
                if (selectedGroupList === undefined || selectedGroupList.length < 1) {
                  addNotification({ type: 'warning', message: 'Please select at least one group.' });
                } else if (selectedGroupList.length > 1) {
                  addNotification({ type: 'warning', message: 'Please select only one group.' });
                } else {
                  setOpenWizard(true);
                }
              }
              return '';
            }}
          />
        </>
      </DropDown>
      )}
      {openWizard && type === 'lights-page' && (
        <NodesEnergyReportWizard
          closeModal={() => {
            setOpenWizard(false);
            closeReports();
          }}
          selectedNodes={selectedNodes}
          selectedSite={selectedSite}
          selectedCustomer={selectedCustomer}
        />
      )}
      {openWizard && type === 'groups-page' && (
        <GroupsEnergyReportWizard
          closeModal={() => {
            setOpenWizard(false);
            closeReports();
          }}
          selectedGroup={selectedGroupList !== undefined ? selectedGroupList[0] : {}}
          selectedSite={selectedSite}
          selectedCustomer={selectedCustomer}
        />
      )}
      {openEnergyModal && (
        <EnergyModal
          selectedSite={selectedSite}
          selectedCustomer={selectedCustomer}
          selectedNodes={type === 'groups-page' ? null : selectedNodes}
          selectedGroup={selectedGroupList !== undefined ? selectedGroupList[0] : {}}
          setOpenEnergyModal={() => {
            setOpenEnergyModal(false);
            closeReports();
          }}
        />
      )}
    </>
  );
}

export default Reports;
