import React from 'react';

import { GroupActionsModalContentProps } from '../../../types/GroupActionsModalContentProps';
import GroupActionSubmitInfo from './GroupActionsSubmitInfo';
import GroupActionsTargetGroup from './GroupActionsTargetGroup';

function GroupActionsModalContent(props: GroupActionsModalContentProps): JSX.Element {
  const {
    selectedNodesSize,
    groupName,
    title,
    children,
    icon,
    infoboxTitle,
    type,
    addClass,
    titleType,
    infoboxTitlePlaceholder,
  } = props;

  const hasGroupName = groupName !== '';
  const fontSizeThreshold = 6;
  const nodesSizeStyle = { fontSize: selectedNodesSize && selectedNodesSize.toString().length > fontSizeThreshold ? '24px' : '32px' };

  return (
    <div className={`selected-info ${hasGroupName ? 'has-selected-node' : ''} ${addClass}`}>
      { (!title && type !== 'create-group') && <div className="selected-info__title">Move selected nodes</div>}
      { (title && !titleType) && <div className="selected-info__title">{title}</div>}
      {type !== 'create-group' && (
      <div className="selected-info__content">
        <div className="selected-info__content-nodes">
          { title && titleType === 'baseline' && <span className="selected-info__content-nodes-title">{title}</span>}
          {selectedNodesSize && <span style={nodesSizeStyle}>{selectedNodesSize}</span>}
        </div>
        { children
          || (
          <GroupActionsTargetGroup
            hasGroupName={hasGroupName}
            groupName={groupName}
            icon={icon}
            infoboxTitle={infoboxTitle}
            infoboxTitlePlaceholder={infoboxTitlePlaceholder}
          />
          )}
      </div>
      )}
      <GroupActionSubmitInfo isVisible={(type !== 'org-group' && hasGroupName) || type === 'create-group'} />
    </div>
  );
}

export default GroupActionsModalContent;
