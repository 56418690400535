import React from 'react';
import Modal from '../../../../../Common/Components/Modal';
import { AdminSidebarEnableMfaUserProps } from '../../../../../types/AdminSidebarEnableMfaUserProps';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequestAll } from '../../../../../utils/fetch';
import { ReactComponent as WarningIcon } from '../../../../../img/icons/warning.svg';

function AdminSidebarEnableMfaUser(props: AdminSidebarEnableMfaUserProps): JSX.Element {
  const {
    setOpenModal,
    selectedUsers,
    updateCustomers,
    setLoader } = props;

  const { addNotification } = useAppContext();

  return (
    <Modal
      setModalOpen={() => setOpenModal(0)}
      className="admin-user-modal"
      title="Enable MFA"
      primaryButtonAction={async () => {
        setOpenModal(0);
        setLoader();
        if (selectedUsers !== undefined) {
          try {
            const requests: { path: string, data: { email: string, name: string, roles: string, mfa: boolean }}[] = Array.from(selectedUsers.values()).map(
              (value) => ({
                path: `/customers/${value.accountid}/users/${value.userid}`,
                data: { email: value.email,
                  name: value.name,
                  roles: value.roles,
                  mfa: true },
              }),
            );
            postRequestAll(requests)
              .then((results) => {
                if (results.resolved === requests.length) {
                  addNotification({ type: 'success', message: 'Multi-factor authentication will be required for these user(s).' });
                  if (updateCustomers) {
                    updateCustomers();
                  }
                } else {
                  addNotification({ type: 'error', message: 'Your "Enable MFA" operation is failed.' });
                }
              });
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Enable MFA" operation is failed.' });
          }
        }
      }}
      primaryButtonLabel="Apply"
      secondaryButtonAction={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      borderTopColor="yellow"
    >
      <div className="admin-user-modal-content">
        <WarningIcon className="admin-user-modal-content-icon" />
        Require multi-factor authentication for these users?
      </div>
    </Modal>
  );
}

export default AdminSidebarEnableMfaUser;
