import React, { useEffect, useState } from 'react';
import Checkbox from './Checkbox';
import { WizardColumnSelectorProps } from '../../types/WizardDateAndColumnSelectorProps';
import Utils from '../../utils/Utils';

function WizardColumnSelector(props: WizardColumnSelectorProps): JSX.Element {
  const {
    selectedColumns,
    setSelectedColumns,
    mode,
    nodeGroup,
    allSelected,
    setAllSelected,
  } = props;

  const columns = [
    'site_name',
    'org_name',
    'group_names',
  ];

  const nodeColumns = [
    'node_id',
    'node_name',
    'fixture',
    'power_draw',
    'location',
    'schedule',
  ];

  const [allPossibleColumns] = useState<string[]>(() => [
    ...((mode === 'aggregated' && (nodeGroup === 'site' || nodeGroup === 'node')) ? columns.filter((e) => !(e === 'group_names')) : columns),
    ...((mode === 'individual') ? nodeColumns : []),
  ]);

  useEffect(() => {
    setSelectedColumns(selectedColumns.filter((item) => allPossibleColumns.includes(item)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPossibleColumns]);

  const columnCheckboxRenderer = (element: string, index: number) => (
    <div key={element} className={!(allPossibleColumns.includes(element)) ? 'columns--disabled' : ''}>
      <Checkbox
        checked={selectedColumns.includes(element)}
        dark={false}
        disabled={!(allPossibleColumns.includes(element))}
        onClick={(checked) => {
          if (checked) {
            setSelectedColumns([...selectedColumns, element]);
          } else {
            setSelectedColumns(
              selectedColumns.filter((id: string) => id !== element),
            );
          }
        }}
      />
      <span>{Utils.getColumnNameString(element)}</span>
    </div>
  );
  return (
    <div className="column-selector">
      <div className="column-selector__instruction">Select report output columns</div>
      <div className="columns columns--all">
        <div>
          <Checkbox
            checked={allSelected}
            dark={false}
            onClick={(checked) => {
              setSelectedColumns(checked ? allPossibleColumns : []);
              setAllSelected(checked);
            }}
          />
          <span>All</span>
        </div>
      </div>
      <div className="columns columns--general">
        {columns.map((element, index) => columnCheckboxRenderer(element, index))}
      </div>
      <div className="columns columns--node">
        {nodeColumns.map((element, index) => columnCheckboxRenderer(element, index))}
      </div>
    </div>
  );
}

export default WizardColumnSelector;
