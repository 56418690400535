import React from 'react';
import DropDown from '../../../Common/Components/DropDown';
import { ScheduleDropdownProps } from '../../../types/ScheduleDropdown';
import Utils from '../../../utils/Utils';
import SchedulePageList from './SchedulePageList';

function ScheduleDropdown(props: ScheduleDropdownProps): JSX.Element {
  const {
    schedules,
    handleActiveToolbar,
    selectedDate,
    setSelectedSchedules,
    selectedSchedules,
    setOpenScheduleModal,
    setActiveSchedule,
    newSchedule,
    defaultGroupDefaultSchedule,
  } = props;

  const isNonReadOnly = Utils.isNonReadOnly();
  const modalScheduleEdit = 2;

  return (
    <DropDown
      primaryButtonLabel={isNonReadOnly ? 'Create schedule' : ''}
      primaryButtonAction={() => {
        setActiveSchedule(newSchedule);
        setOpenScheduleModal(1);
        handleActiveToolbar(0);
      }}
      primaryButtonType="primary"
      className="dropdown-schedule-list"
      primaryButtonSize="small"
    >
      <div className="dropdown-schedule-list__content">
        <SchedulePageList
          data={schedules}
          setSelectedItems={setSelectedSchedules}
          selectedItems={selectedSchedules}
          selectedDate={selectedDate}
          setOpenScheduleModal={() => {
            setOpenScheduleModal(modalScheduleEdit);
            handleActiveToolbar(0);
          }}
          setActiveSchedule={setActiveSchedule}
          defaultGroupDefaultSchedule={defaultGroupDefaultSchedule}
        />
      </div>
    </DropDown>
  );
}

export default ScheduleDropdown;
