/* eslint-disable no-nested-ternary */
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import useSWR from 'swr';
import AutoCompleteBox from './AutoCompleteBox';
import Button from './Button';
import Textbox from './Textbox';
import { ReactComponent as MapIcon } from '../../img/icons/plus.svg';
import { ReactComponent as TrashCanIcon } from '../../img/icons/trash.svg';
import { Recipient } from '../../types/EnergyReportPayload';
import { UserObject } from '../../types/UserObject';
import { RecipientEditorProps } from '../../types/RecipientEditorProps';

const listWidth = 200;
const listHeight = 196;
const listRowHeight = 45;

function RecipientEditor(props: RecipientEditorProps): JSX.Element {
  const {
    recipients,
    setRecipients,
    selectedCustomer,
  } = props;

  const emailRegex = /\S+@\S+\.\S+/;

  const [newRecipientName, setNewRecipientName] = useState<string>();
  const [newRecipientMail, setNewRecipientMail] = useState<string>();
  const [newRecipientMailError, setNewRecipientMailError] = useState<boolean>(false);
  const [newRecipientMailErrorMessage, setNewRecipientMailErrorMessage] = useState<string>();
  const [newRecipientNameError, setNewRecipientNameError] = useState<boolean>(false);
  const [displayedRecipients, setDisplayedRecipients] = useState<{ recipient: Recipient, isFilled: boolean }[]>([]);

  const { data: userRespRowData } = useSWR<Map<string, UserObject>>(selectedCustomer.id ? [
    `/customers/${selectedCustomer.id}/users`,
    'GetUsers',
  ] : null);
  const userEmails = useMemo(() => Array.from((userRespRowData || new Map<string, UserObject>()).values())
    .filter((user) => (user.email && user.email !== ''))
    .map((user) => user.email), [userRespRowData]);

  const list = createRef<List>();
  const emptyRecipientCount = 4;

  useEffect(() => {
    const emptyRecipient = {
      name: '',
      email: '',
    } as Recipient;

    const recipientsWithFilledFlag = recipients.map((rec) => ({ recipient: rec, isFilled: true }));
    setDisplayedRecipients(recipientsWithFilledFlag
      .concat(
        new Array(Math.max((emptyRecipientCount - recipientsWithFilledFlag.length), 0))
          .fill({ recipient: emptyRecipient, isFilled: false }),
      ));
  }, [recipients]);

  const renderer = ({ key, index, style }: ListRowProps) => (
    <div
      className={`recipient-editor__list-element ${displayedRecipients[index].isFilled ? 'recipient-list-element--filled' : 'recipient-list-element--empty'}`}
      key={key}
      style={style}
    >
      <Textbox
        disabled
        value={displayedRecipients[index].recipient.name}
      />
      <Textbox
        disabled
        value={displayedRecipients[index].recipient.email}
      />
      <TrashCanIcon onClick={() => removeRecipient(displayedRecipients[index].recipient.email)} />
    </div>
  );

  const addRecipient = () => {
    const nameError = !newRecipientName || newRecipientName.length === 0;
    const mailError = !newRecipientMail || newRecipientMail.length === 0;
    const uniqueMailError = recipients.filter((rec) => rec.email === newRecipientMail).length !== 0;
    const invalidMailError = !emailRegex.test(newRecipientMail || '');
    setNewRecipientNameError(nameError);
    setNewRecipientMailErrorMessage(
      mailError
        ? 'Required field'
        : (invalidMailError
          ? 'Recipient email should be a valid email address.'
          : (uniqueMailError
            ? 'Recipient email should be unique.'
            : '')),
    );
    setNewRecipientMailError(mailError || uniqueMailError || invalidMailError);
    if (!nameError && !mailError && !uniqueMailError && !invalidMailError) {
      setRecipients([...recipients, { name: newRecipientName, email: newRecipientMail } as Recipient]);
      setNewRecipientName('');
      setNewRecipientMail('');
    }
  };

  const removeRecipient = (mailToDelete: string) => {
    setRecipients(recipients.filter((rec) => rec.email !== mailToDelete));
  };

  return (
    <div className="recipient-editor">
      <div className="recipient-editor__instruction">Specify recipients</div>
      <div className="recipient-editor__header">
        <Textbox
          onChange={(e) => {
            setNewRecipientName(e.target.value);
          }}
          label="Name"
          placeholder="Enter user name"
          value={newRecipientName}
          error={newRecipientNameError}
          errorMessage="Required field"
          isRequired
        />
        <AutoCompleteBox
          value={newRecipientMail}
          setValue={setNewRecipientMail}
          autoCompleteOptions={userEmails}
          label="Email"
          placeHolder="Enter email address"
          error={newRecipientMailError}
          errorMessage={newRecipientMailErrorMessage}
          isRequired
          id="recipient-editor__email"
        />
        <Button onClick={() => addRecipient()} buttonType="primary" extraClasses="round-button"><MapIcon /></Button>
      </div>
      <div className="recipient-editor__list">
        <List
          ref={list}
          width={listWidth}
          height={listHeight}
          rowHeight={listRowHeight}
          rowCount={displayedRecipients && displayedRecipients.length}
          rowRenderer={renderer}
          list={displayedRecipients}
          containerStyle={{
            width: '100%',
            maxWidth: '100%',
          }}
          style={{
            width: '100%',
          }}
        />
      </div>
      <span className="recipient-editor__datasize-text">
        {recipients.length}
        {' '}
        {recipients.length > 1 ? 'recipients' : 'recipient'}
      </span>
    </div>
  );
}

export default RecipientEditor;
