import React, { useState } from 'react';
import useSWR from 'swr';

import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import AdminSidebarAdminItems from '../Components/AdminSidebarAdminItems';
import Table from '../../../../Common/Components/Table';
import getHeaderProps from '../../../../utils/getHeaderProps';
import { SystemalertsPageProps } from '../../../../types/SystemalertsPageProps';
import { SystemAlertObject } from '../../../../types/SystemAlertObject';
import SystemAlertModal from './Components/SystemAlertModal';

function SystemAlertsPage(props: SystemalertsPageProps): JSX.Element {
  const headers = getHeaderProps('SystemAlerts');

  const [openModal, setOpenModal] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState<SystemAlertObject>();

  const { data: systemalertsResp, mutate: updateAlerts } = useSWR<Array<SystemAlertObject>>(() => '/system-feedback-channel/system-alerts');

  return (
    <div className="content content--admin">
      <AdminSidebar title="System alerts">
        <AdminSidebarAdminItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin sitemanager">
        <div className="table table--light site-manager auto-height">
          <Table
            headers={headers}
            data={systemalertsResp}
            skipCellMeasure
            cellOnClickColumns={['id', 'severity', 'status', 'category', 'cause', 'remedy', 'comment', 'closedBy']}
            cellOnClick={(alert: SystemAlertObject) => {
              setSelectedAlert(alert);
              setOpenModal(1);
            }}
            autoheight
          />
        </div>
      </div>
      {openModal && selectedAlert && (
        <SystemAlertModal
          setOpenModal={setOpenModal}
          selectedAlert={selectedAlert}
          updateAlerts={updateAlerts}
        />
      )}
    </div>
  );
}

export default SystemAlertsPage;
