import React from 'react';
import useSWR from 'swr';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';

import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { FirmwareObject } from '../../../types/FirmwareObjects';
import { LightsFirmwareTable } from '../../../types/LightsFirmwareTable';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Utils from '../../../utils/Utils';
import { firmwareFetcherFn } from '../../../utils/ApiDataHelpers';

function LightsFirmwareMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems } = props;
  const headers = getHeaderProps('LightsFirmware');

  const { data: firmwareResp } = useSWR<Array<FirmwareObject> | undefined>(['/firmwares', 'GetFirmwares'], firmwareFetcherFn);
  const selectedNodes: Array<LightsFirmwareTable> = [];
  selectedItems.forEach((node) => {
    const firmware = Utils.getFirmwareByNodeModelName(node, firmwareResp);

    const nodes: LightsFirmwareTable = {
      nodeId: node.nodeid,
      modelName: node.modelName,
      firmwareId: firmware?.firmwareid,
      version: firmware?.release,
      date: Utils.getConvertedDate(firmware?.when).split(',')[0],
    };

    selectedNodes.push(nodes);

    return nodes;
  });

  return (
    <div className="firmware-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>App firmware</span>
          </div>
          <div className="slide-in__content">
            <div className="table">
              <Table
                headers={headers}
                data={selectedNodes}
                fixColCount={1}
                dark
              />
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsFirmwareMulti;
