import React, { useMemo } from 'react';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Utils from '../../../utils/Utils';

function LightsIDsMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, customAttributes } = props;

  const headers = useMemo(() => getHeaderProps(
    'LightsMain',
    `-v3-${Utils.getApiHost()}-${Utils.getUserID()}`,
    Object.values(customAttributes?.customAttributes || {}),
    true,
  ).filter((header) => header.key === 'nodeid' || header.key.startsWith('customAttributes_')), [customAttributes]);

  const tableData = useMemo(() => Array.from(selectedItems.values()), [selectedItems]);

  return (
    <div className="lights-ids-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Light IDs</span>
          </div>
          <div className="slide-in__content">
            <div className="table small-table">
              <Table
                headers={headers}
                data={tableData}
                dataFetchedAlready
                fixColCount={1}
                dark
              />
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsIDsMulti;
