import { TableHeadersProp } from '../types/TableHeadersProp';
import { fixedGisCustomAttributes } from './constants';

const paddingLeft10Right5: TableHeadersProp['rowStyle'] = { paddingLeft: '10px', paddingRight: '5px' };
const paddingLeft5Right15: TableHeadersProp['rowStyle'] = { paddingLeft: '5px', paddingRight: '15px' };
const paddingLeft10Right15: TableHeadersProp['rowStyle'] = { paddingLeft: '10px', paddingRight: '15px' };
const paddingLeft10Right10: TableHeadersProp['rowStyle'] = { paddingLeft: '10px', paddingRight: '10px' };
const paddingLeft15Right15: TableHeadersProp['rowStyle'] = { paddingLeft: '15px', paddingRight: '15px' };
const paddingLeft20Right15: TableHeadersProp['rowStyle'] = { paddingLeft: '20px', paddingRight: '15px' };
const paddingLeft15Right20: TableHeadersProp['rowStyle'] = { paddingLeft: '15px', paddingRight: '20px' };

export const defaultHeaders: { [index: string]: TableHeadersProp[] } = {
  LightsMain: [
    { key: 'rowSelectCheckbox', width: 47, canFilter: false, uniqueKey: 'nodeid', rowStyle: paddingLeft10Right5, headStyle: paddingLeft10Right5, isCustomizable: false },
    { key: 'status', val: 'Status', width: 77, canFilter: false, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'nodeid', val: 'Node ID', rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'location', width: 34, canFilter: false, canSort: false, rowStyle: { justifyContent: 'center', ...paddingLeft5Right15 }, headStyle: paddingLeft5Right15, isCustomizable: false },
    { key: 'modelName', val: 'Model' },
    { key: 'name', val: 'Node name' },
    { key: 'fixturename', val: 'Fixture' },
    { key: 'lightinggroup', val: 'Lighting group' },
    { key: 'orggroup', val: 'Org group' },
    { key: 'schedulename', val: 'Schedule' },
    { key: 'schedDriverLevel', val: 'Sched. driver level', width: 210 },
    { key: 'senStat', val: 'Last driver level' },
    { key: 'ligLastReported', val: 'Last driver level report', width: 230 },
    { key: 'softwareVersion', val: 'App Firmware' },
    { key: 'deviceFirmwareVersion', val: 'Modem Firmware', width: 300 },
    { key: 'iccid', val: 'ICCID' },
    { key: 'msisdn', val: 'MDN' },
    { key: 'commissionedDate', val: 'Commission Date' },
    { key: 'note', val: 'Note', width: 400 },
    { key: 'latitude', val: 'Latitude' },
    { key: 'longitude', val: 'Longitude' },
  ],
  LightsEnergy: [
    { key: 'nodeid', val: 'Node ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'commissionedDate', val: 'Cumulative since', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'cumulativeEnergyUse', val: 'Active', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'cumulativeReactiveEnergyUse', val: 'Reactive', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'cumulativeOnStateLuminaire', val: 'Fixture burn', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'cumulativeOnStateNode', val: 'Node burn', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
  ],
  LightsOperation: [
    { key: 'nodeid', val: 'Node ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'fixturename', val: 'Fixture', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'schedulename', val: 'Schedule', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'scheduledDriverLevel', val: 'Sched. driver level', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'driverLevel', val: 'Last driver level', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'lastDriverLevelReport', val: 'Last driver level report', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'activePower', val: 'Power', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'cumulativeEnergyUse', val: 'Energy', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'ambientLight', val: 'Ambient', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
  ],
  LightsNetwork: [
    { key: 'nodeid', val: 'Node ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'tsNetStat', val: 'Network', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'platform', val: 'Platform', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'radioSignalStrength', val: 'RSS', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'sinr', val: 'SINR', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'linkQuality', val: 'Link Q', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'activationDate', val: 'Activated', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'commissionedDate', val: 'Comissioned', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'ligLastReported', val: 'Last data reported', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'lastRegistration', val: 'Last registration', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'firstInNetsense', val: 'First in NetSense', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'modemBootCount', val: 'Reboots', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
  ],
  LightsNetworkNonVerizonUsers: [
    { key: 'nodeid', val: 'Node ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'platform', val: 'Platform', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'radioSignalStrength', val: 'RSS', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'activationDate', val: 'Activated', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'commissionedDate', val: 'Comissioned', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'ligLastReported', val: 'Last data reported', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
  ],
  LightsPower: [
    { key: 'nodeid', val: 'Node ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'activePower', val: 'Power', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'mainsVoltage', val: 'Voltage', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'mainsCurrent', val: 'Current', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'powerFactor', val: 'PF', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'reactivePower', val: 'Reactive', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
  ],
  LightsFirmware: [
    { key: 'nodeId', val: 'Node ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'modelName', val: 'Model', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'firmwareId', val: 'Firmware ID', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'version', val: 'Version', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
    { key: 'date', val: 'Date', canFilter: false, rowStyle: paddingLeft15Right15, headStyle: paddingLeft15Right15 },
  ],
  AuditEntry: [
    { key: 'when', val: 'Date', canFilter: true },
    { key: 'activity', val: 'Activity', canFilter: true },
    { key: 'userid', val: 'User', canFilter: true },
    { key: 'targetid', val: 'Target', canFilter: true },
    { key: 'message', val: 'Details', canFilter: true },
  ],
  SessionHistory: [
    { key: 'deviceid', val: 'Device ID', canFilter: false, width: 170 },
    { key: 'mdn', val: 'MDN', canFilter: false, width: 140 },
    { key: 'imei', val: 'IMEI', canFilter: false, width: 170 },
    { key: 'iccid', val: 'ICCID', canFilter: false },
    { key: 'idaddress', val: 'IP Address', canFilter: false, width: 135 },
    { key: 'usage', val: 'Usage', canFilter: false, width: 120 },
    { key: 'start', val: 'Start Time/Date', canFilter: false },
    { key: 'end', val: 'End Time/Date', canFilter: false },
    { key: 'duration', val: 'Duration', canFilter: false, width: 180 },
  ],
  UsersVerizonAdminMain: [
    { key: 'rowSelectCheckbox', width: 47, canFilter: false, uniqueKey: 'userid', rowStyle: paddingLeft10Right15, headStyle: paddingLeft10Right15, isCustomizable: false },
    { key: 'email', val: 'Username (Email)', width: 250, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'edit', width: 47, canFilter: false, canSort: false, rowStyle: { justifyContent: 'center', ...paddingLeft5Right15 }, headStyle: paddingLeft5Right15, isCustomizable: false },
    { key: 'name', val: 'Name' },
    { key: 'rolesTitle', val: 'Role' },
    { key: 'phone', val: 'Mobile' },
    { key: 'mfa', val: 'MFA' },
    { key: 'tableAccountName', val: 'Account', canFilter: false, canSort: false },
    { key: 'tablePartnerName', val: 'Partner', canFilter: false, canSort: false },
  ],
  UsersVerizonNonAdminMain: [
    { key: 'email', val: 'Username (Email)', width: 250, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'name', val: 'Name' },
    { key: 'rolesTitle', val: 'Role' },
    { key: 'phone', val: 'Mobile' },
    { key: 'mfa', val: 'MFA' },
    { key: 'tableAccountName', val: 'Account', canFilter: false, canSort: false },
    { key: 'tablePartnerName', val: 'Partner', canFilter: false, canSort: false },
  ],
  UsersPartnerAdminMain: [
    { key: 'rowSelectCheckbox', width: 47, canFilter: false, uniqueKey: 'userid', rowStyle: paddingLeft5Right15, headStyle: paddingLeft5Right15, isCustomizable: false },
    { key: 'email', val: 'Username (Email)', width: 250, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'edit', width: 47, canFilter: false, canSort: false, rowStyle: { justifyContent: 'center', ...paddingLeft5Right15 }, headStyle: paddingLeft5Right15, isCustomizable: false },
    { key: 'name', val: 'Name' },
    { key: 'rolesTitle', val: 'Role' },
    { key: 'phone', val: 'Mobile' },
    { key: 'mfa', val: 'MFA' },
    { key: 'tableAccountName', val: 'Account' },
    { key: 'tablePartnerName', val: 'Partner' },
  ],
  UsersPartnerNonAdminMain: [
    { key: 'email', val: 'Username (Email)', width: 250, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'name', val: 'Name' },
    { key: 'rolesTitle', val: 'Role' },
    { key: 'phone', val: 'Mobile' },
    { key: 'mfa', val: 'MFA' },
    { key: 'tableAccountName', val: 'Account' },
    { key: 'tablePartnerName', val: 'Partner' },
  ],
  UsersEndUserAdminMain: [
    { key: 'rowSelectCheckbox', width: 47, canFilter: false, uniqueKey: 'userid', rowStyle: paddingLeft5Right15, headStyle: paddingLeft5Right15, isCustomizable: false },
    { key: 'email', val: 'Username (Email)', width: 250, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'edit', width: 47, canFilter: false, canSort: false, rowStyle: { justifyContent: 'center', ...paddingLeft5Right15 }, headStyle: paddingLeft5Right15, isCustomizable: false },
    { key: 'name', val: 'Name' },
    { key: 'rolesTitle', val: 'Role' },
    { key: 'phone', val: 'Mobile' },
    { key: 'mfa', val: 'MFA' },
  ],
  UsersEndUserNonAdminMain: [
    { key: 'email', val: 'Username (Email)', width: 250, rowStyle: paddingLeft10Right10, headStyle: paddingLeft10Right10, isCustomizable: false },
    { key: 'name', val: 'Name' },
    { key: 'rolesTitle', val: 'Role' },
    { key: 'phone', val: 'Mobile' },
    { key: 'mfa', val: 'MFA' },
  ],
  JobDetailsAddNodes: [
    { key: 'entityid', val: 'Node ID' },
    { key: 'name', val: 'Node name' },
    { key: 'fromgroup', val: 'From group' },
    { key: 'togroup', val: 'To group' },
    { key: 'statusFmt', val: 'Status' },
    { key: 'failmessage', val: 'Error message' },
    { key: 'updated', val: 'Time stamp' },
  ],
  JobDetailsUpdateGroup: [
    { key: 'entityid', val: 'Node ID' },
    { key: 'name', val: 'Node name' },
    { key: 'fromgroup', val: 'From group' },
    { key: 'togroup', val: 'To group' },
    { key: 'statusFmt', val: 'Status' },
    { key: 'failmessage', val: 'Error message' },
    { key: 'updated', val: 'Time stamp' },
  ],
  JobDetailsCreateGroup: [
    { key: 'entityid', val: 'Node ID', width: 160 },
    { key: 'name', val: 'Node name', width: 160 },
    { key: 'group', val: 'To group', width: 160 },
    { key: 'status', val: 'Status', width: 160 },
    { key: 'updated', val: 'Time stamp' },
  ],
  FirmwareJobDetails: [
    { key: 'entityid', val: 'Node ID', width: 160 },
    { key: 'name', val: 'Node name' },
    { key: 'toVersion', val: 'To version', width: 160 },
    { key: 'statusFmt', val: 'Status', width: 140 },
    { key: 'failmessage', val: 'Last reported step', width: 220 },
    { key: 'updated', val: 'Time stamp' },
  ],
  SiteManagerMain: [
    { key: 'name', val: 'Site name', rowStyle: { textDecoration: 'underline', color: '#339FD7', cursor: 'pointer' } },
    { key: 'city', val: 'City' },
    { key: 'state', val: 'State' },
    { key: 'org_name', val: 'Account' },
    { key: 'partner_org_name', val: 'Partner' },
    { key: 'actions',
      val: 'Actions',
      canFilter: false,
      canSort: false,
      rowStyle: { overflow: 'unset', display: 'block', paddingRight: 15, paddingLeft: 15 } },
  ],
  EnergyGraphOrgGroupBased: [
    { key: 'tick_datetime', val: 'Time' },
    { key: 'base', val: 'Organizational Group' },
    { key: 'energy_consumption', val: 'Consumption' },
    { key: 'unit', val: 'Unit' },
    { key: 'overall_estimation', val: 'Is Estimate' },
  ],
  EnergyGraphLightingGroupBased: [
    { key: 'tick_datetime', val: 'Time' },
    { key: 'base', val: 'Lighting Group' },
    { key: 'energy_consumption', val: 'Consumption' },
    { key: 'unit', val: 'Unit' },
    { key: 'overall_estimation', val: 'Is Estimate' },
  ],
  EnergyGraphSiteBased: [
    { key: 'tick_datetime', val: 'Time' },
    { key: 'base', val: 'Site' },
    { key: 'energy_consumption', val: 'Consumption' },
    { key: 'unit', val: 'Unit' },
    { key: 'overall_estimation', val: 'Is Estimate' },
  ],
  EnergyGraphNodeBased: [
    { key: 'tick_datetime', val: 'Time' },
    { key: 'base', val: 'Node' },
    { key: 'energy_consumption', val: 'Consumption' },
    { key: 'unit', val: 'Unit' },
    { key: 'overall_estimation', val: 'Is Estimate' },
  ],
  FixturesDetail: [
    { key: 'namewarning', val: 'Name', rowStyle: { overflow: 'unset' } },
    { key: 'description', val: 'Description' },
    { key: 'fixtureType', val: 'Type' },
    { key: 'PowerDraw', val: 'Wattage' },
    { key: 'manufacturer', val: 'Manufacturer' },
    { key: 'manufacturersku', val: 'SKU' },
    { key: 'edit', val: 'Edit', canFilter: false, canSort: false },
  ],
  AccountsList: [
    { key: 'name', val: 'Name' },
    { key: 'type', val: 'Type' },
    { key: 'street1', val: 'Street 1' },
    { key: 'street2', val: 'Street 2' },
    { key: 'city', val: 'City' },
    { key: 'state', val: 'State' },
    { key: 'postal_code', val: 'Postal code' },
    { key: 'country', val: 'Country' },
    { key: 'contact_email', val: 'Contact email' },
    { key: 'contact_phone', val: 'Contact phone' },
    { key: 'contact_name', val: 'Contact name' },
    { key: 'partner', val: 'Partner name' },
    { key: 'partner_id', val: 'Partner Id' },
    { key: 'account_id', val: 'Account Id' },
  ],
  NotFoundNodeIds: [{ key: 'nodeId', val: 'Node Id' }],
  CommissioningList: [
    { key: 'rowSelectCheckbox', canFilter: false, uniqueKey: 'node_id', rowStyle: paddingLeft10Right15, headStyle: paddingLeft10Right15, isCustomizable: false },
    { key: 'node_id', val: 'Node Id', isCustomizable: false, rowStyle: paddingLeft20Right15, headStyle: paddingLeft20Right15 },
    { key: 'actions', val: 'Actions', canFilter: false, canSort: false, rowStyle: { overflow: 'unset', ...paddingLeft15Right20 }, isCustomizable: false, headStyle: paddingLeft15Right20 },
    { key: 'account_name', val: 'Account name', rowStyle: paddingLeft20Right15, headStyle: paddingLeft20Right15 },
    { key: 'account_id', val: 'Account ID', rowStyle: paddingLeft15Right20, headStyle: paddingLeft15Right20 },
    { key: 'status', val: 'Status' },
    { key: 'network', val: 'Network' },
    { key: 'declared', val: 'Declared' },
    { key: 'commissioned', val: 'Commissioned' },
    { key: 'note', val: 'Note' },
    { key: 'latitude', val: 'Latitude' },
    { key: 'longitude', val: 'Longitude' },
    { key: 'gps', val: 'GPS sample size' },
    { key: 'app_firmware', val: 'App firmware' },
    { key: 'model', val: 'Model' },
    { key: 'msisdn', val: 'MSISDN' },
    { key: 'iccid', val: 'ICCID' },
  ],
  CustomAttributes: [
    { key: 'nodeid', val: 'Node ID' },
  ],
  AlarmHistory: [
    { key: 'ufname', val: 'Alarm name', canFilter: false },
    { key: 'severityIcon', val: 'Severity', canFilter: false },
    { key: 'status', val: 'Status', canFilter: false },
    { key: 'created', val: 'Created', canFilter: false },
    { key: 'updated', val: 'Updated', canFilter: false },
    { key: 'updated_by', val: 'Updated by', canFilter: false },
  ],
  Alarms: [
    { key: 'rowSelectCheckbox', width: 47, canFilter: false, uniqueKey: 'alertid', rowStyle: paddingLeft10Right5, headStyle: paddingLeft10Right5, isCustomizable: false },
    { key: 'name', val: 'Alarm name', width: 227, isCustomizable: false },
    { key: 'actions', val: 'Actions', canFilter: false, canSort: false, width: 120, isCustomizable: false, rowStyle: paddingLeft10Right10 },
    { key: 'nodeid', val: 'Node ID', width: 160, rowStyle: { textDecoration: 'underline', color: '#339FD7', cursor: 'pointer' } },
    { key: 'severityFmt', val: 'Severity', canFilter: false, width: 120 },
    { key: 'status', val: 'Status', width: 110 },
    { key: 'createdFmt', val: 'Created', width: 180 },
    { key: 'updatedFmt', val: 'Updated', width: 180 },
    { key: 'updatedBy', val: 'Updated by', width: 180 },
    { key: 'nodename', val: 'Node name' },
    { key: 'lightinggroup', val: 'Lighting group' },
    { key: 'orggroups', val: 'Org groups' },
    { key: 'lat', val: 'Latitude' },
    { key: 'lon', val: 'Longitude' },
    { key: 'nodehw', val: 'Model' },
  ],
  SystemAlerts: [
    { key: 'id', val: 'ID', width: 100 },
    { key: 'severity', val: 'Severity', width: 100 },
    { key: 'status', val: 'Status', width: 100 },
    { key: 'category', val: 'Category', width: 100 },
    { key: 'cause', val: 'Cause', width: 200 },
    { key: 'remedy', val: 'Remedy', width: 200 },
    { key: 'comment', val: 'Comment', width: 240 },
    { key: 'closedBy', val: 'ClosedBy', width: 180 },
    { key: 'createdAt', val: 'CreatedAt', width: 180 },
    { key: 'message', val: 'Message', width: 300 },
    { key: 'source', val: 'Source', width: 180 },
    { key: 'updatedAt', val: 'UpdatedAt', width: 180 },
    { key: 'version', val: 'Version' },
  ],
};

export default function getHeaderProps(type: string, version = '', customAttributes?: string[], useCustomAttributes = false, showAllCustomAttributeHeaders = false): TableHeadersProp[] {
  const defaultHeader = [...defaultHeaders[type]];
  let headers: TableHeadersProp[] = [];
  let savedHeaders = false;

  if (localStorage.getItem(`${type}TableHeaders${version}`)) {
    headers = JSON.parse(localStorage.getItem(`${type}TableHeaders${version}`) || '[]');
    savedHeaders = headers.length > 0;
  }

  if (!savedHeaders) {
    headers = defaultHeader;
  }

  if (useCustomAttributes) {
    let allCustomAttributes = fixedGisCustomAttributes;

    if (customAttributes && customAttributes.length > 0) {
      allCustomAttributes = [...fixedGisCustomAttributes, ...customAttributes];
    }

    if (savedHeaders) {
      headers.forEach((header, index) => {
        if (header.key.startsWith('customAttributes_') && !allCustomAttributes.includes(header.key.replace('customAttributes_', ''))) {
          headers.splice(index, 1);
        }
      });
    } else {
      let addCustomAttributeHeaders: string[] = [];

      if (showAllCustomAttributeHeaders) {
        addCustomAttributeHeaders = [...allCustomAttributes];
      } else {
        addCustomAttributeHeaders = [...fixedGisCustomAttributes];
      }

      addCustomAttributeHeaders.forEach((customAttribute) => {
        headers.push({ key: `customAttributes_${customAttribute}`, val: customAttribute });
      });
    }
  }

  return headers.filter((a: TableHeadersProp) => a.visible !== false);
}
