import React, { useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Modal from '../../../Common/Components/Modal';
import { AssignScheduleModalProps } from '../../../types/AssignScheduleModalProps';
import { postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';
import Loading from '../../../Common/Components/Loading';

// icons
import { ReactComponent as SchedulesIcon } from '../../../img/icons/schedule-large.svg';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';

const listWidth = 340;
const rowHeight = 43;

function AssignScheduleModal(props: AssignScheduleModalProps): JSX.Element {
  const {
    setModalOpen,
    group,
    groupsSchedule,
    schedules = [],
    updateSchedules,
    selectedCustomer,
    selectedSite,
  } = props;

  const currentSchedule = groupsSchedule.get(group.groupid);
  const [selectedSchedule, setSelectedSchedule] = useState(currentSchedule);
  const [activeSchedule, setActiveSchedule] = useState(currentSchedule);
  const [isLoading, setIsLoading] = useState(false);

  let orderedSchedules = [...schedules];

  if (activeSchedule) {
    orderedSchedules = [activeSchedule, schedules.filter((schedule) => schedule.name !== activeSchedule?.name)].flat();
  }

  const { addNotification } = useAppContext();

  const scheduleListRenderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>
      <button
        type="button"
        className={`
          assigned-groups__name
          ${selectedSchedule?.name === orderedSchedules[index]?.name ? 'assigned-groups__name--selected' : ''}
          ${activeSchedule?.name === orderedSchedules[index]?.name ? 'assigned-groups__name--current' : ''}
        `}
        onClick={() => setSelectedSchedule(orderedSchedules[index])}
      >
        {orderedSchedules[index]?.name}
      </button>
    </div>
  );

  const rowsInList = 4;

  return (
    <Modal
      title="Assign schedule"
      setModalOpen={setModalOpen}
      width="402"
      height={orderedSchedules.length > 0 ? '536' : 'auto'}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        setIsLoading(true);
        try {
          const result = await postRequest(
            `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/schedules/${selectedSchedule?.scheduleid}/apply/groups/${group.groupid}`,
          );

          if (!result.error) {
            addNotification({ type: 'success', message: `Schedule for "${group.name}" (${group.groupid}) successfully applied.` });
            updateSchedules();
            setActiveSchedule(selectedSchedule);
            setModalOpen(false);
          } else {
            addNotification({ type: 'error', message: `Cannot apply the selected schedule. API reported error: ${result.error}` });
          }
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          addNotification({ type: 'error', message: 'Your "Assign schedule" operation is failed.' });
        }
      }}
      secondaryButtonLabel="Cancel"
    >
      <div className="assigned-groups">
        <div className="assigned-groups__schedule">
          <SchedulesIcon />
          <div className="margin-left-15">
            <div className="assigned-groups__schedule-text">Lighting group</div>
            <div className="assigned-groups__schedule-name">{group.name}</div>
          </div>
        </div>
        <div className="assigned-groups__list-title">Select schedule</div>
        {orderedSchedules.length > 0 ? (
          <div className="assigned-groups__list">
            <List
              width={listWidth}
              height={rowHeight * rowsInList}
              rowHeight={rowHeight}
              rowCount={orderedSchedules.length}
              rowRenderer={scheduleListRenderer}
              list={orderedSchedules}
              autoHeight={orderedSchedules.length < 4}
            />
            {isLoading && (<Loading />)}
          </div>
        ) : (
          <div className="assigned-groups__list-empty">No schedule found</div>
        )}
        <div className="assigned-groups__info">
          <InfoIcon />
          {' '}
          The new schedule will replace the current schedule for the lighting group.
        </div>
        <div className="assigned-groups__warning">
          <WarningIcon />
          <div>
            Please allow up to
            <span className="assigned-groups__warning--medium">3 days</span>
            for the nodes to receive their new schedule.
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AssignScheduleModal;
