/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import Button from '../../Common/Components/Button';
import CustomizeList from '../../Common/Components/CustomizeList';
import DropDown from '../../Common/Components/DropDown';
import Table from '../../Common/Components/Table';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import ToolbarItem from '../../Common/Components/ToolbarItem';
import ToolbarLink from '../../Common/Components/ToolbarLink';
import Tooltip from '../../Common/Components/Tooltip';
import { ReactComponent as CalendarIcon } from '../../img/icons/calendar.svg';
import { ReactComponent as CustomizeIcon } from '../../img/icons/customize-list.svg';
import { ReactComponent as DownloadIcon } from '../../img/icons/download.svg';
// Icons
import { ReactComponent as FilterLargeIcon } from '../../img/icons/filterlarge.svg';
import { ReactComponent as HourglassIcon } from '../../img/icons/hourglass.svg';
import { ReactComponent as MoreIcon } from '../../img/icons/more-horizontal.svg';
import { ReactComponent as AlarmIcon } from '../../img/icons/notification-filled.svg';
import { ReactComponent as PlaybookIcon } from '../../img/icons/playbook.svg';
import { ReactComponent as TooltipIcon } from '../../img/icons/tooltip-inverse.svg';
import { ReactComponent as TrashIcon } from '../../img/icons/trash.svg';
import { AlertObject } from '../../types/AlertObject';
import { GroupObject } from '../../types/GroupObject';
import { NodeObject } from '../../types/NodeObject';
import { PageComponentProps } from '../../types/PageComponentProps';
import { SelectBoxItemType } from '../../types/SelectBoxPropsType';
import { SiteObject } from '../../types/SiteObject';
import { UfalarmObject } from '../../types/UfalarmObject';
import { alertsFetcherFn, groupsNodesFn, nodeNamesFn, nodesFetcherFn, ufnamesActionsFn } from '../../utils/ApiDataHelpers';
import { defaultHeaders } from '../../utils/getHeaderProps';
import useAlarmsPageState from '../../utils/state/useAlarmsPageState';
import { useAppContext } from '../../utils/AppContext';
import Utils from '../../utils/Utils';
// import AlarmsFilter from './Components/AlarmsFilter';
import DatePeriodSelector from '../Energy/components/DatePeriodSelector';
import GroupsFilter from '../Lights/Components/GroupsFilter';
import AlarmPlaybook from './Components/AlarmPlaybook';
import AlarmsClearByType from './Components/AlarmsClearByType';
import AlarmsDuration from './Components/AlarmsDuration';
import ClearAlarm from './Components/ClearAlarm';
import ClearAlarmType from './Components/ClearAlarmType';
import ClearSelectedAlarms from './Components/ClearSelectedAlarms';

function AlarmsPage(props: PageComponentProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
  } = props;
  const {
    selectedAlarms,
    setSelectedAlarms,
    headers,
    setHeaders,
    activeToolbar,
    handleActiveToolbar,
  } = useAlarmsPageState();

  const history = useHistory();

  // daterange
  const maxSelectableDuration = 90;
  const initDate = () => {
    let start = DateTime.fromMillis(Date.now());
    const end = DateTime.fromMillis(Date.now());
    start = start.minus({ days: 7 });
    return {
      start,
      end,
    };
  };
  const [startDate, setStartDate] = useState(initDate().start);
  const [endDate, setEndDate] = useState(initDate().end);

  // toggle "Active" and "All" alarms
  const [active, setActive] = useState(true);

  // selected alarm for clearing and playbook
  const [selectedAlarm, setSelectedAlarm] = useState<AlertObject>();

  // control the modals
  const modalPlaybook = 1;
  const modalClearAlarm = 2;
  const modalDropdownClearByType = 3;
  const modalClearByType = 4;
  const modalClearSelected = 5;

  const toolbarDaterange = 1;
  const toolbarDuration = 2;
  const toolbarFiltergroup = 3;

  const hoursInDay = 24;
  const minsInHour = 60;
  const secsInMin = 60;
  const msInSec = 1000;

  const alarmsLimit = 10000;
  const { addNotification } = useAppContext();

  const fixColCount = 2;

  // check whether alarm duration matches user settings

  const durationMatches = (durationSettings:Record<string, string>, alarm:AlertObject) => {
    const start = (typeof alarm.created === 'number' ? alarm.created : parseInt(alarm.created, 10)) / msInSec;
    let end = 0;
    if (alarm.updated) {
      end = (typeof alarm.updated === 'number' ? alarm.updated : parseInt(alarm.updated, 10)) / msInSec;
    } else {
      end = (new Date()).getTime();
    }
    const duration = end - start;
    const threshold = (parseInt(durationSettings.days, 10) * hoursInDay + parseInt(durationSettings.hours, 10)) * (minsInHour * secsInMin * msInSec);
    return (durationSettings.compare === 'More than'
      ? duration > threshold
      : duration < threshold);
  };

  // check if alarm is contained in selected lighting groups and/or org groups

  const groupsMatch = (groups:Record<string, Array<string>>, alarm:AlertObject) => {
    const match = groups.filteredLightingGroups.includes(alarm.lightinggroup || '')
      || (alarm.orggroups
        && alarm.orggroups.length > 0
        // check if the intersection of the alarm's orggroups array and the selected orggroups array has any elements
        && (alarm.orggroups.split(', ').filter((value) => groups.filteredOrgGroups.includes(value)).length > 0));
    return match;
  };

  // render the Actions column (tooltip, playbook, clear)
  const actionsCell = (alarm: AlertObject) => {
    const { description, updatedBy, status } = alarm;
    return (
      <div className="alarm-actions">
        <Tooltip
          text={description}
          position="top"
          offset={-2}
        >
          <TooltipIcon />
        </Tooltip>
        <Tooltip
          text="Playbook"
          position="top"
          offset={-2}
        >
          <Button
            onClick={() => {
              setSelectedAlarm(alarm);
              setShowModal(modalPlaybook);
            }}
          >
            <PlaybookIcon />
          </Button>
        </Tooltip>
        {updatedBy !== 'System Update' && status === 'Active' && (
        <Tooltip
          text="Clear alarm"
          position="top"
          offset={-2}
        >
          <Button
            onClick={() => {
              setSelectedAlarm(alarm);
              setShowModal(modalClearAlarm);
            }}
          >
            <TrashIcon />
          </Button>
        </Tooltip>
        )}
      </div>
    );
  };

  // render the Severity column, including colored icon
  // eslint-disable-next-line react/prop-types
  const severityCell = (severity: string) =>
    (
      <div>
        <AlarmIcon className={`alarm-icon alarm-icon__${severity}`} />
        <span>{severity}</span>
      </div>
    );

  // Site data

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(() => `/customers/${selectedCustomer.id}/sites`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  // Alarms

  const startSiteZone = startDate.setZone(site?.time_zone || 'UTC').startOf('day');
  const endSiteZone = endDate.setZone(site?.time_zone || 'UTC').endOf('day');
  const { data: alertsResp, mutate: updateAlerts } = useSWR<Array<AlertObject> | undefined>(
    () => {
      const queryString = active
        ? `?filter=active in (true)&cursor&limit=${alarmsLimit}`
        : `?cursor&limit=${alarmsLimit}`;
      return `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alert-dashboard/from/${startSiteZone.toISO()}/to/${endSiteZone.toISO()}${queryString}`;
    },
    alertsFetcherFn,
  );

  // User-Friendly Alarms (for Playbook)

  const { data: ufalarmsResp } = useSWR<Array<UfalarmObject>>(() => '/manage/alarms');

  const alarmActions = useMemo(() => ufnamesActionsFn(ufalarmsResp), [ufalarmsResp]);

  // Nodes (for node names)

  const { data: nodesResp } = useSWR<Array<NodeObject>>(() => `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes`, nodesFetcherFn);
  const nodeNames = useMemo(() => nodeNamesFn(nodesResp || []), [nodesResp]);

  // Groups

  const { data: groupsResp } = useSWR<Array<GroupObject>>(() => `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`);

  const {
    groupsNodes = {},
    lightingGroups = [],
    orgGroups = [],
  } = useMemo(() => groupsNodesFn(groupsResp), [groupsResp]);

  const groupsFilterObject: Record<string, Array<string>> = {
    filteredLightingGroups: [],
    filteredOrgGroups: [],
  };

  const [groupFilterActiveTab, setGroupFilterActiveTab] = useState(0);
  const [applyGroupFilterObject, setApplyGroupFilter] = useState(groupsFilterObject);
  const [filterGroupsSubtitle, setFilterGroupsSubtitle] = useState(
    applyGroupFilterObject.filteredLightingGroups
      .concat(applyGroupFilterObject.filteredOrgGroups).length,
  );

  const [showModal, setShowModal] = useState(0);

  const [selectedClearType, setSelectedClearType] = useState<string>('');

  const [filteredAlarms, setFilteredAlarms] = useState<AlertObject[]>();

  // alarm type and duration
  const defaultAlarmsDurationSettings: Record<string, string> = {
    alarmType: 'All',
    compare: 'Any',
    days: '30',
    hours: '0',
  };

  const [alarmsCount, setAlarmsCount] = useState<number>(0);

  const [alarmsDurationSettings, setApplyAlarmsDuration] = useState(defaultAlarmsDurationSettings);
  const alarmTypeItems: SelectBoxItemType[] = useMemo(() => {
    const tmpArr: SelectBoxItemType[] = [];
    const alertNames = new Set<string>();

    alertsResp?.forEach((alert) => {
      alertNames.add((alert.ufname && alert.ufname.length > 0) ? alert.ufname : alert.name);
    });

    let index = 1;
    alertNames.forEach((name) => {
      tmpArr.push({ key: index.toString(), title: name });
      index += 1;
    });

    return tmpArr;
  }, [alertsResp]);

  const clearableAlarms: SelectBoxItemType[] = useMemo(() => {
    const doNotListAlarms = ['ThreadFailure', 'NodeLocationChangedFailure', 'WarmStartFailure', 'FirmwareUpgradeFailure', 'TelemetryLoggingError', 'AppRestoreError'];
    const tmpArr: SelectBoxItemType[] = [];
    const alertNames = new Set<string>();

    const filteredAlarmsByType = alertsResp?.filter((a) => !doNotListAlarms.includes(a.alarmtype));

    filteredAlarmsByType?.forEach((alert) => {
      alertNames.add((alert.ufname && alert.ufname.length > 0) ? alert.ufname : alert.name);
    });

    let index = 1;
    alertNames.forEach((name) => {
      tmpArr.push({ key: index.toString(), title: name });
      index += 1;
    });

    return tmpArr;
  }, [alertsResp]);

  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  const downloadAlarms = () => {
    const csvHeaders = headers.filter((header) => header.key !== 'nodeid');
    Utils.downloadCSV([{ key: 'nodeid', val: 'NodeID' }, { key: 'severity', val: 'Severity' }, ...csvHeaders].filter((header) => !['actions', 'nodeidFmt', 'severityFmt', 'rowSelectCheckbox'].includes(header.key)), filteredAlarms || [], 'alarms');
  };

  useEffect(() => {
    if (Array.isArray(alertsResp) && alertsResp.length === 0) {
      setFilteredAlarms([]);
      forceUpdate();
    } else if (alertsResp?.length && Array.isArray(alertsResp)) {
      if (alertsResp?.length === alarmsLimit) {
        addNotification({
          type: 'info',
          message: `Maximum of ${alarmsLimit} alarms have been retrieved.  Select a smaller date range.`,
        });
      }
      const alarms = alertsResp.filter((alarm) =>
        ((typeof groupsNodes[alarm.nodeid] !== 'undefined')
        && (alarmsDurationSettings.alarmType === 'All' || alarmsDurationSettings.alarmType === alarm.name)
        && (alarmsDurationSettings.compare === 'Any' || durationMatches(alarmsDurationSettings, alarm))
        && (filterGroupsSubtitle === 0 || groupsMatch(applyGroupFilterObject, alarm))
        ))
        .map((a) => {
          a.name = (a.ufname && a.ufname.length > 0) ? a.ufname : a.name;
          a.status = a.active ? 'Active' : 'Cleared';
          a.updatedBy = a.updated_by;
          a.actions = actionsCell(a);
          a.latTableSort = Number(a.lat);
          a.lat = Number(a.lat).toFixed(6);
          a.lonTableSort = Number(a.lon);
          a.lon = Number(a.lon).toFixed(6);
          a.nodename = nodeNames.get(a.nodeid) || '';
          a.severityFmt = severityCell(a.severity);
          a.severityFmtTableSort = ['Minor', 'Major', 'Critical'].indexOf(a.severity);
          a.created = typeof a.created === 'string' ? parseInt(a.created, 10) : a.created;
          a.updated = typeof a.updated === 'string' ? parseInt(a.updated, 10) : a.updated;
          a.lightinggroup = groupsNodes[a.nodeid].lightinggroup.name;
          a.orggroups = groupsNodes[a.nodeid].orggroups.map((g) => g.name).join(', ');
          a.createdFmtTableSort = a.created / msInSec;
          a.createdFmt = Utils.convertISOtoAlarmTime(DateTime.fromMillis(a.created / msInSec).toISO(), site ? site.time_zone : 'UTC');
          a.updatedFmtTableSort = a.updated / msInSec;
          a.updatedFmt = Utils.convertISOtoAlarmTime(DateTime.fromMillis(a.updated / msInSec).toISO(), site ? site.time_zone : 'UTC');
          a.nodehw = Utils.getModelName(a.nodehw);
          return a;
        });

      const filteredGroupsLength = applyGroupFilterObject.filteredLightingGroups
        .concat(applyGroupFilterObject.filteredOrgGroups).length;
      setFilterGroupsSubtitle(filteredGroupsLength);

      // don't count alarms for nodes that are no longer on this site (not in any groups)
      setAlarmsCount(alertsResp.filter((alarm) => typeof groupsNodes[alarm.nodeid] !== 'undefined').length);

      setFilteredAlarms(alarms);
      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeToolbar,
    alarmsDurationSettings,
    applyGroupFilterObject,
    filterGroupsSubtitle,
    alertsResp,
  ]);

  return (
    <div className="alarms">
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Alarms" subtitle={selectedSite.name} />
            <ToolbarItem
              addClass="toolbar-alarms-active"
              onclick={() => { if (!active) { setActive(true); updateAlerts(); } }}
              content={<span>Active</span>}
              tabPanelisActive={active ? 1 : 0}
              order={1}
            />
            <ToolbarItem
              addClass="toolbar-alarms-all"
              onclick={() => { if (active) { setActive(false); updateAlerts(); } }}
              content={<span>All</span>}
              tabPanelisActive={active ? 1 : 0}
              order={0}
            />
            <ToolbarItem
              icon={<CalendarIcon />}
              fields={[
                { name: 'Start', value: startDate.toFormat('LLLL d, yyyy') },
                { name: 'End', value: endDate.toFormat('LLLL d, yyyy') },
              ]}
              onclick={() => handleActiveToolbar(toolbarDaterange)}
              tabPanelisActive={activeToolbar}
              order={toolbarDaterange}
              addClass="toolbaritem-alarms-daterange"
            >
              <DatePeriodSelector
                startDate={startDate.toJSDate()}
                endDate={endDate.toJSDate()}
                setStartDate={(dt) => {
                  setStartDate(DateTime.fromJSDate(dt as Date));
                }}
                setEndDate={(dt) => setEndDate(DateTime.fromJSDate(dt as Date))}
                maxDays={maxSelectableDuration}
                handleActiveToolbar={handleActiveToolbar}
              />
            </ToolbarItem>
            <ToolbarItem
              addClass="toolbaritem-alarms-duration last"
              icon={<HourglassIcon />}
              onclick={() => handleActiveToolbar(toolbarDuration)}
              order={toolbarDuration}
              tabPanelisActive={activeToolbar}
              fields={
                [{ name: 'Alarm name',
                  value: `${alarmsDurationSettings.alarmType}` },
                { name: 'Duration',
                  value: alarmsDurationSettings.compare === 'Any'
                    ? 'Any'
                    : `${alarmsDurationSettings.compare} ${alarmsDurationSettings.days} days, ${alarmsDurationSettings.hours} hours` },
                ]
                }
            >
              <AlarmsDuration
                className="dropdown-alarms-duration"
                alarmsDurationSettings={alarmsDurationSettings}
                setApplyAlarmsDuration={setApplyAlarmsDuration}
                handleActiveToolbar={handleActiveToolbar}
                alarmTypeItems={[{ key: '0', title: 'All' }, ...alarmTypeItems]}
              />
            </ToolbarItem>
          </div>
          <ToolbarLink
            addClass="toolbaritem-alarms-filtergroups"
            icon={<FilterLargeIcon />}
            title="Filter groups"
            subtitleStyle="light"
            subtitle="All groups"
            subtitleNum={filterGroupsSubtitle}
            subtitleText="group"
            onclick={() => handleActiveToolbar(toolbarFiltergroup)}
            tabPanelisActive={activeToolbar}
            order={toolbarFiltergroup}
          >
            <GroupsFilter
              className="dropdown-groups-filter"
              lightingGroups={lightingGroups}
              orgGroups={orgGroups}
              setApplyGroupFilter={setApplyGroupFilter}
              filteredLightingGroups={applyGroupFilterObject.filteredLightingGroups}
              filteredOrgGroups={applyGroupFilterObject.filteredOrgGroups}
              handleActiveToolbar={handleActiveToolbar}
              setGroupFilterActiveTab={setGroupFilterActiveTab}
              groupFilterActiveTab={groupFilterActiveTab}
            />
          </ToolbarLink>
        </div>
        <button type="button" className={`commissioning__info--actions-btn commissioning__info--actions-list ${activeToolbar === 4 ? 'selected' : ''}`} onClick={() => handleActiveToolbar(4)}>
          <MoreIcon />
        </button>
        {activeToolbar === 4 ? (
          <div className="actions-alarms">
            <DropDown>
              <>
                <button type="button" onClick={() => { setShowModal(5); handleActiveToolbar(0); }} className="actions-alarms__element">
                  <CustomizeIcon />
                  Customize list
                </button>
                <button type="button" onClick={() => { downloadAlarms(); handleActiveToolbar(0); }} className="actions-alarms__element">
                  <DownloadIcon />
                  Download list
                </button>
              </>
            </DropDown>
          </div>
        ) : <></>}
      </Toolbar>
      <div className="alarms-subheading-container">
        <div className="alarms-subheading">
          <span className="alarms-subheading__counts">
            <span>Total for time period</span>
            <span className="alarms-subheading__count">
              {alarmsCount}
            </span>
            {(filterGroupsSubtitle !== 0
              || alarmsDurationSettings.alarmType !== 'All'
              || alarmsDurationSettings.compare !== 'Any')
              && (
                <>
                  (
                  <span className="alarms-subheading__count">
                    {filteredAlarms?.length}
                  </span>
                  <span>after applying filters)</span>
                </>
              )}
            <span> Selected</span>
            <span className="alarms-subheading__count">{selectedAlarms?.size}</span>
          </span>
        </div>
        {selectedAlarms?.size > 0
          && (
          <div className="alarms-clearbytype-link">
            <TrashIcon />
            <Button
              label="Clear selected alarms"
              onClick={(() => {
                handleActiveToolbar(0);
                setShowModal(showModal === modalClearSelected ? 0 : modalClearSelected);
              })}
            />
          </div>
          )}
        <div className="alarms-clearbytype-link">
          <TrashIcon />
          <Button
            label="Clear alarms by type"
            onClick={(() => {
              handleActiveToolbar(0);
              setShowModal(showModal === modalDropdownClearByType ? 0 : modalDropdownClearByType);
            })}
          />
        </div>
        {showModal === modalDropdownClearByType && clearableAlarms.length > 0 && (
          <AlarmsClearByType
            className="dropdown-alarms-clearbytype"
            alarmTypeItems={clearableAlarms}
            handleActiveToolbar={() => null}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            setSelectedClearType={setSelectedClearType}
            setShowModal={setShowModal}
          />
        )}
      </div>
      <div className="alarms-container">
        <div className="table table--light">
          <Table
            headers={headers}
            data={filteredAlarms}
            fixColCount={fixColCount}
            selectedItems={selectedAlarms}
            setSelectedItems={setSelectedAlarms}
            skipCellMeasure
            cellOnClickColumns={['nodeid']}
            cellOnClick={(e) => history.push('/lights', { selectedNodes: [e.nodeid] })}
          />
        </div>
      </div>
      {showModal === modalPlaybook && selectedAlarm && (
        <AlarmPlaybook
          alarm={selectedAlarm}
          actions={alarmActions}
          setShowModal={setShowModal}
        />
      )}
      {showModal === modalClearAlarm && selectedAlarm && (
        <ClearAlarm
          alarm={selectedAlarm}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          setShowModal={setShowModal}
        />
      )}
      {showModal === modalClearByType && (
        <ClearAlarmType
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedClearType={selectedClearType || alarmTypeItems[0].title}
          alarms={filteredAlarms || []}
          setShowModal={setShowModal}
          updateAlerts={() => updateAlerts()}
        />
      ) }
      {showModal === modalClearSelected && (
        <ClearSelectedAlarms
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedAlarms={selectedAlarms}
          setSelectedAlarms={setSelectedAlarms}
          setShowModal={setShowModal}
          updateAlerts={() => updateAlerts()}
        />
      ) }
      {showModal === 6 && (
        <CustomizeList
          setOpenModal={setShowModal}
          headerList={headers}
          setHeaders={setHeaders}
          defaultHeaders={defaultHeaders.Alarms}
        />
      )}
    </div>
  );
}

export default AlarmsPage;
