import React from 'react';
import { DimmingPointsEditPanelProps } from '../../../types/DimmingPointsEditPanelProps';
import DimmingPointsDate from './DimmingPointsDate';
import DimmingPointsSetter from './DimmingPointsSetter';

function DimmingPointsEditPanel(props: DimmingPointsEditPanelProps): JSX.Element {
  const { draggedDimmingPoint, setDraggedDimmingPoint, scheduleData, setScheduleData, selectedSequenceIndex, isReadOnly } = props;

  return (
    <div className="editpanel">
      <DimmingPointsDate
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
        selectedSequenceIndex={selectedSequenceIndex}
        isReadOnly={isReadOnly}
      />
      <DimmingPointsSetter
        scheduleData={scheduleData}
        draggedDimmingPoint={draggedDimmingPoint}
        setDraggedDimmingPoint={setDraggedDimmingPoint}
        selectedSequenceIndex={selectedSequenceIndex}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

export default DimmingPointsEditPanel;
