/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import AssignFixture from '../../Common/Components/AssignFixture';
import LightingMap from '../../Common/Components/LightingMap';
import Loading from '../../Common/Components/Loading';
import ManualOverride from '../../Common/Components/ManualOverride';
import SplitPaneWrapper from '../../Common/Components/SplitPaneWrapper';
import Table from '../../Common/Components/Table';
import TableFooter from '../../Common/Components/TableFooter';
import TableFooterItem from '../../Common/Components/TableFooterItem';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarButton from '../../Common/Components/ToolbarButton';
import ToolbarButtonContainer from '../../Common/Components/ToolbarButtonContainer';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import ToolbarLink from '../../Common/Components/ToolbarLink';
import { ReactComponent as AddLightPoleIcon } from '../../img/icons/add-light-pole.svg';
import { ReactComponent as FilterIcon } from '../../img/icons/filter.svg';
import { ReactComponent as GroupActionsIcon } from '../../img/icons/group-actions.svg';
import { ReactComponent as ManualOverrideIcon } from '../../img/icons/manual-override.svg';
import { ReactComponent as MoreHorizontalIcon } from '../../img/icons/more-horizontal.svg';
import { ReactComponent as NotWorkingIcon } from '../../img/icons/not-working.svg';
import { ReactComponent as TotalEnergyIcon } from '../../img/icons/smart-meter.svg';
// Icons
import { ReactComponent as SuccessIcon } from '../../img/icons/success.svg';
import { ReactComponent as TrashIcon } from '../../img/icons/trash.svg';
import { AlertsNodesObjectWithAlertCounts } from '../../types/AlertObject';
import { CustomAttributeLabels } from '../../types/CustomAttributes';
import { GroupObject } from '../../types/GroupObject';
import { LightsPageProps } from '../../types/LightsPageProps';
import { NodeObject } from '../../types/NodeObject';
import { ScheduleObject } from '../../types/ScheduleObject';
import { SiteObject } from '../../types/SiteObject';
import { TableHeadersProp } from '../../types/TableHeadersProp';
import { calculateDisplayedAlerts, getAlertSeverity, getScheduledDriverLevels, groupsNodesFn, nodeExtendedPropsFn, nodesWithCustomAttributesFetcherFn } from '../../utils/ApiDataHelpers';
import { useAppContext } from '../../utils/AppContext';
import { fixedGisCustomAttributes } from '../../utils/constants';
import getHeaderProps from '../../utils/getHeaderProps';
import useLightsPageState from '../../utils/state/useLightsPageState';
import Utils from '../../utils/Utils';
import DeleteNodes from './Components/DeleteNodes';
import GroupActions from './Components/GroupActions';
import GroupsFilter from './Components/GroupsFilter';
import ListOptions from './Components/ListOptions';
import Reports from './Components/Reports';
import Sidebar from './Components/Sidebar';
import Subtoolbar from './Components/Subtoolbar';
import SubtoolbarLink from './Components/SubtoolbarLink';
import SubtoolbarLinkElement from './Components/SubtoolbarLinkElement';

function LightsPage(props: LightsPageProps): JSX.Element {
  const { selectedCustomer, selectedSite, location: { state: locationState } = {} } = props;
  const {
    selectedNodes,
    setSelectedNodes,
    activeToolbar,
    handleActiveToolbar,
    activeSubtoolbars,
    handleActiveSubtoolbars,
    activeToolbarBtn,
    setActiveToolbar,
    setActiveToolbarBtnToolbar,
  } = useLightsPageState();

  const isSensityUserAdmin: boolean = Utils.isSensityUserAdmin();
  const isNonReadOnly = Utils.isNonReadOnly();
  const defaultZoom = 14;
  const fixColCount = 4;

  // Site data

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id
    ? [`/customers/${selectedCustomer.id}/sites`, 'GetSites'] : null);

  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  // Nodes

  const { data: nodesResp, mutate: updateNodes } = useSWR<Array<NodeObject> | undefined>(
    selectedSite.id
      ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes`, 'GetNodesWithCustomAttributesResp']
      : null,
    nodesWithCustomAttributesFetcherFn,
  );

  // Custom attribute labels

  const { data: customAttributes } = useSWR<CustomAttributeLabels>(selectedSite.id ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/customattributes/labels`, 'CustomAttributeLabels'] : null);

  // Headers
  const headerVersionKey = useMemo(() => `-v3-${Utils.getApiHost()}-${Utils.getUserID()}`, []);
  const [headers, _setHeaders] = useState(getHeaderProps('LightsMain', headerVersionKey, Object.values(customAttributes?.customAttributes || {}), true));
  const setHeaders = (newHeaders: TableHeadersProp[]) => {
    localStorage.setItem(`LightsMainTableHeaders${headerVersionKey}`, JSON.stringify(newHeaders));
    _setHeaders(newHeaders);
  };

  useEffect(() => {
    if (customAttributes?.customAttributes) {
      setHeaders(getHeaderProps('LightsMain', headerVersionKey, Object.values(customAttributes?.customAttributes || {}), true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAttributes, headerVersionKey]);

  // Groups

  const { data: groupsResp, mutate: updateGroup } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups'] : null);

  const {
    groupsNodes = {},
    lightingGroups = [],
    orgGroups = [],
  } = useMemo(() => groupsNodesFn(groupsResp), [groupsResp]);

  const groupsFilterObject: Record<string, Array<string>> = {
    filteredLightingGroups: [],
    filteredOrgGroups: [],
  };

  const [groupFilterActiveTab, setGroupFilterActiveTab] = useState(0);
  const [applyGroupFilterObject, setApplyGroupFilter] = useState(groupsFilterObject);
  const [filterGroupsSubtitle, setFilterGroupsSubtitle] = useState(
    applyGroupFilterObject.filteredLightingGroups
      .concat(applyGroupFilterObject.filteredOrgGroups).length,
  );

  // Schedules

  const { data: schedulesResp } = useSWR<Array<ScheduleObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/schedules`, 'GetSchedules'] : null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currDate = useMemo(() => new Date(), [schedulesResp]);
  const sunTimes = useMemo(() => Utils.getSunriseSunset(currDate, Utils.getSiteLatLng(site), site?.time_zone || 'America/Los_Angeles'), [currDate, site]);
  const scheduledDriverLevels = useMemo(() => getScheduledDriverLevels(currDate, sunTimes, schedulesResp), [currDate, schedulesResp, sunTimes]);

  // Alerts

  const { data: alertsResp } = useSWR<AlertsNodesObjectWithAlertCounts>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/nodes`, 'GetAlertsNodes'] : null);

  const displayedAlerts = useMemo(() => (alertsResp ? calculateDisplayedAlerts(alertsResp) : []), [alertsResp]);

  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [nodesList, setNodesList] = useState<Map<string, NodeObject>>(new Map());
  const [pinnedNode, setPinnedNode] = useState<NodeObject | undefined>();
  const [zoomedNode, setZoomedNode] = useState<NodeObject | undefined>();
  const [filteredAndSelectedNodes, _setFilteredAndSelectedNodes] = useState<Map<string, NodeObject>>(new Map());
  const setFilteredAndSelectedNodes = () => {
    const tmpMap = new Map();

    filteredNodes.current?.forEach((node) => {
      if (selectedNodes.has(node.nodeid)) {
        tmpMap.set(node.nodeid, node);
      }
    });

    _setFilteredAndSelectedNodes(tmpMap);
  };

  useEffect(() => {
    setFilteredAndSelectedNodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes]);

  useEffect(() => {
    if (nodesResp?.length && Array.isArray(groupsResp)) {
      setNodesList(nodeExtendedPropsFn(nodesResp, groupsNodes, site?.time_zone, alertsResp, setPinnedNode, setZoomedNode));
    }
  }, [nodesResp, groupsResp, groupsNodes, site?.time_zone, alertsResp]);

  useEffect(() => {
    if (mapLoaded && nodesList.size > 0 && locationState?.selectedNodes && locationState?.selectedNodes.length > 0) {
      const tmpMap = new Map();

      locationState.selectedNodes.forEach((nodeId) => {
        if (nodesList.has(nodeId)) {
          tmpMap.set(nodeId, nodesList.get(nodeId));
        }
      });

      locationState.selectedNodes = [];
      setSelectedNodes(tmpMap);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesList, mapLoaded, locationState]);

  // Filter nodes

  const filteredNodes: React.MutableRefObject<NodeObject[] | undefined> = useRef();
  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  useEffect(() => {
    if (Array.isArray(nodesResp) && nodesList.size === 0) {
      filteredNodes.current = [];
      forceUpdate();
    } else if (nodesList?.size && Array.isArray(groupsResp)) {
      filteredNodes.current = Array.from(nodesList.values())
        .reduce((filtered: Array<NodeObject>, node: NodeObject) => {
          const nodeStatusOk = node.mapStatus === 'Clear';

          if (activeToolbar === 1 && activeSubtoolbars.length !== 0) {
            const level = parseInt(node.level, 10);

            const level1 = 1;
            const level25 = 25;
            const level50 = 50;
            const level75 = 75;
            if (!((activeSubtoolbars.includes('level_off') && level === 0)
            || (activeSubtoolbars.includes('level_1-25') && level >= level1 && level <= level25)
            || (activeSubtoolbars.includes('level_25-50') && level >= level25 && level <= level50)
            || (activeSubtoolbars.includes('level_50-75') && level >= level50 && level <= level75)
            || (activeSubtoolbars.includes('level_75-100') && level >= level75))
            ) {
              return filtered;
            }
          } if (activeToolbar === 2 && !nodeStatusOk) {
            return filtered;
          } if (activeToolbar === 3) {
            if (activeSubtoolbars.length === 0 && nodeStatusOk) {
              return filtered;
            }

            if (activeSubtoolbars.length !== 0) {
              let found = false;

              activeSubtoolbars.forEach((subToolbar) => {
                if (displayedAlerts.find((a) => subToolbar === a.alertType)?.nodeIDs?.map((nodeAlert) => nodeAlert.id).includes(node.nodeid)) {
                  found = true;
                }
              });

              if (!found) {
                return filtered;
              }
            }
          }

          if (groupFilterActiveTab === 0
            && applyGroupFilterObject.filteredLightingGroups.length
            && !applyGroupFilterObject.filteredLightingGroups.includes(node.lightinggroup)
          ) {
            return filtered;
          }

          if (groupFilterActiveTab === 1
            && applyGroupFilterObject.filteredOrgGroups.length
          ) {
            if (!groupsNodes[node.nodeid]?.orggroups.some(
              (orgGroup) => applyGroupFilterObject.filteredOrgGroups.includes(orgGroup.name),
            )) {
              return filtered;
            }
          }

          node.schedDriverLevel = scheduledDriverLevels.get(node.scheduleid) || 0;

          filtered.push(node);
          return filtered;
        }, [] as Array<NodeObject>);

      const filteredGroupsLength = applyGroupFilterObject.filteredLightingGroups
        .concat(applyGroupFilterObject.filteredOrgGroups).length;
      setFilterGroupsSubtitle(filteredGroupsLength);
      setFilteredAndSelectedNodes();

      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSubtoolbars,
    activeToolbar,
    applyGroupFilterObject,
    alertsResp,
    groupsResp,
    nodesResp,
    nodesList,
  ]);

  const { addNotification } = useAppContext();
  const shouldOpenDeleteNodesModal = () => {
    if (filteredAndSelectedNodes.size === 0) {
      setActiveToolbarBtnToolbar(0);
      addNotification({ type: 'error', message: 'No nodes were selected for the operation.' });
    }
  };

  const headerStatusIndex = headers.findIndex((header) => header.key === 'status');
  const headersForDownload = [...headers];
  headersForDownload.splice(headerStatusIndex, 1, { key: 'mapStatus', val: 'Status' });

  const [gpsPinPosition, setGpsPinPosition] = useState<{ lat: string, lng: string }>();
  const storageKey = 'LightsPageSplitPos';
  const [splitPaneSize, setSplitPaneSize] = useState<number>(parseInt(localStorage.getItem(storageKey) || '470', 10));

  const toolbarTotalNodes = 1;
  const toolbarEverythingOK = 2;
  const toolbarWatchList = 3;
  const toolbarFilterGroups = 4;
  const toolbarBtnManualOverride = 2;
  const toolbarBtnAssignFixture = 3;
  const toolbarBtnGroupActions = 4;
  const toolbarBtnEnergyReport = 5;
  const toolbarBtnDeleteNodes = 6;
  const toolbarBtnListOptions = 7;

  useEffect(() => {
    if (activeToolbar !== 0) {
      setActiveToolbarBtnToolbar(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeToolbar]);

  useEffect(() => {
    if (activeToolbarBtn !== 0) {
      setActiveToolbar(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeToolbarBtn]);

  return (
    <>
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Lights" subtitle={selectedSite.name} />
            <ToolbarLink
              title={`${(nodesResp ? nodesResp.length : '-')}`}
              subtitle="Total nodes"
              onclick={() => undefined}
              order={toolbarTotalNodes}
              tabPanelisActive={activeToolbar}
            />
            <ToolbarLink
              icon={<SuccessIcon className="ok" />}
              title={`${(alertsResp ? alertsResp.okNodeCount : '-')}`}
              subtitle="Everything OK"
              onclick={() => handleActiveToolbar(toolbarEverythingOK)}
              tabPanelisActive={activeToolbar}
              order={toolbarEverythingOK}
            />
            <ToolbarLink
              icon={<NotWorkingIcon className="attention" />}
              title={`${(alertsResp ? alertsResp.alertNodeCount : '-')}`}
              subtitle="Watch list"
              onclick={() => handleActiveToolbar(toolbarWatchList)}
              tabPanelisActive={activeToolbar}
              order={toolbarWatchList}
              addClass="last"
            />
          </div>
          <ToolbarLink
            icon={<FilterIcon />}
            title="Filter groups"
            subtitleStyle="light"
            subtitle="All groups"
            subtitleNum={filterGroupsSubtitle}
            subtitleText="group"
            onclick={() => handleActiveToolbar(toolbarFilterGroups)}
            tabPanelisActive={activeToolbar}
            order={toolbarFilterGroups}
            addClass="filter-groups"
          >
            <GroupsFilter
              className="dropdown-groups-filter"
              lightingGroups={lightingGroups}
              orgGroups={orgGroups}
              setApplyGroupFilter={setApplyGroupFilter}
              filteredLightingGroups={applyGroupFilterObject.filteredLightingGroups}
              filteredOrgGroups={applyGroupFilterObject.filteredOrgGroups}
              handleActiveToolbar={handleActiveToolbar}
              setGroupFilterActiveTab={setGroupFilterActiveTab}
              groupFilterActiveTab={groupFilterActiveTab}
            />
          </ToolbarLink>
        </div>
        <ToolbarButtonContainer>
          {isNonReadOnly ? (
            <>
              <ToolbarButton
                toggleToolbarBtn={setActiveToolbarBtnToolbar}
                order={toolbarBtnManualOverride}
                activeToolbarBtn={activeToolbarBtn}
                icon={<ManualOverrideIcon />}
                tooltipText="Manual override"
                noborder
              >
                <ManualOverride
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectedItems={filteredAndSelectedNodes}
                  listLength={filteredAndSelectedNodes.size}
                  activeToolbarBtn={activeToolbarBtn}
                  closeManualOverwrite={() => setActiveToolbarBtnToolbar(0)}
                  updateNodes={updateNodes}
                  type="nodes"
                />
              </ToolbarButton>
              <ToolbarButton
                toggleToolbarBtn={setActiveToolbarBtnToolbar}
                order={toolbarBtnAssignFixture}
                activeToolbarBtn={activeToolbarBtn}
                icon={<AddLightPoleIcon />}
                tooltipText="Assign fixture"
              >
                <AssignFixture
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  selectedItems={filteredAndSelectedNodes}
                  closeAssignFixture={() => setActiveToolbarBtnToolbar(0)}
                  type="nodes"
                  selectedLength={filteredAndSelectedNodes.size}
                  updateNodes={updateNodes}
                />
              </ToolbarButton>
              <ToolbarButton
                toggleToolbarBtn={setActiveToolbarBtnToolbar}
                order={toolbarBtnGroupActions}
                activeToolbarBtn={activeToolbarBtn}
                icon={<GroupActionsIcon />}
                tooltipText="Group actions"
              >
                <GroupActions
                  lightingGroups={lightingGroups}
                  orgGroups={orgGroups}
                  selectedNodes={filteredAndSelectedNodes}
                  schedulesResp={schedulesResp}
                  closeDropdown={() => setActiveToolbarBtnToolbar(0)}
                  selectedCustomer={selectedCustomer}
                  selectedSite={selectedSite}
                  updateGroup={() => {
                    updateGroup();
                    updateNodes();
                  }}
                />
              </ToolbarButton>
            </>
          ) : <></>}
          <ToolbarButton
            toggleToolbarBtn={setActiveToolbarBtnToolbar}
            order={toolbarBtnEnergyReport}
            activeToolbarBtn={activeToolbarBtn}
            icon={<TotalEnergyIcon />}
            tooltipText="Energy use report"
          >
            <Reports
              type="lights-page"
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              selectedNodes={Array.from(filteredAndSelectedNodes.values())}
              closeReports={() => setActiveToolbarBtnToolbar(0)}
            />
          </ToolbarButton>
          {isSensityUserAdmin ? (
            <ToolbarButton
              toggleToolbarBtn={setActiveToolbarBtnToolbar}
              order={toolbarBtnDeleteNodes}
              activeToolbarBtn={activeToolbarBtn}
              icon={<TrashIcon />}
              tooltipText="Delete nodes"
              shouldOpenModal={shouldOpenDeleteNodesModal}
            >
              <DeleteNodes
                selectedCustomer={selectedCustomer}
                selectedSite={selectedSite}
                selectedNodes={filteredAndSelectedNodes}
                activeToolbarBtn={activeToolbarBtn}
                closeDeleteNodes={() => setActiveToolbarBtnToolbar(0)}
                updateNodes={updateNodes}
              />
            </ToolbarButton>
          ) : <></>}
          <ToolbarButton
            toggleToolbarBtn={setActiveToolbarBtnToolbar}
            order={toolbarBtnListOptions}
            last
            activeToolbarBtn={activeToolbarBtn}
            icon={<MoreHorizontalIcon />}
            tooltipText="List options"
          >
            <div className="toolbar-tabs__links-moreinfo">
              <ListOptions
                customAttributes={[...fixedGisCustomAttributes, ...Object.values(customAttributes?.customAttributes || {})]}
                headersForDownload={headersForDownload}
                headers={headers}
                setHeaders={setHeaders}
                closeListOptions={() => setActiveToolbarBtnToolbar(0)}
                nodesList={nodesList}
                setSelectedNodes={setSelectedNodes}
                filteredNodes={filteredNodes.current}
                selectedNodes={selectedNodes}
                filteredAndSelectedNodes={filteredAndSelectedNodes}
              />
            </div>
          </ToolbarButton>
        </ToolbarButtonContainer>
      </Toolbar>
      <Subtoolbar type="attention" activeToolbar={activeToolbar} order={toolbarWatchList}>
        {displayedAlerts
          .sort((a, b) => ((getAlertSeverity(b.alertType).index) - (getAlertSeverity(a.alertType).index)))
          .map((alert) => (
            <SubtoolbarLink
              title={alert.name}
              changeActiveSubtoolbar={() => handleActiveSubtoolbars(alert.alertType)}
              key={alert.alertType}
              id={alert.alertType}
              activeSubtoolbars={activeSubtoolbars}
            >
              <SubtoolbarLinkElement
                subtitle={`${alert.nodeIDs?.length}`}
                icon={getAlertSeverity(alert.alertType).icon}
              />
            </SubtoolbarLink>
          ))}
      </Subtoolbar>
      <div className="content">
        <Sidebar
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedItems={filteredAndSelectedNodes}
          groups={groupsResp}
          groupsNodes={groupsNodes}
          lightingGroups={lightingGroups.filter((group) => group.type !== 'site-lighting')}
          orgGroups={orgGroups}
          updateGroup={updateGroup}
          updateNodes={updateNodes}
          gpsPinPosition={gpsPinPosition}
          setGpsPinPosition={setGpsPinPosition}
          customAttributes={customAttributes}
        />
        <div className="inner-content">
          <SplitPaneWrapper storageKey={storageKey} type="lights" setSplitPaneSize={setSplitPaneSize}>
            <>
              {site ? (
                <LightingMap
                  id="LightsPageMap"
                  zoom={defaultZoom}
                  center={Utils.getSiteLatLng(site)}
                  data={filteredNodes.current}
                  selectedItems={selectedNodes}
                  setSelectedItems={setSelectedNodes}
                  setMapLoaded={setMapLoaded}
                  featuresEnabled={{ selectionToolControl: true, layersSelectionControl: true, alertsColorLabel: true }}
                  gpsPinPosition={gpsPinPosition}
                  setGpsPinPosition={setGpsPinPosition}
                  mapSize={splitPaneSize}
                  pinnedNode={pinnedNode}
                  zoomedNode={zoomedNode}
                />
              ) : (<Loading />)}
            </>
            <div className="table">
              <Table
                headers={headers.filter((header) => !header.isHidden)}
                data={filteredNodes.current}
                selectedItems={selectedNodes}
                setSelectedItems={setSelectedNodes}
                fixColCount={fixColCount}
                multiSelect
                skipCellMeasure
                dark
              />
            </div>
          </SplitPaneWrapper>
          <TableFooter>
            <>
              <TableFooterItem
                selectedValuesLength={filteredNodes.current?.length || 0}
                boldText="Nodes"
                text="filtered"
                hasBorder
              />
              <TableFooterItem
                selectedValuesLength={filteredAndSelectedNodes.size}
                boldText="Nodes"
                text="selected"
              />
            </>
          </TableFooter>
        </div>
      </div>
    </>
  );
}

export default LightsPage;
