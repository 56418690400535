import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import ModalFullPage from '../../../Common/Components/ModalFullPage';
import { ScheduleModalProps } from '../../../types/ScheduleModalProps';
import { ScheduleObject } from '../../../types/ScheduleObject';
import { useAppContext } from '../../../utils/AppContext';
import ScheduleToolbar from './ScheduleToolbar';
import DimmingSequences from './DimmingSequences';
import DimmingPoints from './DimmingPoints';
import { deleteRequest, postRequest } from '../../../utils/fetch';
import Utils from '../../../utils/Utils';

function ScheduleModal(props: ScheduleModalProps): JSX.Element {
  const { selectedCustomer, selectedSite, schedules, activeSchedule, openScheduleModal, setOpenScheduleModal, updateSchedules } = props;

  const { addNotification } = useAppContext();

  const [scheduleData, setScheduleData] = useState<ScheduleObject>(activeSchedule);
  const [selectedSequenceIndex, setSelectedSequenceIndex] = useState<number>(0);
  const [openToolbarDropdown, setOpenToolbarDropdown] = useState<Record<string, boolean>>({ open: false, nonUnique: false });
  const scheduleNames = useMemo(
    () => schedules?.filter((schedule) => schedule.scheduleid !== scheduleData.scheduleid).map((schedule) => schedule.name),
    [schedules, scheduleData],
  );

  const modalScheduleCreate = 1;
  const modalScheduleEdit = 2;
  const modalScheduleView = 3;
  const daysOfWeek = 7;
  const maxSpecialDayEvents = 100;
  const isSensityUserAdmin = Utils.isSensityUserAdmin();

  const isReadOnly = openScheduleModal === modalScheduleView || (!isSensityUserAdmin && (scheduleData.name === 'Default schedule'));

  const validateSchedule = (): boolean => {
    let valid = true;

    setOpenToolbarDropdown({ open: false });

    if (scheduleData.name === '') {
      setOpenToolbarDropdown({ open: true, nonUnique: false });
      return false;
    }

    if (scheduleNames?.includes(scheduleData.name)) {
      setOpenToolbarDropdown({ open: true, nonUnique: true });
      return false;
    }

    const selectedDays: Set<string> = new Set();

    scheduleData.events.forEach((event, index) => {
      if (valid && !event.date && !event.days) {
        addNotification({ type: 'error', message: `Sequence ${index + 1} has no day or date specified.` });
        valid = false;
      }

      if (valid && event.actions.length === 0) {
        addNotification({ type: 'error', message: `Sequence ${index + 1} has no dimming points.` });
        valid = false;
      }

      if (event.days) {
        event.days.forEach((day) => selectedDays.add(day));
      }
    });

    if (valid && selectedDays.size !== daysOfWeek) {
      addNotification({ type: 'error', message: 'Schedule must contain a valid dimming sequence for each day of the week.' });
      return false;
    }

    if (scheduleData.events.filter((e) => e.date).length > maxSpecialDayEvents) {
      addNotification({ type: 'error', message: `Schedule cannot contain more than ${maxSpecialDayEvents} special day events.` });
      valid = false;
    }

    return valid;
  };

  const saveSchedule = async () => {
    if (!isReadOnly && validateSchedule()) {
      let url = `/customers/${selectedCustomer?.id}/sites/${selectedSite?.id}/schedules`;

      if (openScheduleModal === modalScheduleEdit) {
        // edit schedule
        url += `/${scheduleData.scheduleid}`;
      }

      scheduleData.events = scheduleData.events.map((event) => {
        const newEvent = { ...event };

        if (newEvent.date && newEvent.date instanceof Date) {
          newEvent.date = DateTime.fromJSDate(newEvent.date).toFormat('yyyy-LL-dd');
        }

        newEvent.actions = newEvent.actions.map((action) => {
          const newAction = { ...action };

          if (newAction.time === '24:00:00') {
            newAction.time = '00:00:00';
          }

          return newAction;
        });

        return newEvent;
      });

      const operation = openScheduleModal === modalScheduleCreate ? 'Create' : 'Edit';

      try {
        const result = await postRequest(url, scheduleData);

        if (!result.error) {
          addNotification({ type: 'info', message: `Your "${operation} schedule" operation is completed.` });
          setOpenScheduleModal(0);

          if (updateSchedules) {
            updateSchedules();
          }
        } else {
          addNotification({ type: 'error', message: `Your "${operation} schedule" operation is failed: ${result.error}` });
        }
      } catch (e) {
        addNotification({ type: 'error', message: `Your "${operation} schedule" operation is failed.` });
      }
    }
  };

  const deleteSchedule = async () => {
    try {
      const result = await deleteRequest(`/customers/${selectedCustomer?.id}/sites/${selectedSite?.id}/schedules/${scheduleData.scheduleid}`);

      if (!result.error) {
        addNotification({ type: 'info', message: 'Your "Delete schedule" operation is completed.' });
        setOpenScheduleModal(0);

        if (updateSchedules) {
          updateSchedules();
        }
      } else {
        addNotification({ type: 'error', message: `Your "Delete schedule" operation is failed: ${result.error}` });
      }
    } catch (e) {
      addNotification({ type: 'error', message: 'Your "Delete schedule" operation is failed.' });
    }
  };

  return (
    <ModalFullPage>
      <ScheduleToolbar
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
        openScheduleModal={openScheduleModal}
        setOpenScheduleModal={setOpenScheduleModal}
        saveSchedule={saveSchedule}
        deleteSchedule={deleteSchedule}
        openToolbarDropdown={openToolbarDropdown}
        isReadOnly={isReadOnly}
        scheduleNames={scheduleNames}
      />
      <DimmingSequences
        dimmingSequencesTitle="Dimming sequences"
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
        selectedSequenceIndex={selectedSequenceIndex}
        setSelectedSequenceIndex={setSelectedSequenceIndex}
        isReadOnly={isReadOnly}
      />
      <DimmingPoints
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
        selectedSequenceIndex={selectedSequenceIndex}
        isReadOnly={isReadOnly}
      />
    </ModalFullPage>
  );
}

export default ScheduleModal;
