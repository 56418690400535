import { DimmingPointDay } from '../types/DimmingPointsDateProps';

export const MARKER_PIN = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAYAAAAl+Z4RAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAkhJREFUOI2lk09IFGEYxn/vN7vWQlFeOkRQFF285snYaFHTIIiOHYKgRIK6R3SRhAjr4HWDpJsQWER2WNzcP1YXJcgkgiSFTIgOEmhuzjdvh123aXa+Vek9zTzv8/zmmeEbITI6kW5D6UPoBg4DBviCkCOQh9Jd+hj2Sz04fSLJSmoI5DrgRcG18UGH8RI3JVPw6wCdPJ3ABs9BzzqC0XmB512QTME3Va69u4MwwDlsMAggmjt1HKNzQDLG+B3YBeyL2f1GbJvB6FVHGJRxRMcdLVpQ74oBepxFhZfAM+ceehLAkZDwCxgHUSRQKhs5UqkAG2RrlVqAi7XXAjiaAHaHAClgD565JJnSj5Der7mTBzHmSSgMkDLAQqRWL9a+04l026ag+Y5jGDMNdES8iwaYjXm3Q1gvAFBFqHg/gQMxvvcGpNAgK59pYUEn0iPk00/Zqz7CVGNeCgaSo0DlX11msUEZuAycpyIzKF8j6QokR6tHOZ/OovTFVGwykpWuUn/1KOMPAGs7SK8R2AGo/qpI59slYHDbcZU7cub1tzoAgP1r9xH9sI34HK2rDzZv6gBpn9kg4BqgzZ4N5oa0z2w0AACke2oKZKQJ4LF0FSfDgmmw+HobWI8Jr+NzKyo2AKS3vIwQ00IeSW95eUtAdew9wA/3wjNDcc5YgHS+WQTydUF5JZnCwrYBtdTYX6KMuVxugNFi/dqaossmroUqQj69Aiid5VaR+PPhbFALzAPzrjBAwrWozRJgmxm2Aqwi+l+AT6g4vxPAH2dWvKbwzJ/NAAAAAElFTkSuQmCC';
export const MARKER_GREEN = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQVJREFUKJGVkD1PwmAURs9t6eLCTtiZqm4SZ1Rc6kcC8T8Q/o0O/AKjxAguReJs6GiZnHVzEQaNwfI4ALVgQuKzvfc9J7nPhX/Gso+Kavkck6awndmnIsNrhdZ++yMc6rQ8RddAARSDGeAD72Ia9K37mAoV1fIu30Pgw3DqPbuJAaoKSsLtgDYSvM0Ha48cgByTJlDIwgA9u3sGzsCKLkkDwAGY7aw4Cy9yb7dPwNCgnArzOrYKZyOkVDAUAX5VQWkV3NPxNuAbGmQErwWMhNs50MlWFnawK+DF+LpYOuu+jnYNuwQrAsP52Ae9CtX71o2WhN/zJo1FQcHA4fM8tHC8rt/a/ABykFwlDPBWSgAAAABJRU5ErkJggg==';
export const MARKER_GREEN_SELECTED = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAN1JREFUKJGdkDFOQkEURc/9/1NTGt0CC5AF0CidkVUQdoMFa/ixsPqJsTawAXZgQkIFjTEwcy1m+BIUE73de++eN/cN/FE6LgYedSt2E6PrNPRCdGaN6vU34MZ3/YgfgUsgCuQ035g4fNbTawsMPOqW7JfABVCepAiC1Z6q96J6UwBU7CZ586kZoIz4qiSMAQqAnDmeP1RR0G+BnE3ngLTUbgHhhX8BjAvh+RHQmQFbIPzgD0Jv4uOhBRrVaxNvBav8dACCsVMv3jdqtjn6l9L3hvHhQMO84H16MP9Ln7foUipMXMQUAAAAAElFTkSuQmCC';
export const MARKER_ORANGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAPhJREFUKJGVkD1Ow1AQhL99zyJQ5hCR3fNTRhYHoUtNQUjlyE4KhLAlEK1vwRXo+OkTbmLZ7y2FjZU/CZhutDOzswv/hGySOo9iEU0Fzrvhmxeegpv1y57BFWECLABzIHdpp6uUn2GdR3EnrlFmxlZDY6shyjVQgSZajMYAQbtGM8CgzO3tOt+IfnZ5eIJwr5gMuDQAIpwCmKAqd8sYG5QACmd9pZb/Cu0NCp8Avhlc7aq8ayZtbT76GxAeUWKEB1eEx6Y+Khl48a6ZICwBL16zztjCFdECNGH/rR5kbqeruy0DgBajsWJShQtABd7Fayazr9c/3HgY392aUVv4F5KrAAAAAElFTkSuQmCC';
export const MARKER_ORANGE_SELECTED = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAALJJREFUKJGdkMsOwVAQhr//8Cqie5el8EzEirRs24Rnsbdh57J3eRRxxqJVDU2K2U3O951/ZuDHUrG5xUFfslDQyR53Xizrw/PqQ7gnzQkwA1zJv/Pa6BTmwi0O+k62NnB6Qy2FvMMPNLpuXGpZRAlcGMEZLuIZL9GqWtagnQtZ8hdOJhgcq2jB4ZUgFhUxXt6iXEjvrLnAl8GgqcaXbZZUGDJp9AwXGnQBE+zlLXrCf9UDXTk3NtDiVZIAAAAASUVORK5CYII=';
export const MARKER_CYAN = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAOpJREFUKJGVkTlSw0AQRV9LlGWHPgtLpJnSScgcEwGJKC8BAQnkvgUHoABlLOcZyWB/Ao9dQqIo6Khn+r/f0z3wz7D2IQ8qzJgCxwCClwTunod23wN8o1JiDiQ9W7GoRjZlV8yDiij+AC7SjHGaMRacAQ1G6YP83sDVenS15Gqdd83zRpeulvJaD/sOwCFAmrHsAuvB9s7gqA3o19W0NDvgHeCz4bSrSldMYvoGcBDb3QoKgxvXaKgByzVYumJiYgFsEjGL2jh40ByjpL/WDcZVldn1NwDAB3ltP+4kvvk1EbOnkVV/mPHn+AJB1VItIXBK3wAAAABJRU5ErkJggg==';
export const MARKER_CYAN_SELECTED = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKdJREFUKJGdkTkOwkAQBKs3st/DESEL/gWy8QfgLc4JWGcc70EkOwQ+kY0wdLarru4ZDfwo9R+rh60lUmABYHBxcPSRigGQPG1rxh5wg1gjL2OlLVAnnzDce2dVgwgyNj6WdwASGYyYuxlcqDxt/WzCsvM+YN+AxtMA9wnArQUEh4891V9w1tvBRyowckQYGT4gdudYZR3eKXlYYtXhlnX21RlZY/5LL06kMyymhxEPAAAAAElFTkSuQmCC';
export const MARKER_YELLOW = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAPNJREFUKJGlkEFKw2AQhb8paiqoEM8wceMZLDmBR3DXtSt1E7HtwoUbD+BZWj2A3iALLyCIFfL/NfS5MI1KEAoODMwwbx7vPfhPqfJcwe8V/L3pmYIf/8RYC45ZgTQGel0mJrZdXrE6qvK8AX+AnZPUKUmdgp0CEaNQlQ2+CYI/KLgUsrMOefSLr5vPWkkKPgd2SOrU7Pn114MO94nxBZhbv9xb6dU6mbQegCcA4uZJB7aIw2Z6BNhollsgB90oZn224h3sGos4REyAJeqNWg9NUmOMgm6sS4xLS8rrrsgqGyj4VMHnCv6m4FNVB0dr+Pu7PgHPTG2lH2NjWgAAAABJRU5ErkJggg==';
export const MARKER_YELLOW_SELECTED = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAALBJREFUKJGlj8EJwkAQRf/syfQxsQG9KrEBK1KMacBevEdBr9qApgoVdr3s92DYLMTAggNzGf77/w/wz9BqQadHOn21e6DTZayRIH7nK5BbAKbvhEqyZhMAWi0gqEGYziKIAYEHZSHZ/WTanBL4Ie46GAhLRPGThBenMcAEgDFwTQAuMbAbzPnePGjKAMio2YOoIPA9QOAhWEt2O/fNbD6n05pOn3T6oNOadjxLqDs8H92wRGy2kFxyAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_FIXED_100 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAM1JREFUKJGd0TFKA2EQxfHfrI2dpcQreIDkDIqdniLkNlrkDGJhlRtIcgFvICykSpos6O5Y7G5YEiPowBTf4/0/5s3wx4rhI/Phgt2MGHfKinoesVgfAZl3E7xghEZGiAxsKG4jXt/2QPtz9Y5L6exghhol59cRz5uiVXczjI7MaLW8oppCB8QYzS9RG0wGADLilL1POQBy1QU8ZS4olgOgnhPbLuDBNFFTfPD5tAfaPccNSpEp1G1nkiVxH7HY8uPhqmkfEEu+Hnvzv+oblylGw7agypEAAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_FIXED_90 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAO5JREFUKJGdkDFOw1AQRN98IzmpLJECmZSUHCA5gCtouUWU20CRQ1BbQtQouQCdO5AsRbKUVKawhyKxIYEUsN3uzlvNLPyx9L2piiyp4zC32gmAHFaKokWa5usfQPmWTS09GlKJ1kb7/cbodjx+eumBqsiSj4FeERc20YEF0diUg9rX51fPmwBQx2FuSI/FALuZL+uhZgABwGonEu3JoFIrM+2B/SWdAjpJD8hhdfyxQ1sOlpZfQBQtMFuJ5hc7DYT3OI4eeiBN87WlG5sSbIlmB9u2y9ByNxrlW45tVEWW1EPNuoAWyzg+u+/E/6pPykleQxvy6o8AAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_FIXED_80 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAOZJREFUKJGdj7FKw2AUhb/zF1wzSn0Ft1DaQNdMuvoWpW+jQx/CN3CyIA2kq28gBDq1W6L5j0M0EW0FPXCHezkffBf+GH1dyjJPoFkSPQMgqAjBqzRd734A2+08w+EePLYVBUKWrX20rqfTx6ceKMs8EfWzrXPw6JtFK6kyZ5eTycM+dLdmCR4fKQOMbF/I9QKgA6JntuIvr8aIsgHo3HSqbiBgD0BQgXwSEA42mx4IwSs7HID2SL8FvbzF5q4H0nS9i+ZKUtUZqO0GS6pQvMmy4vChPqQs80SuF8OD3ry2ze1n+V95B78MZKv9uHQrAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_FIXED_70 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAPBJREFUKJGdkTFuwkAQRd/fJZKpKCNyhZQU5gJpNilSRLkE4jZJwRWQaGjpEVYU0eUGkSxRQWUkeyeFwUSAi+RXMzv/SfN34I/S72YxHfSKrhtjSuupZd6XkxDWmwtgPk+HghnEvnAxmkkygbaK9vj0/LFsgMV00CuSzhdwC+bPlqiAPCnK+4fXz60DKLpuDLF/aQYwL9ndPvEjAFe/KRUutgU1IxoMTwAQzdQGHD7AToAsqwO2eIUjulUDeF9OMO0OAc9VWeTb3/DeACGsNzILQC5hQAVUda3cmXsJIdvBlcPtEz86BhSsXEdvR/O/9ANPdVk1vD4KLQAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_FIXED_60 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAARxJREFUKJGVkD1KA1EUhc95781kUoVMI8kWLINMsLeZWAQENxGyGy2yhYAgwS69ZBCxcweKIBqSapJ5c6+FTn4IIp7ucr+vOAf4Z7h7TMedRl43QyiT769m1vpRmj69HwiTSdIlcANIy3uIiDIMSYALivbO+w/3G2E67jTyyD2XpR59fK6tLwQA4BwRx7XSOb5FuT8+u3xcGADI62YISGsXBgDvFfP52pLaXkV2AAAGAKBMvIfswlWKQlAUKgp0twIAEeUBXUUBUHUrULMwJJ07dIKACEIaiJltBGv9CIplHNfKIDB7cLMZlip4sQGu92a9uz05VWPGpLaLQuVHMKp8NcKLXn+W7QnVvKvIDqqCBGbG8SpNs+Wv/f7KFxjVdiqXTNuZAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_FIXED_50 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASBJREFUKJGVkLFKw2AUhc+9v5Lkt9BR2rG0k2ORFneX1KEg+BKlb6NDXyEgSHHrLgkibm51UwSnFpo/geZeh5K2oVDwbJf7fcM5wD9D+8cs6tazgMdQ6m2+mhiznoTh+++BMJ32+gR5BNBwTkVVyVomVSxYMbgZvr5shVnUrWc+f+S5ns/nK+NcAQDwfEanXSs8j3/8rLi4vntbMABkAY8BNPZhAMgzwfxzZYjQzH0zAgAGACj1nFPZh8u4tECaiijQ3wkAVJUO6Mo8qjuBNLGWyfP5gLPWwFpmCMdbwZj1RATLTrtWBNZU4FbrrFDRL3OKh8qsz0+XV8oUEaGZpiIbgVkV3yx8OxjGSUUo5819MyoLEhDzCd2HYbI82u9Y/gCsp3cwvJgEuwAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_FIXED_40 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASZJREFUKJGVkM9KAlEUxr9z7kTqwFxyE/oK7ZIcad9mbCEEvYT4NrXwFYQgpJ37cFBp1xsUQYvyiqg095wW4Z9BEPp2h/P7Lb4P+Gdo9xj0anZZ5A6U4r+vpsZk3SR5+dwT+v24AZUHIlRmMy8iStYGpIopK5rXrdHzRhj0anZxzK+LhZyOxt/GuR8AQBgaxPWyL5XMR3Hlz65uJ1MGgGWRO0So7MIAMJ97jCdfhhnVVcG0AYABAErxbOZlF17HuQzOZaJAYysAEFHao3PzqG4F0tTagMLQ7HHWBoiigCE83AjGZF0RuLhe9lEU5ODa+Yn3Xt/MEe5zsz49XlwKUY8ZVecyAYAoClgE70b5ptkapjlhPe+qYNrrggQMOaC7JEndwX6H8gspxXgwEvaNiwAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_FIXED_30 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASxJREFUKJGVkE1Kw1AUhc99L6nNpIGkUtotOCzS4lSlJXVQENxE6W500C0EBCmOjE6lQcSZO1CE0AdpB/1L3nUgaRsCBc/scr9vcA7wz9D+EfhNe2mJIZhaf18OpUxGnvcRFYTxuNUG63si1JVKNDOT65rEjFgwelf9t9etEPhNe3EkPufztPYURFKpNQDAtg10O7W0UjF+rFV6cnnzHgsAWFpiSIT6PgwAcZzg+SWSRGisynIAAAIAwNRSKtH7cJbpdA2lNpqB9k4AwMxUoHPzMO8E4tB1TbJto8BVqyU4jimgxWQrSJmMtMas26mlrlvKwRfnx6nW/CVN3OVmfXw4PdNEPhEaSm00ADiOKZjxLVlc9/qTMCdk867KcpAVJGAiDLr1vHB2sN+h/ALSr3g4FEz5VwAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_FIXED_20 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAStJREFUKJGVkLFqwlAUhv9zb406iQSSaH2DjlKU7l1iB0HqoI8gvk07+AhNKRTp5l4SSunWN0jRW0R0qVFzT4eiJgSE/tvhfN/w/8A/Q8lj4tVL66IYgqnx9+VAyt3IdT++M8J43GiC9ROAilJbzcxk2wYBWApG66b99noQJl699JMXn4vFzn7wQjmbRQAA0zTQ79Xicjk3LUbxxXX3fSkAYF0UQwCVJAwA8/kG3mMoiVCNCnIAAAIAwNRQaquT8D7TaQSlNpqB5lEAwMyUoVPzMB8F4sC2DTJNI8M5Th6WZQho4R8EKXcjZqz6vVrsOPkU3L09j7XmUOZwn5r15fnyShN5RKgqtdEAYFmGYMaXZNFptf0gJeznjQpysC9IgC/O6M51g9XJfqfyC9xTdjcj50pOAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_FIXED_10 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAATBJREFUKJGVkE1LAmEUhc+9rzljGzfC4IxCrltKKO3bjC2EoJ9Qi9F/U5T9BCEIaec+HCLatRf0JQkXushxct7bIvxCIjq7y3mexbnAP0ObR7ddzkYZbkKo8tNKqNSi5fuvHztCp1OpijH3RMhrHRsRIc+zSAQTBdRO689PK6HbLmc/0/w2Hn85N7d9NRzOAACOY6ERlJJcLv2+HyeHJ+cvEwaAKMNNIuQ3YQAYjeZo3fUVM9y5rQIAYACAUEXr2GzCywwGM2gdGQGqawGAiNAOveoAkMhaIAk9zyLHsXbgYtGG59kMw72VoNSiZQymjaCUFAqZLfjy4iBJEhmqPVxvvfXx4eg4AbWZ4WodGQBwXZuNgU6Bz2r1XrglLN87t1WwHEhAj1N05fvh9Ld9f+YbwPN5OW1rzXcAAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_FIXED_0 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAATVJREFUKJGVkL1LQlEYxp/3HKt7XRxT73CvQjg0SijtLdcGIeifEOdadOxjr8F/QQhC2tzDS0Rbi4OewUiadNCreM7bEH5cBKFne3l/v+F5gH+GNo92M58IbVEFU+Hvy4GUi4bvf/xsCa1WocjGPBEhpVRomJk8zyZmjCRQOi+/va6EdjOfmOyLz+Fwfnhz25X9/gQA4DgW6rWcTiYPvuNzfXx2+T4SABDaokqE1CYMAINBiLv7rhQC6ZklKwAgAABMBaVCswkv0+tNoNTUMFBcCwCYmbboyDzMa4E48DybHMfa4rLZOFzXFjCisxKkXDSMwbhey+lMJh6Br6+OtNY8kHt4jMz68nxyqkFNIZBWamoAwHVtYQy+YhAXpXIniAjLeWeWrCwLEtARMXrw/WC8s9+u/AJfvnc8Bs9CXgAAAABJRU5ErkJggg==';

export const MARKER_SCHEDULE_PHOTOCELL_100 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKdJREFUKJGdkTkOwkAQBKs3st/DESEL/gWy8QfgLc4JWGcc70EkOwQ+kY0wdLarru4ZDfwo9R+rh60lUmABYHBxcPSRigGQPG1rxh5wg1gjL2OlLVAnnzDce2dVgwgyNj6WdwASGYyYuxlcqDxt/WzCsvM+YN+AxtMA9wnArQUEh4891V9w1tvBRyowckQYGT4gdudYZR3eKXlYYtXhlnX21RlZY/5LL06kMyymhxEPAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_PHOTOCELL_90 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAK5JREFUKJGdkbsRwjAQBfdUhh3hFsAMEQN9wVjQANRCTgIJ3xYgM13oERgLDzgwXCbN7tPdCX4sax5G98dEqADy19XJpPUhSzdfwuhWzmQsAPedq+WxlxZReCVvBc4+0QoKQUzPWbJzAAF5WuBGC84Znvp5M/od5h1EAaEOgt4CXDvwl3dL0ipGtFeQzEeh2rOWBqEVNuanLNnDx8flt3LsTAVmw2ounSXzNfxXPQG6wEGaIEQSCgAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_PHOTOCELL_80 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAALJJREFUKJGdj0sKwkAUBKvnQn4jREX0XkqiF9CzZO9GEcFf0Ps4z0ViDAkStHfDdFXz4Meo/Ggfz1NMERDkvydhmzQMkhrQOlzmEkvAVa0Gq/uwHxVAbt6CucpoVkde5mbpqLvLbObizFwtF05nzsd85q3XeK3RLwFYI5B33sC1qS24FIBk669DZgD+6RUXQBoGicEK5OtqeaTFY9zb50ufdA63CfKRwQAwwfnpFb/Lf+UFWnQ9DfVViy0AAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_PHOTOCELL_70 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAALRJREFUKJGdkTsOgkAURc+b3ZDQ+SkN1lIY2Y4GZAO6EUMisbTRzk8prsZ5FsKISoJ6u8ncM+fNDPwYqS/8LA9uSixKr9w9iMryEoXrD8DL8qkoc8A0nJsWkzB2gJ/lgVW2KObVCSggWFUzvEajnQFQJYGG8nMGI2ITKr1C54v7dh1QituidcP5C+DkAKOyaPFYjCQOeLyzpAi2sawyK8bhHt4+zlttBiI2Bvql74iRpCr/lTtmGjp6MPPr5gAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_PHOTOCELL_60 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAPJJREFUKJGVkDtSw0AMQJ/kNCR27hEmHZ+SCTUuGHydMHYoaOEiiWcwlDTQ8SkxB7FNFa8oEhtDMgO8SrvaJ2kF/0S6h3GaTWojFuNgnX0Sk+u3KLzZEEZpNhVjBuiWuhf5WRi3wjjNJs64x0yXZYF9VKtnO316wRBEnJkev0cnDwpgRgLosixwZYk5w5zhqoq6KAFUxCU07Q32gLZyl/rrbr8VVs6vWLfDazPzT7z+oAlfWkFNrgB6wRD1fUQVRPEGAV7gAzhUknZLALuL2xnYlM21OkzO8yi8/CYAjOZ3RyIuBg7XMz+jkuSn4eMf/ridT15qVyOdBN2cAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_PHOTOCELL_50 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAP1JREFUKJGVkDtOw0AQQN9snOQAiYTlnCAoHZ8ShRoXCF8nyA4FLVwELGEoaaDjU2JuECtRLhCMdyiIjSGRgFftzujND/6J1D+DOBkWSijKzjL7ICrnL4F/tSL042Qkyhgwa+qepEd+WAmDOBla5RZrzSKb8D6fA+B0OrS9HhhjVc3+a3BwZwBUiQCzyCbk0ylaFGhRkM9mvGUZgBGxEWV7hS2gqlwn/4ptV8Kn8yta7/BczvyTZrdbPp8qwaicAbS9Hk3XRZwG0nBobbi0PA/AYiSqrgSweXk9Bh2xelaLynEa+KffBID+xc2eiA2B3eXMjxiJ0kP//g87rucD+VNXKtAHng0AAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_PHOTOCELL_40 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQBJREFUKJGVkL1Kw1AUgL9zIdr2FZQuLUpCN39GqbMZxLxOJamDq76IFoyOLrr5MxproCh9iBoJ3uNgE6MtqN907zl85w/+iVQ/nUHcfVdCUTam2RtROX4I/LMZwR3EPVH6gJlT9yDZ88NS6AzirlUusdZM0iFv4zEAi0vLNFwPjLGqZvsx2LkyAKpEgJmkQ7LRCM1zNM/JXp55TZ8AjIiNKNorrAFl5SrZV2y9FD6dX9Fqh/ti5p/Ums3ieVcKRuUIoOF61FptxHEQZ4F6q019ZRXAYiQqrwTgnZ73QXvMntWisp8E/uE3AcA9udgSsSGwOZ35FiNRsutf/2HH+XwAiLJXKd7dATEAAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_PHOTOCELL_30 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQJJREFUKJGVkL1OwmAUQM/9GEpEHsMfDEETQDaDsx2MfR1Mi4OrvoiSiI4uumlpopGC7wGYNOG7DtKmCol6pntzc+4f/BPJJ9Vevz1XfFGai+qTqFwOPfdmSaj0+h1RuoBZ0fcsPnH9TKj2+m2r3Ot8biZRyMd4BEBxY5Nys4UUClbVHI68owcDoEoAmEkUMn19wSYJNkmYDd+YRgMAI2ID0vEKdSDrnGf2Pk7DRiZ8Ob+i+QmDdOefrG1tp2GYCUblAqDcbFGq7WIcB+MUWa/tUao3ACxGguxLADvXt13QDstvtaicxp57/k0AqFzdHYhYH9hf7PyMkSA+dh//cONqPgHSu1gwTlr/swAAAABJRU5ErkJggg==';
export const MARKER_SCHEDULE_PHOTOCELL_20 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQNJREFUKJGVkMFOwkAQQN9s6kkT+Q0MxkTUo5YQTvZg7O9gWjx41R/RJlaPBsEYowichN/AIEkPuOMB2lQhUd9pdmffzOzAP5H8oRTF7qcSiLI7z76IysWb710vCMUorovSAMySuqeDYy/IhFIUu1a50+nUjFpNJv0uAKubWxSqNcRxrKqpDP3DtgFQJQTMqNVk/PSITRJskjDuPPPevgcwIjYkba9QBrLKeT562d1OJsycX9F8h24680/Wtstp+ArgABiVcxV1C9UarDhM+j3Q2eP1gwqAxUiYbQlg4+qmAVpnca0WlZOB7519EwCKl7f7IjYA9uYzdzASDo68hz/8cTlf97ZdUFTOLC8AAAAASUVORK5CYII=';
export const MARKER_SCHEDULE_PHOTOCELL_10 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQFJREFUKJGVkDtLw2AUhp/zkW7SQfonKt28IaUSZ4ME83cqSR1c9Y9oqEkXURHFxcto/BUVL0OWJsfBJEYjqM/0XXjOeXnhn0j90gsjO1N8UZaL3xtROXjwnOOG0A2joSgjwPwwdzfZdvxK6IWRnStnOpuZaTzm7foKgLnVNTquh1hWrmo2Hr3NSwOgSgCYaTzm+fSELE3J0pSXi3OeJhGAEckDyvUKi0A1uc7r59tSJXw4v6L1Dfdl5u+0+4PyeAdgARiVfRW1O66HtFpFNKHdHzDvbAHkGAmqlgAWjuIR6JBmrTkqO4nn7H0RALqHk3WR3AdWisy3GAkS12m28VfeAb7vXBvFpbthAAAAAElFTkSuQmCC';
export const MARKER_SCHEDULE_PHOTOCELL_0 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAQtJREFUKJGVkD1Lw1AUhp9zsV2lg/Q3VLr5MUoUSgcziJlEKPR/VJI6uOqPKNbFRowdRFx082M0/Q8VP5ZMJTkOJjHagvpM957Lc857D/wTKV7qfmDFiivKSvp6JypHT459PiXU/KAjShcwM/ruh9u2mwt1P7AS5VonEzPu93i/ugRg3tqg2mojpVKiatZHzuaNAVDFA8y43+PlbEAcRcRRxOsw4PnkGMCIJB7ZeIUlIO9c5O2rtpwLn86vaHHCY5b5J5VGMzs+AMwBGJVDFbWqrTZSLqfRhEqjycLOLkCCES/fEsDi4KIL2mF6rQkqe6FjH3wTAGqnwzWRxAVW08z3GPHCLfv2D3+czQcoPVvhZ1y9ygAAAABJRU5ErkJggg==';

export const BASEPATH = window.NSN?.basePath || '';

export const modelNames: Record<string, string> = {
  'unode-v2': 'Core Node',
  unode: 'Core Node',
  'unode-v3': 'Internal Core Node',
  'unode-v4': 'Core Node EX Wifi',
  'unode-v5': 'Core Node EX Cellular',
  'unode-v6': 'Core Node EX LTE',
  'unode-v61': 'Core Node EX LTE',
  'unode-v62': 'Core Node EX LTE',
  'falcon-q': 'Video Node',
  'unode-v7': 'Video Node 4k',
  'unode-v9': 'Verizon Digital Kiosk',
  merlin: 'Video Node 4k',
  'merlin-tx2': 'Video Node 4k Gen 2',
  'genericx86-64': 'Verizon Digital Kiosk',
  vdk: 'Verizon Digital Kiosk',
  vdkmaster: 'Verizon Digital Kiosk',
  ngcn: 'City Hub',
  cnext: 'City Hub',
  lco: 'Light Sense Node',
  'generic-camera': 'Generic Camera',
};

export const modelTypes: Record<string, string> = {
  'unode-v2': 'Lighting',
  'unode-v3': 'Lighting',
  'unode-v4': 'Lighting',
  'unode-v5': 'Lighting',
  'unode-v6': 'Lighting',
  'unode-v61': 'Lighting',
  'unode-v62': 'Lighting',
  'unode-v7': 'Video',
  'unode-v9': 'Kiosk',
  merlin: 'Video',
  'merlin-tx2': 'Video',
  vdk: 'Kiosk',
  vdkmaster: 'Kiosk',
  'genericx86-64': 'Kiosk',
  ngcn: 'Lighting',
  cnext: 'Lighting',
  lco: 'Lighting',
  LCO: 'Lighting',
  'Light Sense': 'Lighting',
  'Light Sense node': 'Lighting',
  'City Hub': 'Lighting',
  'Core Node': 'Lighting',
  'Internal Core Node': 'Lighting',
  'Core Node EX Wifi': 'Lighting',
  'Core Node EX Cellular': 'Lighting',
  'Core Node EX LTE': 'Lighting',
  'Video Node': 'Video',
  'Video Node 4k': 'Video',
  'Video Node 4k Gen 2': 'Video',
  'Verizon Digital Kiosk': 'Kiosk',
  'falcon-q': 'Video',
  'generic-camera': 'Video',
  'Generic Camera': 'Video',
};

export const modelSubTypes: Record<string, string> = {
  LCO: 'LCO',
  lco: 'LCO',
  'Light Sense node': 'LCO',
  cnext: 'CityHub',
  'City Hub': 'CityHub',
};

export const jobStatusMsg: Record<string, string> = {
  ALL: 'All',
  COMPLETED: 'Completed',
  PARTIAL_SUCCESS: 'Partial success',
  RUNNING: 'In progress',
  ABORTING: 'Stopping',
  ABORTED: 'Stopped',
  FAILED: 'Failed',
};

export const jobTypeMsg: Record<string, string> = {
  ALL: 'All',
  ADD_NODES: 'Move nodes to group',
  REMOVE_NODES: 'Remove nodes from group',
  DELETE_NODES: 'Delete nodes',
  CREATE_GROUP: 'Create lighting group with nodes',
  UPDATE_GROUP: 'Update lighting group',
  DELETE_GROUP: 'Delete group',
  UPDATE_FIRMWARE_NODE: 'Update node firmware',
  UPDATE_FIRMWARE_GROUP: 'Update group firmware',
  UPDATE_FIRMWARE_SITE: 'Update site firmware',
};

export const chartTypes: Record<string, string> = {
  site: 'Sites',
  organizational: 'Org Group',
  lighting: 'Lighting Group',
  nodeid: 'Node ID',
  nodeList: 'Nodes selected',
};

export const reportModes: Record<string, string> = {
  aggregated: 'Aggregated',
  individual: 'Individual Nodes',
};

export const reportColumns: Record<string, string> = {
  site_name: 'Site Name',
  org_name: 'Account Name',
  group_names: 'Group Name',
  node_id: 'Node ID',
  node_name: 'Node Name',
  fixture: 'Fixture',
  power_draw: 'Fixture Wattage',
  location: 'Location (lat/long)',
  schedule: 'Schedule',
};

export const reportGranularityOptions: Record<string, string> = {
  month: 'Monthly',
  day: 'Daily',
  hour: 'Hourly',
  '15min': '15 min',
};

export const scheduleDays: DimmingPointDay[] = [
  { key: 'sun', name: 'su' },
  { key: 'mon', name: 'mo' },
  { key: 'tue', name: 'tu' },
  { key: 'wed', name: 'we' },
  { key: 'thu', name: 'th' },
  { key: 'fri', name: 'fr' },
  { key: 'sat', name: 'sa' },
];

export const fixedGisCustomAttributes = [
  'SLID', 'LAMPSEQ', 'LAT', 'LON', 'LAMPDESC', 'LAMPWATTS', 'LUMTYPE', 'CATALOG_NUM',
];

export const firmwareStatusMessages: Record<string, string> = {
  COMMAND_SENT: 'In process',
  NODE_REBOOTING: 'In process',
  START_DOWNLOAD: 'In process',
  STOP_DOWNLOAD: 'In process',
  UPDATE_SUCCESSFUL: 'Succeeded',
  UPDATE_FAILED: 'Failed',
  NODE_OFFLINE: 'Failed',
  JOB_SENT: 'In process',
  JOB_RECEIVED: 'In process',
  JOB_DONE: 'Succeeded',
  DOWNLOAD_SENT: 'In process',
  DOWNLOAD_IN_PROGRESS: 'In process',
  DOWNLOAD_SUCCESSFUL: 'In process',
  INSTALL_SUCCESSFUL: 'Succeeded',
  FIRMWARE_NOT_FOUND: 'Failed',
  FAIL_REGISTRATION: 'Failed',
  FAIL_JOB_REGISTRATION: 'Failed',
  MODEM_INSTALL_FAILED: 'Failed',
  MODEM_STATE_INVALID: 'Failed',
  MODEM_DOWNLOAD_IN_PROGRESS: 'In process',
  MODEM_INSTALL_IN_PROGRESS: 'In process',
  MODEM_DOWNLOAD_FAILED: 'Failed',
  MODEM_DOWNLOAD_SUCCESSFUL: 'In process',
  DEVICE_INSTALL_FAILED: 'Failed',
  DEVICE_DOWNLOAD_IN_PROGRESS: 'In process',
  DEVICE_INSTALL_IN_PROGRESS: 'In process',
  DEVICE_DOWNLOAD_FAILED: 'Failed',
  DEVICE_STATE_INVALID: 'Failed',
  DEVICE_DOWNLOAD_SUCCESSFUL: 'In process',
  DEVICE_INSTALL_SUCCESSFUL: 'Succeeded',
  MODEM_INSTALL_SUCCESSFUL: 'Succeeded',
  UNABLE_TO_ACCESS_S3_BUCKET: 'Failed',
  STOP_REQUEST_REJECTED_BY_DEVICE: 'Failed',
  STOP_REQUEST_SENT_TO_DEVICE: 'Failed',
  UPDATE_ALREADY_IN_PROGRESS: 'In process',
  DEVICE_DOWNLOAD_ATTEMPT_INVALID: 'Failed',
  DEVICE_RESTARTED: 'In process',
};

export const maxSelectedNodes = 300;
export const slideInWidth = 460;
export const sensitySystemsOrgId = 'efe5bdb3-baac-5d8e-6cae57771c13';
export const fixSunrise = 420; // 7am (end of sunrise)
export const fixSunset = 1020; // 5pm (start of sunset)
export const httpOk = 200;
export const httpNotFound = 404;
export const userGuideUrl = 'https://netsense-resources.sensity.com/LightingUserGuide/index.html';
