import React from 'react';
import Modal from '../../../../Common/Components/Modal';
import { LightsGroupsMoveNodeToLightingGroupModalProps } from '../../../../types/LightsGroupProps';
import { postRequest } from '../../../../utils/fetch';
import GroupActionSubmitInfo from '../../Components/GroupActionsSubmitInfo';
import GroupActionsTargetGroup from '../../Components/GroupActionsTargetGroup';

function LightsGroupsMoveNodeToLightingGroupModal(props: LightsGroupsMoveNodeToLightingGroupModalProps): JSX.Element {
  const {
    setModalOpen,
    selectedNode,
    selectedCustomer,
    selectedSite,
    addNotification,
    updateGroup,
    selectedLightingGroup,
  } = props;
  return (
    <Modal
      title="Move nodes to lighting group"
      width="570"
      className="lighting-group__modal"
      setModalOpen={() => setModalOpen(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setModalOpen(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          const postBody = { nodeList: [selectedNode.nodeid] };

          const result = await postRequest(
            `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${selectedLightingGroup?.id}/add-nodes`,
            postBody,
          );

          if (!result.error) {
            addNotification({ type: 'success', message: 'Your "Move nodes to lighting group" operation is completed.' });
            if (updateGroup) {
              updateGroup();
            }
            setModalOpen(0);
          } else {
            addNotification({ type: 'error', message: `Your "Move nodes to lighting group" operation is failed: ${result.error}` });
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Move nodes to lighting group" operation is failed.' });
        }
      }}
    >
      <div>
        <div className="lighting-group__modal-content">
          <div className="lighting-group__modal-selected">
            <div className="lighting-group__modal-selected-node">Selected node</div>
            <div>{selectedNode.nodeid}</div>
          </div>
          <GroupActionsTargetGroup
            hasGroupName={!!selectedLightingGroup?.name}
            groupName={selectedLightingGroup?.name}
          />
        </div>
        <GroupActionSubmitInfo isVisible />
      </div>
    </Modal>
  );
}

export default LightsGroupsMoveNodeToLightingGroupModal;
