import React, { useState } from 'react';
import { SidebarProps } from '../../../types/SidebarProps';
import SidebarItem from './SidebarItem';

import LightsOperation from '../SidebarItems/LightsOperation';
import LightsAlarms from '../SidebarItems/LightsAlarms';
import LightsEnergy from '../SidebarItems/LightsEnergy';
import LightsFirmware from '../SidebarItems/LightsFirmware';
import LightsGroups from '../SidebarItems/LightsGroups';
import LightsIDs from '../SidebarItems/LightsIDs';
import LightsLocation from '../SidebarItems/LightsLocation';
import LightsNetwork from '../SidebarItems/LightsNetwork';
import LightsPower from '../SidebarItems/LightsPower';
import LightsSensorCharts from '../SidebarItems/LightsSensorCharts';

import LightsOperationMulti from '../SidebarItems/LightsOperationMulti';
import LightsAlarmsMulti from '../SidebarItems/LightsAlarmsMulti';
import LightsNetworkMulti from '../SidebarItems/LightsNetworkMulti';
import LightsPowerMulti from '../SidebarItems/LightsPowerMulti';
import LightsEnergyMulti from '../SidebarItems/LightsEnergyMulti';
import LightsFirmwareMulti from '../SidebarItems/LightsFirmwareMulti';
import LightsIDsMulti from '../SidebarItems/LightsIDsMulti';

import { ReactComponent as OperationIcon } from '../../../img/icons/operation.svg';
import { ReactComponent as AlarmsIcon } from '../../../img/icons/alarms.svg';
import { ReactComponent as NetworkIcon } from '../../../img/icons/network.svg';
import { ReactComponent as LocationIcon } from '../../../img/icons/location.svg';
import { ReactComponent as IDsIcon } from '../../../img/icons/ids.svg';
import { ReactComponent as SensorChartsIcon } from '../../../img/icons/sensor-charts.svg';
import { ReactComponent as PowerIcon } from '../../../img/icons/electric-utility.svg';

// not the exact icons
import { ReactComponent as SmartMeterIcon } from '../../../img/icons/smart-meter.svg';
import { ReactComponent as HardwareIcon } from '../../../img/icons/hardware.svg';
import { ReactComponent as GroupsIcon } from '../../../img/icons/groups-2.svg';

const maxSelectedItemsSize = 10;

function Sidebar(props: SidebarProps): JSX.Element {
  const {
    selectedCustomer,
    selectedSite,
    selectedItems,
    groups,
    groupsNodes,
    lightingGroups,
    orgGroups,
    updateGroup,
    updateNodes,
    gpsPinPosition,
    setGpsPinPosition,
    customAttributes,
  } = props;
  const [toggleSidebar, setToggleSidebar] = useState(-1);
  const toggleState = { toggleSidebar, setToggleSidebar };
  const itemProps = { selectedCustomer, selectedSite, selectedItems };
  const hasMultiNodeEnabled = (selectedItems.size > 0
    && selectedItems.size <= maxSelectedItemsSize);
  const hasOnlySingleNodeEnabled = selectedItems.size === 1;
  const openMultinodeVersion = selectedItems.size > 1;

  const sidebarOperation = 0;
  const sidebarAlarms = 1;
  const sidebarNetwork = 2;
  const sidebarPower = 3;
  const sidebarEnergy = 4;
  const sidebarLocation = 5;
  const sidebarFirmware = 6;
  const sidebarLightIDs = 7;
  const sidebarGroups = 8;
  const sidebarSensorChart = 9;

  if (toggleSidebar !== -1
    && (selectedItems.size === 0
      || selectedItems.size > maxSelectedItemsSize
      || (openMultinodeVersion && (toggleSidebar === sidebarLocation || toggleSidebar === sidebarGroups)))) {
    setToggleSidebar(-1);
  }

  return (
    <>
      <div className="sidebar">
        <SidebarItem
          order={sidebarOperation}
          icon={<OperationIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Operation"
        />
        <SidebarItem
          order={sidebarAlarms}
          icon={<AlarmsIcon />}
          isEnabled={hasMultiNodeEnabled}
          colorType="light"
          {...toggleState}
          tooltipText="Alarms"
        />
        <SidebarItem
          order={sidebarNetwork}
          icon={<NetworkIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Network"
        />
        <SidebarItem
          order={sidebarPower}
          icon={<PowerIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Power"
        />
        <SidebarItem
          order={sidebarEnergy}
          icon={<SmartMeterIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Energy"
        />
        <SidebarItem
          order={sidebarLocation}
          icon={<LocationIcon />}
          isEnabled={hasOnlySingleNodeEnabled}
          {...toggleState}
          tooltipText="Location"
        />
        <SidebarItem
          order={sidebarFirmware}
          icon={<HardwareIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Firmware"
        />
        <SidebarItem
          order={sidebarLightIDs}
          icon={<IDsIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Light IDs"
        />
        <SidebarItem
          order={sidebarGroups}
          icon={<GroupsIcon />}
          isEnabled={hasOnlySingleNodeEnabled}
          {...toggleState}
          tooltipText="Groups"
        />
        <SidebarItem
          order={sidebarSensorChart}
          icon={<SensorChartsIcon />}
          isEnabled={hasMultiNodeEnabled}
          {...toggleState}
          tooltipText="Sensor chart"
          colorType="light"
        />
      </div>
      {toggleSidebar === sidebarOperation && (openMultinodeVersion
        ? <LightsOperationMulti {...itemProps} />
        : <LightsOperation {...itemProps} />)}
      {toggleSidebar === sidebarAlarms && (openMultinodeVersion
        ? <LightsAlarmsMulti {...itemProps} />
        : <LightsAlarms {...itemProps} />)}
      {toggleSidebar === sidebarNetwork && (openMultinodeVersion
        ? <LightsNetworkMulti {...itemProps} />
        : <LightsNetwork {...itemProps} />)}
      {toggleSidebar === sidebarPower && (openMultinodeVersion
        ? <LightsPowerMulti {...itemProps} />
        : <LightsPower {...itemProps} />)}
      {toggleSidebar === sidebarEnergy && (openMultinodeVersion
        ? <LightsEnergyMulti {...itemProps} />
        : <LightsEnergy {...itemProps} />)}
      {toggleSidebar === sidebarLocation && (
      <LightsLocation
        {...itemProps}
        gpsPinPosition={gpsPinPosition}
        setGpsPinPosition={setGpsPinPosition}
        updateNodes={updateNodes}
      />
      )}
      {toggleSidebar === sidebarFirmware && (openMultinodeVersion
        ? <LightsFirmwareMulti {...itemProps} />
        : <LightsFirmware {...itemProps} />)}
      {toggleSidebar === sidebarLightIDs && (openMultinodeVersion
        ? (
          <LightsIDsMulti
            {...itemProps}
            customAttributes={customAttributes}
          />
        )
        : (
          <LightsIDs
            {...itemProps}
            customAttributes={customAttributes}
            updateNodes={updateNodes}
          />
        ))}
      {toggleSidebar === sidebarGroups && (
      <LightsGroups
        {...itemProps}
        groups={groups}
        groupsNodes={groupsNodes}
        lightingGroups={lightingGroups}
        orgGroups={orgGroups}
        updateGroup={() => {
          updateGroup();
          updateNodes();
        }}
      />
      )}
      {toggleSidebar === sidebarSensorChart && (<LightsSensorCharts {...itemProps} />)}
    </>
  );
}

export default Sidebar;
