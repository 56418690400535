import { useState } from 'react';
import { PageComponentProps, SelectedCustomer, SelectedSite } from '../types/PageComponentProps';

export default function useCustomerSiteSelect(): PageComponentProps {
  const [selectedCustomer, _setSelectedCustomer] = useState({
    id: sessionStorage.getItem('customerID') || '',
    name: sessionStorage.getItem('customerName') || '',
  });

  const [selectedSite, _setSelectedSite] = useState({
    id: sessionStorage.getItem('siteID') || '',
    name: sessionStorage.getItem('siteName') || '',
  });

  const setSelectedSite = (newSelectedSite: SelectedSite) => {
    sessionStorage.setItem('siteID', newSelectedSite.id);
    sessionStorage.setItem('siteName', newSelectedSite.name);
    _setSelectedSite(newSelectedSite);
  };

  const setSelectedCustomer = (newSelectedCustomer: SelectedCustomer) => {
    setSelectedSite({
      id: '',
      name: '',
    });
    sessionStorage.setItem('customerID', newSelectedCustomer.id);
    sessionStorage.setItem('customerName', newSelectedCustomer.name);
    _setSelectedCustomer(newSelectedCustomer);
  };

  return {
    selectedCustomer,
    setSelectedCustomer,
    selectedSite,
    setSelectedSite,
  };
}
