import React, { useState } from 'react';
import Loading from '../../../Common/Components/Loading';
import Modal from '../../../Common/Components/Modal';
import { ClearAlarmTypeProps } from '../../../types/ClearAlarmTypeProps';
import { useAppContext } from '../../../utils/AppContext';
import { postRequest } from '../../../utils/fetch';

function ClearAlarmType(props: ClearAlarmTypeProps): JSX.Element {
  const {
    setShowModal,
    selectedClearType,
    selectedCustomer,
    selectedSite,
    alarms,
    updateAlerts,
  } = props;

  const { addNotification } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="alarm-clear">
      <Modal
        title="Clear alarms"
        width="284"
        setModalOpen={() => setShowModal(0)}
        primaryButtonAction={async () => {
          setIsLoading(true);
          const selectedAlarms = alarms.filter((alarm, idx) => alarm.name === selectedClearType && alarm.active === true);
          if (selectedAlarms.length > 0) {
            const idlist = selectedAlarms.map((alarm, idx) => alarm.alertid);
            try {
              const path = `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/dismiss`;
              const resp = await postRequest(path, { alertids: idlist });
              if (!resp.error) {
                addNotification({ type: 'success', message: `${idlist.length} ${selectedClearType} alerts have been cleared.` });
              } else {
                addNotification({ type: 'error', message: 'Your "Clear alarms" operation has failed.' });
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Clear alarms" operation has failed.' });
            }
          } else {
            addNotification({ type: 'error', message: `There were no active ${selectedClearType} alarms to clear.` });
          }
          setIsLoading(false);
          setShowModal(0);
          updateAlerts();
        }}
        secondaryButtonAction={() => setShowModal(0)}
        primaryButtonLabel="Clear"
        secondaryButtonLabel="Cancel"
      >
        {isLoading
          ? <Loading isLoading />
          : (
            <div className="content">
              <div className="content-text">
                <div>
                  <span>
                    Are you sure you want to clear all
                    <br />
                    <b>{selectedClearType}</b>
                    <span> alarms?</span>
                  </span>
                </div>
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
}

export default ClearAlarmType;
