import React, { useState } from 'react';
import { DateTime, Settings } from 'luxon';
import { AddNotificationProps } from '../../../types/AppContext';
import { NavItem } from '../../../types/WizardModal';
import { useAppContext } from '../../../utils/AppContext';
import WizardModal from '../../../Common/Components/WizardModal';
import WizardNodeAndPeriodSelector from './WizardNodeAndPeriodSelector';
import WizardColumnSelector from '../../../Common/Components/WizardColumnSelector';
import RecipientEditor from '../../../Common/Components/RecipientEditor';
import WizardSummary from '../../../Common/Components/WizardSummary';
import Modal from '../../../Common/Components/Modal';
import { EnergyReportPayload, Recipient } from '../../../types/EnergyReportPayload';
import { postRequest } from '../../../utils/fetch';
import { httpOk } from '../../../utils/constants';
import Utils from '../../../utils/Utils';
import { GroupsEnergyReportWizardProps } from '../../../types/GroupsEnergyReportWizardProps';

import { ReactComponent as SuccessIcon } from '../../../img/icons/success.svg';

function GroupsEnergyReportWizard(props: GroupsEnergyReportWizardProps): JSX.Element {
  const { closeModal, selectedGroup, selectedCustomer, selectedSite } = props;

  const { addNotification } = useAppContext();

  const [granularity, setGranularity] = useState<string>('day');
  const [mode, setMode] = useState<string>('individual');
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const [reportNameErrorActive, setReportNameErrorActive] = useState<boolean>(false);
  const [utilityBillingReference, setUtilityBillingReference] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const validateNodesPage = ({ displayError }: {displayError: boolean}) => {
    let notification: AddNotificationProps | undefined;
    if (startDate === undefined) {
      notification = { type: 'warning', message: 'Please specify start date.' };
    } else if (endDate === undefined) {
      notification = { type: 'warning', message: 'Please specify end date.' };
    }

    if (notification && displayError) {
      addNotification(notification);
    }

    return !notification;
  };
  const validateColumnsAndDatesPage = ({ displayError }: {displayError: boolean}) => {
    let notification: AddNotificationProps | undefined;
    if (selectedColumns.length === 0) {
      notification = { type: 'warning', message: 'Please select at least one column.' };
    }
    if (notification && displayError) {
      addNotification(notification);
    }
    return !notification;
  };
  const validateRecipientsPage = ({ displayError }: {displayError: boolean}) => {
    if (recipients.length !== 0) {
      return true;
    }
    if (displayError) {
      addNotification({ type: 'warning', message: 'Please select at least one recipient.' });
    }
    return false;
  };
  const validateSummaryPage = ({ displayError }: {displayError: boolean}) => {
    const reportNameMissing = !reportName || reportName.length === 0;
    if (displayError) {
      setReportNameErrorActive(reportNameMissing);
    }
    return !reportNameMissing;
  };

  const [doneWithSuccess, setDoneWithSuccess] = useState<boolean>(false);

  async function sendEnergyReportRequest(payload: EnergyReportPayload) {
    try {
      setLoading(true);
      const { status, data, error } = await postRequest<EnergyReportPayload, { queryTaskId: string; }>(
        `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/energyReportEmail`,
        payload,
      );
      if (status === httpOk && data?.queryTaskId) {
        setLoading(false);
        setDoneWithSuccess(true);
      } else {
        throw new Error(error);
      }
    } catch (error) {
      setLoading(false);
      addNotification({ type: 'warning', message: `Could not fetch energy data: '${error}'` });
    }
  }

  const navItems: Map<number, NavItem> = new Map([
    [1, { className: '',
      label: '1',
      labelName: 'Group & time period',
      validatorFn: validateNodesPage,
      content:
  <WizardNodeAndPeriodSelector
    isGroupType
    selectedGroup={selectedGroup}
    selectedNodes={[]}
    granularity="day"
    setGranularity={setGranularity}
    mode={mode}
    setMode={setMode}
    startDate={startDate?.toJSDate()}
    setStartDate={(dt) => setStartDate(DateTime.fromJSDate(dt as Date))}
    endDate={endDate?.toJSDate()}
    setEndDate={(dt) => setEndDate(DateTime.fromJSDate(dt as Date))}
  />,
    }],
    [2, { className: 'long-text',
      label: '2',
      labelName: 'Output columns',
      validatorFn: validateColumnsAndDatesPage,
      content:
  <WizardColumnSelector
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    mode={mode}
    nodeGroup="group"
    allSelected={allSelected}
    setAllSelected={setAllSelected}
  />,
    }],
    [3, { className: '',
      label: '3',
      labelName: 'Recipients',
      validatorFn: validateRecipientsPage,
      content:
  <RecipientEditor
    recipients={recipients}
    setRecipients={setRecipients}
    selectedCustomer={selectedCustomer}
  /> }],
    [4, { className: '',
      label: '4',
      labelName: 'Review & run',
      validatorFn: validateSummaryPage,
      content:
  <WizardSummary
    selectedGroup={selectedGroup}
    groupFilterActiveTab={selectedGroup.type || ''}
    selectedSite={selectedSite}
    mode={mode}
    utilityReference={utilityBillingReference}
    setUtilityReference={setUtilityBillingReference}
    reportName={reportName}
    setReportName={setReportName}
    reportNameErrorActive={reportNameErrorActive}
    loading={loading}
    recipients={recipients}
    selectedColumns={selectedColumns}
    granularity={granularity}
    startDate={startDate as DateTime}
    endDate={endDate as DateTime}
  /> }],
  ]);

  return (
    !doneWithSuccess ? (
      <WizardModal
        title="Energy use report"
        closeModal={closeModal}
        submitButtonAction={() => {
          sendEnergyReportRequest({
            active: true,
            mode,
            siteid: selectedSite.id,
            orgid: selectedCustomer.id,
            groupid: selectedGroup?.id,
            timePeriod: Utils.calculateDateParams(granularity, startDate as DateTime, endDate as DateTime),
            reportName,
            recipients,
            outputColumns: selectedColumns,
            utilityBillingReference,
            timezone: Settings.defaultZoneName,
            granularity,
          } as EnergyReportPayload);
        }}
        width="805"
        height="495"
        navItems={navItems}
      />
    ) : (
      <Modal
        setModalOpen={() => closeModal()}
        title="Success"
        primaryButtonAction={() => closeModal()}
        primaryButtonLabel="OK"
        width="354"
        height="276"
        className="success-modal"
      >
        <>
          <SuccessIcon />
          <div>
            <div>Your request has been submitted.</div>
            <div> Report generation may take several hours depending on amount of data requested. </div>
            <div> Recipients will receive email notification when the report is ready.</div>
          </div>
        </>
      </Modal>
    )
  );
}

export default GroupsEnergyReportWizard;
