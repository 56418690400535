import React, { useState } from 'react';
import Modal from '../../../Common/Components/Modal';
import { ReactComponent as LihtingGroupIcon } from '../../../img/icons/lighting-group.svg';
import { ReactComponent as NodesIcon } from '../../../img/icons/nodes.svg';
import { ReactComponent as OrgGroupIcon } from '../../../img/icons/org-group.svg';
import { EnergyModalProps } from '../../../types/EnergyModalProps';
import { ChartBase } from '../../../types/GroupsSelector';
import EnergyPage from '../../Energy/EnergyPage';

function EnergyModal(props: EnergyModalProps): JSX.Element {
  const {
    setOpenEnergyModal,
    selectedGroup,
    selectedCustomer,
    selectedSite,
    selectedNodes,
  } = props;

  const [chartBase] = useState<ChartBase | undefined>(() => {
    if (selectedNodes && selectedNodes.length > 1) {
      return {
        type: 'nodeList',
        icon: <NodesIcon />,
        selectedNodes,
      } as ChartBase;
    }
    if (selectedNodes) {
      return {
        type: 'nodeid',
        icon: <NodesIcon />,
        selectedNode: selectedNodes[0],
      } as ChartBase;
    }
    return {
      type: selectedGroup?.type,
      icon: selectedGroup?.type === 'organizational' ? <OrgGroupIcon /> : <LihtingGroupIcon />,
      group: selectedGroup,
    } as ChartBase;
  });

  return (
    <Modal
      className="modal--full-screen"
      width="100"
      widthType="%"
      height="100"
      heightType="%"
    >
      <EnergyPage
        selectedSite={selectedSite}
        selectedCustomer={selectedCustomer}
        defaultSelectedChartBase={chartBase}
        isModal
        closeModal={() => setOpenEnergyModal(false)}
      />
    </Modal>
  );
}

export default EnergyModal;
