/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import AssignFixture from '../../Common/Components/AssignFixture';
import Button from '../../Common/Components/Button';
import LightingMap from '../../Common/Components/LightingMap';
import Loading from '../../Common/Components/Loading';
import ManualOverride from '../../Common/Components/ManualOverride';
import SplitPaneWrapper from '../../Common/Components/SplitPaneWrapper';
import TableFooter from '../../Common/Components/TableFooter';
import TableFooterItem from '../../Common/Components/TableFooterItem';
import Toolbar from '../../Common/Components/Toolbar';
import ToolbarButton from '../../Common/Components/ToolbarButton';
import ToolbarButtonContainer from '../../Common/Components/ToolbarButtonContainer';
import ToolbarHeading from '../../Common/Components/ToolbarHeading';
import { AlertCountMap, AlertData, AlertDataByGroupId, AlertNodeData, AlertsNodesObjectWithAlertCounts } from '../../types/AlertObject';
import { GroupObject } from '../../types/GroupObject';
import { NodeObject } from '../../types/NodeObject';
import { PageComponentProps } from '../../types/PageComponentProps';
import { ScheduleObject } from '../../types/ScheduleObject';
import { SiteObject } from '../../types/SiteObject';
import { nodesFetcherFn, groupsNodesWithAlertsFn, nodeExtendedPropsFn, calculateDisplayedAlerts, getScheduledDriverLevels, groupsScheduleFn } from '../../utils/ApiDataHelpers';
import useGroupsPageState from '../../utils/state/useGroupsPageState';
import Utils from '../../utils/Utils';
import CreateGroup from './Components/CreateGroup';
import DeleteGroup from './Components/DeleteGroup';
import EditGroup from './Components/EditGroup';
import EnergyModal from './Components/EnergyModal';
import { LightsGroupsItem } from '../../types/LightsGroupProps';
import Reports from '../Lights/Components/Reports';
import { useAppContext } from '../../utils/AppContext';

// icons
import { ReactComponent as LightingGroupIcon } from '../../img/icons/lighting-group.svg';
import { ReactComponent as TotalEnergyIcon } from '../../img/icons/smart-meter.svg';
import { ReactComponent as OrgGroupIcon } from '../../img/icons/org-group.svg';
import { ReactComponent as ManualOverrideIcon } from '../../img/icons/manual-override.svg';
import { ReactComponent as AddLightPoleIcon } from '../../img/icons/add-light-pole.svg';
import ScheduleModal from '../Schedules/Components/ScheduleModal';
import LightingGroupsList from './Components/LightingGroupsList';
import OrgGroupsList from './Components/OrgGroupsList';

const mapZoomLevel = 14;

function GroupsPage(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;
  const {
    selectedGroupIds,
    setSelectedGroupIds,
    resetSelectedGroupIds,
    activeToolbarBtn,
    setActiveToolbarBtn,
    toggleCreateGroup,
    setToggleCreateGroup,
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openEnergyModal,
    setOpenEnergyModal,
    confirmDeleteGroup,
    setConfirmDeleteGroup,
  } = useGroupsPageState();

  const { addNotification } = useAppContext();

  const isNonReadOnly = Utils.isNonReadOnly();

  // Site data

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id
    ? [`/customers/${selectedCustomer.id}/sites`, 'GetSites']
    : null);
  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  const [activeTabs, setActiveTabs] = useState(0);

  // Nodes

  const { data: nodesResp } = useSWR<Array<NodeObject> | undefined>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes`, 'GetNodesResp']
    : null, nodesFetcherFn);

  // Groups

  const {
    data: groupsResp,
    mutate: updateGroupsList,
  } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);

  const {
    groupsNodes = {},
    lightingGroups = [],
    orgGroups = [],
  } = useMemo(() => groupsNodesWithAlertsFn(groupsResp), [groupsResp]);

  // Schedules
  const { data: schedulesResp } = useSWR<Array<ScheduleObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/schedules`, 'GetSchedules']
    : null);

  const groupsSchedule = useMemo(() => groupsScheduleFn(groupsResp, schedulesResp), [groupsResp, schedulesResp]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currDate = useMemo(() => new Date(), [schedulesResp]);
  const sunTimes = useMemo(() => Utils.getSunriseSunset(currDate, Utils.getSiteLatLng(site), site?.time_zone || 'America/Los_Angeles'), [currDate, site]);
  const scheduledDriverLevels = useMemo(() => getScheduledDriverLevels(currDate, sunTimes, schedulesResp), [currDate, schedulesResp, sunTimes]);

  const [openScheduleModal, setOpenScheduleModal] = useState(0);
  const [groupSchedule, setGroupSchedule] = useState<ScheduleObject>();

  // Alerts

  const { data: alertsResp } = useSWR<AlertsNodesObjectWithAlertCounts>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/nodes`, 'GetAlertsNodes']
    : null);

  const displayedAlerts = useMemo(() => (alertsResp ? calculateDisplayedAlerts(alertsResp) : []), [alertsResp]);

  const [groupAlerts, setGroupAlerts] = useState({});

  useEffect(() => {
    const getAlertCountByGroup = (aResp: {
      name: string;
      nodeIDs?: AlertNodeData[] | undefined;
      count: number;
      alertType: string;
  }[], groupP: GroupObject) => {
      const baseAlertNumber = aResp.reduce<AlertCountMap>((o, key) => Object.assign(o, { [key.alertType]: 0 }), {} as AlertCountMap);
      return aResp.reduce<AlertData>((acc, alertRespItem) => {
        groupP.nodeList.forEach((nodeId) => {
          if (alertRespItem.nodeIDs?.map((n) => n.id).includes(nodeId)) {
            acc[alertRespItem.alertType] += 1;
            acc.count += 1;
          }
        });
        return acc;
      }, { ...{ count: 0 }, ...baseAlertNumber });
    };

    if (groupsResp && alertsResp) {
      const groupAlertNumbers = groupsResp.reduce((acc, groupP) => {
        acc[groupP.groupid] = getAlertCountByGroup(displayedAlerts, groupP);
        return acc;
      }, {} as AlertDataByGroupId);
      setGroupAlerts(groupAlertNumbers);
    }
  }, [groupsResp, displayedAlerts, alertsResp]);

  const [nodesList, setNodesList] = useState<Map<string, NodeObject>>(new Map());
  const [editGroup, setEditGroup] = useState<GroupObject>({} as GroupObject);
  const [energyGroup, setEnergyGroup] = useState<GroupObject>({} as GroupObject);
  const [deleteGroup, setDeleteGroup] = useState<GroupObject>({} as GroupObject);
  const [hasEmptySelectedGroup, setHasEmptySelectedGroup] = useState(false);

  const storageKey = 'GroupsPageSplitPos-v2';
  const [splitPaneSize, setSplitPaneSize] = useState<number>(parseInt(localStorage.getItem(storageKey) || '470', 10));

  useEffect(() => {
    if (nodesResp?.length && Array.isArray(groupsResp)) {
      setNodesList(nodeExtendedPropsFn(nodesResp, groupsNodes, site?.time_zone, alertsResp));
    }
  }, [nodesResp, groupsResp, groupsNodes, site?.time_zone, alertsResp]);

  const filteredNodes: React.MutableRefObject<NodeObject[] | undefined> = useRef();
  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  useEffect(() => {
    if (Array.isArray(nodesResp) && nodesList.size === 0) {
      filteredNodes.current = [];
      forceUpdate();
    } else if (nodesList?.size && Array.isArray(groupsResp)) {
      const tmpLightingGroups: Record<string, number> = {};
      const tmpOrgGroups: Record<string, number> = {};
      filteredNodes.current = Array.from(nodesList.values())
        .reduce((filtered: Array<NodeObject>, node: NodeObject) => {
          node.mapSelected = false;
          const nodeStatusOk = Utils.nodeStatusOk(node);
          const lightingGroupId: string = groupsNodes[node.nodeid]?.lightinggroup.id;

          if (!nodeStatusOk) {
            if (tmpLightingGroups[lightingGroupId]) {
              tmpLightingGroups[lightingGroupId] += 1;
            } else {
              tmpLightingGroups[lightingGroupId] = 1;
            }

            groupsNodes[node.nodeid]?.orggroups.forEach((group) => {
              if (tmpOrgGroups[group.id]) {
                tmpOrgGroups[group.id] += 1;
              } else {
                tmpOrgGroups[group.id] = 1;
              }
            });
          }

          if (activeTabs === 0 && selectedGroupIds.lightingGroupIds.length > 0) {
            if (selectedGroupIds.lightingGroupIds.includes(lightingGroupId)) {
              node.mapSelected = true;
              filtered.push(node);
            }
          } else if (activeTabs === 1 && selectedGroupIds.orgGroupIds.length > 0) {
            groupsNodes[node.nodeid]?.orggroups.forEach((group) => {
              if (selectedGroupIds.orgGroupIds.includes(group.id)) {
                node.mapSelected = true;
                filtered.push(node);
              }
            });
          } else {
            filtered.push(node);
          }

          return filtered;
        }, [] as Array<NodeObject>);

      Object.keys(tmpLightingGroups).forEach((groupid) => {
        lightingGroups.forEach((lightingGroup, index) => {
          if (groupid === lightingGroup.groupid) {
            lightingGroups[index].alertCount = tmpLightingGroups[groupid];
          }
        });
      });
      Object.keys(tmpOrgGroups).forEach((groupid) => {
        orgGroups.forEach((orgGroup, index) => {
          if (groupid === orgGroup.groupid) {
            orgGroups[index].alertCount = tmpOrgGroups[groupid];
          }
        });
      });

      forceUpdate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTabs,
    groupsResp,
    nodesList,
    selectedGroupIds,
  ]);

  const selectedNodesLength = useMemo(() => {
    let length = 0;
    let foundEmpty = false;

    [...lightingGroups, ...orgGroups].forEach(
      (group) => {
        if (selectedGroupIds.lightingGroupIds.includes(group.groupid)) {
          if (activeTabs === 0 && group.nodeList.length === 0) {
            foundEmpty = true;
          }

          length += group.nodeList.length;
        } else if (selectedGroupIds.orgGroupIds.includes(group.groupid)) {
          if (activeTabs === 1 && group.nodeList.length === 0) {
            foundEmpty = true;
          }

          length += group.nodeList.length;
        }
      },
    );

    setHasEmptySelectedGroup(foundEmpty);

    return length;
  }, [activeTabs, lightingGroups, orgGroups, selectedGroupIds.lightingGroupIds, selectedGroupIds.orgGroupIds]);

  const toolbarManualOverride = 1;
  const toolbarAssignFixture = 2;
  const toolbarReport = 4;
  const modalScheduleView = 3;

  const getSelectedGroups = (): LightsGroupsItem[] => {
    const isLightingGroupSelected = activeTabs === 0;
    const groupIds = isLightingGroupSelected ? selectedGroupIds.lightingGroupIds : selectedGroupIds.orgGroupIds;
    const groupTypeList = isLightingGroupSelected ? lightingGroups : orgGroups;
    const groupList: LightsGroupsItem[] = [];
    groupIds.forEach((groupid) => {
      const newGroup = groupTypeList.filter((l) => l.groupid === groupid)[0];
      const newConvertedGroup: LightsGroupsItem = {
        name: newGroup.name,
        id: newGroup.groupid,
        nodeList: newGroup.nodeList,
        schedules: newGroup.schedules,
        type: isLightingGroupSelected ? 'lighting' : 'organizational',
        description: newGroup.description,
        pdprofiles: newGroup.pdprofiles,
      };
      groupList.push(newConvertedGroup);
    });
    return groupList;
  };

  return (
    <>
      <Toolbar>
        <div className="toolbar-tabs__container-tabset">
          <div className="toolbar-tabs__container-tabset-group">
            <ToolbarHeading title="Groups" subtitle={selectedSite.name} />
          </div>
        </div>
        <ToolbarButtonContainer>
          {isNonReadOnly ? (
            <ToolbarButton
              toggleToolbarBtn={setActiveToolbarBtn}
              order={toolbarManualOverride}
              activeToolbarBtn={activeToolbarBtn}
              icon={<ManualOverrideIcon />}
              tooltipText="Manual override"
            >
              <ManualOverride
                selectedCustomer={selectedCustomer}
                selectedSite={selectedSite}
                selectedItems={activeTabs === 0 ? selectedGroupIds.lightingGroupIds : selectedGroupIds.orgGroupIds}
                listLength={activeTabs === 0 ? selectedGroupIds.lightingGroupIds.length : selectedGroupIds.orgGroupIds.length}
                activeToolbarBtn={activeToolbarBtn}
                closeManualOverwrite={() => setActiveToolbarBtn(0)}
                type="groups"
              />
            </ToolbarButton>
          ) : <></>}
          {isNonReadOnly ? (
            <ToolbarButton
              toggleToolbarBtn={setActiveToolbarBtn}
              order={toolbarAssignFixture}
              activeToolbarBtn={activeToolbarBtn}
              icon={<AddLightPoleIcon />}
              tooltipText="Assign fixture"
              shouldOpenModal={() => {
                if (hasEmptySelectedGroup) {
                  setActiveToolbarBtn(0);
                  addNotification({ type: 'error', message: 'Please select groups that contain nodes for this operation.' });
                }
              }}
            >
              <AssignFixture
                selectedCustomer={selectedCustomer}
                selectedSite={selectedSite}
                selectedItems={activeTabs === 0 ? selectedGroupIds.lightingGroupIds : selectedGroupIds.orgGroupIds}
                closeAssignFixture={() => setActiveToolbarBtn(0)}
                type="groups"
                selectedLength={activeTabs === 0 ? selectedGroupIds.lightingGroupIds.length : selectedGroupIds.orgGroupIds.length}
              />
            </ToolbarButton>
          ) : <></>}
          <ToolbarButton
            toggleToolbarBtn={setActiveToolbarBtn}
            order={toolbarReport}
            activeToolbarBtn={activeToolbarBtn}
            icon={<TotalEnergyIcon />}
            tooltipText="Reports"
          >
            <Reports
              type="groups-page"
              selectedGroupList={getSelectedGroups()}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              closeReports={() => setActiveToolbarBtn(0)}
            />
          </ToolbarButton>
        </ToolbarButtonContainer>
      </Toolbar>
      <div className="content">
        <div className="inner-content">
          <SplitPaneWrapper storageKey={storageKey} type="groups" setSplitPaneSize={setSplitPaneSize}>
            <>
              {site ? (
                <LightingMap
                  id="GroupsPageMap"
                  zoom={mapZoomLevel}
                  center={Utils.getSiteLatLng(site)}
                  data={filteredNodes.current}
                  featuresEnabled={{ alertsColorLabel: true, layersSelectionControl: true, selectionToolOnlyPopupBtn: true }}
                  mapSize={splitPaneSize}
                />
              ) : (<Loading />)}
            </>
            <div className="table table--groups">
              <div className="table__buttons">
                <button type="button" className={`table__tab ${activeTabs === 0 ? 'table__tab--active' : ''}`} onClick={() => setActiveTabs(0)}>
                  <LightingGroupIcon />
                  Lighting groups
                </button>
                <button type="button" className={`table__tab table__tab--org ${activeTabs === 1 ? 'table__tab--active' : ''}`} onClick={() => setActiveTabs(1)}>
                  <OrgGroupIcon />
                  Org groups
                </button>
                {isNonReadOnly && (<Button onClick={() => setToggleCreateGroup(true)} label="Create new" buttonSize="small" buttonType="primary" extraClasses="table__button" />)}
              </div>
              {activeTabs === 0 && (
              <LightingGroupsList
                data={lightingGroups}
                alerts={groupAlerts}
                selectedItems={selectedGroupIds.lightingGroupIds}
                setSelectedItems={setSelectedGroupIds}
                setOpenEditModal={setOpenEditModal}
                setEditGroup={setEditGroup}
                setOpenEnergyModal={setOpenEnergyModal}
                setEnergyGroup={setEnergyGroup}
                groupsSchedule={groupsSchedule}
                setGroupSchedule={setGroupSchedule}
                setOpenScheduleModal={setOpenScheduleModal}
                scheduledDriverLevels={scheduledDriverLevels}
              />
              )}
              {activeTabs === 1 && (
              <OrgGroupsList
                data={orgGroups}
                alerts={groupAlerts}
                selectedItems={selectedGroupIds.orgGroupIds}
                setSelectedItems={setSelectedGroupIds}
                setOpenEditModal={setOpenEditModal}
                setEditGroup={setEditGroup}
                setDeleteGroup={setDeleteGroup}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEnergyModal={setOpenEnergyModal}
                setEnergyGroup={setEnergyGroup}
              />
              )}
            </div>
          </SplitPaneWrapper>
          <TableFooter>
            <>
              <div>
                <TableFooterItem
                  selectedValuesLength={lightingGroups.map((group) =>
                    selectedGroupIds.lightingGroupIds
                      .includes(group.groupid)).filter(Boolean).length}
                  boldText="Lighting groups"
                  text="selected"
                />
              </div>
              <div className="table-footer__groups">
                <div>
                  <TableFooterItem
                    selectedValuesLength={orgGroups.map((group) =>
                      selectedGroupIds.orgGroupIds
                        .includes(group.groupid)).filter(Boolean).length}
                    boldText="Org groups"
                    text="selected"
                  />
                </div>
                <TableFooterItem
                  selectedValuesLength={selectedNodesLength}
                  boldText="Nodes"
                  text="selected"
                />
                <Button
                  buttonType="primary"
                  buttonSize="small"
                  extraClasses="table-footer__buttons"
                  onClick={() => resetSelectedGroupIds()}
                  label="Deselect all"
                />
              </div>
            </>
          </TableFooter>
        </div>
        {toggleCreateGroup && (
          <CreateGroup
            modalOpen={setToggleCreateGroup}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            scheduleResp={schedulesResp || []}
            updateGroupsList={updateGroupsList}
            isLightingGroupSelected={activeTabs === 0}
          />
        )}
        {(openEditModal || confirmDeleteGroup) && (
        <EditGroup
          groupType={activeTabs === 0 ? 'lighting' : 'org'}
          setOpenEditModal={setOpenEditModal}
          scheduleResp={schedulesResp || []}
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          editGroup={editGroup}
          confirmDeleteGroup={confirmDeleteGroup}
          setConfirmDeleteGroup={setConfirmDeleteGroup}
          openEditModal={openEditModal}
          updateGroupsList={updateGroupsList}
        />
        )}
        {openEnergyModal && (
        <EnergyModal
          selectedSite={selectedSite}
          selectedCustomer={selectedCustomer}
          selectedGroup={{
            id: energyGroup.groupid,
            name: energyGroup.name,
            nodeList: energyGroup.nodeList,
            schedules: energyGroup.schedules,
            type: energyGroup.type.includes('lighting') ? 'lighting' : 'organizational',
          } as LightsGroupsItem}
          setOpenEnergyModal={setOpenEnergyModal}
        />
        )}
        {openDeleteModal && (
          <DeleteGroup
            modalOpen={setOpenDeleteModal}
            deleteGroup={deleteGroup}
            selectedCustomer={selectedCustomer}
            selectedSite={selectedSite}
            updateGroupsList={updateGroupsList}
          />
        )}
        {openScheduleModal > 0 && groupSchedule && (
          <ScheduleModal
            activeSchedule={groupSchedule}
            openScheduleModal={modalScheduleView}
            setOpenScheduleModal={() => {
              setGroupSchedule(undefined);
              setOpenScheduleModal(0);
            }}
          />
        )}
      </div>
    </>
  );
}

export default GroupsPage;
