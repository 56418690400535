import React, { useEffect, useRef, useState } from 'react';
import { SelectNodesByFileProps } from '../../types/SelectNodesByFileProps';
import Modal from './Modal';
import Button from './Button';
import Utils from '../../utils/Utils';
import getHeaderProps from '../../utils/getHeaderProps';

import { ReactComponent as SuccessIcon } from '../../img/icons/success.svg';
import { ReactComponent as WarningIcon } from '../../img/icons/warning.svg';

function SelectNodesByFile(props: SelectNodesByFileProps): JSX.Element {
  const { children, selectedNodes, setSelectedNodes, nodesList, addClass, closeDropdown } = props;

  const [notFoundInNodeIDs, setNotFoundInNodeIDs] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(0);
  const modalAllNodesFound = 1;
  const modalNoneFound = 2;
  const modalSomeFound = 3;

  useEffect(() => {
    if (notFoundInNodeIDs.length > 0) {
      if (selectedNodes.size > 0) {
        setModalOpen(modalSomeFound);
      } else {
        setModalOpen(modalNoneFound);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notFoundInNodeIDs]);

  const inputFileSelectNodes = useRef<HTMLInputElement>(null);
  const handleFileUpload = () => {
    if (inputFileSelectNodes.current !== null) {
      inputFileSelectNodes.current.click();
    }
  };

  const onChangeSelectNodes = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const nodeListStr = e.target?.result as string;
        const nodeList = nodeListStr?.split(/\r\n|\n|\r/).filter((el) => el !== '');
        const found = new Map();
        const notFound: string[] = [];

        nodeList.forEach((node) => {
          const nodeStr = String(node.replace(/\s+/g, ''));
          if (nodesList.has(nodeStr)) {
            found.set(nodeStr, nodesList.get(nodeStr));
          } else {
            notFound.push(node);
          }
        });
        setSelectedNodes(found);
        setNotFoundInNodeIDs(notFound);

        if (inputFileSelectNodes !== null && inputFileSelectNodes.current !== null) {
          inputFileSelectNodes.current.value = '';
        }

        if (notFound.length === 0) {
          setModalOpen(modalAllNodesFound);
        }
      };
      reader.readAsText(file);
    }
  };

  const downloadNotFoundNodes = () => {
    const header = getHeaderProps('NotFoundNodeIds');
    const idList = notFoundInNodeIDs.map((nodeId) => ({ nodeId }));

    Utils.downloadCSV(header, idList, 'not_found_nodeids');
  };

  return (
    <>
      <button type="button" onClick={() => { handleFileUpload(); }} className={addClass}>
        { children }
      </button>
      <input type="file" id="file" ref={inputFileSelectNodes} accept=".csv" style={{ display: ' none' }} onChange={onChangeSelectNodes} />
      { modalOpen === modalAllNodesFound && (
        <Modal
          width="330"
          setModalOpen={() => { closeDropdown(); setModalOpen(0); }}
          title="Select nodes by file"
          primaryButtonLabel="Ok"
          primaryButtonAction={() => {
            setModalOpen(0);
            closeDropdown();
          }}
          className="select-byfile__success"
        >
          <div className="select-byfile__success-content">
            <SuccessIcon />
            <span>
              <span className="bold">{selectedNodes.size}</span>
              {' '}
              nodes are found.
            </span>
          </div>
          <div className="select-byfile__success-comment">Close the window to see these nodes selected in the list.</div>
        </Modal>
      )}
      { modalOpen === modalNoneFound && (
        <Modal
          width="330"
          setModalOpen={() => { closeDropdown(); setModalOpen(0); }}
          title="Select nodes by file"
          primaryButtonLabel="Ok"
          primaryButtonAction={() => { closeDropdown(); setModalOpen(0); }}
          className="select-byfile__warning"
        >
          <div className="select-byfile__warning-content">
            <WarningIcon />
            <span>None of the nodes were found.</span>
          </div>
        </Modal>
      )}
      { modalOpen === modalSomeFound && (
        <Modal
          width="330"
          setModalOpen={() => { closeDropdown(); setModalOpen(0); }}
          title="Select nodes by file"
          primaryButtonLabel="Ok"
          primaryButtonAction={() => { closeDropdown(); setModalOpen(0); }}
          className="select-byfile__mix"
        >
          <div className="select-byfile__warning-content">
            <WarningIcon />
            <span>
              The following
              <span className="bold">
                {' '}
                {notFoundInNodeIDs.length}
              </span>
              {' '}
              of
              <span className="bold">
                {' '}
                {notFoundInNodeIDs.length + selectedNodes.size}
              </span>
              {' '}
              nodes where not found.
            </span>
          </div>
          <div className="select-byfile__mix--id-list">
            {notFoundInNodeIDs.map((nodeId, i) => <span key={i}>{nodeId}</span>)}
          </div>
          <Button onClick={() => downloadNotFoundNodes()} buttonType="secondary" buttonSize="small">
            <span>Download missing nodes</span>
          </Button>
          <div className="select-byfile__success-content">
            <SuccessIcon />
            <span>
              <span className="bold">{selectedNodes.size}</span>
              {' '}
              nodes are found.
            </span>
          </div>
          <div className="select-byfile__success-comment">Close the window to see these nodes selected in the list.</div>
        </Modal>
      )}
    </>
  );
}

export default SelectNodesByFile;
