import React, { useEffect, useRef, useState } from 'react';
import SlideIn from '../../../Common/Components/SlideIn';
import { ReactComponent as FairIcon } from '../../../img/icons/fair.svg';
import { ReactComponent as PoorIcon } from '../../../img/icons/low-poor.svg';
import { ReactComponent as MiddleGoodIcon } from '../../../img/icons/middle-good.svg';
import { GenericSensorData } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import { useAppContext } from '../../../utils/AppContext';

function LightsPower(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite } = props;
  const siteTimezone = selectedSite.timezone || 'local';
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const displayedSensors = ['activePower', 'mainsVoltage', 'mainsCurrent', 'powerFactor', 'reactivePower'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const timestamp = useRef<number>(0);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);
  const { addNotification } = useAppContext();

  useEffect(() => {
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      [Sensors.getSensorId(sensor, selectedNode.model)],
    ));

    Promise.allSettled(sensorRequests).then((results) => {
      setRefreshLoadingSensors(false);

      if (timestamp.current <= currentDate) {
        const response = Sensors.transformSensorResults(results, reqSensors, selectedNode);

        const newData = Sensors.getSensorTableDataWithValueAndSensor(
          selectedItems,
          reqSensors,
          response,
          originalData,
        );

        setSensorData(newData[0]);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqSensors]);

  const getIcon = (sensorId: 'mainsVoltage' | 'powerFactor') => {
    const voltageGoodMin = 104;
    const voltageGoodMax = 294;
    const powerGood = 90;
    const powerPoor = 80;
    if (sensorId === 'mainsVoltage') {
      if (sensorData?.mainsVoltage?.value && (parseFloat(sensorData?.mainsVoltage.value) < voltageGoodMin || parseFloat(sensorData?.mainsVoltage.value) > voltageGoodMax)) {
        return (
          <Tooltip
            text="Poor"
          >
            <PoorIcon />
          </Tooltip>
        );
      } if (sensorData?.mainsVoltage?.value && (parseFloat(sensorData?.mainsVoltage.value) >= voltageGoodMin && parseFloat(sensorData?.mainsVoltage.value) <= voltageGoodMax)) {
        return (
          <Tooltip
            text="Good"
          >
            <MiddleGoodIcon />
          </Tooltip>
        );
      }
    } if (sensorId === 'powerFactor') {
      if (sensorData?.powerFactor?.value && parseFloat(sensorData?.powerFactor.value) >= powerGood) {
        return (
          <Tooltip
            text="Good"
          >
            <MiddleGoodIcon />
          </Tooltip>
        );
      } if (sensorData?.powerFactor?.value && parseFloat(sensorData?.powerFactor.value) > powerPoor && parseFloat(sensorData?.powerFactor.value) < powerGood) {
        return (
          <Tooltip
            text="Fair"
          >
            <FairIcon />
          </Tooltip>
        );
      } if (sensorData?.powerFactor?.value && parseFloat(sensorData?.powerFactor.value) <= powerPoor) {
        return (
          <Tooltip
            text="Poor"
          >
            <PoorIcon />
          </Tooltip>
        );
      }
    }
    return <></>;
  };

  return (
    <div className="power">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Power</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="custom-list-element sensors">
            <div className="list-element-title">Sensors</div>
            {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  setReqSensors(displayedSensors);
                  setRefreshLoadingSensors(true);
                }}
              />
            )}
            {(refreshLoadingSensors) && (
            <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
            )}

            <div className="slide-in__content">
              <SlideinListElementBig
                title="Active power"
                valueBold={sensorData?.activePower?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.activePower?.time, undefined, siteTimezone)}
                value="W"
                isFirstElement
              />
              <SlideinListElementBig
                title="Mains voltage"
                valueBold={sensorData?.mainsVoltage?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.mainsVoltage?.time, undefined, siteTimezone)}
                value="V"
                icon={getIcon('mainsVoltage')}
              />
              <SlideinListElementBig
                title="Mains current"
                valueBold={sensorData?.mainsCurrent?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.mainsCurrent?.time, undefined, siteTimezone)}
                value={Sensors.getConvertedUnits(sensorData?.mainsCurrent?.value, 'mA')?.unit || 'mA'}
              />
              <SlideinListElementBig
                title="Power factor"
                valueBold={sensorData?.powerFactor?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.powerFactor?.time, undefined, siteTimezone)}
                value="%"
                icon={getIcon('powerFactor')}
              />
              <SlideinListElementBig
                title="Reactive power"
                valueBold={sensorData?.reactivePower?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.reactivePower?.time, undefined, siteTimezone)}
                value="var"
                border="light"
              />
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsPower;
