import React, { useState } from 'react';
import Loading from '../../../Common/Components/Loading';
import Modal from '../../../Common/Components/Modal';
import { ClearSelectedAlarmsProps } from '../../../types/ClearSelectedAlarmsProps';
import { useAppContext } from '../../../utils/AppContext';
import { postRequest } from '../../../utils/fetch';

function ClearSelectedAlarms(props: ClearSelectedAlarmsProps): JSX.Element {
  const { setShowModal,
    selectedAlarms,
    setSelectedAlarms,
    selectedCustomer,
    selectedSite,
    updateAlerts } = props;

  const { addNotification } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="alarm-clear">
      <Modal
        title="Clear selected alarms"
        width="284"
        setModalOpen={() => setShowModal(0)}
        primaryButtonAction={async () => {
          setIsLoading(true);
          if (selectedAlarms.size > 0) {
            const idlist = Array.from(selectedAlarms.keys());
            try {
              const path = `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/dismiss`;
              const result = await postRequest(path, { alertids: idlist });

              if (!result.error) {
                addNotification({ type: 'success', message: `${selectedAlarms.size} alarms successfully cleared.` });
              } else {
                addNotification({ type: 'error', message: `Cannot clear the selected alarms. API reported error: ${result.error}` });
              }
              setShowModal(0);
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Clear alarms" operation is failed.' });
            }
            setIsLoading(false);
            setSelectedAlarms(new Map());
            setShowModal(0);
            updateAlerts();
          }
        }}
        secondaryButtonAction={() => setShowModal(0)}
        primaryButtonLabel="Clear"
        secondaryButtonLabel="Cancel"
      >
        {isLoading
          ? <Loading isLoading />
          : (
            <div className="content">
              <div className="content-text">
                <div>
                  <span>
                    Are you sure you want to clear these
                    <br />
                    <b>{selectedAlarms.size}</b>
                    <span> alarms?</span>
                  </span>
                </div>
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
}

export default ClearSelectedAlarms;
