/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Chart, { ChartConfiguration, ChartDataSets } from 'chart.js';
import 'chartjs-plugin-style';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../Common/Components/Loading';
import NoData from '../../../Common/Components/NoData';
import { EnergyGraphProps, EnergyRecord, FilledEnergyRecord } from '../../../types/EnergyGraphProps';

const blackColor = '#000000';
const tickLabelFontColor = '#747676';
const whiteColor = 'rgba(255, 255, 255, 0.9)';
const estimatedBorderColor = '#339FD7';
const columnColor = '#0088CE';
const estimatedColumnColor = '#99CFEB';

function EnergyReportGraph(props: EnergyGraphProps): JSX.Element {
  const { records, waiting, noDataAvailable, granularity } = props;
  interface SingleBarChartData {
    data: number,
    backgroundColor: Chart.ChartColor,
    borderColor: string,
    label: string,
    energyDataStatus: ('exact' |'estimated' | 'missing'),
  }

  const dateFormatMapForTooltip = new Map([
    ['15min', 'LLLL dd,yyyy HH:mm'],
    ['hour', 'LLLL dd,yyyy HH:mm'],
    ['day', 'LLLL dd,yyyy'],
    ['month', 'LLLL,yyyy'],
  ]);

  const dateFormatMapForXlabel = new Map([
    ['15min', 'MM/dd HH:mm'],
    ['hour', 'MM/dd HH:mm'],
    ['day', 'MM/dd'],
    ['month', 'MM/yyyy'],
  ]);

  const chartRef = useRef<HTMLCanvasElement>(null);

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const [config, setConfig] = useState<ChartConfiguration>();
  const [chart, setChart] = useState<Chart>();
  const [clientHeight, setClientHeight] = useState<number>(0);
  const [charDataArray, setChartDataArray] = useState<SingleBarChartData[]>([]);

  useEffect(() => {
    if (chartRef.current) {
      setContext(chartRef.current.getContext('2d') as CanvasRenderingContext2D);
    }
  }, [chartRef, noDataAvailable, waiting]);

  useEffect(() => {
    if (context) {
      setChartDataArray(records.map((d) => createBarChartDataFromEnergyRecord(d, context as CanvasRenderingContext2D, clientHeight)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, records, clientHeight]);

  const borderDash = [5, 5];
  const lineDash = [5, 0];
  const heightThreshold = 0.65;
  const consumptionDivider = 1000;

  useEffect(() => {
    setConfig({
      type: 'bar',
      data: {
        labels: charDataArray.map((d) => d.label),
        datasets: [{
          sensorId: '',
          sensorLabel: '',
          sensorName: '',
          nodeName: '',
          backgroundColor: charDataArray.map((d) => d.backgroundColor),
          hoverBackgroundColor: charDataArray.map((d) => d.backgroundColor),
          borderColor: charDataArray.map((d) => d.borderColor),
          energyDataStatus: charDataArray.map((d) => d.energyDataStatus),
          data: charDataArray.map((d) => d.data),
          borderWidth: 0,
          barPercentage: 0.95,
          categoryPercentage: 1,
        } as ChartDataSets],
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            top: 70,
          },
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            gridLines: {
              borderDash,
            },
            ticks: {
              autoSkipPadding: 30,
              autoSkip: true,
              maxRotation: 50,
              minRotation: 0,
              fontStyle: 'bold',
              fontSize: 12,
              fontColor: tickLabelFontColor,
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: false,
            },
            position: 'top',
            gridLines: {
              borderDash,
            },
            ticks: {
              beginAtZero: true,
              max: (Math.max(...charDataArray.map((d) => d.data))),
              fontStyle: 'bold',
              fontSize: 12,
              fontColor: tickLabelFontColor,
            },
          }],
        },
        events: ['mousemove'],
        onHover: (event: MouseEvent, chartElement) => {
          (event.target as HTMLElement).style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        onResize: (newSize) => {
          if (chart) {
            setClientHeight(newSize.height);
          }
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          yAlign: 'bottom',
          xAlign: 'center',
          position: 'nearest',
          displayColors: false,
          callbacks: {
            title(tooltips, _) {
              return tooltips.map((t) =>
                DateTime.fromFormat(t.xLabel as string, dateFormatMapForXlabel.get(granularity) || 'MM/dd/yyyy HH:mm')
                  .toFormat(dateFormatMapForTooltip.get(granularity) || 'LLLL dd, yyyy HH:mm'));
            },
            label(tooltips, data) {
              const index = tooltips.index as number;
              const value = Number(tooltips.yLabel);
              const toFixedThreshold = 0.01;
              const toFixedSmall = 4;
              const toFixedBig = 2;
              const valueRounded = value < toFixedThreshold ? value.toFixed(toFixedSmall) : value.toFixed(toFixedBig);
              return isEstimated(data, index) ? `${valueRounded} kWh (estimated)` : `${valueRounded} kWh`;
            },
          },
          filter(tooltipItem, data) {
            const index = tooltipItem.index as number;
            return notMissing(data, index);
          },
          titleFontColor: blackColor,
          titleFontSize: 14,
          bodyFontColor: blackColor,
          bodyFontSize: 12,
          bodyFontStyle: 'bold',
          footerFontColor: tickLabelFontColor,
          footerFontStyle: 'normal',
          backgroundColor: '#ffffff',
          borderColor: '#D8DADA',
          borderWidth: 1,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 10,
          cornerRadius: 0,
          shadowColor: '#00000033',
        },
      },
      plugins: [{
        afterDatasetDraw(chartP: Chart, args: any, _) {
          args.meta.data.forEach(drawBorder(chartP));
          drawYLabel(chartP.ctx as CanvasRenderingContext2D);
          if (clientHeight === 0 && chartP.height) {
            setClientHeight(chartP.height);
          }
        },
      }],
    } as ChartConfiguration);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charDataArray, granularity]);

  useEffect(() => {
    if (context && config) {
      chart?.destroy();
      setChart(new Chart(context as CanvasRenderingContext2D, config as ChartConfiguration));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, config]);

  const createBarChartDataFromEnergyRecord = (record: EnergyRecord, _context: CanvasRenderingContext2D, heightP: number) => {
    const formattedLabel = DateTime.fromFormat(record.tick_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat(dateFormatMapForXlabel.get(granularity) || 'MM/dd/yyyy HH:mm');
    const blueWhiteGradient = _context.createLinearGradient(0, (heightP * heightThreshold), 0, heightP);
    blueWhiteGradient.addColorStop(0, columnColor);
    blueWhiteGradient.addColorStop(1, whiteColor);

    const blueWhiteGradient2 = _context.createLinearGradient(0, (heightP * heightThreshold), 0, heightP);
    blueWhiteGradient2.addColorStop(0, estimatedColumnColor);
    blueWhiteGradient2.addColorStop(1, whiteColor);

    return (((record as FilledEnergyRecord).energy_consumption) ? ({
      data: (record as FilledEnergyRecord).energy_consumption / consumptionDivider,
      backgroundColor: (record as FilledEnergyRecord).overall_estimation === 'Yes' ? blueWhiteGradient2 : blueWhiteGradient,
      energyDataStatus: (record as FilledEnergyRecord).overall_estimation === 'Yes' ? 'estimated' : 'exact',
      borderColor: estimatedBorderColor,
      label: formattedLabel,
    } as SingleBarChartData) : ({
      data: 0,
      backgroundColor: blackColor,
      energyDataStatus: 'missing',
      borderColor: blackColor,
      label: formattedLabel,
    } as SingleBarChartData));
  };

  const drawBorder = (chartP: Chart) => (element: any) => {
    const borderWidth = 1;
    const marginDivider = 2;
    const ctxP = chartP.ctx as CanvasRenderingContext2D;
    const vm = element._view;
    if (charDataArray[element._index].energyDataStatus === 'estimated') {
      const half = vm.width / marginDivider;
      const left = vm.x - half + 1;
      const right = vm.x + half - 1;
      const top = (vm.y + 1);
      const width = right - left;
      const height = chartP.chartArea.bottom - top + (borderWidth / marginDivider) - 1;
      const blueWhiteGradient2 = ctxP.createLinearGradient(0, ctxP.canvas.clientHeight * heightThreshold, 0, ctxP.canvas.clientHeight);
      blueWhiteGradient2.addColorStop(0, estimatedBorderColor);
      blueWhiteGradient2.addColorStop(1, whiteColor);

      ctxP.beginPath();
      ctxP.lineWidth = borderWidth;
      ctxP.strokeStyle = blueWhiteGradient2;
      ctxP.setLineDash(borderDash);
      ctxP.moveTo(left, top);
      ctxP.lineTo(left, top + height);
      ctxP.moveTo(left, top);
      ctxP.lineTo(left + width, top);
      ctxP.moveTo(left + width, top);
      ctxP.lineTo(left + width, top + height);
      ctxP.stroke();
      ctxP.setLineDash(lineDash);
    }
  };

  function drawYLabel(ctxP: CanvasRenderingContext2D) {
    const x = 10;
    const y = 50;
    ctxP.save();
    ctxP.font = 'bold 12px Arial';
    ctxP.fillStyle = '#333333';
    ctxP.textAlign = 'left';
    ctxP.fillText('kWh', x, y);
    ctxP.textAlign = 'right';
    ctxP.restore();
  }

  function notMissing(data: Chart.ChartData, index: number): boolean {
    return (((data?.datasets as ChartDataSets[])[0]
      .energyDataStatus as string[])[index]) !== 'missing';
  }

  function isEstimated(data: Chart.ChartData, index: number) {
    return (((data?.datasets as ChartDataSets[])[0]
      .energyDataStatus as string[])[index]) === 'estimated';
  }

  return (
    <div className="ns-no-border energy-canvas-wrapper">
      {noDataAvailable && <NoData />}
      {waiting && <Loading />}
      {!noDataAvailable && !waiting && <canvas id="chart" ref={chartRef} />}
    </div>
  );
}
export default EnergyReportGraph;
