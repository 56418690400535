import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import { AutoOpenSelectboxListType } from '../../../../types/AutoOpenSelectboxPropsType';
import { AddNodesToOrgGroupProps } from '../../../../types/GroupActionsPropTypes';
import { LightsGroupsItem } from '../../../../types/LightsGroupProps';
import { postRequest } from '../../../../utils/fetch';
import GroupActionsOrgGroup from '../GroupActionsOrgGroup';

function AddNodesToOrgGroup(props: AddNodesToOrgGroupProps): JSX.Element {
  const { setOpenModal, selectedNodes, orgGroups, addNotification, selectedCustomer, selectedSite, updateGroup } = props;
  const [selectedOrgGroup, setSelectedOrgGroup] = useState<AutoOpenSelectboxListType>({ id: '', name: '' });

  return (
    <Modal
      title="Add nodes to org group"
      width="546"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonAction={async () => {
        try {
          if (selectedOrgGroup.id === '') {
            addNotification({ type: 'error', message: 'Please select an org group.' });
          } else {
            const groupObject = orgGroups.find((group) => group.id === selectedOrgGroup.id) as LightsGroupsItem;
            const newNodeList = Array.from(selectedNodes.keys());
            groupObject.nodeList = Array.from(new Set(groupObject.nodeList?.concat(newNodeList)));
            const { id, ...postBody } = groupObject;

            const result = await postRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${selectedOrgGroup.id}`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'success', message: 'Your "Add nodes to org group" operation has completed.' });
              updateGroup();
              setOpenModal(0);
            } else {
              addNotification({ type: 'error', message: `Your "Add nodes to org group" operation is failed: ${result.error}` });
            }
          }
        } catch (e) {
          addNotification({ type: 'error', message: 'Your "Add nodes to org group" operation is failed.' });
        }
      }}
    >
      <GroupActionsOrgGroup
        title="Add selected nodes"
        comment="Nodes will be added to all selected org group."
        selectedNodes={selectedNodes}
        orgGroups={orgGroups.filter((group) => Array.from(selectedNodes.keys()).some((id) => !group.nodeList?.includes(id)))}
        selectedOrgGroup={selectedOrgGroup}
        setSelectedOrgGroup={setSelectedOrgGroup}
      />
    </Modal>
  );
}

export default AddNodesToOrgGroup;
