import React, { useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import AccountForm from './AccountForm';
import EditAccountModalCustomTitle from './EditAccountModalCustomTitle';
import { useAppContext } from '../../../../../utils/AppContext';
import { postRequest } from '../../../../../utils/fetch';
import formValidation from '../../../../../utils/form/formValidation';
import { AccountModalProps, AccountPostProp } from '../../../../../types/AccountModal';
import { CustomerObject } from '../../../../../types/CustomerObject';
import { ModalType } from '../../../../../types/modal';

function AccountModal(props: AccountModalProps): JSX.Element {
  const { type, setModalOpen, currentPartner, partners, customer, updateCustomers } = props;
  const customerItem = customer as CustomerObject;
  const [accountData, setAccountData] = useState({
    name: customerItem.name,
    type: customerItem.type || '',
    street1: customerItem.street1,
    street2: customerItem.street2,
    city: customerItem.city,
    state: customerItem.state,
    postal_code: customerItem.postal_code,
    country: customerItem.country,
    contact_email: customerItem.contact_email,
    contact_phone: customerItem.contact_phone,
    contact_name: customerItem.contact_name,
    vzids: Array.isArray(customerItem.vzids) ? customerItem.vzids.join(',') : customerItem.vzids,
  });

  const [accountValidation, setAccountValidation] = useState({
    hasError: false,
    errorMessage: '',
    hasNameError: false,
    nameErrorMessage: '',
    hasNumError: false,
    numErrorMessage: '',
  });

  let modalProps: ModalType = {
    secondaryButtonLabel: 'Cancel',
    setModalOpen: () => setModalOpen(0),
  };
  if (type === 'create') {
    modalProps = {
      ...modalProps,
      title: 'Create account',
      primaryButtonLabel: 'Create',
    };
  } else {
    modalProps = {
      ...modalProps,
      title: 'Edit Account',
      primaryButtonLabel: 'Save',
      customTitle: <EditAccountModalCustomTitle item={customerItem} title="Edit account" />,
    };
  }

  const [selectedPartner, setSelectedPartner] = useState({ id: currentPartner?.orgid || '', name: currentPartner?.name || '' });
  const { addNotification } = useAppContext();

  const setName = (newName?: string) => {
    const name = newName !== undefined ? newName : accountData.name || '';
    const validation = formValidation(
      name,
      { required: true, min: 2, max: 256, noSpecCharacters: true },
    );

    setAccountData((oldValues) => ({
      ...oldValues,
      name,
    }));

    setAccountValidation((oldValues) => ({
      ...oldValues,
      hasNameError: validation.hasError,
      nameErrorMessage: validation.errorMsg,
    }));
  };

  function setVzids(vzids: string) {
    const validation = formValidation(
      vzids,
      { required: true, checkVzids: true },
    );

    setAccountValidation((oldValues) => ({
      ...oldValues,
      hasNumError: validation.hasError,
      numErrorMessage: validation.errorMsg,
    }));

    setAccountData((oldValues) => ({
      ...oldValues,
      vzids,
    }));
  }

  return (
    <Modal
      width="930"
      className="padding-30"
      {...modalProps}
      primaryButtonAction={async () => {
        if (accountData.name === '') {
          setName(accountData.name);
          return;
        }

        setVzids(accountData.vzids);

        try {
          const postBody: AccountPostProp = {
            name: accountData.name,
            type: accountData.type,
            street1: accountData.street1,
            street2: accountData.street2,
            city: accountData.city,
            state: accountData.state,
            postal_code: accountData.postal_code,
            country: accountData.country,
            contact_email: accountData.contact_email,
            contact_phone: accountData.contact_phone,
            contact_name: accountData.contact_name,
            vzids: accountData.vzids.split(',').map((id: string) => id.trim()),
          };

          const result = await postRequest(type === 'create' ? `/customers/${selectedPartner?.id}/of` : `/customers/${customerItem.orgid}`, postBody);

          if (!result.error) {
            addNotification({ type: 'success', message: `Your ${modalProps.title} operation is completed.` });
            updateCustomers();
            setModalOpen(0);
          } else {
            addNotification({ type: 'error', message: `Your ${modalProps.title} operation is failed: ${result.error}` });
          }
        } catch (error) {
          addNotification({ type: 'error', message: `Your ${modalProps.title} operation is failed.` });
        }
      }}
    >
      <AccountForm
        data={accountData}
        setAccount={setAccountData}
        partners={partners}
        selectedPartner={selectedPartner}
        setSelectedPartner={setSelectedPartner}
        currentPartner={currentPartner}
        validation={accountValidation}
      />
    </Modal>
  );
}

export default AccountModal;
