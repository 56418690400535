import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../Common/Components/Button';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { GenericSensorTableData } from '../../../types/GenericSensorData';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import RealTimeValues from '../Components/RealTimeValues';
import { useAppContext } from '../../../utils/AppContext';

function LightsPowerMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;
  const headers = getHeaderProps('LightsPower');

  const [powerMultiTableData, setPowerMultiTableData] = useState<GenericSensorTableData[]>();
  const { addNotification } = useAppContext();

  const displayedSensors = ['activePower', 'mainsVoltage', 'mainsCurrent', 'powerFactor', 'reactivePower'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const sensorListRealTimeValue = [
    { title: 'Active Power', key: 'activePower' },
    { title: 'Mains Voltage', key: 'mainsVoltage' },
    { title: 'Mains Current', key: 'mainsCurrent' },
    { title: 'Power factor', key: 'powerFactor' },
    { title: 'Reactive Power', key: 'reactivePower' },
  ];
  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);
  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });

  const timestamp = useRef<number>(0);

  useEffect(() => {
    setPowerMultiTableData(undefined);
    setReqSensors(displayedSensors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    const currentDate = Date.now();
    timestamp.current = currentDate;

    const originalData = powerMultiTableData;
    const tmpDisplayedData = powerMultiTableData?.map((rowData) => Object.entries(rowData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? { ...acc, ...{ [e[0]]: '--' } } : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: rowData.nodeid }));
    setPowerMultiTableData(tmpDisplayedData);

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      [Sensors.getSensorId(sensor, 'lco')],
    ));

    Promise.allSettled(sensorRequests).then((results) => {
      if (timestamp.current <= currentDate) {
        const response = Sensors.transformSensorResultsMultiNode(results, reqSensors, Array.from(selectedItems.values()));

        const newData = Sensors.getSensorTableWithDisplayedString(
          selectedItems,
          reqSensors,
          response,
          {},
          originalData,
        );

        setPowerMultiTableData(newData);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqSensors]);

  return (
    <div className="power-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Power</span>
            <div className="slide-in__links">
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenRealTimeValues(!openRealTimeValues);
                  }}
                  label="Get real time values"
                />
              </div>
            </div>
          </div>
          <div className="slide-in__content">
            <div className="table">
              <Table
                headers={headers}
                data={powerMultiTableData}
                fixColCount={1}
                dark
              />
            </div>
          </div>
        </>
      </SlideIn>
      {openRealTimeValues && (
      <RealTimeValues
        sensor={sensorRealTimeValue}
        sensorList={sensorListRealTimeValue}
        sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
        closeButton
        closeButtonAction={() => setOpenRealTimeValues(false)}
        refreshValue={() => {
          setReqSensors([sensorRealTimeValue.key]);
          setOpenRealTimeValues(false);
        }}
      />
      )}
    </div>
  );
}

export default LightsPowerMulti;
