/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import Chart, { ChartYAxe } from 'chart.js';
import 'chartjs-plugin-zoom';
import { PowerGraphProps } from '../../types/PowerGraphProps';

const blackColor = '#000000';
const grayColor = '#333333';
const whiteColor = '#ffffff';
const tickLabelFontColor = '#747676';
const maxTickFontColor = '#0088CE';
const minTickFontColor = '#99CFEB';

function PowerDrawGraph(props: PowerGraphProps): JSX.Element {
  const { rawDatasets, className, type = 'line' } = props;

  const ref = useRef<HTMLCanvasElement>(null);
  const chart = useRef<Chart>();
  const [clientHeight, setClientHeight] = useState<number>(0);

  const borderDash = [5, 5];
  const tickIndex = [0, 1, 5, 8, 10];

  const getYAxes = () => {
    const yAxes: ChartYAxe[] = [];

    yAxes.push(
      {
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          fontColor: tickLabelFontColor,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        afterDataLimits(axis: any) {
          // eslint-disable-next-line no-param-reassign
          axis.max += 1;
        },
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 12,
          fontColor: grayColor,
        },
      },
    );

    return yAxes;
  };

  function xMinDataset() {
    let minDataset: { x: string; y: any }[] = [];
    if (rawDatasets) {
      minDataset = [
        { x: '0', y: rawDatasets.MinPower0 || null },
        { x: '10', y: rawDatasets.MinPower10 || null },
        { x: '50', y: rawDatasets.MinPower50 || null },
        { x: '80', y: rawDatasets.MinPower80 || null },
        { x: '100', y: rawDatasets.MinPower100 || null }];
    }
    return minDataset;
  }

  function xMaxDataset() {
    let maxDataset: { x: string; y: any; }[] = [];
    if (rawDatasets) {
      maxDataset = [{ x: '0', y: rawDatasets.MaxPower0 || null },
        { x: '10', y: rawDatasets.MaxPower10 || null },
        { x: '50', y: rawDatasets.MaxPower50 || null },
        { x: '80', y: rawDatasets.MaxPower80 || null },
        { x: '100', y: rawDatasets.MaxPower100 || null }];
    }
    return maxDataset;
  }
  function isProfileMissing() : boolean {
    return (
      (rawDatasets.MinPower0 === '' || typeof rawDatasets.MinPower0 === 'undefined')
      && (rawDatasets.MinPower10 === '' || typeof rawDatasets.MinPower10 === 'undefined')
      && (rawDatasets.MinPower50 === '' || typeof rawDatasets.MinPower50 === 'undefined')
      && (rawDatasets.MinPower80 === '' || typeof rawDatasets.MinPower80 === 'undefined')
      && (rawDatasets.MinPower100 === '' || typeof rawDatasets.MinPower100 === 'undefined')
      && (rawDatasets.MaxPower0 === '' || typeof rawDatasets.MaxPower0 === 'undefined')
      && (rawDatasets.MaxPower10 === '' || typeof rawDatasets.MaxPower10 === 'undefined')
      && (rawDatasets.MaxPower50 === '' || typeof rawDatasets.MaxPower50 === 'undefined')
      && (rawDatasets.MaxPower80 === '' || typeof rawDatasets.MaxPower80 === 'undefined')
      && (rawDatasets.MaxPower100 === '' || typeof rawDatasets.MaxPower100 === 'undefined')
    );
  }

  function drawXYLabel(ctxP: CanvasRenderingContext2D) {
    const y = 9;
    const dY1 = 190;
    const dY2 = 205;
    const pX = 30;
    const dX = 120;

    ctxP.save();
    ctxP.font = 'bold 12px Arial';
    ctxP.fillStyle = '#333333';
    ctxP.textAlign = 'left';
    ctxP.fillText('Power (W)', pX, y);
    ctxP.fillText('Driver', ctxP.canvas.offsetWidth - dX, dY1);
    ctxP.fillText('level', ctxP.canvas.offsetWidth - dX, dY2);
    ctxP.textAlign = 'left';
    ctxP.restore();
  }

  useEffect(() => {
    if (rawDatasets) {
      chart.current = new Chart(ref.current as HTMLCanvasElement, {
        type,
        plugins: [{
          afterDatasetDraw(chartP: Chart, args: any, _) {
            drawXYLabel(chartP.ctx as CanvasRenderingContext2D);
            if (clientHeight === 0 && chartP.height) {
              setClientHeight(chartP.height);
            }
          },
        }],
        data: {
          datasets: [{
            label: '',
            data: xMinDataset(),
            lineTension: 0,
            borderColor: minTickFontColor,
            pointStyle: 'circle',
            fill: false,
            pointRadius: 4,
            pointHoverRadius: 4,
            backgroundColor: whiteColor,
            pointHoverBackgroundColor: minTickFontColor,
            borderWidth: 2,
          },
          {
            label: '',
            data: xMaxDataset(),
            lineTension: 0,
            borderColor: maxTickFontColor,
            pointStyle: 'circle',
            fill: false,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: maxTickFontColor,
            backgroundColor: whiteColor,
            borderWidth: 2,
          }],

        },
        options: {
          responsive: true,
          showLines: true,
          layout: {
            padding: {
              top: 20,
              right: 75,
            },
          },
          scales: {
            xAxes: [{
              type: 'linear',
              gridLines: {
                borderDash,
              },
              ticks: {
                autoSkip: true,
                fontColor: tickLabelFontColor,
                stepSize: 10,
                callback(value, index, values) {
                  if (tickIndex.includes(index)) {
                    return `${value}%`;
                  }
                  return '';
                },
                maxRotation: 0,
              },
              position: 'bottom',
              scaleLabel: {
                display: true,
                labelString: '',
                fontSize: 12,
                fontColor: grayColor,
              },
            }],
            yAxes: getYAxes(),
          },
          events: ['mousemove'],
          onHover: (event: MouseEvent, chartElement) => {
            (event.target as HTMLElement).style.cursor = chartElement[0] ? 'pointer' : 'default';
          },
          onResize: (newSize) => {
            if (chart) {
              setClientHeight(newSize.height);
            }
          },
          tooltips: {
            xPadding: 10,
            yPadding: 10,
            displayColors: false,
            callbacks: {
              title: (tooltips) => {
                const titleValue = tooltips[0].xLabel;
                return `Driver level ${titleValue}%`;
              },
              label(tooltips, data) {
                const value = Number(tooltips.yLabel);
                let labelVal = '';
                if (tooltips.datasetIndex === 0) {
                  labelVal = 'Min power';
                }
                if (tooltips.datasetIndex === 1) {
                  labelVal = 'Max power';
                }
                return `${labelVal} ${value}W`;
              },
            },
            titleFontColor: blackColor,
            titleFontSize: 12,
            bodyFontColor: blackColor,
            bodyFontSize: 12,
            bodyFontStyle: 'bold',
            footerFontColor: tickLabelFontColor,
            footerFontStyle: 'normal',
            backgroundColor: whiteColor,
            borderColor: '#D8DADA',
            borderWidth: 1,
            cornerRadius: 0,
          },
          legend: {
            display: false,
          },
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canvasH = 275;
  const canvasW = 550;

  return (
    <div className="chart">
      {(rawDatasets) && (
        <>
          <div className="minMaxWrapperText">
            <div className="minWrapper">
              <div className="minColor" />
              Min
            </div>
            <div className="maxWrapper">
              <div className="maxColor" />
              Max
            </div>
          </div>
          {(isProfileMissing()) && (
            <div className="isProfileMissing"> Power draw profile missing </div>
          )}
          <canvas
            ref={ref}
            className={className}
            height={canvasH}
            width={canvasW}
          />
        </>
      )}
    </div>
  );
}

export default PowerDrawGraph;
