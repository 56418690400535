import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import SelectBox from '../../../../Common/Components/SelectBox';
import { UpdateLightingGroupProps } from '../../../../types/GroupActionsPropTypes';
import { LightsGroupsItem } from '../../../../types/LightsGroupProps';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { postRequest } from '../../../../utils/fetch';
import Utils from '../../../../utils/Utils';
import GroupActionsModalContent from '../GroupActionsModalContent';
import GroupActionsTargetGroup from '../GroupActionsTargetGroup';

const selectBoxWidthWider = 410;

function UpdateLightingGroup(props: UpdateLightingGroupProps): JSX.Element {
  const {
    setOpenModal,
    selectedNodes,
    updateLightingGroup,
    setUpdateLightingGroup,
    selectedCustomer,
    selectedSite,
    addNotification,
    lightingGroups,
    updateNodesLightingGroups,
    updateGroup,
  } = props;

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  return (
    <Modal
      title="Redefine lighting group"
      width="615"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonDisabled={submitDisabled}
      primaryButtonAction={async () => {
        if (updateLightingGroup.list.title === '') {
          setUpdateLightingGroup((oldValues) => ({ ...oldValues, hasError: true }));
        } else {
          try {
            setSubmitDisabled(true);
            const groupObject = lightingGroups.find((group) => group.id === updateLightingGroup.list.key) as LightsGroupsItem;
            const newNodeList = Array.from(selectedNodes.keys());
            groupObject.nodeList = newNodeList;
            const { id, ...postBody } = groupObject;

            const result = await postRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${updateLightingGroup.list.key}`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Redefine lighting group" operation has started.  You can check the Jobs page for status.' });
              updateGroup();
              setOpenModal(0);
            } else {
              addNotification({ type: 'error', message: `Your "Redefine lighting group" operation has failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Redefine lighting group" operation has failed.' });
          }
        }
      }}
    >
      <div className="group-actions__modal-container">
        <SelectBox
          label="Target lighting group"
          onClick={(item: SelectBoxItemType) => {
            setUpdateLightingGroup({
              hasError: item.title === '',
              list: item,
            });
          }}
          selectedItemKey={updateLightingGroup.list.key || ''}
          list={Utils.arrayToSelectbox(lightingGroups || [], 'id', 'name')}
          title={updateLightingGroup.list.title || 'Select'}
          type="light"
          listWidth={selectBoxWidthWider}
          isHorizontal
          isRequired
          error={updateLightingGroup.hasError}
          errorMessage="Required"
        />
        <GroupActionsModalContent
          selectedNodesSize={selectedNodes.size}
          groupName={updateLightingGroup.list.title}
          title="Selected nodes"
          addClass="title-top"
          titleType="baseline"
        >
          <div className="double-targetgroups">
            <div className="double-targetgroups__content">
              <div className="double-targetgroups__content-title">
                <span>Move</span>
                <span
                  className="double-targetgroups__content-title-number"
                >
                  {updateNodesLightingGroups().add?.length}
                </span>
              </div>
              <GroupActionsTargetGroup
                hasGroupName={updateLightingGroup.list.title !== ''}
                groupName={updateLightingGroup.list.title}
              />
            </div>
            <div className="double-targetgroups__content default">
              <div className="double-targetgroups__content-title">
                <span>Move</span>
                <span
                  className="double-targetgroups__content-title-number"
                >
                  {updateNodesLightingGroups().remove?.length}
                </span>
              </div>
              <GroupActionsTargetGroup>
                <div className="target-group-info__title-name">
                  Default site lighting group
                </div>
              </GroupActionsTargetGroup>
            </div>
          </div>
        </GroupActionsModalContent>
      </div>
    </Modal>
  );
}

export default UpdateLightingGroup;
