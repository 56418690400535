import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import SelectBox from '../../../../Common/Components/SelectBox';
import Textbox from '../../../../Common/Components/Textbox';
import { CreateLigGroupWithNodesProps } from '../../../../types/GroupActionsPropTypes';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { postRequest } from '../../../../utils/fetch';
import Utils from '../../../../utils/Utils';
import GroupActionsModalContent from '../GroupActionsModalContent';
import formValidation from '../../../../utils/form/formValidation';

import { ReactComponent as ScrollRightIcon } from '../../../../img/icons/scroll-right.svg';

const selectBoxWidth = 350;

function CreateLigGroupWithNodes(props: CreateLigGroupWithNodesProps): JSX.Element {
  const { setOpenModal, selectedNodes, selectedCustomer, selectedSite, addNotification, schedulesResp, updateGroup } = props;

  const getDefaultSchedule = () => {
    const defaultSchedule = schedulesResp?.find((sch) => sch.name === 'Default schedule');
    if (defaultSchedule) {
      return { title: defaultSchedule.name, key: defaultSchedule.scheduleid };
    }
    return { title: '', key: '' };
  };

  const [putNodesLightingGroup, setPutNodesLightingGroup] = useState({
    name: '',
    description: '',
    schedule: (getDefaultSchedule() as SelectBoxItemType),
    hasError: false,
    errorMessage: '',
  });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const validationRules = { required: true, min: 2, max: 256, noSpecCharacters: true };
  function checkNameValidation(newName?: string) {
    const name = newName ?? putNodesLightingGroup.name;
    const validation = formValidation(
      name,
      validationRules,
    );
    setPutNodesLightingGroup((oldValues) => ({
      ...oldValues,
      name,
      hasError: validation.hasError,
      errorMessage: validation.errorMsg,
    }));
  }

  return (
    <Modal
      title="Create lighting group with nodes"
      width="580px"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonDisabled={submitDisabled}
      primaryButtonAction={async () => {
        if (putNodesLightingGroup.name === '') {
          checkNameValidation();
        } else if (!putNodesLightingGroup.hasError) {
          setSubmitDisabled(true);
          try {
            const schedule = putNodesLightingGroup.schedule.key ? [
              {
                scheduleid: putNodesLightingGroup.schedule.key,
                name: putNodesLightingGroup.schedule.title,
              },
            ] : [];

            const postBody = {
              name: putNodesLightingGroup.name,
              description: putNodesLightingGroup.description,
              nodeList: Array.from(selectedNodes.keys()),
              type: 'lighting',
              group_type: 'lco',
              schedules: schedule,
              pdprofiles: [],
            };
            if (postBody.nodeList.length === 0) {
              addNotification({ type: 'error', message: 'Your "Create lighting group with nodes" operation is failed: Node List is empty, it must contain at least one element. Please try again.' });
              setOpenModal(0);
            } else {
              const result = await postRequest(
                `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/create-group-add-nodes`,
                postBody,
              );
              if (!result.error) {
                addNotification({ type: 'info', message: 'Your "Create lighting group with nodes" operation has started.  You can check the Jobs page for status.' });
                updateGroup();
                setOpenModal(0);
              } else {
                addNotification({ type: 'error', message: `Your "Create lighting group with nodes" operation is failed: ${result.error}` });
              }
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Create lighting group with nodes" operation is failed.' });
          }
        }
      }}
    >
      <div className="group-actions__modal-container">
        <div className="content">
          <div className="content__container">
            <div className="content__container-nodes-size">
              <div className="content__container-nodes-selected">
                <span>{selectedNodes.size}</span>
                Nodes selected
              </div>
              <div className="margin-top-15">
                <ScrollRightIcon />
              </div>
            </div>
            <div className="content__container-form">
              <Textbox
                label="Lighting group name"
                value={putNodesLightingGroup.name}
                placeholder="Enter name"
                onChange={(event) => {
                  const name = event.target.value;
                  checkNameValidation(name);
                }}
                isRequired
                error={putNodesLightingGroup.hasError}
                errorMessage={putNodesLightingGroup.errorMessage}
                gridTemplateColumns="350px 350px"
              />
              <Textbox
                label="Description"
                placeholder="Enter description"
                value={putNodesLightingGroup.description}
                onChange={(event) =>
                  setPutNodesLightingGroup((oldValues) =>
                    ({ ...oldValues, description: event.target.value }))}
                gridTemplateColumns="350px 350px"
              />
              <SelectBox
                label="Schedule"
                onClick={(item: SelectBoxItemType) =>
                  setPutNodesLightingGroup((oldValues) => ({ ...oldValues, schedule: item }))}
                selectedItemKey={putNodesLightingGroup?.schedule?.key || ''}
                list={Utils.arrayToSelectbox(schedulesResp || [], 'scheduleid', 'name')}
                title={putNodesLightingGroup.schedule.title || 'no schedule'}
                type="light"
                listWidth={selectBoxWidth}
              />
            </div>
          </div>
        </div>
        <GroupActionsModalContent
          groupName={putNodesLightingGroup.name || ''}
          type="create-group"
        />
      </div>
    </Modal>
  );
}

export default CreateLigGroupWithNodes;
