/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import useSWR from 'swr';
import Button from '../../../Common/Components/Button';
import RebootModal from '../../../Common/Components/RebootModal';
import SlideIn from '../../../Common/Components/SlideIn';
import { ReactComponent as FairIcon } from '../../../img/icons/fair.svg';
import { ReactComponent as HighGoodIcon } from '../../../img/icons/high-good.svg';
import { ReactComponent as PoorIcon } from '../../../img/icons/low-poor.svg';
import { GenericSensorData, StaticSensorValue } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SiteObject } from '../../../types/SiteObject';
import { ConnectionStatusProps, SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import SlideinListElementSmall from '../Components/SlideinListElementSmall';
import LightsNetworkSessionHistory from './Components/LightsNetworkSessionHistory';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';
import { getRequest, postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';
import { TSData } from '../../../types/TSData';

function LightsNetwork(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite } = props;
  const siteTimezone = selectedSite.timezone || 'local';
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const [expand, setExpand] = useState(false);
  const [openSessionHistory, setOpenSessionHistory] = useState(0);
  const [openRebootModal, setOpenRebootModal] = useState(0);

  const { addNotification } = useAppContext();

  const isVerizonUser = Utils.isVerizonUser();
  const isSensityUserAdmin = Utils.isSensityUserAdmin();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatusProps>({} as ConnectionStatusProps);
  const [refreshLoadingIsConnected, setRefreshLoadingIsConnected] = useState(false);
  const connectedStatusPath = `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${selectedNode.nodeid}/connection_status`;

  const timeList = [{ title: '0:00 AM', key: '1' }];
  const startFromTime = timeList[0].title.split(':');
  let convertedStartTime = parseInt(startFromTime[0], 10);

  const everySecond = 2;
  const fullDay = 24;
  const fullDayHalfHours = 48;
  // eslint-disable-next-line no-plusplus
  for (let i = 2; i < fullDayHalfHours; i++) {
    if (i % everySecond === 0) {
      convertedStartTime += 1;
      startFromTime[1] = '00';
    } else {
      startFromTime[1] = '30';
    }

    if (i < fullDay) {
      timeList.push({ title: `${convertedStartTime}:${startFromTime[1]} AM`, key: i.toString() });
    } else {
      timeList.push({ title: `${convertedStartTime}:${startFromTime[1]} PM`, key: i.toString() });
    }
  }

  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(() => `/customers/${selectedCustomer.id}/sites`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  const [tsData, setTsData] = useState<TSData[]>([]);
  const displayedSensors = ['linkQuality', 'sinr', 'radioSignalStrength', 'modemBootCount'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const timestamp = useRef<number>(0);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);

  useEffect(() => {
    if (selectedSite.id && selectedNode.nodeid) {
      getRequest(connectedStatusPath)
        .then((responses) => {
          setConnectionStatus(responses);
          setRefreshLoadingIsConnected(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode.nodeid, refreshLoadingIsConnected]);

  useEffect(() => {
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      [Sensors.getSensorId(sensor, selectedNode.model)],
    ));

    Promise.allSettled(sensorRequests).then((results) => {
      setRefreshLoadingSensors(false);
      const commissioningFields = {
        tsNetStat: { value: '--' },
        activationDate: { value: '--', conversion: Utils.getConvertedDate },
        commissionedDate: { value: '--', conversion: Utils.getConvertedDate },
        ligLastReported: { value: '--', conversion: Utils.getConvertedDate },
        lastRegistration: { value: '--', conversion: Utils.getConvertedDate },
        firstInNetsense: { value: '--', conversion: Utils.getConvertedDate },
      } as {[index: string]: StaticSensorValue};

      if (timestamp.current <= currentDate) {
        const response = Sensors.transformSensorResults(results, reqSensors, selectedNode);

        const newData = Sensors.getSensorTableDataWithValueAndSensor(
          selectedItems,
          reqSensors,
          response,
          originalData,
          commissioningFields,
        );

        setSensorData(newData[0]);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    });

    try {
      postRequest<{ nodeids: string[] }, TSData[]>(
        `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/external_device_information`,
        { nodeids: [selectedNode.nodeid] },
      )
        .then((resp) => {
          if (resp.error) {
            throw Error(resp.error);
          }
          setTsData(resp.data);
        }).catch((error) => {
          addNotification({ type: 'warning', message: `Could not fetch external device information: '${error.message || error}'` });
        });
    } catch (error) {
      addNotification({ type: 'warning', message: `Could not fetch external device information: '${error.message || error}'` });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqSensors]);

  const getIcon = (sensorid: 'sinr' | 'radioSignalStrength' | 'linkQuality') => {
    const sinrGood = 5;
    const sinrPoor = -6;
    const rssGood = -102;
    const rssPoor = -120;
    const linkqGood = -10;
    const linkqPoor = -17;

    if ((sensorid === 'sinr' && sensorData?.sinr?.value && parseFloat(sensorData.sinr.value) >= sinrGood)
      || (sensorid === 'radioSignalStrength' && sensorData?.radioSignalStrength?.value && parseFloat(sensorData?.radioSignalStrength?.value) >= rssGood)
      || (sensorid === 'linkQuality' && sensorData?.linkQuality?.value && parseFloat(sensorData?.linkQuality?.value) >= linkqGood)) { // good
      return (
        <Tooltip
          text="Good"
        >
          <HighGoodIcon />
        </Tooltip>
      );
    } if ((sensorid === 'sinr' && sensorData?.sinr?.value && parseFloat(sensorData?.sinr?.value) < sinrPoor)
    || (sensorid === 'radioSignalStrength' && sensorData?.radioSignalStrength?.value && parseFloat(sensorData?.radioSignalStrength?.value) < rssPoor)
    || (sensorid === 'linkQuality' && sensorData?.linkQuality?.value && parseFloat(sensorData?.linkQuality?.value) < linkqPoor)) { // poor
      return (
        <Tooltip
          text="Poor"
        >
          <PoorIcon />
        </Tooltip>
      );
    } if ((sensorid === 'sinr' && sensorData?.sinr?.value && parseFloat(sensorData?.sinr.value) > sinrPoor && parseFloat(sensorData?.sinr?.value) < sinrGood)
    || (sensorid === 'radioSignalStrength' && sensorData?.radioSignalStrength?.value && parseFloat(sensorData?.radioSignalStrength?.value) > rssPoor && parseFloat(sensorData?.radioSignalStrength?.value) < rssGood)
    || (sensorid === 'linkQuality' && sensorData?.linkQuality?.value && parseFloat(sensorData?.linkQuality?.value) > linkqPoor && parseFloat(sensorData?.linkQuality?.value) < linkqGood)) { // fair
      return (
        <Tooltip
          text="Fair"
        >
          <FairIcon />
        </Tooltip>
      );
    }
    return <></>;
  };

  return (
    <div className="network">
      <SlideIn hasExpandButton expand={expand} setExpand={setExpand}>
        <>
          <div className="slide-in__title">
            <span>Network</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="slide-in__content network__content">
            <SlideinListElementBig
              title="Connected to platform"
              valueBold={connectionStatus ? (connectionStatus.isconnected ? 'Yes' : 'No') : '--'}
              date={connectionStatus && connectionStatus.since ? Utils.getConvertedDate(connectionStatus.since, undefined, site?.time_zone) : '--'}
              prefix="Since"
              border="light"
              isFirstElement
              refreshLoading={refreshLoadingIsConnected}
              refreshValue={() => {
                setRefreshLoadingIsConnected(true);
              }}
            />
            {isVerizonUser && (
            <SlideinListElementBig
              title="Network status"
              valueBold={tsData[0]?.connected || '--'}
              border="light"
              isFirstElement
            />
            )}
            {/*  <div className="slide-in__link btn-container">
                {isVerizonUser && (
                <Button
                  onClick={() => {
                    missingAPIcall();
                    setOpenSessionHistory(1);
                  }}
                  label="Connection history"
                />
                )}
              </div> */}
            <div className="custom-list-element sensors">
              <div className="list-element-title">Sensors</div>
              {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  setReqSensors(displayedSensors);
                  setRefreshLoadingSensors(true);
                }}
              />
              )}
              {(refreshLoadingSensors) && (
              <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
              )}
              <SlideinListElementBig
                title="Signal strength"
                valueBold={sensorData?.radioSignalStrength?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.radioSignalStrength?.time, undefined, siteTimezone)}
                value={sensorData?.radioSignalStrength?.unit || 'dBm'}
                icon={getIcon('radioSignalStrength')}
              />
              {isVerizonUser && (
              <>
                <SlideinListElementBig
                  title="SINR"
                  valueBold={sensorData?.sinr?.value || '--'}
                  date={Utils.getConvertedDate(sensorData?.sinr?.time, undefined, siteTimezone)}
                  value={sensorData?.sinr?.unit || 'dB'}
                  icon={getIcon('sinr')}
                />
                <SlideinListElementBig
                  title="Received link quality"
                  valueBold={sensorData?.linkQuality?.value || '--'}
                  date={Utils.getConvertedDate(sensorData?.linkQuality?.time, undefined, siteTimezone)}
                  value={sensorData?.linkQuality?.unit || 'dB'}
                  icon={getIcon('linkQuality')}
                  border="light"
                />
              </>
              )}
            </div>
          </div>
        </>
      </SlideIn>
      {expand && (
        <SlideIn isExpanded>
          <div className="slide-in__content network__content">
            <div className="slide-in__title" />
            <SlideinListElementSmall
              title="Activated"
              date={tsData[0]?.lastActivationDate ? Utils.getConvertedDate(tsData[0]?.lastActivationDate, undefined, siteTimezone) : '--'}
              isFirstElement
            />
            <SlideinListElementSmall
              title="Commissioned"
              date={Utils.getConvertedDate(selectedNode.commissionedDate, undefined, siteTimezone)}
            />
            <SlideinListElementSmall
              title="Last data report"
              date={Utils.getConvertedDate(selectedNode.ligLastReported, undefined, siteTimezone)}

            />
            {isVerizonUser && (
            <>
              {/* <SlideinListElementSmall
                title="Last registration"
                date="--"
              />
              <SlideinListElementSmall
                title="First platform connection"
                date="--"
                border="light"
              /> */}
              <div className="add-mg-top">
                <SlideinListElementSmall
                  title="Reboot modem"
                  date={sensorData?.modemBootCount?.time ? Utils.getConvertedDate(sensorData?.modemBootCount?.time, undefined, siteTimezone) : '--'}
                  prefix="Last"
                  border="light"
                >
                  <div className="add-prop">
                    <p>
                      Reboot count:
                      <span>{sensorData?.modemBootCount?.value || '--'}</span>
                    </p>
                    {isSensityUserAdmin
                  && (
                  <div className="slide-in__link">
                    <Button onClick={() => setOpenRebootModal(1)} label="Reboot" />
                  </div>
                  )}
                  </div>
                </SlideinListElementSmall>
              </div>
            </>
            )}
          </div>
        </SlideIn>
      )}
      {(openSessionHistory > 0) && (
      <LightsNetworkSessionHistory
        openModal={openSessionHistory}
        setOpenModal={setOpenSessionHistory}
      />
      )}
      {openRebootModal === 1 && (
        <RebootModal
          customerid={selectedCustomer.id}
          siteid={selectedSite.id}
          nodeList={[selectedNode.nodeid]}
          setModalOpen={() => setOpenRebootModal(0)}
        />
      )}
    </div>
  );
}

export default LightsNetwork;
