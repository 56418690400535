import React, { useEffect, useRef, useState } from 'react';
import SlideIn from '../../../Common/Components/SlideIn';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { GenericSensorData } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import SlideinListElementBig from '../Components/SlideinListElementBig';
import { ReactComponent as SyncIcon } from '../../../img/icons/sync.svg';

function LightsEnergy(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite } = props;
  const siteTimezone = selectedSite.timezone || 'local';
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const displayedSensors = ['cumulativeEnergyUse', 'cumulativeReactiveEnergyUse', 'cumulativeOnStateLuminaire', 'cumulativeOnStateNode'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);
  const timestamp = useRef<number>(0);
  const [sensorData, setSensorData] = useState<(GenericSensorData)>({ nodeid: selectedNode.nodeid } as GenericSensorData);
  const [refreshLoadingSensors, setRefreshLoadingSensors] = useState(false);

  const { addNotification } = useAppContext();

  useEffect(() => {
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const originalData = sensorData ? [{ ...sensorData }] : undefined;
    const tmpDisplayedData = Object.entries(sensorData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? acc : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: selectedNode.nodeid });
    setSensorData(tmpDisplayedData as GenericSensorData);

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      [selectedNode],
      [Sensors.getSensorId(sensor, selectedNode.model)],
    ));

    Promise.allSettled(sensorRequests).then((results) => {
      setRefreshLoadingSensors(false);
      if (timestamp.current <= currentDate) {
        const response = Sensors.transformSensorResults(results, reqSensors, selectedNode);

        const newData = Sensors.getSensorTableDataWithValueAndSensor(
          selectedItems,
          reqSensors,
          response,
          originalData,
        );

        setSensorData(newData[0]);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqSensors]);

  return (
    <div className="energy">
      <SlideIn>
        <>
          <div className="slide-in__title">
            <span>Energy</span>
            <div className="slide-in__title-subtitle">
              <span>Node ID</span>
              {selectedNode.nodeid}
            </div>
          </div>
          <div className="custom-list-element sensors">
            <div className="list-element-title">Sensors</div>
            {(!refreshLoadingSensors) && (
              <SyncIcon
                data-testid="icon"
                className="list-element__refresh"
                onClick={() => {
                  setReqSensors(displayedSensors);
                  setRefreshLoadingSensors(true);
                }}
              />
            )}
            {(refreshLoadingSensors) && (
            <SyncIcon data-testid="icon" className="list-element__refresh refreshLoading" />
            )}

            <div className="slide-in__content">
              <SlideinListElementBig
                title="Cumulative since"
                value={Utils.getConvertedDate(selectedNode.commissionedDate)}
                valueType="small"
                isFirstElement
              />
              <SlideinListElementBig
                title="Active energy use"
                valueBold={sensorData?.cumulativeEnergyUse?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.cumulativeEneryUse?.time, undefined, siteTimezone)}
                value={sensorData?.cumulativeEnergyUse?.unit || 'kWh'}
              />
              <SlideinListElementBig
                title="Reactive energy use"
                valueBold={sensorData?.cumulativeReactiveEnergyUse?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.cumulativeReactiveEnergyUse?.time, undefined, siteTimezone)}
                value={sensorData?.cumulativeReactiveEnergyUse?.unit || 'VARh'}
              />
              <SlideinListElementBig
                title="Fixture burn hours"
                valueBold={sensorData?.cumulativeOnStateLuminaire?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.cumulativeOnStateLuminaire?.time, undefined, siteTimezone)}
                value={sensorData?.cumulativeOnStateLuminaire?.unit || 'h'}
              />
              <SlideinListElementBig
                title="Node burn hours"
                valueBold={sensorData?.cumulativeOnStateNode?.value || '--'}
                date={Utils.getConvertedDate(sensorData?.cumulativeOnStateNode?.time, undefined, siteTimezone)}
                value={sensorData?.cumulativeOnStateNode?.unit || 'h'}
                border="light"
              />
              <div className="slide-in__content-info">
                <span>All values are cumulative</span>
                <Tooltip
                  addTooltipClass="energy-tooltip"
                  text="Energy use, node burn hours, and fixture burn hours are calculated over the lifetime of the installed node."
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      </SlideIn>
    </div>
  );
}

export default LightsEnergy;
