import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../Common/Components/Button';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import { GetAllRequests } from '../../../types/Fetch';
import { GenericSensorTableData, StaticSensorValue } from '../../../types/GenericSensorData';
import { NodeObject } from '../../../types/NodeObject';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { TSData } from '../../../types/TSData';
import { useAppContext } from '../../../utils/AppContext';
import { slideInWidth } from '../../../utils/constants';
import { getRequestAll, postRequest } from '../../../utils/fetch';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import RealTimeValues from '../Components/RealTimeValues';
import LightsNetworkSessionHistory from './Components/LightsNetworkSessionHistory';

function LightsNetworkMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite } = props;

  const { addNotification } = useAppContext();

  const isVerizonUser = Utils.isVerizonUser();
  const headers = isVerizonUser ? getHeaderProps('LightsNetwork') : getHeaderProps('LightsNetworkNonVerizonUsers');

  const [networkMultiTableData, setNetworkMultiTableData] = useState<GenericSensorTableData[]>();

  const timestamp = useRef<number>(0);

  const [openSessionHistory, setOpenSessionHistory] = useState(0);
  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);
  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });
  const internalUserSensorList = [
    { title: 'Signal strength', key: 'radioSignalStrength' },
    { title: 'SiNR', key: 'sinr' },
    { title: 'Received link quality', key: 'linkQuality' },
  ];
  const customerSensorList = [{ title: 'Signal strength', key: 'radioSignalStrength' }];

  const sensorListRealTimeValue = Utils.isVerizonUser() ? internalUserSensorList : customerSensorList;

  const displayedSensors = ['linkQuality', 'sinr', 'radioSignalStrength', 'modemBootCount'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);

  useEffect(() => {
    setNetworkMultiTableData(undefined);
    setReqSensors(displayedSensors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    updateAllData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  function updateAllData() {
    const currentDate = Date.now();
    timestamp.current = currentDate;
    const now = new Date();
    Promise.allSettled(
      [getConnectionData(selectedItems), getNetworkSensorData(now), getTsData(selectedItems)],
    ).then((results) => {
      if (results[0].status === 'fulfilled' && results[1].status === 'fulfilled' && results[2].status === 'fulfilled') {
        const newData = (results[1] as PromiseFulfilledResult<GenericSensorTableData[]>).value.map((value) => {
          const tsDataValue = (results[2] as PromiseFulfilledResult<TSData[]>).value;
          const activationDate = tsDataValue.find((d) => d.nodeid === value.nodeid)?.lastActivationDate;
          return {
            ...value,
            ...{
              platform: (results[0] as PromiseFulfilledResult<{ network: string; nodeid: string; }[]>).value.find((d) => d.nodeid === value.nodeid)?.network || '--',
            },
            ...{
              tsNetStat: tsDataValue.find((d) => d.nodeid === value.nodeid)?.connected || '--',
              activationDate: activationDate ? Utils.getConvertedDate(activationDate) : '--',
            },
          };
        });
        setNetworkMultiTableData(newData);
      } else {
        setNetworkMultiTableData([]);
      }
    });
  }

  async function getNetworkSensorData(now: Date): Promise<{ [index: string]: string; nodeid: string; }[]> {
    const commissioningFields = {
      tsNetStat: { value: '--' },
      activationDate: { value: '--', conversion: Utils.getConvertedDate },
      commissionedDate: { value: '--', conversion: Utils.getConvertedDate },
      ligLastReported: { value: '--', conversion: Utils.getConvertedDate },
      lastRegistration: { value: '--', conversion: Utils.getConvertedDate },
      firstInNetsense: { value: '--', conversion: Utils.getConvertedDate },
    } as {[index: string]: StaticSensorValue};

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      [Sensors.getSensorId(sensor, 'lco')],
    ));

    try {
      const results = await Promise.allSettled(sensorRequests);
      const response = Sensors.transformSensorResultsMultiNode(results, reqSensors, Array.from(selectedItems.values()));

      return Sensors.getSensorTableWithDisplayedString(
        selectedItems,
        reqSensors,
        response,
        commissioningFields,
      );
    } catch (e) {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    }

    return [];
  }

  async function getTsData(selectedItemsP: Map<string, NodeObject>): Promise<TSData[]> {
    let tsdata: TSData[] = [];
    try {
      const responses = await postRequest<{ nodeids: string[] }, TSData[]>(
        `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/external_device_information`,
        { nodeids: Array.from(selectedItemsP.keys()) },
      );
      if (responses.error) {
        throw Error(responses.error);
      }
      tsdata = responses.data;
    } catch (error) {
      addNotification({ type: 'warning', message: `Could not fetch external device information: '${error.message || error}'` });
    }
    return tsdata;
  }

  async function getConnectionData(selectedItemsP: Map<string, NodeObject>): Promise<{ nodeid: string; network: string; }[]> {
    const connectionRequests: GetAllRequests = Array.from(selectedItemsP.values())
      .map((node) => ({ path: `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/nodes/${node.nodeid}/connection_status` }));
    const responses = await getRequestAll(connectionRequests);
    return responses.results.filter((res) => res.status === 'fulfilled').map((res) => {
      const result = res as PromiseFulfilledResult<{ 'nodeid': string; isconnected: boolean; }>;
      return { nodeid: result.value.nodeid, network: result.value.isconnected ? 'Yes' : 'No' };
    });
  }

  function updateConnectionData() {
    getConnectionData(selectedItems).then((result) => {
      const newData = networkMultiTableData?.map((value) => (
        { ...value, ...{ platform: result.find((d) => d.nodeid === value.nodeid)?.network } }));
      setNetworkMultiTableData(newData as GenericSensorTableData[]);
    });
    getTsData(selectedItems).then((result) => {
      const newTsdata = networkMultiTableData?.map((value) => (
        { ...value, ...{ tsNetStat: result.find((d) => d.nodeid === value.nodeid)?.connected || '--', activationDate: result.find((d) => d.nodeid === value.nodeid)?.lastActivationDate || '--' } }
      ));
      setNetworkMultiTableData(newTsdata as GenericSensorTableData[]);
    });
  }

  function updateSensorData() {
    getNetworkSensorData(new Date()).then((result) => {
      const newData = networkMultiTableData?.map((value) => (
        { ...value, ...result.find((d) => d.nodeid === value.nodeid) }));
      setNetworkMultiTableData(newData);
    });
  }

  return (
    <div className="network-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Network</span>
            <div className="slide-in__links">
              <div className="slide-in__link">
                <Button
                  onClick={() => { updateConnectionData(); setOpenRealTimeValues(false); }}
                  label="Get new network status"
                />
              </div>
              <div className="slide-in__link">
                <Button
                  onClick={() => setOpenRealTimeValues(!openRealTimeValues)}
                  label="Get real time values"
                />
              </div>
              {Utils.isVerizonUser() && (
              <div className="slide-in__link">
                <Button
                  onClick={() => {
                    setOpenSessionHistory(1);
                    setOpenRealTimeValues(false);
                  }}
                  label="Connection history"
                />
              </div>
              )}
            </div>
          </div>
          <div className="slide-in__content">
            <div className="table">
              <Table
                headers={headers}
                data={networkMultiTableData}
                fixColCount={1}
                dark
              />
            </div>
          </div>
        </>
      </SlideIn>
      {(openSessionHistory > 0) && (
      <LightsNetworkSessionHistory
        openModal={openSessionHistory}
        setOpenModal={setOpenSessionHistory}
      />
      )}
      {openRealTimeValues && (
      <RealTimeValues
        sensor={sensorRealTimeValue}
        sensorList={sensorListRealTimeValue}
        sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
        closeButton
        closeButtonAction={() => setOpenRealTimeValues(false)}
        refreshValue={() => {
          setReqSensors([sensorRealTimeValue.key]);
          updateSensorData();
          setOpenRealTimeValues(false);
        }}
      />
      )}
    </div>
  );
}

export default LightsNetworkMulti;
