import React, { useState } from 'react';
import { DraggedDimmingPoint, DimmingPointsProps } from '../../../types/DimmingPointsProps';

import DimmingPointsTimeline from './DimmingPointsTimeline';
import DimmingPointsEditPanel from './DimmingPointsEditPanel';

function DimmingPoints(props: DimmingPointsProps): JSX.Element {
  const { scheduleData, setScheduleData, selectedSequenceIndex, isReadOnly } = props;
  const [draggedDimmingPoint, setDraggedDimmingPoint] = useState<DraggedDimmingPoint>();

  return (
    <div className="dimmingpoints">
      <div className="dimmingpoints__timeline">
        <div className="dimmingpoints__timeline-title">
          <span>{`Sequence ${selectedSequenceIndex + 1}`}</span>
        </div>
        <DimmingPointsTimeline
          draggedDimmingPoint={draggedDimmingPoint}
          setDraggedDimmingPoint={setDraggedDimmingPoint}
          scheduleData={scheduleData}
          setScheduleData={setScheduleData}
          selectedSequenceIndex={selectedSequenceIndex}
          isReadOnly={isReadOnly}
        />
      </div>
      <DimmingPointsEditPanel
        draggedDimmingPoint={draggedDimmingPoint}
        setDraggedDimmingPoint={setDraggedDimmingPoint}
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
        selectedSequenceIndex={selectedSequenceIndex}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

export default DimmingPoints;
