/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime } from 'luxon';
import React from 'react';
import { ReactComponent as LocationIcon } from '../img/icons/location.svg';
import { ReactComponent as NotWorkingIcon } from '../img/icons/not-working.svg';
import { ReactComponent as SuccessIcon } from '../img/icons/success.svg';
import { AlertObject, AlertNodeData, AlertsNodesObjectWithAlertCounts } from '../types/AlertObject';
import { CustomerObject } from '../types/CustomerObject';
import { GroupObject, GroupObjectWithAlerts, GroupsNodesObject } from '../types/GroupObject';
import { LightsGroupsItem } from '../types/LightsGroupProps';
import { GroupNodesPropsType } from '../types/LightsPageProps';
import { NodeObject } from '../types/NodeObject';
import { ScheduleObject } from '../types/ScheduleObject';
import { ScheduleDimmingPoint, ScheduleTimelineObject } from '../types/ScheduleTimelineProps';
import { UfalarmObject } from '../types/UfalarmObject';
import { sensitySystemsOrgId } from './constants';
import { FirmwareObject } from '../types/FirmwareObjects';
import { getRequest } from './fetch';
import { nodePropMap } from './propMaps';
import Utils from './Utils';
import { ReactComponent as AlertIconMinor } from '../img/icons/alert-icon-minor.svg';
import { ReactComponent as AlertIconMajor } from '../img/icons/alert-icon-major.svg';
import { ReactComponent as AlertIconCritical } from '../img/icons/alert-icon-critical.svg';

const alertConfig: Record <string, {status: 'Clear'| 'Major' | 'Critical' | 'Minor', icon: JSX.Element, index: number}> = {
  Clear: { status: 'Clear', icon: <></>, index: 0 },
  Minor: { status: 'Minor', icon: <AlertIconMinor />, index: 1 },
  Major: { status: 'Major', icon: <AlertIconMajor />, index: 2 },
  Critical: { status: 'Critical', icon: <AlertIconCritical />, index: 3 },
};

const alertSeverities: Record<string, string> = {
  OverPower: 'Critical',
  UnderPower: 'Critical',
  MCUFailure: 'Critical',
  EEPROMFailure: 'Critical',
  LightSensorFailure: 'Critical',
  PowerMeterFailure: 'Major',
  FirmwareUpgradeFailure: 'Minor',
  GPSFailure: 'Minor',
  NodeLocationChangedFailure: 'Minor',
  TemperatureSensorFailure: 'Minor',
  ThreadFailure: 'Minor',
  WarmStartFailure: 'Minor',
  DisconnectLessThan2d: 'Minor',
  DisconnectMoreThan2d: 'Critical',
};

function nodesWithCustomAttributesFetcherFn(url: string): Promise<any> {
  let newUrl = '';

  if (url.includes('?')) {
    newUrl = `${url}&showCustomFields=true`;
  } else {
    newUrl = `${url}?showCustomFields=true`;
  }

  return nodesFetcherFn(newUrl);
}

function nodesFetcherFn(url: string): Promise<any> {
  const headers: HeadersInit = new Headers();
  headers.set('x-enable-semantic-compression', 'true');

  return getRequest(url, { headers }, (data: { data: Array<NodeObject> }): Array<NodeObject> => {
    let nodes: Array<NodeObject> = [];

    if (data?.data) {
      nodes = Utils.decodeSemanticCompressed(data.data, nodePropMap);
    }

    return nodes;
  });
}

function alertsFetcherFn(url: string): Promise<any> {
  return getRequest(url, {}, (data: { data: Array<AlertObject> }): Array<AlertObject> => data.data);
}

function nodeNamesFn(nodesResp: NodeObject[]): Map<string, string> {
  const tmpMap = new Map();
  nodesResp.forEach((node: NodeObject) => {
    tmpMap.set(node.nodeid, node.name);
  });
  return tmpMap;
}

function nodeExtendedPropsFn(
  nodesResp: NodeObject[],
  groupsNodes: GroupNodesPropsType,
  timeZone?: string,
  alertResp?: AlertsNodesObjectWithAlertCounts,
  setPinnedNode?: (node: NodeObject | undefined) => void,
  setZoomedNode?: (node: NodeObject | undefined) => void,
): Map<string, NodeObject> {
  const successIcon = <SuccessIcon fill="#00AC3E" />;
  const alertIcon = <NotWorkingIcon fill="#ED7000" />;
  const tmpMap = new Map();

  nodesResp.forEach((origNode: NodeObject) => {
    const node = { ...origNode };
    const highestSeverityAlert = getHighestSeverityOfAlerts(node.nodeid, alertResp);
    const nodeStatusOk = highestSeverityAlert?.status === 'Clear';
    node.location = (
      <LocationIcon
        fill="#AFAFAF"
        className="location-icon"
        onMouseEnter={() => {
          if (setPinnedNode) {
            setPinnedNode(node);
          }
        }}
        onMouseLeave={() => {
          if (setPinnedNode) {
            setPinnedNode(undefined);
          }
        }}
        onClick={() => {
          if (setZoomedNode) {
            setZoomedNode(node);
          }
        }}
      />
    );
    node.modelName = Utils.getModelName(node.model);
    node.type = Utils.getModelType(node.model);
    node.statusTableSort = nodeStatusOk ? 0 : 1;
    node.status = nodeStatusOk ? successIcon : alertIcon;
    node.lightinggroup = groupsNodes[node.nodeid] ? groupsNodes[node.nodeid].lightinggroup.name : '';
    node.lightinggroupid = groupsNodes[node.nodeid] ? groupsNodes[node.nodeid].lightinggroup.id : '';
    node.orggroup = groupsNodes[node.nodeid] ? groupsNodes[node.nodeid].orggroups.map((group) => group.name).join(', ') : '';
    node.mapStatus = highestSeverityAlert?.status;
    node.mapSelected = false;
    node.ligLastReportedTableSort = Date.parse(node.ligLastReported) || 0;
    node.ligLastReported = Utils.getConvertedDate(node.ligLastReported, undefined, timeZone);
    node.declaredTableSort = Date.parse(node.declared) || 0;
    node.declared = Utils.getConvertedDate(node.declared, undefined, timeZone);
    node.activationDateTableSort = Date.parse(node.activationDate) || 0;
    node.activationDate = Utils.getConvertedDate(node.activationDate, undefined, timeZone);
    node.gpsLastReportedTimestampTableSort = Date.parse(node.gpsLastReportedTimestamp) || 0;
    node.gpsLastReportedTimestamp = Utils.getConvertedDate(node.gpsLastReportedTimestamp, undefined, timeZone);
    node.commissionedDateTableSort = Date.parse(node.commissionedDate) || 0;
    node.commissionedDate = Utils.getConvertedDate(node.commissionedDate, undefined, timeZone);

    tmpMap.set(node.nodeid, node);
  });

  return tmpMap;
}

function groupsNodesFn(groupsResp: GroupObject[] | undefined): {
  groupsNodes: GroupNodesPropsType;
  lightingGroups: Array<LightsGroupsItem>;
  orgGroups: Array<LightsGroupsItem>;
} {
  const tmpGroupsNodes: GroupNodesPropsType = {};
  const tmpLightingGroups: Array<LightsGroupsItem> = [];
  const tmpOrgGroups: Array<LightsGroupsItem> = [];

  if (Array.isArray(groupsResp)) {
    groupsResp.forEach((group) => {
      const groupData: LightsGroupsItem = {
        id: group.groupid,
        name: group.name,
        nodeList: group.nodeList,
        schedules: group.schedules,
        type: group.type,
        description: group.description,
        pdprofiles: group.pdprofiles,
      };
      group.nodeList.forEach((nodeId) => {
        if (!tmpGroupsNodes[nodeId]) {
          tmpGroupsNodes[nodeId] = { orggroups: [], lightinggroup: { id: '', name: '' } };
        }

        if (group.type === 'organizational') {
          tmpGroupsNodes[nodeId].orggroups.push(groupData);
        } else {
          tmpGroupsNodes[nodeId].lightinggroup = groupData;
        }
      });

      if (group.type === 'organizational') {
        tmpOrgGroups.push(groupData);
      } else {
        tmpLightingGroups.push(groupData);
      }
    });
  }

  return {
    groupsNodes: tmpGroupsNodes,
    lightingGroups: tmpLightingGroups,
    orgGroups: tmpOrgGroups,
  };
}

function groupsNodesWithAlertsFn(groupsResp: GroupObject[] | undefined): {
  groupsNodes: GroupsNodesObject;
  lightingGroups: Array<GroupObjectWithAlerts>;
  orgGroups: Array<GroupObjectWithAlerts>;
} {
  const tmpGroupsNodes: GroupsNodesObject = {};
  const tmpLightingGroups: Array<GroupObjectWithAlerts> = [];
  const tmpOrgGroups: Array<GroupObjectWithAlerts> = [];

  if (Array.isArray(groupsResp)) {
    groupsResp.forEach((group: GroupObject) => {
      const groupData = {
        id: group.groupid,
        name: group.name,
        nodeList: group.nodeList,
        schedules: group.schedules,
      };
      group.nodeList.forEach((nodeId) => {
        if (!tmpGroupsNodes[nodeId]) {
          tmpGroupsNodes[nodeId] = { orggroups: [], lightinggroup: { id: '', name: '' } };
        }

        if (group.type === 'organizational') {
          tmpGroupsNodes[nodeId].orggroups.push(groupData);
        } else {
          tmpGroupsNodes[nodeId].lightinggroup = groupData;
        }
      });

      if (group.type === 'organizational') {
        tmpOrgGroups.push({ ...group, alertCount: 0 });
      } else {
        tmpLightingGroups.push({ ...group, alertCount: 0 });
      }
    });
  }

  return {
    groupsNodes: tmpGroupsNodes,
    lightingGroups: tmpLightingGroups,
    orgGroups: tmpOrgGroups,
  };
}

function schedulesGroupsFn(schedulesResp: ScheduleObject[] | undefined): Map<string, string[]> {
  const tmpMap: Map<string, string[]> = new Map();

  if (Array.isArray(schedulesResp)) {
    schedulesResp.forEach((schedule) => {
      tmpMap.set(schedule.scheduleid, schedule.groups.map((group) => group.groupid));
    });
  }

  return tmpMap;
}

function ufnamesActionsFn(ufalarmsResp: UfalarmObject[] | undefined): Record<string, string> {
  const tmpObj: Record<string, string> = {};

  if (Array.isArray(ufalarmsResp)) {
    ufalarmsResp?.forEach((ufalarm) => {
      tmpObj[ufalarm.ufname] = ufalarm.action || 'Contact Verizon support by email at IoTtechsupport@verizonwireless.com.';
    });
  }

  return tmpObj;
}

function groupsScheduleFn(groupsResp: GroupObject[] | undefined, schedulesResp: ScheduleObject[] | undefined): Map<string, ScheduleObject> {
  const tmpMap: Map<string, ScheduleObject> = new Map();

  if (Array.isArray(groupsResp) && Array.isArray(schedulesResp)) {
    groupsResp.forEach((group) => {
      schedulesResp.forEach((schedule) => {
        if (group.schedules.find((grpSchedule) => schedule.scheduleid === grpSchedule.scheduleid)) {
          tmpMap.set(group.groupid, schedule);
        }
      });
    });
  }

  return tmpMap;
}

function getScheduledDriverLevels(currDate: Date, sunTimes: { sunrise: number, sunset: number }, schedulesResp?: ScheduleObject[]): Map<string, number> {
  const tmpMap = new Map<string, number>();

  const timelines = getScheduleTimelineFn(currDate, sunTimes, schedulesResp);
  const h = currDate.getHours();
  const m = currDate.getMinutes();
  const minutesInHour = 60;
  const minutesOfDay = h * minutesInHour + m;

  timelines.forEach((timeline, scheduleid) => {
    tmpMap.set(scheduleid, timeline[minutesOfDay]?.level || 0);
  });

  return tmpMap;
}

function getScheduleTimelineFn(
  selectedDate: Date,
  sunTimes: { sunrise: number, sunset: number },
  schedulesResp?: ScheduleObject[],
  groupsSchedule?: Map<string, ScheduleObject>,
  filteredGroupIds?: Set<string>,
): ScheduleTimelineObject {
  const tmpMap: ScheduleTimelineObject = new Map();

  if (groupsSchedule && groupsSchedule.size > 0 && filteredGroupIds && filteredGroupIds.size > 0) {
    groupsSchedule.forEach((schedule, groupId) => {
      if (filteredGroupIds.has(groupId)) {
        tmpMap.set(groupId, calcScheduleTimeline(schedule, selectedDate, sunTimes));
      }
    });
  } else if (schedulesResp && schedulesResp.length > 0) {
    schedulesResp.forEach((schedule) => {
      tmpMap.set(schedule.scheduleid, calcScheduleTimeline(schedule, selectedDate, sunTimes));
    });
  }

  return tmpMap;
}

function calcScheduleTimeline(schedule: ScheduleObject, selectedDate: Date, sunTimes: { sunrise: number, sunset: number }): { photocell: boolean, level: number }[] {
  const dimmingPoints: ScheduleDimmingPoint[] = [];
  let foundIndex = -1;

  // find a special date, or matching day for the selected day
  for (let i = 0; i < schedule.events.length; i += 1) {
    if (schedule.events[i].date && schedule.events[i].date === selectedDate) {
      foundIndex = i;
      break;
    }
    if (schedule.events[i].days?.includes(DateTime.fromJSDate(selectedDate).toFormat('ccc').toLowerCase())) {
      foundIndex = i;
    }
  }

  // if matching date/day found, get dimming points
  if (foundIndex >= 0) {
    schedule.events[foundIndex].actions.forEach((scheduleAction) => {
      dimmingPoints.push({
        photocell: scheduleAction.photocell_enabled,
        level: scheduleAction.level,
        time: Utils.parseScheduleActionTime(scheduleAction.time, sunTimes),
      });
    });
  }

  if (dimmingPoints.length === 0) {
    // must be all-day photocell
    dimmingPoints[0] = {
      photocell: true,
      level: schedule.events[0].photocell_highLevel,
      time: 0,
    };
  } else {
    // put the dimming points in ascending order by time
    dimmingPoints.sort((a, b) => {
      if (a.time > b.time) return 1;
      if (a.time < b.time) return -1;
      return 0;
    });
  }

  // set the timeline for the group
  const tmpTimeline: { photocell: boolean, level: number }[] = [];
  const lastDimmingPoint = dimmingPoints[dimmingPoints.length - 1];
  const minutesInDay = 1440;

  for (let h = 0, dp = 0; h < minutesInDay; h += 1) {
    let dimmingPoint: ScheduleDimmingPoint;

    // use last dimming point until we reach first dimming point (from midnight to the first dimming point)
    if (dp === 0 && dimmingPoints[0].time > h) {
      dimmingPoint = { ...lastDimmingPoint };
    } else {
      // switch to next dimming point when we have reached the next dimming point
      if (dimmingPoints[dp + 1] && dimmingPoints[dp + 1].time === h) {
        dp += 1;
      }

      dimmingPoint = { ...dimmingPoints[dp] };
    }

    // set photocell enabled minutes to set level to 0 between sunrise and sunset
    if (dimmingPoint.photocell && h >= sunTimes.sunrise && h <= sunTimes.sunset) {
      tmpTimeline[h] = {
        photocell: true,
        level: 0,
      };
    } else {
      tmpTimeline[h] = {
        photocell: dimmingPoint.photocell,
        level: dimmingPoint.level,
      };
    }
  }

  return tmpTimeline;
}

function partnersCustomersFn(customers: CustomerObject[] | undefined): {
  partners: Map<string, CustomerObject>,
  customersMap: Map<string, CustomerObject>,
  partnerCustomers: Map<string, CustomerObject[]>,
} {
  const partners: Map<string, CustomerObject> = new Map();
  const customersMap: Map<string, CustomerObject> = new Map();
  const partnerCustomers: Map<string, CustomerObject[]> = new Map();
  const tmpMapForCustomerWithoutPartners: Map<string, CustomerObject[]> = new Map(); // for customers without partner

  if (customers?.length) {
    customers.forEach((customer) => {
      if (customer.type === 'partner' && customer.orgid !== sensitySystemsOrgId) {
        partners.set(customer.orgid, customer);
        if (!partnerCustomers.has(customer.orgid)) {
          partnerCustomers.set(customer.orgid, []);
        }
      } else if (customer.type !== 'partner') {
        if (customer.po && customer.po !== sensitySystemsOrgId) {
          const tmpArr = partnerCustomers.get(customer.po) || [];
          partnerCustomers.set(customer.po, [...tmpArr, customer]);
        } else {
          const tmpArr = tmpMapForCustomerWithoutPartners.get('') || [];
          tmpMapForCustomerWithoutPartners.set('', [...tmpArr, customer]);
        }

        customersMap.set(customer.orgid, customer);
      }
    });

    partnerCustomers.set('', tmpMapForCustomerWithoutPartners.get('') || []);
  }

  return {
    partners,
    customersMap,
    partnerCustomers,
  };
}

function firmwareFetcherFn(url: string): Promise<any> {
  return getRequest(url, {}, (data: Array<FirmwareObject>): Array<FirmwareObject> => {
    let firmwareList: Array<FirmwareObject> = [];

    if (data && data.length) {
      firmwareList = data.filter((fw) => !fw.type || fw.type === 'lco' || fw.type.startsWith('unode'));
    }

    return firmwareList;
  });
}

function calculateDisplayedAlerts(alertsResp: AlertsNodesObjectWithAlertCounts): { name: string; nodeIDs?: AlertNodeData[]; count: number; alertType: string; }[] {
  return Object.keys(alertsResp.alerts || {}).map((alertType) => {
    const isAlertOld = (updatedAt: number) => {
      const difference = DateTime.fromMillis(Date.now()).diff(DateTime.fromMillis(updatedAt * 1000), 'days').days;
      return difference > 2;
    };
    const groupByDate = (array: AlertNodeData[]) => array.reduce((acc, value) => (isAlertOld(value.updatedAt)
      ? { new: acc.new, old: [...acc.old, value] } : { new: [...acc.new, value], old: acc.old }), { new: [] as AlertNodeData[], old: [] as AlertNodeData[] });

    if (alertType === 'Disconnect') {
      return [
        {
          ...{ alertType: 'DisconnectMoreThan2d' },
          ...{
            name: 'Node unreachable > 2 days',
            nodeIDs: groupByDate(alertsResp?.alerts.Disconnect.nodeIDs || []).old,
            count: groupByDate(alertsResp?.alerts.Disconnect.nodeIDs || []).old.length,
          },
        },
        {
          ...{ alertType: 'DisconnectLessThan2d' },
          ...{
            name: 'Node unreachable < 2 days',
            nodeIDs: groupByDate(alertsResp?.alerts.Disconnect.nodeIDs || []).new,
            count: groupByDate(alertsResp?.alerts.Disconnect.nodeIDs || []).new.length,
          },
        },
      ];
    }
    return [{ ...{ alertType }, ...alertsResp?.alerts[alertType] }];
  })
    .flat()
    .filter((array) => array.count !== 0);
}

function getHighestSeverityOfAlerts(nodeId: string, alertResp?: AlertsNodesObjectWithAlertCounts): {status: 'Critical' | 'Clear' | 'Minor' | 'Major', index: number, icon: JSX.Element} | undefined {
  if (alertResp) {
    const severities = Object
      .keys(alertResp.alerts)
      .map((key) => ({ alertName: key, nodeIds: alertResp.alerts[key].nodeIDs }))
      .filter((alertWithNodes) => alertWithNodes.nodeIds.find((n) => n.id === nodeId))
      .map((alertWithNodes) => getAlertSeverity(alertWithNodes.alertName))
      .sort((severity1, severity2) => severity2.index - severity1.index);
    return severities.length > 0 ? severities[0] : alertConfig.Clear;
  }
  return undefined;
}

function getAlertSeverity(alertName: string): {status: 'Critical' | 'Clear' | 'Minor' | 'Major', icon: JSX.Element, index: number} {
  return alertConfig[alertSeverities[alertName]] || alertConfig.Critical;
}

export {
  getAlertSeverity,
  calculateDisplayedAlerts,
  nodesWithCustomAttributesFetcherFn,
  nodesFetcherFn,
  alertsFetcherFn,
  groupsNodesFn,
  groupsNodesWithAlertsFn,
  nodeExtendedPropsFn,
  schedulesGroupsFn,
  groupsScheduleFn,
  getScheduleTimelineFn,
  partnersCustomersFn,
  ufnamesActionsFn,
  nodeNamesFn,
  firmwareFetcherFn,
  getScheduledDriverLevels,
};
