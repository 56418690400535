/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import Datepicker from '../../../Common/Components/Datepicker';
import Table from '../../../Common/Components/Table';
import { AuditTrailTable } from '../../../types/AuditTrailTable';
import { PageComponentProps } from '../../../types/PageComponentProps';
import { useAppContext } from '../../../utils/AppContext';
import { getRequest } from '../../../utils/fetch';
import getHeaderProps from '../../../utils/getHeaderProps';
import Utils from '../../../utils/Utils';
import AdminSidebarConfigItems from '../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../Common/Components/AdminSidebar';
import { GroupObject } from '../../../types/GroupObject';
import { ScheduleObject } from '../../../types/ScheduleObject';
import TableToolbar from '../../Admin/Pages/Components/TableToolbar';

import { ReactComponent as CalendarIcon } from '../../../img/icons/calendar-white.svg';
import DropDown from '../../../Common/Components/DropDown';
import TableToolbarSubtitle from '../../Admin/Pages/Components/TableToolbarSubtitle';

function AuditLogSettings(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;
  const headers = getHeaderProps('AuditEntry');

  const noSiteMessage = 'No site is selected for the operation.';
  const serverErrorMessage = 'Could not fetch audit trail.';

  const { addNotification } = useAppContext();

  const initDate = () => {
    const start = new Date(Date.now());
    const end = new Date(Date.now());

    start.setDate(start.getDate() - 1);

    return {
      start,
      end,
    };
  };

  const [startDate, setStartDate] = useState(initDate().start);
  const [endDate, setEndDate] = useState(initDate().end);
  const [dataFetchedAlready, setDataFetchedAlready] = useState<boolean>(false);
  const [auditResp, setAuditResp] = useState<AuditTrailTable | undefined>(undefined);

  const hoursInDay = 24;

  const [groupList, setGroupList] = useState<Map<string, GroupObject> | undefined>(undefined);
  const {
    data: groupsResp,
  } = useSWR<Array<GroupObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups`, 'GetGroups']
    : null);

  const [scheduleList, setScheduleList] = useState<Map<string, ScheduleObject> | undefined>(undefined);
  const {
    data: scheduleResp,
  } = useSWR<Array<ScheduleObject>>(selectedSite.id
    ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/schedules`, 'GetSchedules']
    : null);

  const fetchAuditData = async () => {
    if (!selectedCustomer?.id || !selectedSite?.id) {
      addNotification({ type: 'warning', message: noSiteMessage });
    } else {
      const startDateUtcString = convertDateFromLocalToPathString(startDate, 0);
      const endDateUtcString = convertDateFromLocalToPathString(endDate, hoursInDay);
      try {
        setDataFetchedAlready(false);
        setAuditResp(undefined);
        const resp: AuditTrailTable = await getRequest(`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/audits/from/${startDateUtcString}/to/${endDateUtcString}`);
        resp.forEach((record) => {
          record.whenTableSort = Date.parse(record.when) || 0;
          record.when = Utils.getConvertedDate(record.when);
          record.message = Utils.removeTrailingBrackets(record.message);

          if (record.targetid === selectedSite.id) {
            record.targetid = selectedSite.name;
          } else if (record.targettype === 'Group') {
            const groupName = groupList?.get(record.targetid)?.name;
            record.targetid = groupName === undefined ? record.targetid : groupName;
          } else if (record.targettype === 'Schedule') {
            const scheduleName = scheduleList?.get(record.targetid)?.name;
            record.targetid = scheduleName === undefined ? record.targetid : scheduleName;
          }
          return record;
        });
        setAuditResp(resp);
        setDataFetchedAlready(true);
      } catch (e) {
        setAuditResp([]);
        setDataFetchedAlready(false);
        addNotification({ type: 'warning', message: serverErrorMessage });
      }
    }
  };

  useEffect(() => {
    if (auditResp === undefined) {
      fetchAuditData();
    }

    if (groupsResp !== undefined) {
      const tmpMapGroup = new Map();
      groupsResp.forEach((group) => {
        tmpMapGroup.set(group.groupid, group);
      });

      setGroupList(tmpMapGroup);
    }

    if (scheduleResp !== undefined) {
      const tmpMapSchedule = new Map();
      scheduleResp.forEach((schedule) => {
        tmpMapSchedule.set(schedule.scheduleid, schedule);
      });

      setScheduleList(tmpMapSchedule);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditResp, groupsResp, scheduleResp]);

  const downloadAuditList = () => {
    Utils.downloadCSV(headers, auditResp as Record<string, string | number>[], 'audit_trail');
  };

  function convertDateFromLocalToPathString(localDate: Date, hours: number) {
    const pathDate = new Date(localDate);
    pathDate.setHours(hours, 0, 0, 0);
    return Utils.getConvertedDate(pathDate, "yyyy-MM-dd'T'HH:mmZZZ");
  }

  const [toggleDateDropdown, setToggleDateDropdown] = useState(false);

  return (
    <div className="config__content audit-trail">
      <AdminSidebar selectedSite={selectedSite} title="Site config">
        <AdminSidebarConfigItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content">
        <TableToolbar
          title="Audit trail"
          subtitle={<TableToolbarSubtitle listLength={auditResp?.length || 0} download={() => downloadAuditList()} subtitle="Events" />}
        >
          <div className="audit-trail-tabletoolbar">
            <div className="tabletoolbar__children">
              <div className="tabletoolbar__children-content" data-testid="openDateDropdown" onClick={() => setToggleDateDropdown(!toggleDateDropdown)}>
                <CalendarIcon />
                <div className="tabletoolbar__children-content--date">
                  <span className="tabletoolbar__children-content--date-start">Start</span>

                  <span className="tabletoolbar__children-content--date-startdate">{DateTime.fromISO(startDate.toISOString()).toFormat('MMMM dd, yyyy')}</span>
                  <span className="tabletoolbar__children-content--date-end">End</span>
                  <span className="tabletoolbar__children-content--date-enddate">{DateTime.fromISO(endDate.toISOString()).toFormat('MMMM dd, yyyy')}</span>
                </div>
              </div>
              {toggleDateDropdown && (
              <DropDown
                primaryButtonLabel="Cancel"
                primaryButtonAction={() => setToggleDateDropdown(false)}
                primaryButtonType="primary"
                secondaryButtonLabel="Submit"
                secondaryButtonAction={async () => {
                  await fetchAuditData();
                  setToggleDateDropdown(false);
                }}
                secondaryButtonType="secondary"
              >
                <>
                  <Datepicker
                    placement="bottom"
                    selectedDate={startDate}
                    setSelectedDate={setStartDate}
                    placeholder="Select start date"
                    label="Start date"
                    labelPlacement="above"
                    popperPosition="inline"
                    type="dark"
                    maxDate={endDate}
                  />
                  <Datepicker
                    placement="bottom"
                    selectedDate={endDate}
                    setSelectedDate={setEndDate}
                    label="End date"
                    labelPlacement="above"
                    popperPosition="inline"
                    type="dark"
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                </>
              </DropDown>
              )}
            </div>
          </div>
        </TableToolbar>
        <div className="table table--light top-border-light auto-height">
          <Table
            headers={headers}
            data={auditResp}
            dataFetchedAlready={dataFetchedAlready}
            autoheight
          />
        </div>
      </div>
    </div>
  );
}

export default AuditLogSettings;
