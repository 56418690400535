import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import SelectBox from '../../../../Common/Components/SelectBox';
import { MoveNodesToLightingGroupProps } from '../../../../types/GroupActionsPropTypes';
import { SelectBoxItemType } from '../../../../types/SelectBoxPropsType';
import { postRequest } from '../../../../utils/fetch';
import Utils from '../../../../utils/Utils';
import GroupActionsModalContent from '../GroupActionsModalContent';

const selectBoxWidth = 350;

function MoveNodesToLightingGroup(props: MoveNodesToLightingGroupProps): JSX.Element {
  const { setOpenModal, selectedNodes, selectedCustomer, selectedSite, lightingGroups, addNotification, updateGroup } = props;

  const [selectedLightingGroups, setSelectedLightingGroups] = useState({
    hasError: false,
    list: ({ title: '', key: '' } as SelectBoxItemType),
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <Modal
      title="Move nodes to lighting group"
      width="570"
      setModalOpen={() => setOpenModal(0)}
      secondaryButtonLabel="Cancel"
      secondaryButtonAction={() => setOpenModal(0)}
      primaryButtonLabel="Submit"
      primaryButtonDisabled={submitDisabled}
      primaryButtonAction={async () => {
        if (selectedLightingGroups.list.title === '') {
          setSelectedLightingGroups((oldValues) => ({ ...oldValues, hasError: true }));
        } else if (!selectedLightingGroups.hasError) {
          setSubmitDisabled(true);
          try {
            const postBody = { nodeList: Array.from(selectedNodes.keys()) };

            const result = await postRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${selectedLightingGroups.list.key}/add-nodes`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Move nodes to lighting group" operation has started.  You can check the Jobs page for status.' });
              updateGroup();
              setOpenModal(0);
            } else {
              addNotification({ type: 'error', message: `Your "Move nodes to lighting group" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Move nodes to lighting group" operation is failed.' });
          }
        }
      }}
    >
      <div className="group-actions__modal-container">
        <SelectBox
          label="Target lighting group"
          onClick={(item: SelectBoxItemType) => {
            setSelectedLightingGroups({
              hasError: item.title === '',
              list: item,
            });
          }}
          selectedItemKey={selectedLightingGroups.list.key || ''}
          list={Utils.arrayToSelectbox(lightingGroups || [], 'id', 'name')}
          title={selectedLightingGroups.list.title || 'Select'}
          type="light"
          listWidth={selectBoxWidth}
          isHorizontal
          isRequired
          errorMessage="Please select a target lighting group"
          error={selectedLightingGroups.hasError}
        />
        <GroupActionsModalContent
          selectedNodesSize={selectedNodes.size}
          groupName={selectedLightingGroups.list.title}
        />
      </div>
    </Modal>
  );
}

export default MoveNodesToLightingGroup;
