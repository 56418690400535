import React, { useState } from 'react';
import Modal from '../../../Common/Components/Modal';
import SelectBox from '../../../Common/Components/SelectBox';
import Textbox from '../../../Common/Components/Textbox';
import Utils from '../../../utils/Utils';
import { deleteRequest, postRequest } from '../../../utils/fetch';
import { useAppContext } from '../../../utils/AppContext';

import { EditGroupPostProp, EditGroupProps, InterActionGroupProps } from '../../../types/EditGroupProps';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';

import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';
import formValidation from '../../../utils/form/formValidation';

const selectBoxWidth = 309;
const modalWidth = '366';

function EditGroup(props: EditGroupProps): JSX.Element {
  const {
    setOpenEditModal,
    openEditModal,
    groupType,
    scheduleResp,
    selectedCustomer,
    selectedSite,
    editGroup,
    setConfirmDeleteGroup,
    confirmDeleteGroup,
    updateGroupsList,
  } = props;

  const [editLightingGroup, setEditLightingGroup] = useState<InterActionGroupProps>({
    name: groupType === 'lighting' ? editGroup.name : '',
    hasError: false,
    errorMessage: '',
    description: groupType === 'lighting' ? editGroup.description : '',
    schedule: groupType === 'lighting' && editGroup.schedules.length !== 0
      ? Utils.arrayToSelectbox(editGroup.schedules || [], 'scheduleid', 'name')[0]
      : { title: '', key: '' },
  });
  const [editOrgGroup, setEditOrgGroup] = useState<InterActionGroupProps>({
    name: groupType === 'org' ? editGroup.name : '',
    hasError: false,
    errorMessage: '',
    description: groupType === 'org' ? editGroup.description : '',
  });

  const { addNotification } = useAppContext();

  const updateGroup = async (type: 'lighting' | 'organizational') => {
    try {
      const newGroupData: InterActionGroupProps = type === 'lighting' ? editLightingGroup : editOrgGroup;

      const postBody: EditGroupPostProp = {
        name: newGroupData.name,
        description: newGroupData.description,
        nodeList: editGroup.nodeList,
        type: editGroup.type,
        group_type: editGroup.group_type,
      };

      if (type === 'lighting') {
        postBody.schedules = [];

        if (newGroupData.schedule?.key) {
          postBody.schedules = [{
            name: newGroupData.schedule.title,
            scheduleid: newGroupData.schedule.key,
          }];
        }
      }

      const result = await postRequest(
        `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${editGroup.groupid}`,
        postBody,
      );

      if (!result.error) {
        addNotification({ type: 'info', message: 'Your "Edit group" operation is completed.' });
        updateGroupsList();
      } else {
        addNotification({ type: 'error', message: `Your "Edit group" operation is failed: ${result.error}` });
      }
    } catch (e) {
      addNotification({ type: 'error', message: 'Your "Edit group" operation is failed.' });
    }
  };

  const [isClosedScheduleList, setIsClosedScheduleList] = useState(true);
  const validationRules = { required: true, min: 2, max: 256, noSpecCharacters: true };

  return (
    <div className="group-interactions">
      {openEditModal && (
        <>
          {groupType === 'lighting' && (
          <Modal
            title="Edit lighting group"
            setModalOpen={() => setOpenEditModal(false)}
            primaryButtonLabel="Submit"
            primaryButtonAction={async () => {
              if (editLightingGroup.hasError) {
                return;
              }
              updateGroup('lighting');
              setOpenEditModal(false);
            }}
            secondaryButtonAction={() => {
              setOpenEditModal(false);
              setConfirmDeleteGroup(true);
            }}
            secondaryButtonLabel={(editGroup.type !== 'site-lighting' && editGroup.nodeList.length === 0) ? 'Delete group' : undefined}
            tertiaryButtonLabel="Cancel"
            width="369"
          >
            <div className="modal-divided">
              <div className="modal-divided__content">
                <Textbox
                  label="Lighting group name"
                  value={editLightingGroup.name}
                  placeholder="Enter name"
                  onChange={(event) => {
                    const name = event.target.value;
                    const validation = formValidation(
                      name,
                      validationRules,
                    );

                    setEditLightingGroup((oldValues) => ({
                      ...oldValues,
                      name,
                      hasError: validation.hasError,
                      errorMessage: validation.errorMsg,
                    }));
                  }}
                  isRequired
                  error={editLightingGroup.hasError}
                  errorMessage={editLightingGroup.errorMessage}
                  gridTemplateColumns={`auto ${selectBoxWidth}px`}
                  disabled={editGroup.type === 'site-lighting'}
                />
                <Textbox
                  label="Lighting group description"
                  value={editLightingGroup.description}
                  placeholder="Enter description"
                  onChange={(event) => {
                    const description = event.target.value;
                    setEditLightingGroup((oldValues) => ({ ...oldValues, description }));
                  }}
                  gridTemplateColumns={`auto ${selectBoxWidth}px`}
                  disabled={editGroup.type === 'site-lighting'}
                />
                <SelectBox
                  label="Schedule"
                  onClick={(item: SelectBoxItemType) => {
                    setEditLightingGroup((oldValues) => ({ ...oldValues, schedule: item }));
                  }}
                  selectedItemKey={editLightingGroup?.schedule?.key || ''}
                  list={Utils.arrayToSelectbox(scheduleResp || [], 'scheduleid', 'name')}
                  title={editLightingGroup?.schedule?.title || 'no schedule'}
                  type="light"
                  listWidth={selectBoxWidth}
                  setIsClosedScheduleList={() => setIsClosedScheduleList(!isClosedScheduleList)}
                  disabled={editGroup.type === 'site-lighting'}
                />
                { isClosedScheduleList && (
                <>
                  <div className="modal-divided__info info">
                    <InfoIcon />
                    <span>The new schedule will replace the current schedule for the lighting group.</span>
                  </div>
                  <div className="modal-divided__info warning">
                    <WarningIcon />
                    <span>
                      Please allow up to
                      <span className="bold"> 3 days </span>
                      for the nodes to receive their new schedule.
                    </span>
                  </div>
                </>
                )}
              </div>
            </div>
          </Modal>
          )}
          {groupType === 'org' && (
          <Modal
            title="Edit org group"
            setModalOpen={() => setOpenEditModal(false)}
            primaryButtonLabel="Submit"
            primaryButtonAction={async () => {
              setEditOrgGroup(({ ...editOrgGroup, hasError: editOrgGroup.name === '' }));
              if (editOrgGroup.name === '' || editOrgGroup.hasError) {
                return;
              }
              await updateGroup('organizational');
              setOpenEditModal(false);
            }}
            secondaryButtonLabel="Cancel"
            width="369"
          >
            <div className="modal-divided">
              <div className="modal-divided__content">
                <Textbox
                  label="Org group name"
                  value={editOrgGroup.name}
                  placeholder="Enter name"
                  onChange={(event) => {
                    const name = event.target.value;
                    const validation = formValidation(
                      name,
                      validationRules,
                    );
                    setEditOrgGroup((oldValues) => ({
                      ...oldValues,
                      name,
                      hasError: validation.hasError,
                      errorMessage: validation.errorMsg,
                    }));
                  }}
                  isRequired
                  error={editOrgGroup.hasError}
                  errorMessage={editOrgGroup.errorMessage}
                  gridTemplateColumns={`auto ${selectBoxWidth}px`}
                />
                <Textbox
                  label="Org group description"
                  value={editOrgGroup.description}
                  placeholder="Enter description"
                  onChange={(event) => {
                    const description = event.target.value;
                    setEditOrgGroup((oldValues) => ({ ...oldValues, description }));
                  }}
                  gridTemplateColumns={`auto ${selectBoxWidth}px`}
                />
              </div>
            </div>
          </Modal>
          )}
        </>
      )}
      {confirmDeleteGroup && (
      <Modal
        title="Delete lighting group"
        setModalOpen={setConfirmDeleteGroup}
        primaryButtonAction={async () => {
          try {
            const result = await deleteRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${editGroup.groupid}`,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Delete group" operation is completed.' });
              updateGroupsList();
            } else {
              addNotification({ type: 'error', message: `Your "Delete group" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Delete group" operation is failed.' });
          }
          setConfirmDeleteGroup(false);
        }}
        primaryButtonLabel="Submit"
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => setConfirmDeleteGroup(false)}
        width={modalWidth}
      >
        <div className="delete-group__confirm">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <div className="delete-group__warning">Are you sure you want to delete  <span> { editGroup.name } </span>  group?</div>
        </div>
      </Modal>
      )}
    </div>
  );
}

export default EditGroup;
