/* eslint-disable no-restricted-syntax */
import { FormValidationProps, ValidationObjectTypeProps, ValidationTypeProps } from '../../types/formValidation';

export default function formValidation(text: string, validationByTypes: ValidationTypeProps): FormValidationProps {
  let validationObj = {
    hasError: false,
    errorMsg: '',
  };

  const validation: ValidationObjectTypeProps = {
    required: (val: string) => {
      if (!val) {
        return validationObj;
      }
      return {
        hasError: text === '',
        errorMsg: 'Required',
      };
    },
    email: (val: boolean) => {
      if (!val) {
        return validationObj;
      }
      const isValid = text.match(/^([^<>()%&/[\]\\,;:\s@\\"%\u0100-\uffff]+)@([^<>()%&/[\]\\,;:\s@\\"%\u0100-\uffff]+)\.([a-zA-Z]{2,})$/) !== null;
      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : `${text} is an invalid email address.`,
      };
    },
    phone: (val: boolean) => {
      if (!val) {
        return validationObj;
      }
      const isValid = text.match(/[0-9]{3,}/gm) !== null;
      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : `${text} is an invalid phone number.`,
      };
    },
    min: (val: number) => {
      const isValid = val <= text.length;
      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : `Minimum length is ${val} characters.`,
      };
    },
    max: (val: number) => {
      const isValid = val >= text.length;
      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : `Maximum character limit is ${val}.`,
      };
    },
    range: (val: boolean) => {
      if (!val) {
        return validationObj;
      }
      const isValid = text.match(/^[0-9]{1,4}$/g) !== null;

      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : 'Range 0 to 9999.',
      };
    },

    noSpecCharacters: (val: boolean) => {
      // not allowed characters: <>[]{}/*!@|\"$?.% and: \u0100-\uffff
      if (!val) {
        return validationObj;
      }
      // eslint-disable-next-line no-useless-escape
      const isValid = text.match(/^$|^[^\<\>\[\]{}/\=*!@|\\\"\$\?\.%\u0100-\uffff]+$/gm) !== null;

      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : `${text} includes invalid characters`,
      };
    },
    checkVzids: (val: boolean) => {
      if (!val) {
        return validationObj;
      }
      const vzids = text.split(',').map((textItem) => textItem.trim());
      const isValid = vzids.every((id) => id.match(/^\d{10,10}-\d{5,5}$/) !== null);

      return {
        hasError: !isValid,
        errorMsg: isValid ? '' : `${text} format or length not valid`,
      };
    },
  };

  const ruleEntryList = Object.entries(validationByTypes);
  for (let i = 0; i < ruleEntryList.length; i += 1) {
    const rule = ruleEntryList[i];
    const validationTypeKey = rule[0] as keyof typeof validation;
    const validationTypeValue = rule[1] as never;
    validationObj = validation[validationTypeKey](validationTypeValue);
    if (validationObj.hasError) {
      return validationObj;
    }
  }
  /* Object.entries(validationByTypes).forEach(([validationTypeKey, validationTypeValue]) => {
    const currValidationObj = validation[validationTypeKey as keyof typeof validation](validationTypeValue as never);

    if (currValidationObj.hasError && !validationObj.hasError) {
      validationObj = currValidationObj;
    }
  }); */

  return validationObj;
}
