/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import Modal from '../../../../Common/Components/Modal';
import Table from '../../../../Common/Components/Table';
import { AlertObject } from '../../../../types/AlertObject';
import { LightsAlarmHistoryProps } from '../../../../types/LightsAlarmHistoryProps';
import { getRequest } from '../../../../utils/fetch';
import getHeaderProps from '../../../../utils/getHeaderProps';
import Utils from '../../../../utils/Utils';
import RelativeTimeOrDateRangeModal from './RelativeTimeOrDateRangeModal';

import { ReactComponent as AlertIconMinor } from '../../../../img/icons/alert-icon-minor.svg';
import { ReactComponent as AlertIconMajor } from '../../../../img/icons/alert-icon-major.svg';
import { ReactComponent as AlertIconCritical } from '../../../../img/icons/alert-icon-critical.svg';
import Loading from '../../../../Common/Components/Loading';

function LightsAlarmHistory(props: LightsAlarmHistoryProps): JSX.Element {
  const { selectedCustomer, selectedSite, selectedNode, openModal, setOpenModal } = props;

  const headers = getHeaderProps('AlarmHistory');

  const [dateRange, setDateRange] = useState<{ from: DateTime, to: DateTime }>();
  const [alarmHistory, setAlarmHistory] = useState<AlertObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const modalDate = 1;
  const modalTable = 2;
  const ms = 1000;

  return (
    <div className="alarms">
      {(openModal === modalDate) && (
        <>
          <Loading isLoading={isLoading} fullScreen />
          <RelativeTimeOrDateRangeModal
            title="Alarm history"
            setOpenModal={() => setOpenModal(0)}
            primaryButtonAction={async (range: { from: DateTime, to: DateTime }) => {
              setDateRange(range);
              setIsLoading(true);
              const resp: AlertObject[] = await getRequest(`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alert-dashboard/from/${range.from.toISO()}/to/${range.to.toISO()}`);
              const alarms = resp
                .filter((alarm) => alarm.nodeid === selectedNode.nodeid)
                .map((alarm) => {
                  alarm.status = alarm.active ? 'Active' : 'Cleared';
                  alarm.createdTableSort = alarm.created as number;
                  alarm.created = Utils.getConvertedDate(new Date(alarm.created as number / ms));
                  alarm.updatedTableSort = alarm.updated as number;
                  alarm.updated = Utils.getConvertedDate(new Date(alarm.updated as number / ms));
                  let severityIcon: JSX.Element;

                  if (alarm.severity === 'Minor') {
                    severityIcon = <AlertIconMinor />;
                  } else if (alarm.severity === 'Major') {
                    severityIcon = <AlertIconMajor />;
                  } else {
                    severityIcon = <AlertIconCritical />;
                  }

                  alarm.severityIcon = (
                    <>
                      {severityIcon}
                      {alarm.severity}
                    </>
                  );

                  return alarm;
                });

              setAlarmHistory(alarms);
              setIsLoading(false);
              setOpenModal(modalTable);
            }}
          />
        </>
      )}
      {(openModal === modalTable) && (
        <Modal
          width="1050"
          setModalOpen={() => setOpenModal(0)}
          title="Alarm history"
          subtitle={`${Utils.getConvertedDate(dateRange?.from.toJSDate())} - ${Utils.getConvertedDate(dateRange?.to.toJSDate())}`}
          primaryButtonLabel="Close"
          primaryButtonAction={() => setOpenModal(0)}
          secondaryButtonLabel="Download CSV"
          secondaryButtonAction={() => {
            const csvHeaders = headers.filter((item) => item.key !== 'severityIcon');
            csvHeaders.splice(1, 0, { key: 'severity', val: 'Severity' });
            Utils.downloadCSV(csvHeaders, alarmHistory, 'alarm_history');
          }}
        >
          <div className="table table--light">
            <Table
              headers={headers}
              data={alarmHistory}
              fixColCount={0}
              dataFetchedAlready
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LightsAlarmHistory;
