import React, { createRef, useMemo } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import Button from '../../../Common/Components/Button';
import Loading from '../../../Common/Components/Loading';
import { FirmwareListsProps } from '../../../types/FirmwareListProps';
import Utils from '../../../utils/Utils';

const rowHeight = 65;

const releaseDate = 'Release date';
const nodeModel = 'Node model';
const firmwareId = 'Firmware ID';
const imageSize = 'Image size';

function FirmwareVersionList(props: FirmwareListsProps): JSX.Element {
  const { data, openModal } = props;

  const isNonReadOnly = Utils.isNonReadOnly();

  const list = createRef<List>();
  const byteInMb = 1024;

  const firmwareVersionRenderer = ({ key, index, style }: ListRowProps) => (
    <div key={key} style={style}>
      <div className="firmware-list__elements">
        <div className="info-cell info-cell--release">
          <div className="info-cell-data--large">
            {data[index].release}
          </div>
        </div>
        <div className="info-cell info-cell--date">
          <div>
            <div className="info-cell-label">
              {releaseDate}
            </div>
            <div className="info-cell-data">
              {Utils.getConvertedDate(data[index].when, 'yyyy-MM-dd')}
            </div>
          </div>
        </div>
        <div className="info-cell info-cell--model">
          <div>
            <div className="info-cell-label">
              {nodeModel}
            </div>
            <div className="info-cell-data">
              {Utils.getModelName(data[index].type)}
            </div>
          </div>
        </div>
        <div className="info-cell info-cell--id">
          <div>
            <div className="info-cell-label">
              {firmwareId}
            </div>
            <div className="info-cell-data">
              {data[index].firmwareid}
            </div>
          </div>
        </div>
        <div className="info-cell info-cell--size">
          <div>
            <div className="info-cell-label">
              {imageSize}
            </div>
            <div className="info-cell-data">
              {`${Math.floor(data[index].image_size / byteInMb).toLocaleString()} KB`}
            </div>
          </div>
        </div>
        {isNonReadOnly && (
        <div className="info-cell info-cell--btn">
          <div>
            <Button label="Start update" onClick={() => openModal(data[index])} />
          </div>
        </div>
        )}
      </div>
    </div>
  );

  const sortedData = useMemo(() => {
    const tmpData = [...data];
    tmpData.sort((a, b) => new Date(b.when).getTime() - new Date(a.when).getTime());
    return tmpData;
  }, [data]);

  return (
    <div className="list-container">
      {data.length ? (
        <AutoSizer disableWidth>
          {({ height }) => (
            <List
              ref={list}
              width={1}
              height={height}
              rowHeight={rowHeight}
              rowCount={data && data.length}
              rowRenderer={firmwareVersionRenderer}
              list={sortedData}
              containerStyle={{
                width: '100%',
                maxWidth: '100%',
              }}
              style={{
                width: '100%',
              }}
            />
          )}
        </AutoSizer>
      ) : (<Loading />)}
    </div>
  );
}

export default FirmwareVersionList;
