import React, { useEffect, useState } from 'react';
import Modal from '../../../../../Common/Components/Modal';
import Textbox from '../../../../../Common/Components/Textbox';
import Checkbox from '../../../../../Common/Components/Checkbox';
import SelectBox from '../../../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../../../types/SelectBoxPropsType';
import { AdminCreateUserProps, NewUserStateProp, NewUserValidationProp, CreateUserPostProp } from '../../../../../types/AdminCreateUserProps';
import Utils from '../../../../../utils/Utils';
import { postRequest } from '../../../../../utils/fetch';
import { useAppContext } from '../../../../../utils/AppContext';
import formValidation from '../../../../../utils/form/formValidation';

function AdminSidebarCreateUser(props: AdminCreateUserProps): JSX.Element {
  const { modalOpen, partnersList, customersList, updateCustomers, isSensityUserAdmin, isPartnerUserAdmin, isEndUserAdmin } = props;
  const { addNotification } = useAppContext();
  const [partnerAccountsList, setPartnerAccountsList] = useState(customersList);
  const [createNewUser, setCreateNewUser] = useState<NewUserStateProp>({
    username: '',
    partner: { title: '', key: '' },
    account: { title: '', key: '' },
    roles: { title: '', key: '' },
    firstname: '',
    lastname: '',
    mobile: '1',
    mfa: false,
  });

  const [createUserValidation, setCreateUserValidation] = useState<NewUserValidationProp>({
    hasError: false,
    errorMessage: '',
    accountError: false,
    accountErrorMessage: '',
    mobileError: false,
    mobileErrorMessage: '',
    roleError: false,
    roleErrorMessage: '',
    partnerError: false,
    partnerErrorMessage: '',
  });
  const [rolesFilter, setRolesFilter] = useState([{ title: 'None', key: '0' }]);

  const setEmail = (newName?: string) => {
    const username = newName !== undefined ? newName : createNewUser.username;
    const validation = formValidation(
      username,
      { required: true, email: true },
    );
    setCreateNewUser((oldValues) => ({
      ...oldValues,
      username,
    }));
    setCreateUserValidation((oldValues) => ({
      ...oldValues,
      hasError: validation.hasError,
      errorMessage: validation.errorMsg,
    }));
  };

  const setRole = (newName?: SelectBoxItemType) => {
    const roles = newName !== undefined ? newName : createNewUser.roles;
    const validation = formValidation(
      roles.key,
      { required: true },
    );
    setCreateNewUser((oldValues) => ({
      ...oldValues,
      roles,
    }));
    setCreateUserValidation((oldValues) => ({
      ...oldValues,
      roleError: validation.hasError,
      roleErrorMessage: validation.errorMsg,
    }));
  };
  const setPartner = (newName?: SelectBoxItemType) => {
    const partner = newName !== undefined ? newName : createNewUser.partner;
    const validation = formValidation(
      partner.key,
      { required: true },
    );
    setCreateNewUser((oldValues) => ({
      ...oldValues,
      partner,
      roles: { title: '', key: '' },
    }));
    setCreateUserValidation((oldValues) => ({
      ...oldValues,
      partnerError: validation.hasError,
      partnerErrorMessage: validation.errorMsg,
      accountError: false,
      accountErrorMessage: '',
    }));
  };
  const setMobile = (newName?: string) => {
    if (newName === '' || !newName?.startsWith('1')) {
      // eslint-disable-next-line no-param-reassign
      newName = `1${newName}`;
    }
    const mobile = (newName !== undefined) ? newName : createNewUser.mobile;
    const validation = formValidation(
      mobile,
      { phone: true },
    );
    setCreateNewUser((oldValues) => ({
      ...oldValues,
      mobile,
    }));
    setCreateUserValidation((oldValues) => ({
      ...oldValues,
      mobileError: validation.hasError,
      mobileErrorMessage: validation.errorMsg,
    }));
  };

  const checkMobileSelected = (checked?:boolean) => {
    const validationError = ((createNewUser.mobile === '') || (createNewUser.mobile === '1'));
    const validationErrorMsg = ((createNewUser.mobile === '') || (createNewUser.mobile === '1')) ? 'Enter mobile number for two-factor authentication.' : '';
    if (checked) {
      const mfa = !createNewUser.mfa;
      setCreateNewUser((oldValues) => ({
        ...oldValues,
        mfa,
      }));
      setCreateUserValidation((oldValues) => ({
        ...oldValues,
        mobileError: validationError,
        mobileErrorMessage: validationErrorMsg,
      }));
    } else {
      const mfa = !createNewUser.mfa;
      setCreateNewUser((oldValues) => ({
        ...oldValues,
        mfa,
      }));
      setCreateUserValidation((oldValues) => ({
        ...oldValues,
        mobileError: false,
        mobileErrorMessage: '',
      }));
    }
  };
  const checkPartnerSelected = (partneruser?:boolean, newName?: SelectBoxItemType) => {
    const account = newName !== undefined ? newName : createNewUser.account;
    if (!partneruser) {
      const validationError = (createNewUser.partner.key === '');
      const validationErrorMsg = (createNewUser.partner.key === '') ? 'Please select a partner' : '';
      if (validationError) {
        setCreateNewUser((oldValues) => ({
          ...oldValues,
          roles: { title: '', key: '' },
        }));
        setCreateUserValidation((oldValues) => ({
          ...oldValues,
          accountError: validationError,
          accountErrorMessage: validationErrorMsg,
        }));
      } else {
        setCreateNewUser((oldValues) => ({
          ...oldValues,
          account,
          roles: { title: '', key: '' },
        }));
        setCreateUserValidation((oldValues) => ({
          ...oldValues,
          accountError: false,
          accountErrorMessage: '',
        }));
      }
    } else {
      setCreateNewUser((oldValues) => ({
        ...oldValues,
        account,
        roles: { title: '', key: '' },
      }));
      setCreateUserValidation((oldValues) => ({
        ...oldValues,
        accountError: false,
        accountErrorMessage: '',
      }));
    }
  };

  useEffect(() => {
    if (isSensityUserAdmin && createNewUser.partner.title === 'Sensity Systems') {
      setRolesFilter(Utils.getUserRolesByType('sensity'));
    } else if (((isPartnerUserAdmin) || (isSensityUserAdmin && createNewUser.partner.key !== '')) && createNewUser.account.key === '') {
      setRolesFilter(Utils.getUserRolesByType('partner'));
    } else if ((createNewUser.account.key !== '') || (isEndUserAdmin)) {
      setRolesFilter(Utils.getUserRolesByType('account'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createNewUser.partner,
    createNewUser.account,
  ]);

  useEffect(() => {
    if (createNewUser.partner.key !== '') {
      setPartnerAccountsList(customersList.filter((partneraccount) => (partneraccount.type !== 'partner') && (partneraccount.po === createNewUser.partner.key)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createNewUser.partner,
    customersList,
  ]);
  const endUserAdminWidth = 350;
  const otherAdminWidth = 300;
  const addEndUserClass = isEndUserAdmin ? 'enduser' : '';
  const selectBoxWidth = (isEndUserAdmin) ? endUserAdminWidth : otherAdminWidth;
  const partnerDrop = (isSensityUserAdmin)
    ? (
      <SelectBox
        label="Partner"
        onClick={(item: SelectBoxItemType) => setPartner(item)}
        selectedItemKey={createNewUser?.partner?.key || ''}
        list={Utils.arrayToSelectbox(partnersList || [], 'orgid', 'name')}
        title={createNewUser.partner.title || 'Select'}
        type="light"
        error={createUserValidation.partnerError}
        errorMessage={createUserValidation.partnerErrorMessage}
        isRequired
        listWidth={selectBoxWidth}
      />
    )
    : (
      <Textbox
        label="Partner"
        value={partnersList[0]?.name}
        placeholder={partnersList[0]?.name}
        disabled
        gridTemplateColumns={`auto ${selectBoxWidth}px`}
      />
    );

  return (
    <div className="user-interactions create-user">
      <Modal
        title="Create new user"
        setModalOpen={modalOpen}
        primaryButtonLabel="Create"
        primaryButtonAction={async () => {
          const currentUser = createNewUser;
          if (currentUser.username === '') {
            setEmail(currentUser.username);
            return;
          }
          if (isSensityUserAdmin && currentUser.partner.key === '') {
            setPartner(currentUser.partner);
            return;
          }
          if (currentUser.roles.key === '') {
            setRole(currentUser.roles);
            return;
          }
          if (createUserValidation.mobileError) {
            return;
          }
          try {
            const phone = currentUser.mobile === '' || currentUser.mobile === '1' ? '' : currentUser.mobile;
            const postBody: CreateUserPostProp = {
              email: currentUser.username,
              roles: currentUser.roles.key,
              name: `${currentUser.firstname} ${currentUser.lastname}`.trimEnd(),
              phone,
              mfa: currentUser.mfa,
            };
            const selectedPartner = (isPartnerUserAdmin) ? partnersList[0].orgid : currentUser.partner.key;
            const selectedPartnerAccount = (currentUser.account.key !== '') ? currentUser.account.key : selectedPartner;
            const selectedCustomerId = (isEndUserAdmin) ? customersList[0].orgid : selectedPartnerAccount;
            const result = await postRequest(
              `/customers/${selectedCustomerId}/users`,
              postBody,
            );

            if (!result.error) {
              addNotification({ type: 'info', message: 'Your "Create user" operation is completed.' });
              if (updateCustomers) {
                updateCustomers();
              }
            } else {
              addNotification({ type: 'error', message: `Your "Create user" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Create user" operation is failed.' });
          }
          modalOpen(false);
        }}
        secondaryButtonLabel="Cancel"
        width={(isEndUserAdmin ? '410' : '710')}
        secondaryButtonAction={() => modalOpen(false)}
      >
        <div className={`modal-divided ${addEndUserClass || ''}`}>
          <div className="modal-divided__leftcontent">
            <div className="modal-divided__content-data">
              { (!isEndUserAdmin)
                && (
                <div>
                  {partnerDrop}
                  <SelectBox
                    label="Account"
                    onClick={(item: SelectBoxItemType) => {
                      checkPartnerSelected(isPartnerUserAdmin, item);
                    }}
                    selectedItemKey={createNewUser?.account?.key || ''}
                    list={Utils.arrayToSelectbox(partnerAccountsList || [], 'orgid', 'name')}
                    title={createNewUser.account.title || 'Select'}
                    type="light"
                    error={createUserValidation.accountError}
                    errorMessage={createUserValidation.accountErrorMessage}
                    listWidth={selectBoxWidth}
                  />
                </div>
                )}
              <SelectBox
                label="Role"
                onClick={(item: SelectBoxItemType) =>
                  setCreateNewUser((oldValues) => ({ ...oldValues, roleError: false, roleErrorMessage: '', roles: item }))}
                selectedItemKey={createNewUser?.roles?.key || ''}
                list={Utils.arrayToSelectbox(rolesFilter || [], 'key', 'title')}
                title={createNewUser.roles.title || 'Select'}
                isRequired
                error={createUserValidation.roleError}
                errorMessage={createUserValidation.roleErrorMessage}
                type="light"
                listWidth={selectBoxWidth}
              />
              <Textbox
                label="Username (Email)"
                name="Email"
                value={createNewUser.username}
                placeholder="Enter"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                isRequired
                error={createUserValidation.hasError}
                errorMessage={createUserValidation.errorMessage}
                gridTemplateColumns={`auto ${selectBoxWidth}px`}
              />
            </div>
          </div>
          <div className="modal-divided__rightcontent">
            <Textbox
              label="First name"
              name="FirstName"
              value={createNewUser.firstname}
              placeholder="Enter"
              onChange={(event) => {
                const firstname = event.target.value;
                setCreateNewUser((oldValues) => ({ ...oldValues, firstname }));
              }}
              gridTemplateColumns={`auto ${selectBoxWidth}px`}
            />
            <Textbox
              label="Last name"
              name="LastName"
              value={createNewUser.lastname}
              placeholder="Enter"
              onChange={(event) => {
                const lastname = event.target.value;
                setCreateNewUser((oldValues) => ({ ...oldValues, lastname }));
              }}
              gridTemplateColumns={`auto ${selectBoxWidth}px`}
            />
            <Textbox
              label="Mobile (SMS)"
              name="Mobile"
              value={createNewUser.mobile}
              placeholder="Enter"
              onChange={(event) => {
                setMobile(event.target.value);
              }}
              error={createUserValidation.mobileError}
              errorMessage={createUserValidation.mobileErrorMessage}
              gridTemplateColumns={`auto ${selectBoxWidth}px`}
            />
            <div className="users-mfa">
              <span>Multi-factor authentication (MFA)</span>
              <div className="mfa-content">
                <Checkbox
                  checked={createNewUser.mfa}
                  onClick={(checked) => {
                    checkMobileSelected(checked);
                  }}
                />
                <span>Required</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AdminSidebarCreateUser;
