import React, { useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import AdminSidebarConfigItems from '../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../Common/Components/AdminSidebar';
import Button from '../../../Common/Components/Button';
import { PageComponentProps } from '../../../types/PageComponentProps';
import Utils from '../../../utils/Utils';
import getHeaderProps from '../../../utils/getHeaderProps';
import Table from '../../../Common/Components/Table';
import Modal from '../../../Common/Components/Modal';
import { useAppContext } from '../../../utils/AppContext';
import TableToolbar from '../../Admin/Pages/Components/TableToolbar';
import TableToolbarSubtitle from '../../Admin/Pages/Components/TableToolbarSubtitle';
import { CustomAttributeEntry, CustomAttributeLabels } from '../../../types/CustomAttributes';
import Loading from '../../../Common/Components/Loading';
import { getRequest } from '../../../utils/fetch';
import Tooltip from '../../../Common/Components/Tooltip';

type CustomAttributeFlatEntry = Record<string, string>;

function CustomAttributes(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;
  const { addNotification } = useAppContext();

  const { data: customAttributes } = useSWR<CustomAttributeLabels>(selectedSite.id ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/customattributes/labels`, 'CustomAttributeLabels'] : null);

  const { data: customAttributeEntries, mutate: updateAttributeEntries } = useSWR<Array<CustomAttributeFlatEntry> | undefined>(
    selectedSite.id ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/customattributes`] : null,
    (url: string) => getRequest(url).then((data: { data: CustomAttributeEntry[] }) => transformCustomAttributeEntries(data.data)),
  );

  const transformCustomAttributeEntries = (nodeAttrList: CustomAttributeEntry[]): CustomAttributeFlatEntry[] =>
    nodeAttrList.map((nodeEntry) => {
      const newObj = {
        nodeid: nodeEntry.nodeid,
      };

      nodeEntry.customAttributes.forEach((attrEntry) => {
        newObj[`customAttributes_${attrEntry.label}TableFilter`] = attrEntry.value;
        newObj[`customAttributes_${attrEntry.label}TableSort`] = attrEntry.value; // DO NOT CHANGE
        newObj[`customAttributes_${attrEntry.label}`] = <Tooltip offset={2} position="top-start" text={attrEntry.value}><div>{attrEntry.value}</div></Tooltip>;
      });

      return newObj;
    });

  const headers = useMemo(() => getHeaderProps('CustomAttributes', '', Object.values(customAttributes?.customAttributes || {}), true, true), [customAttributes]);
  const [openModal, setOpenModal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const downloadCustomAttributes = () => {
    if (customAttributeEntries) {
      Utils.downloadCSV(
        headers,
        // following is code to set the value of each attribute for the download and
        //  replace the Tooltip element with plain text
        customAttributeEntries.map((entry) => {
          const newEntry = entry;
          Object.keys(entry).forEach((key) => {
            if (key !== 'nodeid') {
              if (!key.match(/Table(Filter|Sort)$/)) {
                newEntry[key] = entry[`${key}TableSort`];
              }
            }
          });
          return newEntry;
        }),
        'custom_attributes',
      );
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const handleFileUpload = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setUploadFile(file);
      setOpenModal(1);
    }
  };
  const clearInputFileAndCloseModal = () => {
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.value = '';
    }
    setOpenModal(0);
  };

  const colWidthCalcFn = (col: number): number => {
    if (col <= 9) {
      return 400;
    }

    return 450;
  };

  return (
    <>
      <div className="config__content custom-attributes">
        <AdminSidebar selectedSite={selectedSite} title="Site config">
          <AdminSidebarConfigItems path={window.location.pathname} />
        </AdminSidebar>
        <div className="page-content page-content--medium-margin page-content--large-bottom-margin">
          <TableToolbar
            title="Custom attributes"
            subtitle={<TableToolbarSubtitle listLength={customAttributeEntries?.length || 0} download={() => downloadCustomAttributes()} />}
            addClass="btn-container"
          >
            <div className="tabletoolbar__children">
              <Button
                label="Upload GIS file"
                buttonType="secondary"
                onClick={() => handleFileUpload()}
                extraClasses="config__content-toolbar-button"
              />
            </div>
          </TableToolbar>
          <div className="table table--light top-border-light auto-height">
            <Table
              headers={headers}
              data={customAttributeEntries}
              fixColCount={1}
              autoheight
              skipCellMeasure
              colWidthCalcFn={colWidthCalcFn}
            />
          </div>
        </div>
      </div>
      {openModal === 1 && (
        <Modal
          width="280"
          title="Upload GIS file"
          setModalOpen={() => setOpenModal(0)}
          primaryButtonLabel="Yes"
          primaryButtonAction={async () => {
            setIsLoading(true);
            try {
              const resp = await Utils.uploadGISfile(`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures/import`, uploadFile);
              const uploadResp = await resp.json();

              if (uploadResp.error) {
                const errorMessage = Utils.consolidateDuplicationErrorMessage(uploadResp.message);
                addNotification({ type: 'error', message: `Your "Upload GIS file" operation has failed: ${errorMessage || ''}` });
              } else {
                addNotification({ type: 'success', message: 'Your "Upload GIS file" operation has completed.' });
                updateAttributeEntries();
              }
            } catch (e) {
              addNotification({ type: 'error', message: 'Your "Upload GIS file" operation has failed' });
            }
            clearInputFileAndCloseModal();
            setIsLoading(false);
          }}
          secondaryButtonLabel="Cancel"
          secondaryButtonAction={() => clearInputFileAndCloseModal()}
        >
          <>
            <Loading isLoading={isLoading} />
            <div className="upload-confirm">
              <div className="upload-confirm__content">
                <span>Are you sure you want to upload a new GIS file?</span>
                <span>This action will override the existing custom attributes.</span>
              </div>
            </div>
          </>
        </Modal>
      )}
      <input type="file" id="file" ref={inputFile} accept=".xlsx" style={{ display: 'none' }} onChange={onChangeFileUpload} />
    </>
  );
}

export default CustomAttributes;
