/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Loading from '../../Common/Components/Loading';
import { DataType } from '../../types/login';
import { BASEPATH, httpNotFound } from '../../utils/constants';
import Utils from '../../utils/Utils';
import { getURL } from '../../utils/fetch';

class LoginCode extends Component<RouteComponentProps, unknown> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get('code') || '';
    const scope = query.get('scope') || '';
    const state = query.get('state') || '';

    if (code !== '') {
      const url = getURL(`/code?code=${code}&client_id=${window.NSN.loginappClientId}&redirect_uri=${window.location.origin}${BASEPATH}/code&scope=${scope}&state=${state}`);

      fetch(url, {
        method: 'GET',
        credentials: 'include',
      }).then((res) => {
        localStorage.setItem(Utils.csrfTokenName, res.headers.get(Utils.csrfTokenName) || '');
        return res.json();
      }).then((data) => this.onLoginSuccess(data))
        .catch(LoginCode.onLoginError);
    }
  }

  onLoginSuccess(data: DataType) {
    // If login error, show error message
    if (data.error || data.status === httpNotFound) {
      return;
    }

    window.NSN = {
      ...window.NSN,
      userInfo: {
        ...data,
        name: data.name.replace(/([a-z])([A-Z])/g, '$1 $2'),
      },
      site: {},
    };

    // Clear session storage and set `userInfo` on storage
    sessionStorage.clear();
    sessionStorage.setItem('userInfo', JSON.stringify(data));

    if (data.user.orgs.length !== 1) {
      this.setBrowserTitleAndRedirect('Intelligent Lighting', '/sites');
      return;
    }

    // Set customerID to session storage
    sessionStorage.setItem('customerID', data.user.orgs[0]);
    sessionStorage.setItem('customerName', data.user.orgNames[0]);

    // Redirect if 'not one site'
    if (data.user.sites.length !== 1) {
      this.setBrowserTitleAndRedirect('Intelligent Lighting', '/sites');
      return;
    }

    // Updated session storage
    sessionStorage.setItem('siteID', data.user.sites[0]);
    sessionStorage.setItem('siteName', data.user.siteNames[0]);

    // Redirect
    this.setBrowserTitleAndRedirect('Intelligent Lighting', '/sites');
  }

  static onLoginError(xhr: XMLHttpRequest) {
    const errorText = 'Login failed';
    let errorDescription = 'Please contact the administrators.';
    const responseJSON = JSON.parse(xhr.responseText);

    if (responseJSON && responseJSON.message) {
      errorDescription = `${responseJSON.message}. ${errorDescription}`;
    }

    window.location.href = `${window.NSN.loginappURL}/error?error=${errorText}&error_description=${errorDescription}`;
  }

  setBrowserTitleAndRedirect(title: string, newPathName: string) {
    window.NSN.link = newPathName;

    if (window && window.document) {
      window.document.title = title;
    }

    this.props.history.push(newPathName);
  }

  render() {
    return (<Loading fullScreen isLoading />);
  }
}

export default withRouter(LoginCode);
