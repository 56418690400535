/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import DropDown from '../../../Common/Components/DropDown';
import ModalFullPage from '../../../Common/Components/ModalFullPage';
import LightingChart from './LightingChart';
import { LightingChartModalProps } from '../../../types/LightingChartModalProps';
import Button from '../../../Common/Components/Button';
import SelectBox from '../../../Common/Components/SelectBox';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import DateRangePicker from '../../../Common/Components/DateRangePicker';
import Utils from '../../../utils/Utils';
import Sensors from '../../../utils/Sensors';

import { ReactComponent as SensorChartsIcon } from '../../../img/icons/sensor-charts.svg';
import { ReactComponent as CalendarIcon } from '../../../img/icons/calendar.svg';
import { ReactComponent as CSVFileIcon } from '../../../img/icons/csv-file.svg';
import { ReactComponent as PDFFileIcon } from '../../../img/icons/pdf-file.svg';
import { ReactComponent as CloseIcon } from '../../../img/icons/close.svg';
import { SensorReportResponseItem } from '../../../types/SensorRequestPayload';

function LightingChartModal(props: LightingChartModalProps): JSX.Element {
  const {
    chartDataset,
    rawChartDataset,
    chartProps,
    fetchAndDisplayChartData,
    setChartModalToggle,
    isLoading,
    setSelectedSensor1,
    setSelectedSensor2,
    setStartDate,
    setEndDate,
  } = props;

  const sensorsSelectboxWidth = 238;

  const [openSensorDropdown, setOpenSensorDropdown] = useState(false);
  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const [dropdownSensor1, setDropdownSensor1] = useState<SelectBoxItemType>(chartProps.sensor1);
  const [dropdownSensor2, setDropdownSensor2] = useState<SelectBoxItemType>(chartProps.sensor2);
  const [dropdownStartDate, setDropdownStartDate] = useState(chartProps.startDate);
  const [dropdownEndDate, setDropdownEndDate] = useState(chartProps.endDate);

  const [csvDataSet, setCsvDataSet] = useState<SensorReportResponseItem[]>(rawChartDataset);
  const getCSVDataSet = (newHiddenLegends: Set<string>) => {
    const filteredData = rawChartDataset.filter((data) => {
      const name = `${data.nodeid}-${Sensors.getSensorName(data.sensor)}`;
      return !newHiddenLegends.has(name);
    });
    setCsvDataSet(filteredData);
  };

  const sensorList2 = chartProps.sensorList.map((v) => ({ title: v.title, key: v.key }));
  sensorList2.unshift({ title: 'None', key: '0' });

  useEffect(() => {
    setCsvDataSet(rawChartDataset);
  }, [rawChartDataset]);

  const generateCsvData = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csvData: Record<string, any>[] = [];

    csvDataSet.forEach((dataset) => {
      const sensorName = Sensors.getSensorName(dataset.sensor);
      const nodeid = `${dataset.nodeid}\t`;
      let { sensor } = dataset;

      dataset.datapoints.forEach((datapoint) => {
        if (chartProps.sensor1.key === sensorName) {
          sensor = chartProps.sensor1.title;
        }

        if (chartProps.sensor2.key && chartProps.sensor2.key === sensorName) {
          sensor = chartProps.sensor2.title;
        }

        csvData.push({
          time: DateTime.fromISO(datapoint.timestamp).toFormat('LL/dd/yyyy h:mm a ZZZZ'),
          node: nodeid,
          sensor,
          value: datapoint.value,
          flags: datapoint.flags ? datapoint.flags.join(', ') : 'None',
        });
      });
    });

    return csvData;
  }, [chartProps.sensor1.key, chartProps.sensor1.title, chartProps.sensor2.key, chartProps.sensor2.title, csvDataSet]);

  return (
    <ModalFullPage>
      <div className="sensorchart">
        <div className="sensorchart__content">
          <div className="sensorchart__content-title">
            Sensor chart
          </div>
          <div className="sensorchart__content-toolbar">
            <DropDown>
              <button
                type="button"
                className="sensorchart__content-toolbar__btn"
                onClick={() => setOpenSensorDropdown(!openSensorDropdown)}
              >
                <SensorChartsIcon />
                <div className="sensorchart__content-toolbar__btn-content">
                  <span className="sensorchart__content-toolbar__btn-content-label">Sensor 1</span>
                  <span className={`sensorchart__content-toolbar__btn-content-text ${chartProps.sensor1.title !== '' ? 'filled' : ''}`}>
                    {chartProps.sensor1.title || ''}
                  </span>
                  <span className="sensorchart__content-toolbar__btn-content-label">Sensor 2</span>
                  <span className={`sensorchart__content-toolbar__btn-content-text ${chartProps.sensor2.title !== '' ? 'filled' : ''}`}>
                    {chartProps.sensor2.title || ''}
                  </span>
                </div>
              </button>
            </DropDown>
            {openSensorDropdown && setSelectedSensor1 && setSelectedSensor2 && (
            <div className="sensorchart__content-toolbar-edit">
              <SelectBox
                label="Sensor 1"
                type="dark"
                list={chartProps.sensorList}
                onClick={(item: SelectBoxItemType) => setDropdownSensor1(item)}
                title={dropdownSensor1.title}
                selectedItemKey={dropdownSensor1.key}
                listWidth={sensorsSelectboxWidth}
              />
              <SelectBox
                label="Sensor 2"
                type="dark"
                list={sensorList2}
                onClick={(item: SelectBoxItemType) => setDropdownSensor2(item)}
                title={dropdownSensor2.title}
                selectedItemKey={dropdownSensor2.key}
                listWidth={sensorsSelectboxWidth}
              />
              <div className="sensorchart__content-toolbar-edit-footer">
                <Button
                  onClick={() => setOpenSensorDropdown(false)}
                  label="Cancel"
                  buttonType="primary"
                  buttonSize="medium"
                  extraClasses="margin-right-10"
                />
                <Button
                  onClick={() => {
                    setSelectedSensor1(dropdownSensor1);
                    setSelectedSensor2(dropdownSensor2);
                    setOpenSensorDropdown(false);
                    fetchAndDisplayChartData(undefined, undefined, dropdownSensor1, dropdownSensor2);
                  }}
                  label="Submit"
                  buttonType="secondary"
                  buttonSize="medium"
                  extraClasses="margin-right-0"
                />
              </div>
            </div>
            )}
          </div>
          <div className="sensorchart__content-toolbar">
            <DropDown>
              <button
                type="button"
                className="sensorchart__content-toolbar__btn"
                onClick={() => setOpenDateDropdown(!openDateDropdown)}
              >
                <CalendarIcon />
                <div className="sensorchart__content-toolbar__btn-content">
                  <span className="sensorchart__content-toolbar__btn-content-label">Start</span>
                  <span className={`sensorchart__content-toolbar__btn-content-text ${chartProps.startDate ? 'filled' : ''}`}>
                    {DateTime.fromJSDate(chartProps.startDate).toFormat('MMMM d, yyyy') || ''}
                  </span>
                  <span className="sensorchart__content-toolbar__btn-content-label">End</span>
                  <span className={`sensorchart__content-toolbar__btn-content-text ${chartProps.endDate ? 'filled' : ''}`}>
                    {DateTime.fromJSDate(chartProps.endDate).toFormat('MMMM d, yyyy') || ''}
                  </span>
                </div>
              </button>
            </DropDown>
            {openDateDropdown && setStartDate && setEndDate && (
            <div className="sensorchart__content-toolbar-edit">
              <DateRangePicker
                startDate={dropdownStartDate}
                setStartDate={setDropdownStartDate}
                endDate={dropdownEndDate}
                setEndDate={setDropdownEndDate}
                maxDaysRange={90}
                setDaysDelta={() => true}
                type="dark"
              />
              <div className="sensorchart__content-toolbar-edit-footer">
                <Button
                  onClick={() => setOpenDateDropdown(false)}
                  label="Cancel"
                  buttonType="primary"
                  buttonSize="medium"
                  extraClasses="margin-right-10"
                />
                <Button
                  onClick={() => {
                    setStartDate(dropdownStartDate);
                    setEndDate(dropdownEndDate);
                    setOpenDateDropdown(false);
                    fetchAndDisplayChartData(dropdownStartDate, dropdownEndDate);
                  }}
                  label="Submit"
                  buttonType="secondary"
                  buttonSize="medium"
                  extraClasses="margin-right-0"
                />
              </div>
            </div>
            )}
          </div>
        </div>
        <div className="sensorchart-btns">
          <Button
            onClick={() => {
              html2canvas(document.querySelector('.chart-container') as HTMLElement, { useCORS: true })
                .then((canvas) => {
                  const imgData = canvas.toDataURL('image/png');
                  const scale = window.devicePixelRatio;
                  const width = canvas.width / scale;
                  const height = canvas.height / scale;
                  // eslint-disable-next-line new-cap
                  const doc = new jsPDF({ orientation: 'l', unit: 'px', format: [width, height] });
                  doc.addImage(imgData, 'JPEG', 0, 0, width, height);
                  doc.save(`sensor-chart-${Utils.getConvertedDate(new Date())}.pdf`);
                });
            }}
            extraClasses="sensorchart-btns-pdf"
          >
            <PDFFileIcon />
          </Button>
          <Button
            onClick={() => Utils.downloadCSV([
              { key: 'time', val: 'Time' },
              { key: 'node', val: 'Node' },
              { key: 'sensor', val: 'Sensor' },
              { key: 'value', val: 'Value' },
              { key: 'flags', val: 'Flags' },
            ], generateCsvData(), 'sensor-chart')}
            extraClasses="sensorchart-btns-csv"
          >
            <CSVFileIcon />
          </Button>
          <Button onClick={() => setChartModalToggle(false)} extraClasses="sensorchart-btns-close"><CloseIcon /></Button>
        </div>
      </div>
      <LightingChart
        id="line-chart"
        rawDatasets={chartDataset || []}
        chartProps={chartProps}
        isLoading={isLoading}
        changedDataset={getCSVDataSet}
        sensorName1={chartProps.sensor1.title || ''}
        sensorName2={chartProps.sensor2.title || ''}
      />
    </ModalFullPage>
  );
}

export default LightingChartModal;
