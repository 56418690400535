/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import useSWR from 'swr';
import Button from '../../../../Common/Components/Button';
import Table from '../../../../Common/Components/Table';
import { PageComponentProps } from '../../../../types/PageComponentProps';
import { FixtureObject } from '../../../../types/FixtureObject';
import useFixturesPageState from '../../../../utils/state/useFixturesPageState';
import { getRequest } from '../../../../utils/fetch';
import AdminSidebarConfigItems from '../../Components/AdminSidebarConfigItems';
import AdminSidebar from '../../../../Common/Components/AdminSidebar';
import CreateEditFixture from './Components/CreateEditFixture';
import CopyFixture from './Components/CopyFixture';
import { CustomerObject } from '../../../../types/CustomerObject';
import { AllSiteObject } from '../../../../types/SiteObject';

import ViewFixture from './Components/ViewFixture';
import Tooltip from '../../../../Common/Components/Tooltip';

import { ReactComponent as EditIcon } from '../../../../img/icons/edit.svg';
import { ReactComponent as WarningIcon } from '../../../../img/icons/orangewarning.svg';

import Utils from '../../../../utils/Utils';
import TableToolbar from '../../../Admin/Pages/Components/TableToolbar';
import TableToolbarSubtitle from '../../../Admin/Pages/Components/TableToolbarSubtitle';

function FixturesSettings(props: PageComponentProps): JSX.Element {
  const { selectedCustomer, selectedSite } = props;
  const {
    headers,
    toggleActionsFixture,
    setToggleActionsFixture,
    toggleCopyFixture,
    setToggleCopyFixture,
    toggleViewFixture,
    setToggleViewFixture,
  } = useFixturesPageState();

  const tooltipOffset = 0;
  const isNonReadOnly = Utils.isNonReadOnly();

  const [actions, setActions] = useState('');

  const { data: allCustomers } = useSWR<Array<CustomerObject> | undefined>(
    ['/customers', 'GetCustomers'],
    (url) => getRequest(url, {}, (data: Array<CustomerObject>): Array<CustomerObject> => {
      const tmpData = data ? [...data] : [];
      tmpData?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return tmpData;
    }),
  );

  const { data: allSites } = useSWR<AllSiteObject[]>(
    ['/sites', 'FixturesSettings'],
    (url) => getRequest(url, {}, (data: { sites: Array<AllSiteObject> }): Array<AllSiteObject> => data?.sites?.filter((s) => s.orgid !== '')
      .map((site) => ({
        ...site,
      }))),
  );

  const { data: fixturesResp, mutate: updateFixtures } = useSWR<Array<FixtureObject> | undefined>(
    [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/fixtures`, 'FixturesSettings'],
    (url) => getRequest(url, {}, (data: Array<FixtureObject>): Array<FixtureObject> => data?.map((fixture) => {
      function isProfileMissing() : boolean {
        return (
          (fixture.MinPower0 === '' || typeof fixture.MinPower0 === 'undefined')
          || (fixture.MinPower10 === '' || typeof fixture.MinPower10 === 'undefined')
          || (fixture.MinPower50 === '' || typeof fixture.MinPower50 === 'undefined')
          || (fixture.MinPower80 === '' || typeof fixture.MinPower80 === 'undefined')
          || (fixture.MinPower100 === '' || typeof fixture.MinPower100 === 'undefined')
          || (fixture.MaxPower0 === '' || typeof fixture.MaxPower0 === 'undefined')
          || (fixture.MaxPower10 === '' || typeof fixture.MaxPower10 === 'undefined')
          || (fixture.MaxPower50 === '' || typeof fixture.MaxPower50 === 'undefined')
          || (fixture.MaxPower80 === '' || typeof fixture.MaxPower80 === 'undefined')
          || (fixture.MaxPower100 === '' || typeof fixture.MaxPower100 === 'undefined')
        );
      }
      const baseObj = { namewarning: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className="fixture-name"
          onClick={() => {
            setToggleViewFixture(true);
            setFixtureDetails(fixture);
          }}
        >
          <div className="fixture-name-text">{fixture.name}</div>
          {(isProfileMissing()) && (
            <Tooltip text="Power draw profile missing" offset={tooltipOffset} addStyle={{ marginLeft: '10px' }}>
              <WarningIcon
                className="missingProfileIcon"
              />
            </Tooltip>
          )}
        </div>
      ),
      namewarningTableFilter: fixture.name,
      namewarningTableSort: fixture.name,
      description: fixture.description || '--',
      fixtureType: fixture.fixtureType || '--',
      PowerDraw: `${fixture.PowerDraw}W` || '0W',
      manufacturer: fixture.manufacturer || '--',
      manufacturersku: fixture.manufacturersku || '--' };
      const editColumn = isNonReadOnly ? {
        edit: (
          <div className="fixture-edit">
            <EditIcon
              onClick={() => {
                setToggleActionsFixture(true);
                setFixtureDetails(fixture);
                setActions('Edit');
              }}
            />
          </div>
        ) } : {};

      return ({
        ...fixture,
        ...baseObj,
        ...editColumn,
      });
    })),
  );
  const [fixtureDetails, setFixtureDetails] = useState<FixtureObject>({} as FixtureObject);
  const downloadFixtures = () => {
    Utils.downloadCSV([{ key: 'name', val: 'Name' }, ...headers].filter((header) => !['rowSelectCheckbox', 'edit', 'namewarning'].includes(header.key)), fixturesResp || [], 'fixtures');
  };

  return (
    <div className="config__content fixtures">
      <AdminSidebar selectedSite={selectedSite} title="Site config">
        <AdminSidebarConfigItems path={window.location.pathname} />
      </AdminSidebar>
      <div className="page-content page-content--medium-margin page-content--large-bottom-margin">
        <TableToolbar
          title="Fixtures"
          subtitle={<TableToolbarSubtitle listLength={fixturesResp?.length || 0} download={() => downloadFixtures()} />}
          addClass="btn-container"
        >
          <>
            {isNonReadOnly && (
              <div className="tabletoolbar__children">
                <Button
                  onClick={() => {
                    setToggleCopyFixture(true);
                  }}
                  label="Copy from another site"
                  buttonType="secondary"
                />
                <Button
                  onClick={() => {
                    setToggleActionsFixture(true);
                    setActions('Create');
                  }}
                  label="Create new"
                  buttonType="primary"
                />
              </div>
            )}
          </>
        </TableToolbar>
        <div className="table table--light top-border-light auto-height">
          <Table
            headers={headers}
            data={fixturesResp}
            skipCellMeasure
            colWidthCalcFn={(col: number, width: number): number => {
              const margin = 5;
              const remainCol = 4;
              const { PowerDraw, manufacturersku, edit } = { PowerDraw: 200, manufacturersku: 150, edit: 120 };
              const colWidth = (width - PowerDraw - manufacturersku - edit - margin) / remainCol;
              return [colWidth, colWidth, colWidth, PowerDraw, colWidth, manufacturersku, edit][col];
            }}
            autoheight
          />
        </div>
      </div>
      {toggleActionsFixture && (
      <CreateEditFixture
        modalOpen={setToggleActionsFixture}
        selectedCustomer={selectedCustomer}
        selectedSite={selectedSite}
        updateFixtures={updateFixtures}
        actions={actions}
        fixtureDetails={(actions === 'Edit') ? fixtureDetails : undefined}
      />
      )}
      {toggleCopyFixture && (
      <CopyFixture
        modalOpen={setToggleCopyFixture}
        allAccountsDetails={allCustomers}
        allSitesDetails={allSites}
        selectedCustomer={selectedCustomer}
        selectedSite={selectedSite}
        updateFixtures={updateFixtures}
      />
      )}
      {toggleViewFixture && (
      <ViewFixture
        modalOpen={setToggleViewFixture}
        fixtureDetails={fixtureDetails}
      />
      )}
    </div>
  );
}

export default FixturesSettings;
