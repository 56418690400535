import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../Common/Components/Button';
import SlideIn from '../../../Common/Components/SlideIn';
import Table from '../../../Common/Components/Table';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../img/icons/info.svg';
import { GenericSensorTableData } from '../../../types/GenericSensorData';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import { slideInWidth } from '../../../utils/constants';
import getHeaderProps from '../../../utils/getHeaderProps';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import RealTimeValues from '../Components/RealTimeValues';

function LightsEnergyMulti(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite } = props;
  const { addNotification } = useAppContext();

  const headers = getHeaderProps('LightsEnergy');
  const [energyMultiTableData, setEnergyMultiTableData] = useState<GenericSensorTableData[]>();

  const timestamp = useRef<number>(0);

  const [openRealTimeValues, setOpenRealTimeValues] = useState(false);
  const [sensorRealTimeValue, setSelectedSensorRealTimeValue] = useState({ title: 'Select', key: '0' });
  const sensorListRealTimeValue = [
    { title: 'Active energy use', key: 'cumulativeEnergyUse' },
    { title: 'Reactive energy use', key: 'cumulativeReactiveEnergyUse' },
    { title: 'Fixture burn hours', key: 'cumulativeOnStateLuminaire' },
    { title: 'Node burn hours', key: 'cumulativeOnStateNode' },
  ];
  const displayedSensors = ['cumulativeEnergyUse', 'cumulativeReactiveEnergyUse', 'cumulativeOnStateLuminaire', 'cumulativeOnStateNode'];
  const [reqSensors, setReqSensors] = useState(displayedSensors);

  useEffect(() => {
    setEnergyMultiTableData(undefined);
    setReqSensors(displayedSensors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    const currentDate = Date.now();
    timestamp.current = currentDate;

    const originalData = energyMultiTableData;
    const tmpDisplayedData = energyMultiTableData?.map((rowData) => Object.entries(rowData).reduce((acc, e) =>
      (reqSensors.includes(e[0]) ? { ...acc, ...{ [e[0]]: '--' } } : { ...acc, ...{ [e[0]]: e[1] } }), { nodeid: rowData.nodeid }));
    setEnergyMultiTableData(tmpDisplayedData);

    const sensorRequests = reqSensors.map((sensor: string) => Sensors.retrySensorDataFetcher(
      selectedSite.id,
      selectedCustomer.id,
      Array.from(selectedItems.values()).map((n) => n),
      [Sensors.getSensorId(sensor, 'lco')],
    ));

    Promise.allSettled(sensorRequests).then((results) => {
      if (timestamp.current <= currentDate) {
        const response = Sensors.transformSensorResultsMultiNode(results, reqSensors, Array.from(selectedItems.values()));

        const newData = Sensors.getSensorTableWithDisplayedString(
          selectedItems,
          reqSensors,
          response,
          { commissionedDate: { value: '' } },
          originalData,
        );

        // eslint-disable-next-line no-return-assign
        newData.forEach((e) => e.commissionedDate = Utils.getConvertedDate(selectedItems.get(e.nodeid)?.commissionedDate || '') || '--');
        setEnergyMultiTableData(newData);
      }
    }).catch((e) => {
      addNotification({ type: 'warning', message: 'An error occured while fetching sensor data' });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqSensors]);

  return (
    <div className="energy-multinode">
      <SlideIn slideInWidth={slideInWidth}>
        <>
          <div className="slide-in__title">
            <span>Energy</span>
            <div className="slide-in__title-subtitle">
              <span>
                <span>Lifetime cumulative values</span>
                <Tooltip
                  addTooltipClass="energy-tooltip"
                  text="Energy use, node burn hours, and fixture burn hours are calculated over the lifetime of the installed node."
                >
                  <InfoIcon />
                </Tooltip>
              </span>
              <div className="slide-in__link">
                <Button
                  onClick={() => setOpenRealTimeValues(!openRealTimeValues)}
                  label="Get real time values"
                />
              </div>
            </div>
          </div>
          <div className="slide-in__content">
            <div className="table">
              <Table
                headers={headers}
                data={energyMultiTableData}
                fixColCount={1}
                dark
              />
            </div>
          </div>
        </>
      </SlideIn>
      {openRealTimeValues && (
      <RealTimeValues
        sensor={sensorRealTimeValue}
        sensorList={sensorListRealTimeValue}
        sensorSelectboxAction={(item: SelectBoxItemType) => setSelectedSensorRealTimeValue(item)}
        closeButton
        closeButtonAction={() => setOpenRealTimeValues(false)}
        refreshValue={() => {
          setReqSensors([sensorRealTimeValue.key]);
          setOpenRealTimeValues(false);
        }}
      />
      )}
    </div>
  );
}

export default LightsEnergyMulti;
