import React from 'react';
import Modal from '../../../Common/Components/Modal';
import { useAppContext } from '../../../utils/AppContext';
import { deleteRequest } from '../../../utils/fetch';

import { ReactComponent as WarningIcon } from '../../../img/icons/warning.svg';
import { DeleteGroupProps } from '../../../types/DeleteGroupProps';

const modalWidth = '366';

function DeleteGroup(props: DeleteGroupProps): JSX.Element {
  const { modalOpen, deleteGroup, selectedCustomer, selectedSite, updateGroupsList } = props;
  const { addNotification } = useAppContext();

  return (
    <div className="delete-group">
      <Modal
        setModalOpen={() => modalOpen(false)}
        title="Delete org group"
        primaryButtonAction={async () => {
          try {
            const result = await deleteRequest(
              `/customers/${selectedCustomer.id}/sites/${selectedSite.id}/groups/${deleteGroup.groupid}`,
            );

            if (!result.error) {
              addNotification({ type: 'success', message: 'Your "Delete group" operation is completed.' });
              updateGroupsList();
            } else {
              addNotification({ type: 'error', message: `Your "Delete group" operation is failed: ${result.error}` });
            }
          } catch (e) {
            addNotification({ type: 'error', message: 'Your "Delete group" operation is failed.' });
          }
          modalOpen(false);
        }}
        primaryButtonLabel="Submit"
        secondaryButtonLabel="Cancel"
        secondaryButtonAction={() => modalOpen(false)}
        width={modalWidth}
        borderTopColor="yellow"
      >
        <div className="delete-group__confirm">
          <WarningIcon />
          <div>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <div>All nodes will be removed from <span> { deleteGroup.name } </span> group.</div>
            <div className="delete-group__warning">Are you sure you want to delete this group?</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteGroup;
