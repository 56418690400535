import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import Modal from '../../../Common/Components/Modal';
import { JobDetailsPropsType } from '../../../types/JobDetails';
import { FirmwareJobDetailsObject, FirmwareJobNodeStatus, FirmwareJobDetailsDataItem } from '../../../types/FirmwareJobDetailsObject';
import { NodeObject } from '../../../types/NodeObject';
import { nodeNamesFn, nodesFetcherFn } from '../../../utils/ApiDataHelpers';
import Table from '../../../Common/Components/Table';
import Utils from '../../../utils/Utils';
import Loading from '../../../Common/Components/Loading';

import { ReactComponent as JobSuccessIcon } from '../../../img/icons/job-success.svg';
import { ReactComponent as JobErrorIcon } from '../../../img/icons/job-error.svg';
import { ReactComponent as JobSyncIcon } from '../../../img/icons/job-sync.svg';
import { ReactComponent as JobStoppedIcon } from '../../../img/icons/job-stopped.svg';

import CopyJobId from './CopyJobId';
import { jobStatusMsg, jobTypeMsg, firmwareStatusMessages } from '../../../utils/constants';

function FirmwareJobDetails(props: JobDetailsPropsType): JSX.Element {
  const {
    headers,
    job,
    customer,
    site,
    setModalOpen,
  } = props;

  const history = useHistory();

  function StatusCell(status: string) {
    switch (status) {
      case 'In process':
        return (
          <span>
            <JobSyncIcon />
            <span className="jobdetails-container__stopped"> In process</span>
          </span>
        );
        break;
      case 'Failed':
        return (<span className="jobdetails-container__failed">Failed</span>);
        break;
      case 'Succeeded':
        return (<span className="jobdetails-container__succeeded">Completed</span>);
        break;
      case 'Stopped':
        return (<span className="jobdetails-container__stopped">Stopped</span>);
        break;
      case 'Skipped':
        return (<span className="jobdetails-container__stopped">Skipped</span>);
        break;
      default:
        return (<span />);
        break;
    }
  }

  const downloadJobDetails = (data: Record<string, string>[]) => {
    const fileName = 'job_details';
    let csvHeaders = headers;
    csvHeaders = csvHeaders.map((header) => {
      if (header.key === 'statusFmt') {
        return { key: 'status', val: 'Status' };
      }
      return header;
    });
    Utils.downloadCSV(csvHeaders, data, fileName);
  };

  const defaultJobDetailFilterSettings: Record<string, string> = {
    status: 'All',
  };

  const [jobDetailFilterSettings, setJobDetailFilter] = useState(defaultJobDetailFilterSettings);

  const [filteredDetails, setFilteredDetails] = useState<FirmwareJobDetailsDataItem[]>([]);

  // Nodes (for node names)

  const { data: nodesResp } = useSWR<Array<NodeObject>>(() => `/customers/${customer.id}/sites/${site.id}/nodes`, nodesFetcherFn);
  const nodeNames = useMemo(() => nodeNamesFn(nodesResp || []), [nodesResp]);

  const { data: jobDetailsResp } = useSWR<FirmwareJobDetailsObject>(site.id && job.id
    ? [`/customers/${customer.id}/sites/${site.id}/ota_status/${job.id}`, 'GetFirmwareJobDetails']
    : null);

  useEffect(
    () => {
      if (jobDetailsResp) {
        jobDetailsResp.stats = { total: 0, processed: 0, passed: 0, failed: 0, skipped: 0, stopped: 0 };
        Object.keys(jobDetailsResp.nodes_status).forEach((key) => {
          const statusArray = jobDetailsResp.nodes_status[key];
          jobDetailsResp.stats.total += 1;
          jobDetailsResp.stats.processed += 1;
          switch (statusArray[statusArray.length - 1].status) {
            case 'DEVICE_INSTALL_FAILED':
            case 'DEVICE_DOWNLOAD_FAILED':
              jobDetailsResp.stats.failed += 1;
              break;
            case 'DEVICE_INSTALL_SUCCESSFUL':
              jobDetailsResp.stats.passed += 1;
              break;
            case 'SKIPPED':
              jobDetailsResp.stats.skipped += 1;
              break;
            case 'DEVICE_DOWNLOAD_IN_PROGRESS':
            case 'DOWNLOAD_SENT':
            case 'DEVICE_DOWNLOAD_SUCCESSFUL':
            case 'DEVICE_RESTARTED':
            case 'DEVICE_CHECK_IN_PROGRESS':
              jobDetailsResp.stats.processed -= 1;
              break;
            default: break;
          }
        });
      }
    },
    [jobDetailsResp,
      nodesResp,
    ],
  );

  const [, setDataLoadingComplete] = useState({});
  const forceUpdate = React.useCallback(() => setDataLoadingComplete({}), []);

  useEffect(
    () => {
      const firmwareJobDetailsData: FirmwareJobDetailsDataItem[] = [];
      if (jobDetailsResp && Object.keys(jobDetailsResp?.nodes_status).length === 0) {
        setFilteredDetails([]);
        forceUpdate();
      } else if (jobDetailsResp && Object.keys(jobDetailsResp?.nodes_status).length > 0) {
      // create array of table rows
      // eslint-disable-next-line react-hooks/exhaustive-deps

        Object.keys(jobDetailsResp.nodes_status).forEach((key) => {
          const detailArray = jobDetailsResp.nodes_status[key];
          const detail:FirmwareJobNodeStatus = detailArray[detailArray.length - 1];
          firmwareJobDetailsData.push({
            entityid: detail.nodeid,
            name: nodeNames.get(detail.nodeid) || 'n/a',
            nameFmtTableSort: nodeNames.get(detail.nodeid) || 'n/a',
            nameFmtTableFilter: nodeNames.get(detail.nodeid) || 'n/a',
            toVersion: job.toVersion,
            status: firmwareStatusMessages[detail.status] || 'Unknown',
            statusFmtTableFilter: firmwareStatusMessages[detail.status],
            statusFmtTableSort: firmwareStatusMessages[detail.status],
            failmessage: detail.status,
            updated: detail.when ? Utils.convertISOtoJobTime(detail.when, site.time_zone) : '---',
            updatedFmtTableSort: Date.parse(detail.when) || 0,
            updatedFmtTableFilter: Date.parse(detail.when) || 0,

          });
        });
      }

      let details: FirmwareJobDetailsDataItem[] = firmwareJobDetailsData.filter((detail: FirmwareJobDetailsDataItem) => {
        switch (jobDetailFilterSettings.status) {
          case 'All':
            return true;
            break;
          case 'processed':
            return detail.status !== 'In process';
            break;
          case 'passed':
            return detail.status === 'Succeeded';
            break;
          default:
            return detail.status === jobDetailFilterSettings.status;
            break;
        }
      });
      details = details.map((detail) => {
        const d = detail;
        d.statusFmt = StatusCell(detail.status);
        return d;
      });
      setFilteredDetails(details);
      forceUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forceUpdate,
      jobDetailFilterSettings,
      jobDetailsResp,
    ],
  );

  // eslint-disable-next-line consistent-return
  const jobStatusDisplay = (status: string) => {
    switch (status) {
      case 'COMPLETED':
      case 'Succeeded':
        return (
          <span className="jobs-list-item__status job-succeeded">
            <JobSuccessIcon />
            {jobStatusMsg[status]}
          </span>
        );
      case 'RUNNING':
      case 'In process':
        return (
          <span className="jobs-list-item__status job-in-progress">
            <JobSyncIcon />
            {jobStatusMsg[status]}
          </span>
        );
      case 'FAILED':
      case 'Failed':
        return (
          <span className="jobs-list-item__status job-failed">
            <JobErrorIcon />
            {jobStatusMsg[status]}
          </span>
        );
      case 'ABORTED':
      case 'Stopped':
        return (
          <span className="jobs-list-item__status job-stopped">
            <JobStoppedIcon />
            {jobStatusMsg[status]}
          </span>
        );
      default:
        break;
    }
  };

  return (
    <Modal
      className="jobdetails-modal"
      width="1246"
      height="659"
      setModalOpen={setModalOpen}
      title={jobTypeMsg[job.operationType]}
      primaryButtonLabel={job.operationType !== 'UPDATE_FIRMWARE_SITE' ? 'View on lights page' : undefined}
      primaryButtonAction={() => () => history.push('/lights', { selectedNodes: filteredDetails.map((detail) => detail.entityid) })}
      secondaryButtonLabel="Export CSV"
      secondaryButtonAction={() => {
        downloadJobDetails(
          filteredDetails.map((detail:FirmwareJobDetailsDataItem) => (
            {
              entityid: detail.entityid,
              name: detail.name,
              toVersion: detail.toVersion,
              status: detail.status,
              failmessage: detail.failmessage,
              updated: detail.updated,
            }
          )),
        );
      }}
    >
      <CopyJobId
        text1="Click to copy Job ID"
        text2="Job ID copied to clipboard!"
        value={job.id}
      />
      <div className="jobdetails-header">
        <div className="jobdetails-header__firmware">
          <div>
            <div>Firmware ID</div>
            <div>{job.toVersion}</div>
          </div>
          <div>
            <div>Site</div>
            <div>{site.name}</div>
          </div>
        </div>
        <div className="jobdetails-header__startdate">
          <div>Start date</div>
          <span>{site ? Utils.convertISOtoJobTime(job.taskCreationTime, site.time_zone) : ''}</span>
        </div>
        { /* <div className="jobdetails-header__enddate">
          <div>End date</div>
          <span>{site ? Utils.convertISOtoJobTime(job.finishTime, site.time_zone) : ''}</span>
        </div> */
        }
        <div className="jobdetails-header__status">
          <div>Job status</div>
          {jobStatusDisplay(job.status)}
        </div>
      </div>
      <div className="jobdetails-summary">
        <div
          className="jobdetails-summary__total"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'All' })}
        >
          <div>Total nodes</div>
          <div>{jobDetailsResp?.stats?.total?.toLocaleString() || '--'}</div>
        </div>
        <div
          className="jobdetails-summary__processed"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'processed' })}
        >
          <div>Processed</div>
          <div>{jobDetailsResp?.stats?.processed?.toLocaleString() || '--'}</div>
        </div>
        <div
          className="jobdetails-summary__succeeded"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'passed' })}
        >
          <div>Completed</div>
          <span>{jobDetailsResp?.stats?.passed?.toLocaleString() || '--'}</span>
        </div>
        <div
          className="jobdetails-summary__failed"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'Failed' })}
        >
          <div>Failed</div>
          <span>{jobDetailsResp?.stats?.failed?.toLocaleString() || '--'}</span>
        </div>
        <div
          className="jobdetails-summary__skipped"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'Skipped' })}
        >
          <div>Skipped</div>
          <div>{jobDetailsResp?.stats?.skipped?.toLocaleString() || '--'}</div>
        </div>
        <div
          className="jobdetails-summary__stopped"
          role="button"
          onClickCapture={() => setJobDetailFilter({ status: 'Stopped' })}
        >
          <div>Stopped</div>
          <div>{jobDetailsResp?.stats?.stopped?.toLocaleString() || '--'}</div>
        </div>
      </div>
      <div className="jobdetails-container">
        {jobDetailsResp
          ? (
            <div className="table table--light">
              <Table
                headers={headers}
                data={filteredDetails}
                skipCellMeasure
              />
            </div>
          ) : (
            <Loading />
          )}
      </div>
    </Modal>
  );
}

export default FirmwareJobDetails;
