/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { ChartDataSets } from 'chart.js';
import { subDays } from 'date-fns';

import Button from '../../../Common/Components/Button';
import SelectBox from '../../../Common/Components/SelectBox';
import SlideIn from '../../../Common/Components/SlideIn';
import DateRangePicker from '../../../Common/Components/DateRangePicker';
import { SensorListTypes } from '../../../types/LightingChartPropTypes';
import { NodeObject } from '../../../types/NodeObject';
import { SelectBoxItemType } from '../../../types/SelectBoxPropsType';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { useAppContext } from '../../../utils/AppContext';
import Sensors from '../../../utils/Sensors';
import Utils from '../../../utils/Utils';
import LightingChartModal from '../Components/LightingChartModal';
import { SensorReportResponseItem } from '../../../types/SensorRequestPayload.d';

const sensorsSelectboxWidth = 220;
const sensorsSlideInWidth = 260;

function LightsSensorCharts(props: SlideInPanelPropsType): JSX.Element {
  const { selectedItems, selectedCustomer, selectedSite } = props;

  const { addNotification } = useAppContext();
  const selectedNode = selectedItems.values().next().value as NodeObject;
  const [sensor1, setSelectedSensor1] = useState<SelectBoxItemType>({ title: 'Select', key: '0' });
  const [sensor2, setSelectedSensor2] = useState<SelectBoxItemType>({ title: 'Select', key: '0' });
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [daysDelta, setDaysDelta] = useState(0);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [chartDataset, setChartDataset] = useState<ChartDataSets[]>();
  const [rawChartDataset, setRawChartDataset] = useState<SensorReportResponseItem[]>();
  const [chartModalToggle, _setChartModalToggle] = useState(false);
  const setChartModalToggle = (newVal: boolean) => {
    setChartDataset([]);
    setRawChartDataset([]);
    _setChartModalToggle(newVal);
  };

  const fetchAndDisplayChartData = (
    newStartDate?: Date,
    newEndDate?: Date,
    newSensor1?: SelectBoxItemType,
    newSensor2?: SelectBoxItemType,
  ) => {
    Sensors.fetchAndDisplayChartData(
      newStartDate || startDate,
      newEndDate || endDate,
      selectedCustomer,
      selectedSite,
      selectedItems,
      newSensor1 || sensor1,
      newSensor2 || sensor2,
      setChartDataset,
      setRawChartDataset,
      setChartModalToggle,
      setWaiting,
      addNotification,
    );
  };

  const lsnSensorNames: string[] = [
    'driverLevel',
    'ambientLight',
    'cumulativeEnergyUse',
    'cumulativeReactiveEnergyUse',
    'cumulativeOnStateLuminaire',
    'cumulativeOnStateNode',
    'activePower',
    'mainsVoltage',
    'mainsCurrent',
    'powerFactor',
    'reactivePower',
    'radioSignalStrength',
  ];
  const internalSensorNames: string[] = lsnSensorNames.concat([
    'sinr',
    'linkQuality',
    'cellId',
    'nodePowerCycleCount',
    'relayCycleCount',
    'modemBootCount',
    'modemUptime',
    'apFsRestoreCount',
    'internalTemperature',
  ]);
  const sensorNameList: string[] = Utils.isVerizonUser() ? internalSensorNames : lsnSensorNames;

  const sensorList: SensorListTypes[] = sensorNameList.map((sensorName) => {
    const sensorNameStr = Sensors.getSensorTitleByName(sensorName);
    const sensorUnit = Sensors.getSensorUnit(sensorName) ? `(${Sensors.getSensorUnit(sensorName)})` : '';
    return {
      title: `${sensorNameStr} ${sensorUnit}`,
      key: sensorName,
      conversion: Sensors.getSensorConversion(sensorName),
    };
  });

  return (
    <div className="sensors">
      <SlideIn bgStyle="light" slideInWidth={sensorsSlideInWidth}>
        <div>
          <div className="slide-in__title">
            <span>Sensor chart</span>
            <div className="slide-in__title-subtitle">
              {selectedItems.size === 1
                && (
                <>
                  <span className="slide-in__title-subtitle-node">Node ID</span>
                  <div className="slide-in__title-subtitle-nodelist">
                    <span>{selectedNode.nodeid}</span>
                  </div>
                </>
                )}
              {selectedItems.size > 1
                && (
                <span className="slide-in__title-subtitle-node">
                  <span className="slide-in__title-subtitle-node-num">{selectedItems.size}</span>
                  {' '}
                  Nodes
                </span>
                )}
            </div>
          </div>
          <div className="slide-in__content">
            <div className="slide-in__content-title">
              <p>Select one or two sensors</p>
            </div>
            <div className="slide-in__content-data">
              <form action="sensors">
                <SelectBox
                  label="Sensor 1"
                  type="dark"
                  list={sensorList}
                  onClick={(item: SelectBoxItemType) => {
                    setSelectedSensor1(item);
                  }}
                  title={sensor1.title}
                  selectedItemKey={sensor1.key}
                  listWidth={sensorsSelectboxWidth}
                />
                <SelectBox
                  label="Sensor 2"
                  type="dark"
                  list={sensorList}
                  onClick={(item: SelectBoxItemType) => {
                    setSelectedSensor2(item);
                  }}
                  title={sensor2.title}
                  selectedItemKey={sensor2.key}
                  listWidth={sensorsSelectboxWidth}
                />
                <div className="slide-in__content-data--title">Select time period</div>
                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  maxDaysRange={90}
                  setDaysDelta={setDaysDelta}
                  type="dark"
                />
              </form>
            </div>
            <div className="slide-in__link">
              <Button
                onClick={() => {
                  if (sensor1.key === '0' && sensor2.key === '0') {
                    addNotification({ type: 'warning', message: 'Select at least one Sensor.' });
                  } else if (selectedItems.size === 0) {
                    addNotification({ type: 'warning', message: 'Select at least one node.' });
                  } else if (daysDelta > 30) {
                    addNotification({ type: 'warning', message: 'Please select maximum 30 day difference between both the dates.' });
                  } else {
                    fetchAndDisplayChartData();
                  }
                }}
                label="Create chart"
              />
            </div>
            {chartModalToggle
              && (
              <div className="chart-modal">
                <LightingChartModal
                  chartDataset={chartDataset || []}
                  rawChartDataset={rawChartDataset || []}
                  chartProps={{
                    sensor1,
                    sensor2,
                    startDate,
                    endDate,
                    sensorList,
                  }}
                  fetchAndDisplayChartData={fetchAndDisplayChartData}
                  setChartModalToggle={setChartModalToggle}
                  isLoading={waiting}
                  setSelectedSensor1={setSelectedSensor1}
                  setSelectedSensor2={setSelectedSensor2}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
              )}
          </div>
        </div>
      </SlideIn>
    </div>
  );
}

export default LightsSensorCharts;
