import React, { useState } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import Checkbox from '../../../Common/Components/Checkbox';
import DropDown from '../../../Common/Components/DropDown';
import { GroupsFilterPropsType } from '../../../types/GroupsFilter';

import { ReactComponent as LightingIcon } from '../../../img/icons/lighting-group.svg';
import { ReactComponent as OrgGroupIcon } from '../../../img/icons/org-group.svg';

function GroupsFilter(props: GroupsFilterPropsType): JSX.Element {
  const {
    className,
    lightingGroups,
    orgGroups,
    setApplyGroupFilter,
    filteredLightingGroups,
    filteredOrgGroups,
    handleActiveToolbar,
    setGroupFilterActiveTab,
    groupFilterActiveTab,
  } = props;

  const [selectedLightingGroups, setSelectedLightingGroups] = useState<Array<string>>(
    filteredLightingGroups,
  );
  const [selectedOrgsGroups, setSelectedOrgsGroups] = useState<Array<string>>(filteredOrgGroups);

  function rowRendererLightingGroups({ key, index, style }: ListRowProps) {
    return (
      <div key={key} style={style} className="groups-filter__list-row">
        <Checkbox
          checked={selectedLightingGroups.includes(
            lightingGroups[index].name,
          )}
          onClick={(checked) => {
            if (checked) {
              setSelectedLightingGroups([
                ...selectedLightingGroups,
                lightingGroups[index].name,
              ]);
            } else {
              setSelectedLightingGroups(
                selectedLightingGroups.filter(
                  (name: string) => name !== lightingGroups[index].name,
                ),
              );
            }
          }}
        />
        <span>{lightingGroups[index].name}</span>
        <span className="groups-filter__node-length">
          {lightingGroups[index].nodeList?.length}
        </span>
      </div>
    );
  }

  function rowRendererOrgGroups({ key, index, style }: ListRowProps) {
    return (
      <div key={key} style={style} className="groups-filter__list-row">
        <Checkbox
          checked={selectedOrgsGroups.includes(orgGroups[index].name)}
          onClick={(checked) => {
            if (checked) {
              setSelectedOrgsGroups([
                ...selectedOrgsGroups,
                orgGroups[index].name,
              ]);
            } else {
              setSelectedOrgsGroups(
                selectedOrgsGroups.filter(
                  (name: string) => name !== orgGroups[index].name,
                ),
              );
            }
          }}
        />
        <span>{orgGroups[index].name}</span>
        <span className="groups-filter__node-length">
          {orgGroups[index].nodeList?.length}
        </span>
      </div>
    );
  }

  const listWidth = 290;
  const listHeight = 310;
  const listRowHeight = 41;

  return (
    <DropDown
      primaryButtonAction={() => {
        setSelectedOrgsGroups([]);
        setSelectedLightingGroups([]);
      }}
      primaryButtonType="primary"
      primaryButtonLabel="Clear filter"
      primaryButtonSize="small"
      secondaryButtonLabel="Apply filter"
      secondaryButtonAction={() => {
        setApplyGroupFilter({
          filteredLightingGroups: selectedLightingGroups,
          filteredOrgGroups: selectedOrgsGroups,
        });
        handleActiveToolbar(0);
      }}
      secondaryButtonType="secondary"
      secondaryButtonSize="small"
      className={className}
    >
      <div className="dropdown__content groups-filter">
        <div className="groups-filter__tabs">
          <button
            type="button"
            onClick={() => setGroupFilterActiveTab(0)}
            className={`${groupFilterActiveTab === 0 && 'active'}`}
          >
            <LightingIcon />
            Lighting groups
          </button>
          <button
            type="button"
            onClick={() => setGroupFilterActiveTab(1)}
            className={`${groupFilterActiveTab === 1 && 'active'}`}
          >
            <OrgGroupIcon />
            Org groups
          </button>
        </div>
        <div className="groups-filter__tab-contents">
          {groupFilterActiveTab === 0 && (
            <div className="groups-filter__lighting-tab-content">
              <List
                width={listWidth}
                height={listHeight}
                rowCount={lightingGroups.length}
                rowHeight={listRowHeight}
                rowRenderer={rowRendererLightingGroups}
                data={lightingGroups}
              />
            </div>
          )}
          {groupFilterActiveTab === 1 && (
            <div className="groups-filter__org-tab-content">
              <List
                width={listWidth}
                height={listHeight}
                rowCount={orgGroups.length}
                rowHeight={listRowHeight}
                rowRenderer={rowRendererOrgGroups}
                data={orgGroups}
              />
            </div>
          )}
        </div>
      </div>
    </DropDown>
  );
}

export default GroupsFilter;
