import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Button from '../../../Common/Components/Button';
import Loading from '../../../Common/Components/Loading';
import SlideIn from '../../../Common/Components/SlideIn';
import { AlertObject, AlertNewObject } from '../../../types/AlertObject';
import { NodeObject } from '../../../types/NodeObject';
import { SiteObject } from '../../../types/SiteObject';
import { SlideInPanelPropsType } from '../../../types/SlideInPanelPropsType';
import { UfalarmObject } from '../../../types/UfalarmObject';
import { ufnamesActionsFn } from '../../../utils/ApiDataHelpers';
import AlarmPlaybook from '../../Alarms/Components/AlarmPlaybook';
import SlideinInfoAlert from '../Components/SlideinInfoAlert';
import LightsAlarmHistory from './Components/LightsAlarmHistory';
import LightsAlarmsModal from './Components/LightsAlarmsModal';

const alarmsSlideInWidth = 390;

function LightsAlarms(props: SlideInPanelPropsType): JSX.Element {
  const { selectedCustomer, selectedSite, selectedItems } = props;
  const selectedNode = selectedItems.values().next().value as NodeObject;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoaded, setIsLoaded] = useState(false);
  const [openConfirmDismissAlert, setOpenConfirmDismissAlert] = useState(false);
  const [openAlarmPlaybook, setOpenAlarmPlaybook] = useState(0);
  const [alertObj, setAlertObj] = useState<AlertNewObject>();
  const [alerts, setAlerts] = useState<AlertNewObject[] | undefined>(undefined);
  const [alertDismissArray, setAlertDismissArray] = useState<Array<string> | undefined>(undefined);
  const [openAlarmHistory, setOpenAlarmHistory] = useState(0);

  const [expand, setExpand] = useState(false);
  const { data: sitesResp } = useSWR<Array<SiteObject> | undefined>(selectedCustomer.id
    ? [`/customers/${selectedCustomer.id}/sites`, 'GetSites'] : null);

  const site = useMemo(() => sitesResp?.find(
    (item) => item.siteid === selectedSite.id,
  ), [sitesResp, selectedSite.id]);

  const { data: alertsResp,
    mutate: refreshAlerts } = useSWR<Array<AlertObject>>(
      selectedSite.id && selectedNode.nodeid
        ? [`/customers/${selectedCustomer.id}/sites/${selectedSite.id}/alerts/node/${selectedNode.nodeid}`, 'RefreshAlerts']
        : null,
    );

  useEffect(() => {
    if (alertsResp !== undefined) {
      const newAlertList = alertsResp.filter((alert) => !alert.dismissed);

      const newList: Array<AlertNewObject> = [];

      if (newAlertList !== undefined) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < newAlertList.length; i++) {
          if (i !== 0 && newAlertList[i].ufname === newAlertList[i - 1].ufname) {
            const newListCurrentIndex = newList.length - 1;
            if (newList[i - 1]) {
              // eslint-disable-next-line max-len
              newList[i - 1].nodeData.push({ id: newAlertList[i].nodeid, alertid: newAlertList[i].alertid, description: newAlertList[i].description, dismissed: newAlertList[i].dismissed, severity: newAlertList[i].severity, createdAt: newAlertList[i].created, updatedAt: newAlertList[i].updated });
            } else {
              // eslint-disable-next-line max-len
              newList[newListCurrentIndex].nodeData.push({ id: newAlertList[i].nodeid, alertid: newAlertList[i].alertid, description: newAlertList[i].description, dismissed: newAlertList[i].dismissed, severity: newAlertList[i].severity, createdAt: newAlertList[i].created, updatedAt: newAlertList[i].updated });
            }
          } else {
            const obj = { ufname: newAlertList[i].ufname,
              severity: newAlertList[i].severity,
              name: newAlertList[i].name,
              type: newAlertList[i].type,
              nodeData: [
                {
                  id: newAlertList[i].nodeid,
                  alertid: newAlertList[i].alertid,
                  description: newAlertList[i].description,
                  dismissed: newAlertList[i].dismissed,
                  severity: newAlertList[i].severity,
                  createdAt: newAlertList[i].created,
                  updatedAt: newAlertList[i].updated,
                },
              ] };
            newList.push(obj);
          }
        }
      }

      setAlerts(newList);
      setSorted(newList);
      setIsLoaded(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsResp]);

  const { data: ufalarmsResp } = useSWR<Array<UfalarmObject>>(() => '/manage/alarms');
  const alarmActions = useMemo(() => ufnamesActionsFn(ufalarmsResp), [ufalarmsResp]);
  const [sorted, setSorted] = useState(alerts);

  return (
    <div className="alarms">
      <SlideIn
        bgStyle="light"
        slideInWidth={alarmsSlideInWidth}
        expand={expand}
        setExpand={setExpand}
      >
        <>
          <div className="slide-in__title">
            <span>Alarms</span>
            <div className="slide-in__title-subtitle">
              <span className="bold">Node ID</span>
              {selectedNode.nodeid}
              <div className="slide-in__links">
                <div className="slide-in__link">
                  <Button label="View history" onClick={() => setOpenAlarmHistory(1)} />
                </div>
              </div>
            </div>
          </div>
          <div className="slide-in__content">
            {sorted !== undefined
              ? (
                <SlideinInfoAlert
                  alerts={sorted}
                  site={site}
                  setOpenConfirmDismissAlert={() => setOpenConfirmDismissAlert(true)}
                  setAlertObj={setAlertObj}
                  setAlertDismissArray={setAlertDismissArray}
                  setOpenAlarmPlaybook={() => setOpenAlarmPlaybook(1)}
                />
              )
              : <Loading />}
          </div>
          { openConfirmDismissAlert && (
            <LightsAlarmsModal
              setOpenConfirmDismissAlert={setOpenConfirmDismissAlert}
              selectedCustomer={selectedCustomer}
              selectedSite={selectedSite}
              alertObj={alertObj}
              alertDismissArray={alertDismissArray}
              refreshAlertsList={refreshAlerts}
              setLoader={() => setIsLoaded(false)}
            />
          )}
        </>
      </SlideIn>
      {(openAlarmHistory > 0) && (
        <LightsAlarmHistory
          selectedCustomer={selectedCustomer}
          selectedSite={selectedSite}
          selectedNode={selectedNode}
          openModal={openAlarmHistory}
          setOpenModal={setOpenAlarmHistory}
        />
      )}
      {openAlarmPlaybook === 1 && alertObj && (
      <AlarmPlaybook
        alarm={alertObj}
        actions={alarmActions}
        setShowModal={setOpenAlarmPlaybook}
      />
      )}
    </div>
  );
}

export default LightsAlarms;
